const locale = {
  aWholeProductIsFinished:
    "Um produto completo é um produto acabado a ser vendido em seu local. Com as variantes, você pode optar por vender diferentes variações deste produto, como sabor, cor e tamanho. Todos os produtos inteiros e suas variantes teriam cada um seu próprio item de suprimento.",
  accessToken: "Token de Acesso",
  accommodation: "Acomodação",
  account: "Conta",
  accountAlreadyExists: "Este e-mail já está associado a uma conta neste local",
  accountCantSeemToFind:
    "Oh não! Não conseguimos encontrar sua conta. Entre em contato com o gerente do local para obter assistência.",
  accountCode: "Código de Conta",
  accountCodeAddedName: "Código de Conta “{0}“ adicionado.",
  accountCodeAlreadyExist: "O código da conta já existe.",
  accountEmailAdded: "Conta “{0}“ adicionada.",
  accountEmailRemoved: "Conta “{0}“ removida.",
  accountIsActive: "Conta está Ativa",
  accountMapping: "Mapeamento de Conta",
  accountMappingDescription: "Defina e mapeie suas contas para o sistema PouchVENUE.",
  accountName: "Nome da Conta",
  accountNotFound: "Conta não encontrada",
  accountSettings: "Configurações da Conta",
  accountType: "Tipo de Conta",
  accountingSoftware: "Software de Contabilidade",
  accounts: "Contas",
  accrNo: "Conta ref#:",
  actions: "Ações",
  activateInPos: "Ativar no PDV",
  activateOnPos: "Ativar no Ponto de Venda (PDV)",
  activateVariant: "Ativar Variante",
  active: "Ativo",
  activeDateRange: "Intervalo de Datas Ativo",
  actualClosingBalance: "Saldo de Fechamento Real",
  actualQty: "Qtd Real",
  actualStockQty: "Quantidade Real de Inventário",
  add: "Adicionar",
  addANote: "Adicionar nota...",
  addAValidAttributeAndOptions: "Adicione um atributo e opções válidos.",
  addAWholeProduct: "Adicionar um Produto Inteiro",
  addAccount: "Adicionar Conta",
  addAnotherAccount: "Adicionar outra conta",
  addAnotherAttribute: "Adicionar Outro Atributo",
  addAnotherProductOrSupplyItem: "Adicionar outro produto ou item de suprimento",
  addAnotherSupplyItem: "Adicionar outro item de suprimento",
  addCustomQuestion: "Adicionar Pergunta Personalizada",
  addNewAccount: "Adicionar nova conta",
  addNewAccountCode: "Executar Fim do dia",
  addNewAccountCodeName: "Adicionar novo código de conta “{0}”",
  addNewPaymentMethod: "Adicionar Novo Método de Pagamento",
  addNewSupplyItem: "Adicionar Novo Item de Suprimento",
  addNewSupplyItemSelect: "Adicionar Novo Item de Suprimento “{0}”",
  addNewTax: "Adicionar Novo Imposto",
  addNote: "Adicionar Nota",
  addOption: "Adicionar Opção",
  addPaymentMethod: "Adicionar Método de Pagamento",
  addProduct: "Adicionar Produto",
  addProducts: "Adicione produtos ou itens de suprimento neste conjunto de produtos.",
  addThisProduct: "Adicione este produto como um novo produto e item de suprimento.",
  addVariant: "Adicionar Variante",
  addedStock: "Inventário Adicionado",
  adjustQuantity: "Ajustar Quantidade",
  adjustTheSupplyItemsVariant:
    "Ajuste os itens de suprimento e as quantidades para esta variante específica.",
  all: "Tudo",
  allAccountTypes: "Todos os Tipos de Conta",
  allCategories: "todas as Categorias",
  allDevices: "Todos os Dispositivos",
  allDevicesUsed: "Todos os Dispositivos Usados",
  allDiscounts: "Todos os Descontos",
  allGuestProfiles: "Todos os Perfis de Convidados",
  allLocation: "Todos os Locais",
  allLocations: "Todos os Locais",
  allMeasurements: "Todas as Medidas",
  allPaymentMethods: "Todos os Métodos de Pagamento",
  allProducts: "Todos os Produtos",
  allReportsReadyForDownload: "Todos os relatórios prontos para download",
  allRightsReserved: "Todos os direitos reservados.",
  allRoles: "Todas as Funções",
  allSafeToDeleteLocation: "Tudo seguro! Não há produtos com este local.",
  allSelectedAvailablePos: "Todos os produtos selecionados estarão disponíveis no PDV.",
  allSelectedNotPos: "Todos os produtos selecionados não serão exibidos em seu PDV.",
  allShiftsClosed: "Todos os Turnos Fechados",
  allStaff: "Todos os Funcionários",
  allStatus: "Todos os Status",
  allStockLevels: "Todos os Níveis de Inventário",
  allTransactions: "Todas as Transacções",
  allVoucherTypes: "Todos os Tipos de Voucher",
  allVouchers: "Todos os Vouchers",
  allowAccess: "Permitir Acesso",
  allowAccessAndConnect: "Permitir Acesso e Conexão",
  allowGuestWBKeeping: "Permitir que os convidados mantenham suas tags no check-out.",
  allowOrRestrictGuestWBKeeping:
    "Permitir ou restringir convidados de manter suas tags no check-out.",
  allowPostpaidAndLimit: "Permitir Créditos Pós-Pagos e definir limite",
  allowPostpaidInGuestProfile:
    "Permitir que este perfil de convidado faça compras sem créditos. Eles serão cobrados quando fizerem o check-out ndo local.",
  allowThisGuestProfileLimit:
    "Permitir que este perfil de convidado faça compras sem créditos. Eles serão cobrados quando fizerem o check-out ndo local.",
  allowedPaymentMethod: "Métodos de Pagamento Permitidos",
  almostThere: "Quase lá!",
  alreadyExists: "Já existe.",
  amount: "Quantidade",
  and: "e",
  andWouldBe: "e seria",
  anyMoreQuestions: "Mais alguma pergunta? {0}",
  applyFilter: "Aplicar Filtro",
  areYouSureYouWantToDeleteLocation:
    "Tem certeza que deseja excluir {0}? Atualmente, este local tem um valor de inventário de {1}.",
  areYouSureYouWantToDeleteProduct:
    "Tem certeza que deseja excluir {0}? Este item de suprimento possui atualmente {1} {2} em inventário.",
  areYouSureYouWantToDeleteProduct2:
    "Tem certeza que deseja excluir {0}? Ele não estará mais disponível em seu local. As transações e relatórios anteriores não serão afetados.",
  areYouSureYouWantToRemoveVariant:
    "Tem certeza de que deseja remover a variante “{0}”? Seu item de suprimento não seria removido.",
  arrivalDate: "Data de Chegada",
  arrivals: "Chegadas",
  askDeleteGuestProfile: "Excluir perfil de convidado?",
  askDeleteStaffProfile: "Excluir perfil de funcionários?",
  askGuestInfoCheckIn: "Peça informações dos convidados durante o check-in.",
  assemble: "Montar",
  assembleAProduct: "Montar um produto",
  asset: "Recurso",
  assignAProfile: "Atribuir um perfil",
  assignFreeCreditsStaff: "Atribuir créditos grátis para funcionários neste grupo",
  assignVoucherStaff: "Atribuir os vouchers aos funcionários deste grupo",
  assignVoucherToGuest: "Atribuir os vouchers aos convidados deste grupo no momento do registro.",
  assignedRooms: "Quartos/Camas Atribuídos",
  assignedSpace: "Espaço Atribuído",
  attribute: "Atributo",
  attributeAlreadyExists: "O nome do atributo já existe neste produto",
  automaticallyGenerateSku: "Gerar SKU automaticamente",
  availability: "Disponibilidade",
  availabilityOnPos: "Disponibilidade no PDV",
  availableAnytime: "Disponível a qualquer hora",
  availableEveryday: "Disponível todos os dias",
  availableOnPos: "Disponível no Ponto de Venda (POS)",
  averageAmountPerItem: "Méd. Quantidade por Item",
  averageDurationOfStay: "Duração Média da Estadia",
  averageOrderValue: "Valor Médio do Pedido",
  awaitingInviteResponse: "Aguardando resposta ao convite.",
  back: "Voltar",
  banATag: "Banir uma Tag",
  banDetails: "Detalhes da Proibição",
  banList: "Lista de Banimento",
  banTag: "Banir Tag",
  banTagListSettings: "Configurações da List de Tags Proibidas",
  banTags: "Banir Tags",
  bannedBy: "Banida Por",
  bannedTags: "Tags Banidas",
  banningThisTagWillOnlyTakeEffect:
    "Banir esta tag só terá efeito na próxima vez que seus dispositivos forem sincronizados e atualizados (geralmente a cada 5 minutos quando conectado à Internet). Para refletir essas mudanças imediatamente, sincronize e atualize seus dispositivos assim que banir esta tag.",
  basicInfo: "Informação Básica",
  belowParLevel: "Abaixo do Nível Ideal",
  billAmount: "Valor da Conta",
  birthdate: "Data de Nascimento",
  birthday: "Aniversário",
  bookedBy: "Reservado por",
  bookingAmount: "Valor da Reserva",
  bookingDescription: "Acompanhe todas as reservas em seu local.",
  bookingDetails: "Detalhes da Reserva",
  bookingId: "ID da Reserva",
  bookingList: "Lista de Reservas",
  bookingNo: "Núm. Reserva",
  bookingSummary: "Sumário da Reserva",
  bookings: "Reservas",
  bottle: "garrafa",
  browseProducts: "Procurar Produtos",
  browseVouchers: "Procurar Vouchers",
  businessAddress: "Endereço Profissional",
  businessContactNumber: "Número de Contato Comercial",
  businessDay: "Dia Útil",
  businessName: "Nome da Empresa",
  byAssemblingAProduct:
    "Ao montar um produto, você pode criar um produto com vários itens de suprimento, bem como decompor um único item de suprimento com variantes.",
  byCreatingPrinterProfiles:
    "Ao criar perfis de impressora, você pode definir quais produtos imprimir em notas de pedidos para as diferentes estações de seu local, como cozinhas, bares, etc.",
  cancel: "Cancelar",
  cancelBanTag: "Cancelar Proibição de Tag",
  cancelled: "Cancelado",
  cannotBeDeleted: "Não pode ser excluído",
  cannotDeletePartOfComposite: "{0} não pode ser excluído porque faz parte de {1} composto {2}.",
  cantFindDesc:
    "Não consegue encontrar o que está procurando? Tente uma pesquisa ou filtro diferente.",
  capacity: "Capacidade",
  card: "Cartão",
  cash: "Dinheiro",
  categories: "Categorias",
  categoriesDeleted: "{0} categorias excluídas",
  categorizeAndManage: "Categorize e gerencie todas as transações com plano de contas.",
  category: "categoria",
  categoryCreated: 'Categoria "{0}" criada.',
  categoryDeleted: 'Categoria "{0}" excluída.',
  categoryList: "Lista de Categorias",
  categoryName: "Nome da Categoria",
  categoryNameAlreadyExist: "O nome da categoria já existe.",
  categoryReport: "Relatório de Categoria",
  categoryUpdated: 'Categoria "{0}" atualizada.',
  change: "Mudar",
  changePassword: "Mudar Senha",
  changePhoto: "Mudar Foto",
  changesSaved: "Alterações salvas.",
  changingCurrencyWontAffect:
    "Alterar a moeda não afetará o valor do preço de seus produtos, apenas seus símbolos de moeda.",
  chargeTaxesOnThisProduct: "Cobrar impostos sobre este produto",
  chartOfAccounts: "Plano de Contas",
  chartOfAccountsDescription: "Atualize e gerencie seu plano de contas.",
  checkInDate: "Data de Check-in",
  checkInDetails: "Detalhes do Check-in",
  checkInQuestions: "Perguntas de Check-in",
  checkInSettings: "Configurações de Check-in",
  checkIns: "Check-ins",
  checkOutDetails: "Detalhes de Check-out",
  checkOutTheGuide: "Confira o guia para conectar integrações.",
  checkOuts: "Check-outs",
  checkStocks: "Verificar Inventários",
  checkYourEmail: "Verifique seu e-mail",
  checkYourEmailAt: "Verifique seu e-mail em {0} para concluir a configuração da sua conta.",
  checkYourEmailOrAdmin: "Verifique seu e-mail ou entre em contato com o administrador.",
  checkbox: "Caixa de Seleção",
  checkedIn: "Check-in realizado",
  checkedInBy: "Check-in realizado por",
  checkedInDate: "Data de Check-in",
  checkedOut: "Check-out realizado",
  checkedOutBy: "Check-out realizado por",
  checkedOutDate: "Data de Check-Out",
  checkedOutOn: "Check-out realizado em",
  checkinId: "ID de Check-in",
  checkingYourInternet: "Verificando sua conectividade de Internet ou dados.",
  checkout: "Check-out",
  chooseAPassword: "Use uma senha forte que você não usa em nenhum outro lugar.",
  chooseAReason: "Escolha um motivo",
  chooseASimpleOrMultipleItem: "Escolha um ou vários itens de suprimento para criar um produto.",
  chooseAVenue: "Escolha um local",
  chooseAtleast1Location: "Escolha pelo menos 1 local",
  chooseCategoryColor: "Escolha uma cor de categoria",
  chooseColumns: "Escolha quais colunas você gostaria de ver neste relatório.",
  chooseInformation: "Escolha quais informações incluir no detalhamento de vendas.",
  chooseLocationForThisStaff: "Escolha a designação de local para este funcionário",
  choosePassword: "Escolha a senha",
  choosePaymentMethod: "Escolher Método de Pagamento",
  chooseProductsCanBeDiscounted:
    "Escolha quais produtos de seu inventário podem receber descontos.",
  chooseRole: "Escolha as funções para este funcionário",
  chooseTheLocationWhereThisProduct:
    "Escolha os locais onde este produto é vendido e onde os suprimentos são deduzidos.",
  chooseVouchers: "Escolha os Vouchers",
  cityStateCountry: "Cidade, Estado, País, Código Postal",
  clear: "Limpar",
  clearAllFilters: "Limpar todos os filtros",
  clearDownloads: "Limpar Downloads",
  clearedTags: "Tags Apagadas",
  clearedUnusedAndExpiredCredits: "Créditos Liberados, Não Usados e Expirados",
  clearedUnusedAndExpiredCreditsMessage:
    "Para créditos que foram carregados, mas foram compensados, não reembolsáveis ou expirados.",
  clearedUnusedAndExpiredCreditsPlaceholder: "[xxxx] - Outras Receitas (expiração)",
  clearedUnusedExpiredCredit: "Outras Receitas (expiração)",
  clickHereToRetry: "Clique aqui para tentar novamente.",
  closePreview: "Fechar Preview",
  closeReceiptPreview: "Fechar Visualização do Recibo",
  code: "Código",
  coffeeBeans: "grãos de café",
  columns: "Colunas",
  combineYourProducts: "Combine seus produtos para serem vendidos como um conjunto.",
  communityGuidelines: "diretrizes da comunidade",
  companyDetails: "Detalhes da Companhia",
  completeStocktake: "Verificação de Inventário Finalizada",
  completeStocktakeConfirmation: "Finalizar {0}?",
  completeSubscription: "Assinatura Completa",
  completeYourSubscription: "Conclua sua inscrição para acessar seu local",
  completed: "Finalizado",
  completedBy: "Finalizado por",
  completedOn: "Finalizado em",
  composite: "Composto",
  composition: "Composição",
  compositionGuide: "Guia de Composição",
  confirmPassword: "Confirmar a senha",
  confirmed: "Confirmado",
  connectAndInstall: "Conecte e Instale",
  connected: "Conectado",
  contactNumber: "Número de Contato",
  contactUs: "contato",
  contactUs2: "Contato",
  continue: "Continuar",
  continueAs: "Continue como {0}",
  continueBanningThisTag: "Continuar banindo esta tag?",
  continuingOnThisPageWillLogout: "Continuar nesta página fará o logout de sua sessão atual.",
  copied: "Copiado!",
  copyLink: "Copiar Link",
  copyrightYear: "Copyright © 2024",
  costOfGoodsSold: "Custo das Mercadorias Vendidas (CMV)",
  costOfGoodsSoldMessage: "A conta padrão para o custo de um suprimento depois de vendido.",
  costOfGoodsSoldPlaceholder: "[xxxx] - CMV/CMV",
  country: "País",
  createALocation: "Criar um Local",
  createAccount: "Criar Conta",
  createAndManageProducts: "Crie e gerencie produtos que podem ser vendidos em seus locais",
  createAndManageRawMaterials:
    "Crie e gerencie itens de matéria-prima e seus inventários de inventário para cada local.",
  createAndManagerDiscounts: "Crie e gerencie seus descontos com produtos com desconto",
  createAndManagerVouchers: "Crie e gerencie seus vouchers que serão usados para resgatar produtos",
  createCategoriesToLink: "Crie categorias para vincular e categorizar seus produtos",
  createCategory: "Criar Categoria",
  createDiscountPage: "Criar Página de Desconto",
  createLocation: "Criar Local",
  createLocationToStoreYourProducts: "Crie um local para armazenar seus produtos.",
  createNewCategory: "Criar Nova Categoria",
  createNewDiscount: "Criar Novo Desconto",
  createNewGuestProfile: "Criar Novo Perfil de Convidado",
  createNewLocation: "Criar Novo Local",
  createNewProduct: "Criar Novo Produto",
  createNewProductSet: "Criar Novo Conjunto de Produtos",
  createNewProfile: "Criar Novo Perfil",
  createNewStaffProfile: "Criar Novo Perfil de Funcionário",
  createNewStocktake: "Criar Nova Verificação de Inventário",
  createNewSupplyItem: "Criar Novo Item de Suprimento",
  createNewVoucher: "Criar Novo Voucher",
  createNote: "Criar Nota",
  createPrinterProfile: "Criar Perfil de Impressora",
  createProduct: "Criar Produto",
  createProductSet: "Criar Conjunto de Produtos",
  createStaffProfile: "Criar Perfil de Funcionário",
  createStaffWhoCan:
    "Crie um funcionário que pode recarregar créditos, registrar convidados e vender em seus locais.",
  createTheProduct: "Crie os produtos para vender em seu local.",
  createVoucherPage: "Criar Página de Voucher",
  createVouchersForGuest: "Crie vouchers para seus convidados e funcionários usarem!",
  createYourOwnIntegration: "Crie suas próprias integrações com PouchCONNECT",
  createYourVenue: "Criar Seu Local",
  createdSupplyItem: "Criar Item de Suprimento",
  credit: "Crédito",
  creditAutomaticallyExpires: "Os créditos expiram automaticamente após o check-out",
  creditBalance: "Saldo de Créditos",
  creditLimits: "Limites de Créditos",
  creditSettings: "Configurações de Crédito",
  credits: "Créditos",
  creditsDescription:
    "Atribua créditos grátis para convidados neste grupo e defina seu limite de crédito pós-pago.",
  creditsExpiration: "Vencimento de Créditos",
  creditsExpirationDescription:
    "Defina os créditos para expirar quando um convidado fizer check-out.",
  creditsExpirationOnCheckout: "Vencimento dos Créditos no Check-out",
  creditsLiabilityAccount: "Conta de Garantia de Créditos",
  creditsLiabilityAccountMessage:
    "A conta de reserva para todos os créditos que são carregados em uma tag antes das vendas.",
  creditsLiabilityAccountPlaceholder: "[xxxx] - Garantia/Créditos",
  creditsNeverExpires: "Os créditos nunca expiram após o check-out.",
  creditsWouldNeverExpire: "Os créditos nunca expiram quando um convidado está fazendo o check-in.",
  currency: "Moeda",
  currencyAndFormat: "Moeda e Formato",
  currentGuestProfiles: "Perfis de Convidados Atuais",
  currentPassword: "Senha Atual",
  currentPasswordAndNewPasswordMatched: "A senha atual e a nova são correspondidas",
  custom: "Personalizado",
  customDateTime: "Data e Intervalo de Tempo Personalizados",
  customFields: "Campos Personalizados",
  customQuestion: "Pergunta Personalizada",
  customQuestions: "PerguntasPersonalizadas",
  customTax: "Imposto Personalizado",
  customerBehaviourOverTime: "Comportamento do Cliente Horas Extras",
  customizeReceipt: "Personalize cada seção do seu recibo.",
  customizeSectionReceipt: "Personalize as seções do seu recibo.",
  dashboard: "Painel",
  date: "Data",
  dateAndTimeOfTransaction: "Data e Hora da Transação",
  dateBanned: "Data de Banimento",
  dateCreated: "Data de Criação",
  dateIssuedWithColonSymbol: "Data de Emissão:",
  dateModified: "Data Modificada",
  dateOfCheckin: "Data de Check-in",
  dateOfCheckout: "Data de Check-out",
  dateOfRegistration: "Data de Registro",
  dateRange: "Intervalo de Datas",
  day: "dia",
  days: "dias",
  daysAfterCheckout: "dias após o check-out",
  debitCredit: "Cartão de Débito / Crédito",
  default: "Padrão",
  defaultAccountForTaxes: "A conta padrão para impostos cobrados para cada produto.",
  defaultFields: "Campos Padrão",
  defaultQuestions: "Perguntas Padrão",
  defaultRevenueAccount: "Conta de Receita Padrão",
  defaultRevenueAccountMessage: "A conta padrão para todas as receitas de vendas.",
  defaultTax: "Imposto Padrão",
  defectiveTag: "Tag Defeituosa",
  defectiveTags: "Tags Defeituosas",
  delete: "Excluir",
  deleteAProduct: "Excluir Produto",
  deleteAProduct2: "Excluir Produto?",
  deleteAProductSet: "Excluir Conjunto de Produtos",
  deleteCategories: "Excluir {0} categorias",
  deleteCategory: "Excluir categoria",
  deleteCurrentlyBeingUsed:
    "{0} está sendo usado em alguns conjuntos de produtos. Para continuar, remova este produto dos seguintes conjuntos de produtos",
  deleteDiscount: "Excluir Desconto",
  deleteGuestProfile: "Excluir Perfil de Convidado",
  deleteLocation: "Excluir Local",
  deleteLocationNote: "Nota: Suas transações anteriores com este local serão mantidas.",
  deleteName: 'Excluir "{0}"?',
  deleteNote: "Apagar Nota",
  deletePaymentMethod: "Excluir Método de Pagamento",
  deletePrinterProfile: "Excluir Perfil de Impressora",
  deleteProduct: "Excluir {0}?",
  deleteProductSet: "Excluir Conjunto de Produtos?",
  deleteProducts: "Excluir {0} Produtos?",
  deleteProducts2: "Excluir {0} Produtos",
  deleteQuestion: "Excluir Pergunta",
  deleteSelectedCategories: "Excluir categorias selecionadas",
  deleteSelectedCategory: "Excluir categoria selecionada",
  deleteSelectedProduct: "Excluir produto selecionado",
  deleteSelectedProducts: "Excluir produtos selecionados",
  deleteStaff: "Excluir Funcionário",
  deleteStaffProfile: "Excluir Perfil de Funcionário",
  deleteSupplyItem: "Excluir Item de Suprimento",
  deleteTax: "Excluir Imposto",
  deleteThis: "Excluir este {0}",
  deleteThisMultiple: "Excluir {0} {1}?",
  deleteThisQuestion: "Excluir esta pergunta?",
  deleteVoucher: "Excluir Voucher",
  deleted: "Excluído",
  deletedPaymentMethod: 'Método de pagamento "{0}" excluído.',
  deletedTax: 'Imposto "{0}" excluído.',
  deletingLoading: "Excluindo ...",
  deletingName: "Excluindo {0}",
  deletingThisLocation:
    "Excluir este local também removeria os saldos de inventário de todos os itens de suprimento. Essa ação não pode ser desfeita.",
  departure: "Saída",
  departureDate: "Data de saída",
  describeThisProduct: "Descreva este produto ...",
  describeThisProductSet: "Descreva este conjunto de produtos",
  description: "Descrição",
  deviceId: "ID do Dispositivo",
  deviceImei: "IMEI do Dispositivo",
  deviceImeiSerial: "IMEI / Serial do Dispositivo",
  deviceInfo: "Informação do Dispositivo",
  deviceList: "Lista des Dispositivos",
  deviceMac: "Endereço MAC do dispositivo",
  deviceMacAddress: "Endereço MAC do dispositivo",
  deviceName: "Nome do Dispositivo",
  deviceNameAlreadyExists: "Este nome de dispositivo já existe",
  deviceNameUpdated: "Dispositivo “{0}” atualizado.",
  deviceSerialNumber: "Número de Série do Dispositivo",
  devices: "Dispositivos",
  difference: "Diferença",
  disableSellingOnPos: "Desativar venda em PDV",
  discard: "Descartar",
  discardChanges: "Descartar Mudanças",
  discardWristbandKeepingSettings: "Descartar Configurações de Manutenção da Pulseira",
  disclaimerCategoryReport:
    "ISENÇÃO DE RESPONSABILIDADE: Pode haver produtos com várias categorias atribuídas a eles, o que resultará na contagem dupla em cada item de linha.",
  disconnect: "Desconectar",
  discount: "Desconto",
  discountApplied: "Desconto Aplicado",
  discountCanBeApplied: "Este desconto pode ser aplicado em seu local.",
  discountCreated: 'Desconto "{0}" criado.',
  discountDeleted: 'Desconto "{0}" excluído.',
  discountName: "Nome do Desconto",
  discountNameAlreadyExist: "Este nome de desconto já existe.",
  discountRateOrValue: "Taxa ou Valor de Desconto",
  discountReport: "Relatório de Desconto",
  discountType: "Tipo de Desconto",
  discountUpdated: 'Desconto "{0}" atualizado.',
  discountableProducts: "Produtos com Desconto",
  discounts: "Descontos",
  discountsPage: "Página de Descontos",
  discrepancy: "Discrepância",
  displayCategoryInPos: "Exibir esta categoria nos dispositivos PDV.",
  distribution: "Distribuição",
  done: "Feito",
  dontSellOnPos: "Não vender no PDV",
  dontWorry: "Não se preocupe!",
  download: "Download",
  downloading: "Fazendo download...",
  dropdown: "Suspenso",
  duplicateOption: "Opção duplicada",
  eWallet: "E-wallet",
  easierGuestReg: "Registro e Check-ins mais Fáceis de Convidados",
  edit: "Editar",
  editAccessToken: "Editar Token de Acesso",
  editAccount: "Editar Conta",
  editCategory: "Editar Categoria",
  editDevices: "Editar Dispositivo",
  editDiscountsPage: "Editar Página de Desconto",
  editGuestProfile: "Editar Perfil de Convidado",
  editLocation: "Editar Local",
  editLocations: "Editar Locais",
  editNewCategory: "Editar Nova Categoria",
  editNote: "Editar Nota",
  editPaymentMethod: "Editar Método de Pagamento",
  editPaymentMethods: "Editar Métodos de Pagamento",
  editPrinter: "Editar Perfil de Impressora",
  editProduct: "Editar Produto",
  editProductSet: "Editar Conjunto de Produtos",
  editQuestions: "Editar Perguntas",
  editStaff: "Editar Funcionário",
  editStaffPage: "Editar Página de Funcionário",
  editStaffProfilePage: "Editar Página de Perfil de Funcionário",
  editSupplyItem: "Editar Item de Suprimento",
  editTax: "Editar Imposto",
  editVariants: "Editar Variantes",
  editVoucherPage: "Editar Página de Voucher",
  editYourAccountPreferences: "Edite suas preferências de conta, endereço de e-mail e senha.",
  egAppliesToGuests: "Por exemplo, aplica-se a convidados com 60 anos ou mais",
  egSeniorDiscount: "por exemplo, Desconto Sênior",
  egSizeFlavor: "por exemplo, Tamanho, Sabor",
  eightCharactersMinimum: "8 caracteres no mínimo",
  eitherYouHavePoorConnection:
    "Ou você tem uma conectividade de rede ruim, seu acesso foi negado ou algo deu errado do nosso lado.",
  email: "E-mail",
  emailAddress: "Endereço de E-mail",
  emailAlreadyInVenue: "Este e-mail já está associado a uma conta neste local.",
  emailDoesntHaveRecord:
    "O e-mail que você digitou não corresponde ao nosso registro, verifique e tente novamente.",
  enableSellingOnPos: "Habilitar venda no PDV",
  end: "Fim",
  endDay: "Finalizar Dia",
  endOfDay: "Fim de Dia",
  endOfDayChecklist: "Checklist de Fim de Dia",
  endOfDayReports: "Relatórios de Fim de Dia",
  enterEachOption: "Insira cada opção separada por uma vírgula",
  enterEmailToLogin: "Digite seu endereço de e-mail para se inscrever ou fazer login.",
  enterYourEmailAddress: "Insira o seu endereço de email",
  enterYourPassword: "Coloque sua senha",
  enteredPasswordIncorrect: "A senha inserida está incorreta.",
  equity: "Patrimônio",
  errorLoadingColors: "Erro ao carregar cores. Por favor, tente novamente mais tarde.",
  example1: "Exemplo 1",
  example1AssembleProduct:
    "{0}: Um Café Latte precisaria de {1}, {2} e {3}. O inventário seria deduzido dos grãos de café, leite e açúcar quando um Café Latte é comprado.",
  example2: "Exemplo 2",
  example2AssembleProduct:
    "{0}: O vinho pode ser vendido como {1} ou {2}. Selecione o vinho como o item de suprimento, a seguir crie as variantes “garrafa” e “taça” e ajuste a quantidade do item por variante, de forma que seja deduzido do mesmo item de suprimento de forma adequada.",
  expStockQty: "Exp. Qtd em inventário",
  expectedEndingBalance: "Saldo Final Esperado",
  expectedQty: "Qtd Esperada",
  expense: "Despesa",
  expired: "Expirado",
  export: "Exportar",
  exportCheckinDetails: "Exportar Detalhes de Check-in",
  exportRegisterReport: "Exportar Relatório de Registro",
  exportTransactions: "Exportar Transações",
  exportXlsx: "Exportar como XLSX",
  extendThePower: "Amplie o poder do seu PouchVENUE com os melhores add-ons.",
  failed: "Falha",
  failedToLoad: "Falha ao carregar.",
  feedback: "Feedback",
  feelFreeToContactPouchnation: "Sinta-se à vontade para nos contatar em PouchNATION.",
  filters: "Filtros",
  firstName: "Primeiro Nome",
  fixedAmount: "Quantia Fixa",
  fixedAmountRange: "Valor fixo deve ser entre 1-9,999,999",
  footerSection: "Seção de Rodapé",
  forApproval: "Para Aprovação",
  forceBanned: "Detalhes de Check-out Forçado e Banimento",
  forceCheckOutBy: "Check-out forçado por",
  forceCheckout: "Forçar Check-out",
  forceCheckoutDetails: "Detalhes de Check-out Forçado",
  forceCheckoutName: "Forçar Check-out {0}?",
  forcedCheckedOutBy: "Check-out forçado por",
  formatting: "Formatação",
  freeCredits: "Créditos Gratuitos",
  freeCreditsAreCreditsThatAreFreeOfCharge:
    "Créditos Gratuitos são créditos sem custos. Esses créditos são",
  freeCreditsHoverTooltip:
    "Os créditos gratuitos não são reembolsáveis e devem ser priorizados e usados primeiro no momento do pagamento.",
  freeCreditsIssued: "Créditos Gratuitos Emitidos",
  freeCreditsLiabilityAccount: "Conta de Garantia de Créditos Gratuitos",
  freeCreditsLiabilityAccountMessage:
    "Custo dos créditos gratuitos emitidos para convidados, uma vez usados.",
  freeCreditsLiabilityAccountPlaceholder: "[xxxx] - Garantia/Créditos Gratuitos",
  freeCreditsRemoved: "Créditos Gratuitos Removidos",
  freeTrial: "Teste Gratuito de 30 dias!",
  gender: "Gênero",
  general: "Geral",
  generalSettings: "Configurações Gerais",
  generatingReport: "Gerando Relatório ...",
  getNotifications: "Receber Notificações",
  getStarted: "Iniciar",
  glass: "copo",
  glossary: "Glossário",
  goToXDashboard: "Ir para o {0} Painel de Controle",
  grossProfit: "Lucro Bruto",
  guest: "Convidado",
  guestCheckedOutAndBanned: "O convidado fez check-out e a tag foi banida.",
  guestDetails: "Detalhes do Convidado",
  guestInfoNotYetSync: "As informações do convidado ainda não foram sincronizadas",
  guestInformationYouCollect: "Informações do convidado que você coleta no momento do check-in.",
  guestList: "Lista de Convidados",
  guestName: "Nome do Convidado",
  guestNameAndOrTagId: "Nome do Convidado e/ou ID da Tag",
  guestOrTagStatus: "Status do Convidado ou Tag",
  guestProfile: "Perfil de Convidado",
  guestProfileDeleted: "Perfil de convidado excluído.",
  guestProfileDescriptionPlaceholder:
    "por exemplo, “Visitantes que fazem check-in na praça de alimentação“",
  guestProfileList: "Lista de Perfis de Convidados",
  guestProfileName: "Nome do Perfil do Convidado",
  guestProfileNameCreate: "Perfil de convidado “{0}” criado.",
  guestProfileNamePlaceholder: "por exemplo, “Convidados da praça de alimentação“",
  guestProfiles: "Perfis de Convidados",
  guestQuestions: "Perguntas de Convidados",
  guestSince: "Convidado desde {0}",
  guestSpecialRequest: "Pedidos Especiais de Convidados",
  guestTap: "Tap de Convidado",
  guestWithColonSymbol: "Convidado:",
  guests: "Convidados",
  guestsCheckedIn: "Convidados Fizeram Check In",
  guestsNewBalance: "Novo Saldo de Convidado",
  guestsPreviousBalance: "Saldo Anterior de Convidado",
  guestsPreviousBalanceTotalPaidAndNewBalance:
    "Saldo Anterior de Convidado, Total Pago e Novo Saldo",
  headOfBooking: "Head de Reserva",
  headerSection: "Seção do Cabeçalho",
  hello: "Olá",
  hiHeresWhatsHappening: "Olá, {0}, aqui está o que está acontecendo com {1}",
  hidden: "Oculto",
  hide: "Ocultar",
  hideDetails: "Ocultar Detalhes",
  hideInPos: "Esconder no PDV",
  hideVariant: "Ocultar Variante",
  holdingAccountForToppedUpItems:
    "A conta de reserva para todos os créditos que são carregados em uma tag antes das vendas",
  hour: "hora",
  hours: "horas",
  id: "ID",
  ifYouLeaveThisPage:
    "Se você sair desta página, todas as alterações não salvas serão perdidas. Tem certeza de que deseja descartar as alterações?",
  ifYoudLikeToJustStopSellingProduct:
    "Se você deseja simplesmente parar de vender um produto em seu PDV, simplesmente desative-o desligando “Vender em PDV”.",
  imageTypeSize: "JPG GIF ou PNG. Tamanho máximo de 1 MB",
  in: "em",
  inProgress: "Em Progresso",
  inStock: "Em Inventário",
  inactive: "Inativo",
  include: "Incluir",
  indeterminate: "indeterminado",
  information: "Informação",
  informationToCollect: "Informações a serem coletadas no registro do convidado.",
  integrateDesc: "Integre com poderosos sistemas de gerenciamento de propriedade e muito mais.",
  integrationSettingDesc:
    "Conecte-se com outros aplicativos de negócios e produtividade para ajudar a crescer e otimizar seus negócios.",
  integrationSettings: "Configurações de Integração",
  integrations: "Integrações",
  invalidFileType: "Tipo de arquivo inválido. Os tipos de arquivo permitidos são JPG ou PNG.",
  invalidOptions: "Opção (ões) inválida (s).",
  invalidTimeRange: "Intervalo de Tempo Inválido",
  invalidToken: "Token Inválido",
  inventory: "Inventário",
  inventoryAccount: "Conta de Inventário",
  inventoryAccountMessage: "Para itens de suprimentos comprados e inventário antes de vender.",
  inventoryAccountPlaceholder: "[xxxx] - Patrimonio/Inventário",
  inventoryGainLossAccount: "Conta de Ganho/Perda de Inventário",
  inventoryGainLossAccountMessage:
    "Para discrepâncias na verificação de inventário, ajustes de inventário, etc.",
  inventoryGainLossAccountPlaceholder: "[xxxx] - Ganho/Perda nas Vendas de Inventário",
  inventoryManagement: "Gestão de inventário",
  inventoryMovement: "Movimento de Inventário",
  inventoryMovementPage: "Página de Movimento de Inventário",
  inventoryReport: "Relatório de Inventário",
  inventoryValue: "Valor de Inventário",
  invitationHasBeenSentTo: "O convite foi enviado para {0}.",
  inviteNewStaff: "Convidar Novo Funcionário",
  inviteYourVenueStaff: "Convide o funcionário de seu local, gerencie sua função e acesse seu PDV",
  isAreserveWord: "é uma palavra de reserva.",
  issuanceLimit: "Limite de Emissão",
  issue: "Emitir",
  issueFreeCredits: "Emitir Créditos Gratuitos",
  issuedBy: "Emitido por",
  issuedOn: "Emitido em",
  item: "Item",
  itemName: "Nome do Item",
  itemTax: "Imposto do Item",
  items: "Itens",
  itemsSold: "Itens Vendidos",
  lastIssuedTo: "Última Emissão Para",
  lastModifiedBy: "Modificado pela Última Vez por",
  lastModifiedOn: "Última Modificação em",
  lastName: "Último Nome",
  lastPurchasedProduct: "Último Produto Adquirido",
  lastSync: "Última Sincronização",
  lastSynced: "Última sincronização em {0}",
  lastVisitedLocation: "Último Local Visitado",
  learnMore: "Saber Mais",
  legalBusinessName: "Razão Social",
  letsGetStarted: "Vamos começar",
  liability: "Responsabilidade",
  limit: "Limite",
  limitVoucherPerPerson: "Limite de voucher emitido por pessoa",
  linkAccountingItem: "Link de Item Contábil",
  linkWithMewsAccountingItems: "Link com Itens de Contabilidade do MEWS",
  loading: "Carregando...",
  location: "Local",
  locationDesignation: "Designação do Local",
  locationName: "Nome do Local",
  locationNameAlreadyExist: "Este local já existe. Escolha um nome diferente.",
  locationNameCreated: 'Local "{0}" criado.',
  locationNameDeleted: 'Local "{0}" excluído.',
  locationNameUpdated: 'Local "{0}" atualizado.',
  locationVoucherRedeemed: "Escolha os locais onde este voucher será resgatado.",
  locations: "Locais",
  locationsList: "Lista de Locais",
  logIn: "Login",
  loginHasEnded: "Sua sessão de login já terminou. Por favor faça login novamente.",
  loginSessionEnded: "Sessão de Login Encerrada",
  logout: "Sair",
  looksLikeAlreadyInSystem: "Parece que você já está em nosso sistema!",
  looksLikeYouEncounteredSomething:
    "Parece que você encontrou um erro, nós rastreamos esses erros automaticamente. Enquanto isso, tente atualizar.",
  looksLikeYouHaveNoLocationYet: "Parece que você ainda não tem locais.",
  lostTag: "Tag perdida",
  lostTags: "Tags Perdidas",
  lowOrOutOfStock: "Inventário Baixo ou Sem",
  lowParLevel: "Nível Par Baixo",
  lowerCaseNoneRefundable: "não reembolsável",
  lowerCaseTopUpCredits: "créditos de recarga",
  mailingList: "Lista de Mala Direta",
  manageAccountNotificationStockLevel:
    "Gerenciar contas para notificar quando um item cai para seu nível de alerta.",
  manageAndAssignQuestions:
    "Gerenciar e atribuir perguntas de check-in, créditos gratuitos e vouchers para diferentes perfis de convidados.",
  manageBusinessInfo: "Gerenciar Informações Comerciais",
  manageEmailNotif: "Gerenciar notificações por e-mail enviadas para você e seus funcionários.",
  manageHowVenueTax:
    "Gerencie como seu local cobra impostos, dependendo da sua região. Consulte um especialista em impostos para entender suas obrigações fiscais.",
  manageImportantDetailsSettings:
    "Gerencie os detalhes e regras importantes do seu local, como configurações do local, configurações de pagamento e crédito, configurações de check-in e configurações de sua conta.",
  manageInventory: "Gerenciar Inventário",
  manageInventoryAndProducts: "Gerenciar o inventário e produtos deste conjunto.",
  manageInventoryAndSupply: "Gerenciar o inventário e itens de suprimento deste produto.",
  manageNotifcations: "Gerencie alertas de e-mail e notificações enviadas para seus funcionários.",
  managePlacesYouStock: "Gerencie os locais em que você armazena o inventário e vende produtos",
  manageReceipts: "Gerencie a aparência e as informações de seus recibos.",
  manageSupplyItems: "Gerenciar Itens de Suprimentos",
  manageThePosDevicesVenue:
    "Gerenciar dispositivos PDV que estão logados e estão sendo usados em seu local",
  manageTheStocksPerLocation: "Gerenciar os inventários por local deste item de suprimento.",
  manageToppedUpCredits:
    "Gerenciar créditos recarregados, créditos gratuitos, créditos pós-pagos e créditos expirados.",
  manageVariants: "Gerenciar Variantes",
  manageYourAccountsSales:
    "Gerencie suas contas com relação a vendas, custo de mercadorias, impostos sobre produtos ou descontos.",
  manageYourStaffProfileDescription:
    "Gerencie seus funcionários, suas funções, designações e perfil de funcionários a que pertencem.",
  manageYourVenueSetting: "Gerencie as configurações do local, como impostos e moeda.",
  manageYourVenueSubscription:
    "Gerencie a assinatura, as informações de cobrança e as faturas do seu local.",
  manageYourVenueTax: "Adicione e gerencie como seu local cobra impostos.",
  manager: "Gerente",
  managerAuthorization: "Autorização do Gerente",
  mapAndManageAccounts:
    "Mapeie e gerencie suas contas padrão para cada processo de negócios PouchVENUE.",
  mapAndManageAccountsLink: "Mapeie e gerencie suas contas em {0} para uma contabilidade adequada",
  mapYourAccounts: "Mapeie suas contas para ativos de inventário, despesas de discrepância, etc.",
  margin: "Margem",
  markUp: "Lucro",
  maximumLengthCharacters: "Máximo de {0} caracteres",
  maximumValueAllowed: "O valor máximo permitido é 20.000.000",
  maximumValueIs: "O valor máximo é ",
  measurements: "Medidas",
  milk: "leite",
  minOfNCharacters: "Mínimo de {0} caracteres",
  minimum: "Mínimo",
  minimumCharactersAllowed: "Mínimo de {0} caracteres permitidos",
  minimumOf: "Mínimo de 2 itens necessários.",
  minimumValueIs: "O valor mínimo é ",
  minute: "minuto",
  minutes: "minutos",
  missedCheckIn: "Check-in Perdido",
  missedCheckOut: "Check-out Perdido",
  mixed: "Misto",
  mobileNumber: "Número de Celular",
  month: "mês",
  months: "meses",
  monthsAfterCheckout: "meses após o check-out",
  mostPurchasedProduct: "Produto Mais Comprado",
  mostVisitedLocation: "Localização Mais Visitada",
  movement: "Movimento",
  nOptions: "{0} opções",
  nTon: "{0} a {1}",
  name: "Nome",
  nameAdded: "“{0}” adicionado.",
  nameYourProduct: "Nomeie seu produto ...",
  nameYourProductSet: "Nomeie seu conjunto de produtos ...",
  nameYourSupplyItem: "Nomeie seu item de suprimento",
  nationality: "Nacionalidade",
  needHelp: "Precisa de ajuda?",
  netWeightN: "Peso líquido {0}",
  new: "Novo",
  newBalance: "Novo Saldo",
  newGuests: "Novos Convidados",
  newInviteLink: 'Novo link de convite foi enviado para "{0}"',
  newPassWord: "Nova Senha",
  newProduct: "Novo Produto",
  newProductSet: "Novo Conjunto de Produtos",
  newQuantity: "Nova Quantidade",
  newToPouchNation: "Novo na PouchNATION?",
  no: "Não",
  noAccountsAvailable: "Nenhuma Conta Disponível",
  noAccountsYet: "Ainda Sem Contas",
  noAvailableCategories: "Sem Categorias Disponíveis",
  noAvailableGuestProfile: "Nenhum Perfil de Convidado disponível",
  noAvailableLocations: "Sem locais disponíveis",
  noAvailableLocationsYet: "Ainda não há locais disponíveis.",
  noAvailablePaymentMethodYet: "Nenhum método de pagamento disponível ainda.",
  noAvailableStaff: "Nenhum Funcionário Disponível",
  noBannedTagsYet: "Nenhuma tag banida ainda.",
  noBookingsYet: "Ainda não há reservas.",
  noCheckInsInDateRange: "Não houve check-ins neste intervalo de datas.",
  noData: "Sem Dados",
  noDeviceAvailable: "Nenhum Dispositivo Disponível",
  noDevicesFound: "Nenhum dispositivo encontrado",
  noEndDate: "Sem data de término",
  noLocation: "Sem Local",
  noLocationAvailable: "Nenhum Local Disponível",
  noNotesFound: "Nenhuma Nota Encontrada",
  noOfGuests: "No. de Convidados",
  noOfNights: "No. de Noites",
  noProductsAvailable: "Nenhum produto disponível",
  noProductsSelected: "Nenhum produto selecionado ainda.",
  noRecordsOf: "Sem registros de",
  noRemainingBalance: "Sem Saldo Restante",
  noReportsFound: "Nenhum relatório encontrado.",
  noResultInSearch: "Não encontramos nenhum resultado para sua pesquisa.",
  noResults: "Sem Resultados",
  noResultsFound: "Nenhum Resultado Encontrado",
  noResultsMatching: "Nenhum resultado correspondente",
  noResultsMatchingX: "Nenhum resultado corresponde a “{0}”",
  noReturnCreditsInDateRange: "Não houve devolução de créditos neste intervalo de datas.",
  noSalesInDateRange: "Não houve vendas neste intervalo de datas.",
  noSearchResultsForName: "Nenhum resultado de pesquisa para {0}.",
  noStocks: "Sem Inventário",
  noTagUid: "Sem UID de Tag",
  noTagsFound: "Nenhuma tag encontrada.",
  noVoucherAvailable: "Nenhum Voucher Disponível",
  noVoucherMovement: "Nenhum movimento de voucher.",
  noVouchersSelectedYet: "Nenhum voucher selecionado ainda.",
  nonRefundable: "não reembolsável",
  notCheckedIn: "Não Fez Check-in",
  notSellingOnPos: "Não Vendendo no PDV",
  note: "nota",
  notePreviousTransaction: "Nota: Suas transações anteriores com esses produtos serão mantidas.",
  noteTransactionStaff: "Nota: Suas transações anteriores com este funcionário serão mantidas.",
  notedByOn: "Notado por {0} em {1}",
  notes: "Notas",
  notificationSettings: "Configurações de Notificação",
  notifications: "Notificações",
  nthGuestWhoUseThisTag: "{0} convidado que usou esta tag.",
  nthStaffWhoUsedThisTag: "{0} funcionários que usaram esta tag.",
  nthUse: "{0} usam",
  numberOfGuests: "nº de Convidados",
  numberOfNights: "# de Noites",
  offline: "Offline.",
  ok: "Ok",
  okay: "Ok",
  onPos: "No PDV",
  onceYouBanATag:
    "Depois de banir uma tag, essa tag não pode ser usada para transações dentro do seu local.",
  onceYouCompleteStocktake:
    "Depois de concluir uma verificação de inventário, todas as quantidades reais dos itens de suprimento serão ajustadas de acordo. Essa ação não pode ser desfeita.",
  open: "Abrir",
  openReceiptPreview: "Visualizar Recibo Aberto",
  openWindow: "Abrir Janela para Locais",
  openingBalance: "Saldo Inicial",
  optionNameAlreadyExists: "O nome da opção já existe",
  options: "Opções",
  orNo: "OU#",
  orderQty: "Qtd Pedidos",
  original: "Original",
  other: "Outro",
  others: "Outros",
  overview: "Visão Geral",
  pairedTagOnCheckin: "Tag Emparelhada no Check-in",
  pairedTags: "Tags Emparelhadas",
  parLevel: "Nível Par",
  password: "Senha",
  passwordDoesNotMatch: "As senhas inseridas não são iguais",
  passwordIncorrect: "A senha que você digitou está incorreta.",
  passwordsDoNotMatch: "As senhas não coincidem",
  paymentAndCreditSettings: '"Configurações de Pagamento e Crédito',
  paymentMethod: "Método de Pagamento",
  paymentMethodAddedName: 'Método de Pagamento "{0}" adicionado.',
  paymentMethodAlreadyExist: "Método de pagamento já existe",
  paymentMethodName: "Nome do Método de Pagamento",
  paymentMethods: "Métodos de Pagamento",
  paymentMethodsDescription: "Crie e gerencie métodos de pagamento que você aceita em seu local.",
  paymentMethodsSettings: "Configurações de Métodos de Pagamento",
  paymentMethodsYouAccept: "Métodos de pagamento que você aceita em seu local.",
  paymentStatus: "Status do Pagamento",
  perPage: "{0} por página",
  percentage: "Porcentagem",
  percentageBetween: "A porcentagem deve estar entre 1-100",
  permitNumberWithColonSymbol: "Número da Licença:",
  phone: "Telefone",
  pleaseCheckYourNetwork: "Verifique sua rede para atualizar os dados do local.",
  pleaseConnectToAnAccounting:
    "Conecte-se a uma integração de contabilidade para gerenciar seu plano de contas",
  pleaseCreateLocation:
    "Crie pelo menos 1 local para alocar os inventários deste item de suprimento.",
  pleaseCreateYourChartOfAccounts:
    "Crie seu plano de contas nas configurações de categorias de contabilidade em seu painel MEWS.",
  pleaseEnsureLocation:
    "Certifique-se de que um local não tenha transações em andamento antes de iniciar uma verificação de inventário.",
  pleaseSelectLocation: "Selecione um local",
  pleaseSelectStockUnit: "Selecione a Unidade de Inventário",
  pointOfSale: "Ponto de Venda",
  posDevices: "Dispositivos de Ponto de Venda",
  posProvider: "Provedor de PDV",
  postPaidCreditsIsTheAmount:
    "Os créditos pós-pagos são a quantia que um hóspede pode usar quando seu saldo chegar a zero. Quando um hóspede possui saldo de créditos pós-pagos, o valor deve ser liquidado e pago por meio de recarga ou no check-out",
  postPaidCreditsUsed: "Créditos Pós-Pagos Usados",
  postpaidCreditLimit: "Limite de Crédito Pós-Pago",
  postpaidCredits: "Créditos Pós-Pagos",
  postpaidCreditsLiabilityAccount: "Conta de Garantia de CréditosPós-Pagos",
  postpaidCreditsLiabilityAccountMessage:
    "Créditos usados quando um hóspede atinge o saldo zero. A ser pago através de recarga no momento do check-out.",
  postpaidCreditsLiabilityAccountPlaceholder: "[xxxx] - Patrimonio/Créditos Pós-Pagos",
  postpaidCreditsUsed: "Créditos Pós-Pagos Usados",
  postpaidCreditsUsedCanOnlyBeReplenished:
    '"Os créditos pós-pagos usados só podem ser repostos e pagos por recarga ou no check-out.',
  pouchConnect: "PouchCONNECT",
  pouchLogo: "Logotipo PouchNATION",
  pouchnation: "PouchNATION",
  powerYourVenue: "Potencialize seu Local com Integrações.",
  preloadedVouchers: "Vouchers Pré-Carregados",
  preparingReport: "Preparando Relatório",
  previousBalance: "Saldo Anterior",
  price: "Preço",
  pricePerOrderQty: "Preço por Quantidade de Pedidos",
  pricing: "Preços",
  pricingAndTaxes: "Preços e Impostos",
  printableItems: "Itens para Impressão",
  printableItemsSelect: "Escolha quais produtos são impressos neste perfil de impressora.",
  printerLocationsDesc: "Escolha os locais onde este perfil de impressora pode ser usado.",
  printerProfile: "Perfil de Impressora",
  printerProfileCreated: "Perfil de impressora “{0}” criado.",
  printerProfileDeleted: "Perfil de Impressora Excluído",
  printerProfileName: "Nome do Perfil da Impressora",
  printerProfileNameAlreadyExists: "Este nome de perfil de impressora já existe.",
  printerProfileUpdated: "Perfil da Impressora “{0}” atualizado.",
  printerProfiles: "Perfis de Impressora",
  printers: "Impressoras",
  prioritizedAndUsedFirst: "priorizados e usados primeiro nos pagamentos.",
  privacy: "Privacidade",
  privacyPolicy: "política de privacidade",
  privacyPolicy2: "Política de privacidade",
  proceed: "Continuar",
  proceedToCheckout: "Continuar para o Check-out",
  processingTransactions: "Processando Transações ...",
  product: "Produto",
  productCategories: "Categorias de Produtos",
  productCategory: "Categoria de Produto",
  productCount: "Contagem de Produto",
  productDeleted: 'Produto "{0}" excluído.',
  productList: "Lista de Produtos",
  productName: "Nome do Produto",
  productRedeemed: "Produto Resgatado",
  productRemovedFromInventory: "{0} produto removido do inventário.",
  productSet: "Conjunto de Produtos",
  productSetDeleted: 'Conjunto de produtos "{0}" excluído.',
  productSetName: "Nome do Conjunto de Produtos",
  productUpdated: "{0} produto atualizado.",
  products: "Produtos",
  productsArePartOfComposite:
    "Esses produtos fazem parte de outros produtos compostos. Para excluí-los, remova-os primeiro dos produtos compostos ou exclua-os {0} com os produtos compostos que os contêm.",
  productsAreSupplyItemsAre:
    "{0} são os itens que você vende em seu local. {1} são os itens que você usa para construir seus produtos. Os itens de suprimentos são gerenciados em seu {2}.",
  productsCanBeRedeemed:
    "Escolha quais produtos de seu inventário podem ser resgatados a partir deste voucher.",
  productsDeleted: "{0} produtos excluídos.",
  productsDoesNotExist: "Os produtos que você deseja adicionar devem existir em todos os locais.",
  productsForDeletion:
    "{0} produto(s) com este local também podem ser excluídos. Isto não pode ser desfeito.",
  productsRemovedFromInventory: "{0} produtos removidos do inventário.",
  productsToBeDeleted: "Produtos a serem excluídos",
  productsUpdated: "{0} produtos atualizados.",
  productsVSupplyItems: "Produtos x Itens de Suprimentos",
  productsVariants: "Produtos / Variantes",
  profile: "Perfil",
  profileName: "Nome do Perfil",
  profileNameAlreadyExists: "O nome do perfil já existe, digite um nome diferente.",
  propertyManagementSystem: "Sistema de Gestão de Propriedade",
  purchasingAndInventory: "Compra e Inventário",
  qty: "Qtd",
  quantity: "Quantidade",
  quantitySupplyItems: "A quantidade de itens de suprimento usados para este produto.",
  question: "Pergunta",
  questionAddedName: "Pergunta “{0}“ adicionada.",
  questionAlreadyExist: "A pergunta já existe.",
  questionDeleted: "Pergunta “{0}“ excluída.",
  questionDescription:
    "Informações a serem coletadas no registro do convidado. Use essas perguntas atribuindo-as ao seu {0}.",
  questionSettings: "Configurações de Pergunta",
  questions: "Perguntas",
  radioButton: "Botão Interruptor",
  reachedReorderPoint: "Ponto de Encomendar Novamente Atingido",
  reallyUnbanTag: "Desbanir realmente esta tag?",
  reallyUnbanThisTagDescription:
    "O cancelamento do banimento desta tag só terá efeito na próxima vez que seus dispositivos forem sincronizados e atualizados (geralmente a cada 5 minutos quando conectado à Internet). Para refletir essas alterações imediatamente, sincronize e atualize seus dispositivos assim que cancelar o banimento desta tag.",
  reason: "Razão",
  reasonsForStay: "Razões para Ficar",
  receiptFormatting: "Formatação de Recibo",
  receiptNumber: "Número do Recibo",
  receiptSettings: "Configurações de Recibo",
  receipts: "Recibos",
  recentTransactions: "Transações Recentes",
  reconnect: "Reconectar",
  redeem: "Resgatar",
  redeemableProducts: "Produtos Resgatáveis",
  redeemed: "Resgatado",
  refreshingThisPage: "Atualizando esta página",
  refundedCredits: "Créditos Reembolsados",
  registerGuests: "Cadastrar Convidados",
  registerInfo: "Informações de Cadastro",
  registerReports: "Relatórios de Cadastro",
  registerReportsPage: "Página de Relatórios de Cadastro",
  registerStaff: "Registrar Funcionário",
  remove: "Excluir",
  removeFreeCredits: "Remover Créditos Gratuitos",
  removeFromList: "Remover da lista",
  removeNameAsYourStaff: "Remover {0} como funcionário?",
  removeStocks: "Remover Inventários",
  removeStocksConfirm: "Remover Inventários",
  removeStocksDetails: "Tem certeza de que deseja remover {0} {1} dos inventários de {2} em {3}?",
  removeThisAccount: "Remover esta conta?",
  removeThisSupplyItem: "Remova este item de suprimento primeiro dos produtos para excluí-lo.",
  removeVariant: "Remover Variante",
  removeVariantFirstFromProduct: "Primeiro remova esta variante do produto",
  removedStock: "Inventário Removido",
  reorderPoint: "Ponto de Encomendar Novamente",
  reorderPointReached: "Ponto de Encomendar Alcançado",
  reorderStocks: "Encomendar iInventários",
  reports: "Relatórios",
  reportsFailedToLoad: "Os relatórios não foram carregados.",
  reportsInfoPage: "Página de Informações de Relatórios",
  requireManagerAuthorization: "Requer autorização do gerente ao reivindicar este desconto.",
  required: "Obrigatório",
  resendInvite: "Reenviar Convite?",
  resetExpiration: "Reiniciar Expiração",
  resetTheExpirationPeriod:
    "Redefina o período de expiração assim que um convidado conclui uma transação sem fazer check-in.",
  restrictUseOfTags: "Restrinja o uso de certas tags em seu local.",
  retailPrice: "Preço de Varejo",
  retailPriceMustNotBeNegative: "O preço de varejo não deve ser negativo",
  retryReloadingChart: "Tentar Carregar o Gráfico Novamente",
  returnCredits: "Devolução de Créditos",
  returnedTags: "Tags Devolvidas",
  returnedVoucher: "Voucher Devolvido",
  returningGuests: "Convidados de Retorno",
  revenue: "Receita",
  revenueAccount: "Conta de Receita",
  revenueAccountPlaceholder: "[xxxx] - Receita/Vendas FOC",
  reviewYourDailyVenueActivity: "Revise sua atividade diária no local.",
  revokeInvite: "Revogar Convite",
  revokeThisInvite: "Revogar este convite?",
  roadMap: "Plano",
  role: "Função",
  roleAndDesignation: "Função e Designação",
  roles: "Funções",
  rooms: "Quartos",
  runEndOfDay: "Executar Fim do dia",
  sale: "Venda",
  saleDiscounts: "Receita/Descontos de Vendas",
  saleFoc: "Receita/Vendas FOC",
  saleReturn: "Retorno de Venda",
  sales: "Vendas",
  salesDiscounts: "Descontos em Vendas",
  salesDiscountsMessage: "O custo de todos os descontos aplicados.",
  salesDiscountsPlaceholder: "[xxxx] - Desconto de Vendas",
  salesFOCPlaceholder: "[xxxx] - Receita/Vendas FOC",
  salesInformation: "Informação de Vendas",
  salesReport: "Relatório de Vendas",
  save: "Salvar",
  saveAccountSetting: "Salvar Configuração de Conta",
  saveCategoryForm: "Salvar formulário de categoria",
  saveCreditSetting: "Salvar Configuração de Crédito",
  saveDeviceForm: "Salvar Formulário do Dispositivo",
  saveDiscountForm: "Salvar Formulário de Desconto",
  saveGeneralSettings: "Salvar Configurações Gerais",
  saveLocationForm: "Salvar Formulário de Local",
  saveNewSupplyItemForm: "Salvar Formulário de Novo Item de Suprimento",
  savePaymentMethod: "Salvar Método de Pagamento",
  savePrinterProfileForm: "Salvar Formulário de Perfil da Impressora",
  saveProduct: "Salvar Produto",
  saveProductSet: "Salvar Conjunto de Produtos",
  saveQuestion: "Salvar Pergunta",
  saveReceiptSettings: "Salvar Configurações de Recibo",
  saveStaffForm: "Salvar Formulário de Funcionário",
  saveStaffProfileForm: "Salvar Formulário de Perfil de Funcionário",
  saveTaxSetting: "Salvar Configuração de Impostos",
  saveVenueForm: "Salvar Formulário de Local",
  saveVoucherForm: "Salvar Formulário de Voucher",
  saveWristbandKeepingSettings: "Salvar as Configurações de Manutenção da Pulseira",
  savedChanges: "Mudanças Salvas",
  search: "Pesquisar",
  search2: "Pesquisar...",
  searchBanTags: "Pesquisar tags proibidas por tag UID",
  searchForProducts: "Pesquisar produtos ou itens de suprimentos",
  searchForStockId: "Pesquisar ID de Inventário ...",
  searchForStocktakeId: "Pesquisar ID de Verificação de Inventário...",
  searchGuestsByNameOrId: "Pesquisar convidados por nome ou ID de tag",
  searchMovementPlaceholder: "Pesquisar movimentos por nome de voucher, dispositivo, convidado",
  searchProductByKeyOrSku: "Pesquisar produtos por palavra-chave ou SKU",
  searchProductsByKeyword: "Pesquisar produtos por palavra-chave",
  searchProductsIn: "Pesquisar produtos em {0}",
  searchReportsByShiftId: "Relatórios de pesquisa por id de turno",
  searchResult: "resultado da pesquisa",
  searchResults: "Resultados da Pesquisa",
  searchSupplyItemBy: "Pesquisar item de suprimentos por nome ou SKU",
  searchTag: "Pesquisar Tag",
  searchTransactionPlaceholder:
    "Pesquisar transações por nome do convidado, ID da tag, localização, ID de check-in",
  searchVouchers: "Pesquisar Vouchers",
  second: "segundo",
  seconds: "segundos",
  selectAType: "Selecione um tipo",
  selectAccount: "Selecionar Conta",
  selectAccountPlaceholder: "Selecione Conta ...",
  selectAccountType: "Selecionar Tipo de Conta",
  selectAndMakeComposition: "Selecione a montagem e composição deste produto.",
  selectCategoriesOrProducts: "Selecione categorias ou produtos de seus locais escolhidos",
  selectCategory: "Selecionar Categoria",
  selectCountry: "Selecione o pais",
  selectDevice: "Selecionar Dispositivo",
  selectGuestProfile: "Selecionar Perfil de Convidado",
  selectItemsThisProductMadeOf:
    '"Selecione os itens de que este produto é feito. Defina a quantidade de cada item de suprimento que será deduzido por pedido de venda deste produto.',
  selectLocation: "Selecionar Local",
  selectProducts: "Selecionar Produtos",
  selectStaff: "Selecionar Funcionário",
  selectStatus: "Selecionar Status",
  selectStockLevel: "Selecionar Nível de Inventário",
  selectStockUnit: "Selecionar Unidade de Inventário",
  selectSupplyItem: "Selecionar item de suprimento",
  selectTax: "Selecionar Imposto",
  selectToIncludeCategory: "Selecione para incluir este produto em uma categoria ..",
  selectToIncludeProductSetInCategory:
    "Selecione para incluir este conjunto de produtos em uma categoria.",
  selectVenue: "Selecionar Local",
  selectVouchers: "Selecionar Vouchers",
  sellOnPos: "Vender no PDV",
  selling: "Vendendo",
  sellingOnPos: "Vendendo no PDV",
  sellingQty: "Qtd de Venda",
  serialNumber: "Número de Série",
  serialNumberAbbv: "S/N",
  set: "Definir",
  setAnAmountToLimitPostPaid:
    "Defina um valor para limitar os créditos pós-pagos permitidos de um hóspede.",
  setAsDefaultTax: "Definir como imposto padrão",
  setAsNonRefundable: "Definir como não reembolsável",
  setCode: "Definir Código",
  setExpirationPeriod: "Definir período de expiração",
  setUpYourAccount: "Crie sua conta!",
  sets: "Conjuntos",
  settings: "Definições",
  share: "Compartilhar",
  shareThisLink:
    "Compartilhe este link para abrir {0} em qualquer dispositivo! Copie o link e compartilhe-o com qualquer um de seus funcionários.",
  shift: "Turno",
  shiftEnd: "Fim do Turno",
  shiftId: "ID de Turno",
  shiftStart: "Início de Turno",
  shifts: "Turnos",
  shouldBeANumber: "Deve ser um número.",
  show: "Mostrar",
  showHideColumns: "Mostrar / Ocultar Colunas",
  showLess: "Mostrar Menos",
  showMore: "Mostrar Mais",
  signIn: "Entrar",
  signInButton: "Botão de Entrar",
  signUp: "Inscrever-se",
  sku: "SKU",
  skuAlreadyExists: "SKU já existe.",
  skuStockKeepingUnit: "SKU (unidade de manutenção de inventário)",
  someArePartOfCompositeNote:
    "{0} {1} abaixo não podem ser excluídos porque fazem parte de outros produtos compostos. Para excluí-los, remova-os dos produtos compostos ou exclua-os {2} com os produtos compostos que os contêm.",
  somethingWentWrong: "Algo deu errado.",
  spend: "Gastar",
  staff: "Funcionários",
  staffCanAccess: "O funcionário pode acessar o aplicativo PDV e realizar ações.",
  staffList: "Lista de Funcionários",
  staffName: "Nome dos Funcionários",
  staffNameAndOrTagId: "Nome do funcionário e/ou ID da tag",
  staffProfile: "Perfil de Funcionário",
  staffProfileDeleted: "Perfil de Funcionário excluído",
  staffProfileList: "Lista de Perfil de Funcionários",
  staffProfileName: "Nome de Perfil de Funcionário",
  staffProfileNameCreated: "Perfil de Funcionário “{0}” criado.",
  staffProfileNamePlaceholder: "Digite o nome deste perfil de funcionário...",
  staffProfiles: "Perfis de Funcionários",
  staffRemoved: "Funcionário excluído.",
  staffTagWithColonSymbol: "Tag de Funcionário:",
  staffWithColonSymbol: "Funcionário:",
  standard: "Padrão",
  start: "Começar",
  startStocktake: "Iniciar Verificação de Inventário",
  startedOn: "Começou em",
  status: "Status",
  staying: "Ficando",
  stockBelowParLevel: "O inventário está abaixo do nível ideal",
  stockLevelAlerts: "Alertas de Inventário",
  stockLevelNotificationSettings: "Configurações de Notificação de Nível de Inventário",
  stockLevels: "Níveis de Inventário",
  stockManager: "Gerenciador de Inventário",
  stockReachedReorderPoint: "O inventário atingiu o ponto de novo pedido",
  stockUnit: "Unidade de Inventário",
  stockVariance: "Variância deInventário",
  stockWeightPerN: "Peso do Inventário por {0}",
  stocks: "Inventários",
  stocktake: "Verificação de Inventário",
  stocktakeAlreadyExists: "Verificação de Inventário já existe",
  stocktakeSaved: "Verificação de Inventário Salva",
  stocktakeWithId: "Verificação de Inventário # ST- {0}",
  streetVillage: "Endereço, Cidade / Vila",
  subTotal: "Subtotal",
  subscribeAndRegister: "Inscreva-se e registe-se agora para obter um",
  subscription: "Assinatura",
  success: "Sucesso",
  sugar: "açúcar",
  suggested: "Sugerido",
  summary: "Resumo",
  supplyCost: "Custo de Suprimento",
  supplyItem: "Item de Suprimento",
  supplyItemAlreadyExists: "O nome do item de suprimento já existe.",
  supplyItemDeleted: "Item de Suprimento Excluído",
  supplyItemNameCreated: "Item de suprimento “{0}” criado.",
  supplyItemSummaryPage: "Página de Resumo do Item de Suprimento",
  supplyItems: "Itens de Suprimento",
  supplyUnit: "Unidade de Suprimento",
  syncToMews: "Sincronizar com MEWS",
  tagBanned: "Tag banida.",
  tagId: "ID de Tag",
  tagIds: "IDs de tag",
  tagInfo: "Informação de Tag",
  tagUID: "Tag UID",
  tagUnbanned: "Tag não banida.",
  tags: "Tags",
  tagsIssued: "Tags Emitidas",
  tapCount: "Contagem de Toques",
  tapStatus: "Status de Toques",
  tax: "Imposto",
  taxAddedName: 'Imposto "{0}" adicionado.',
  taxAlreadyExists: "O imposto já existe.",
  taxEditNote:
    "Nota: Os produtos vinculados a este imposto serão atualizados automaticamente. Isso não afetará as vendas anteriores.",
  taxEg: "por exemplo, “Imposto sobre Valor Agregado“",
  taxIncluded: "Imposto incluído",
  taxName: "Nome ddo Imposto",
  taxNumber: "Número de Identificação Fiscal",
  taxOnPurchasesAccount: "Imposto sobre a Conta de Compras",
  taxOnPurchasesAccountMessage: "IVA e outros impostos sobre pedidos de compra.",
  taxOnPurchasesAccountPlaceholder: "[xxxx] - Passivo/Imposto de Compras",
  taxOnSalesLiabilityAccount: "Imposto sobre a Conta de Passivo de Vendas",
  taxOnSalesLiabilityAccountMessage: "A conta padrão para impostos cobrados para cada produto.",
  taxOnSalesLiabilityAccountPlaceholder: "[xxxx] -Passivo/Imposto sobre Vendas",
  taxPercentageAndValue: "",
  taxRate: "Taxa de Imposto",
  taxSettings: "Configurações Fiscais",
  taxes: "Impostos",
  termsAndConditions: "Termos e Condições",
  termsInAllowingAccess:
    "Ao clicar em Permitir acesso, você autoriza {0} a usar, receber e armazenar suas informações. Revise {1} de {2} e {3}. Você pode interromper a qualquer momento nas configurações de Integração de sua conta PouchNATION.",
  termsInSignUp: "Ao me inscrever, eu concordo com os {0} {1} e {2} da PouchNATION.",
  termsOfService: "termos de serviço",
  termsOfUse: "Termos de Uso",
  textbox: "Caixa de Texto",
  theLinkYouAreTryingToAccess: "O link que você está tentando acessar não é do seu Local.",
  theLinkYouAreTryingToAccessDoesNotBelongToLocation:
    "O link que você está tentando acessar não é de seu local atribuído",
  theShiftAndTransactionDetails:
    "Os detalhes do turno e das transações serão exibidos aqui assim que o turno for encerrado na registradora.",
  thereIsSomeError:
    "Pps! Existe algum erro em nosso lado. Por favor, tenha paciência e verifique novamente mais tarde.",
  thereNthOtherProducts:
    "{0} {1} outros produtos com este local e outros locais permanecerão em seu inventário.",
  theseProductsCannotBeDeleted: "Esses produtos não podem ser excluídos.",
  thisAppRequests: "Este aplicativo solicita as seguintes informações:",
  thisAppWouldRead: "Este aplicativo poderia ler e/ou gravar as seguintes informações:",
  thisEmailIsAlreadyAdded: "Este email já foi adicionado.",
  thisEmailIsInvalid: "Este email é Inválido.",
  thisFieldIsRequired: "Este campo é obrigatório.",
  thisMonth: "Este mês",
  thisProductNameAlreadyExists: "Este nome de produto já existe",
  thisProductsHasMultipleVariant: "Este produto tem várias opções, como tamanho, cor, sabor, etc.",
  thisQuestionIsLinkedToNProfiles: "Esta pergunta está vinculada a {0} perfis de convidados.",
  thisServesAsAnOfficialReceipt: "- ISSO SERVE COMO UM RECIBO OFICIAL -",
  thisSkuExists: "Este SKU já existe",
  thisStaffHasNpLongerAccess:
    "Este funcionário não terá mais acesso a {0}. Essa ação não pode ser desfeita.",
  thisTagIdIsAlreadyBanned: "Este ID de Tag já foi banido.",
  thisVenueFreeTrial: "Este local estará em teste gratuito pelos primeiros 30 dias.",
  thisWeek: "Esta semana",
  time: "Tempo",
  timeEnded: "Tempo Finalizado",
  timeRange: "Intervalo de Tempo",
  timeStarted: "Tempo de Início",
  to: "a",
  toAddMoreCreditsToATag:
    "Para adicionar mais créditos a uma tag, os convidados podem ser capazes de",
  toBeCheckedIn: "A Fazer Check-in",
  toBeCheckedOut: "A Fazer Check-out",
  toContinueCreatingDiscount: "Para continuar criando este desconto, primeiro crie um local.",
  toContinueCreatingProducts: "Para continuar criando este produto, primeiro crie um local.",
  toContinueCreatingStaff: "Para continuar criando este funcionário , primeiro crie um local.",
  toContinueCreatingStocktake:
    "Para continuar criando esta verificação de inventário, primeiro crie um local.",
  toDeleteItem: "para excluir este item.",
  today: "Hoje",
  together: "Juntos",
  topCategoriesBySales: "Principais Categorias por Vendas",
  topLocations: "Principais Locais",
  topProducts: "Principais Produtos",
  topSpendingGuests: "Convidados que Mais Gastam",
  topupAndReturnCredits: "Recarregar e Devolver Créditos",
  topupCredits: "Recarregar Créditos",
  total: "Total",
  totalAmount: "Valor Total",
  totalBookings: "Total de Reservas",
  totalFreeCreditsIssued: "Total de Créditos Gratuitos Emitidos",
  totalFreeCreditsRemoved: "Total de Créditos Gratuitos Removidos",
  totalInStock: "Total em Inventário",
  totalPaid: "Total Pago",
  totalProductSales: "Vendas Totais do Produto",
  totalRefundedCredits: "Total de Créditos Reembolsados",
  totalRemoved: "Total Removido",
  totalReturnCredits: "Total de Devolução de Créditos",
  totalReturnedCredits: "Total de Créditos Devolvidos",
  totalSales: "Total de Vendas",
  totalSpent: "Total Gasto",
  totalSpentToDate: "Total Gasto até a Data",
  totalStocksPerLocation: "Inventário Total por Local",
  totalTagsUsed: "Total de Tags Emitidas",
  totalTax: "Imposto Total",
  totalTopUps: "Total de Recargas",
  totalTopupCredits: "Total de Créditos de Recarga",
  totalTransactions: "Total de Transações",
  totalUse: "Uso Total",
  totalVisits: "Visitas Totais {0}",
  totals: "Totais",
  trackAndManage: "Rastreie e gerencie suas contagens manuais de inventário.",
  trackEveryGuestCheckedIn:
    "Rastreie todos os convidados que fizeram check-in em seu local e veja seus produtos e locais favoritos",
  trackWhichItemsLow:
    "Rastreie quais itens estão com poucos inventários e precisam ser reabastecidos",
  trackedInventory: "Inventário Rastreado",
  transaction: "Transação",
  transactionId: "ID de Transação",
  transactionInfoPage: "Página de Informações de Transação",
  transactionStatus: "Status da Transação",
  transactionType: "Tipo de Transação",
  transactions: "Transações",
  transactionsFailedToProcess: "as transações falharam no processamento.",
  transactionsPage: "Página de Transações",
  try: "Tente:",
  turnOffPos: "Desativar a venda de {0} {1}?",
  turnOnPos: "Ativar venda de {0} {1}?",
  txnId: "Id de Trans.: #",
  type: "Digite",
  typeAQuestion: "Digite uma pergunta",
  typeAReason: "Digite um motivo ...",
  typeAnOption: "Digite uma opção",
  typeDisconnect: "Digite DISCONNECT em maiúsculas para confirmar",
  typeHere: "Digite aqui...",
  typeToSearch: "Digite para pesquisar um item!",
  typeToSetAccountCode: "Digite para definir/selecionar um código de conta.",
  unableToDelete: "Incapaz de Excluir",
  unbaleToUploadMoreThanMb: "Incapaz de carregar mais de 1 MB.",
  unban: "Cancelar Banimento",
  unbanTag: "Cancelar Banimento Tag",
  unbanningWillMakeTagAccessible:
    "O cancelamento do banimento tornará essa tag acessível em seu local.",
  uncategorized: "Sem categoria",
  undo: "DESFAZER",
  unitSold: "Unidade Vendida",
  unitsSold: "Unidades Vendidas",
  unknown: "Desconhecido",
  updatedOn: "atualizado em {0}",
  uploadPhoto: "Carregar Foto",
  uponRegistration:
    "no momento do registro ou com qualquer funcionário de carregamento/quiosque dentro do local.",
  usage: "Uso",
  usageDatePeriod: "Período de Data de Uso",
  useVenueAppAndLogin:
    "Use o Venue App e faça login com sua tag NFC ativada ou com seu nome de usuário ou senha atual. Ainda não tem o aplicativo? Pergunte ao gerente do local ou {0}.",
  used: "Usado",
  usesLastIssuedTo: "usos, última emissão para",
  validUntilWithColonSymbol: "Válido Até;",
  value: "Valor",
  valueBetween: "O valor deve estar entre",
  valueRedeemed: "Valor Resgatado",
  variant: "Variante",
  variantAlreadyExist: "Variante já existe",
  variantIsCurrentlyBeingUsed: "A variante está sendo usada no seguinte produto",
  variants: "Variantes",
  vatReg: "Reg. IVA",
  venueCurrency: "Moeda do Local",
  venueDashboard: "Painel de Controle do Local",
  venueDetails: "Detalhes do Local",
  venueLocation: "Localização do Local",
  venueName: "Nome do Local",
  venueNameAlreadyExist: "O nome do local já existe, escolha outro nome",
  venueSettings: "Configurações do Local",
  view: "Ver",
  viewAllTransactions: "Ver Todas as Transações",
  viewAndExportDiscountReport:
    "Veja e exporte a lista de seus descontos e o valor total deduzido de cada desconto",
  viewAndExportEachClosedRegister:
    "Veja e exporte cada relatório de registro fechado feito por seu funcionário",
  viewAndExportSalesReportByCategories: "Veja e exporte seu relatório de vendas por categorias",
  viewAndExportVoucherReport:
    "Veja e exporte todos os vouchers aplicados em produtos resgatados e o valor total do varejo e suprimentos resgatados por cada voucher",
  viewBillingStatement: "Ver Extrato de Cobrança",
  viewBookingInfo:
    "Veja informações de reserva em diferentes plataformas, como MEWS, PouchNATION Ticketing e muito mais!",
  viewCategoryDetails: "Exibir Detalhes da Categoria",
  viewDetails: "Ver Detalhes",
  viewDevice: "Ver Dispositivo",
  viewDiscountDetails: "Ver Detalhes do Desconto",
  viewEveryTransactions:
    "Veja todas as transações feitas em seu local dentro de um determinado período de tempo",
  viewIntegrations: "Ver Integrações",
  viewNOfNTransactions: "Ver {0} / {1} Transações",
  viewNTransactions: "Ver {0} Transações",
  viewPlansAndPricing: "Ver Planos e Preços",
  viewPreview: "Ver Preview",
  viewTaxSettings: "Ver Configurações de Impostos",
  viewTheSalesSummaryOfYourVenue:
    "Veja o resumo de vendas de seu local e seus principais locais, produtos e muito mais.",
  viewVoucherDetails: "Ver Detalhes do Voucher",
  void: "Cancelar",
  voidAuthorization: "Autorização de Cancelamento",
  voidConfirmation: "Tem certeza que deseja cancelar {0} Esta ação não pode ser desfeita.",
  voidId: "Cancelar {0}?",
  voidInfo: "Informação de Cancelamento",
  voidReason: "Razão de Cancelamento",
  voidStocktake: "Cancelar Verificação de Inventário",
  voided: "Cancelado",
  voidedBy: "Cancelado por",
  voidedItems: "Itens Cancelados",
  voidedOn: "Cancelado em",
  voidedValue: "Valor Cancelado",
  voucher: "Voucher",
  voucherApplied: "Voucher Aplicado",
  voucherAvailable: "Este voucher está disponível para emissão em seu local.",
  voucherCreated: "{0} adicionado.",
  voucherDeleted: "Voucher {0} excluído.",
  voucherMovementPage: "Página de Movimento de Voucher",
  voucherName: "Nome do Voucher",
  voucherNameAlreadyExist: "Este nome de voucher já existe.",
  voucherReport: "Relatório de Voucher",
  voucherRetailValue: "Valor de Varejo de Vouchers",
  voucherSupplyValue: "Valor de Fornecimento de Vouchers",
  voucherType: "Tipo de Voucher",
  voucherUpdated: "Voucher Atualizado",
  vouchers: "Vouchers",
  vouchersCreationLimitInfo:
    "Você só pode criar um máximo de 8 vouchers por local. Você pode excluir vouchers expirados ou inativos para abrir espaço para mais vouchers.",
  vouchersIssued: "Vouchers Emitidos",
  vouchersListPage: "Página de Lista de Vouchers",
  vouchersMaximumNumberReached: "Número máximo de vouchers atingido.",
  vouchersRedeemed: "Vouchers Resgatados",
  vouchersRemoved: "Vouchers Removidos",
  vouchersUsage: "Uso de Vouchers",
  weDidNotFoundReports: "Não encontramos nenhum relatório para seus filtros.",
  weeksAfterCheckout: "semanas após o check-out",
  weightInGrams: "Peso em gramas",
  welcomeBack: "Bem vindo de volta!",
  whenAnItemAlertLevel: "Quando um item cai para seu nível de alerta.",
  whole: "Inteiro",
  wholeProduct: "Produtos Inteiros",
  withPendingPayments: "Com Pagamentos Pendentes",
  withRemainingBalance: "Com Saldo Remanescente",
  wristbandKeeping: "Mantendo Pulseira",
  wristbandTag: "Tag de Pulseira",
  wristbandTagKeeping: "Manutenção de Tag de Pulseira",
  wristbandTagSettings: "Configurações de Tag de Pulseira",
  xConnectedFailed:
    "Parece que não conseguimos reconhecer este token de acesso. Consulte as instruções abaixo para recuperar seu token de acesso adequado.",
  xConnectedSuccessfully: "{0} Conectado com Sucesso",
  year: "ano",
  years: "anos",
  yes: "Sim",
  youAreAboutToDelete: 'Você está prestes a excluir o {0} "{1}". Isto não pode ser desfeito.',
  youAreAboutToDeleteAQuestionThatIsLinked:
    "Você está prestes a excluir uma pergunta que está vinculada a um perfil de convidado. Isto não pode ser desfeito.",
  youAreAboutToDeleteGuestProfile:
    "Você está prestes a excluir este perfil de convidado. Isto não pode ser desfeito.",
  youAreAboutToDeleteMultiple: "Você está prestes a excluir o {0} {1}. Isto não pode ser desfeito.",
  youAreAboutToDeletePaymentMethod: 'Você está prestes a remover o método de pagamento "{0}".',
  youAreAboutToDeleteQuestion:
    "Você está prestes a excluir esta questão. Isto não pode ser desfeito.",
  youAreAboutToDeleteStaffName:
    "Você está prestes a remover {0} de seus funcionários. Este funcionário não terá mais acesso a {1}. Essa ação não pode ser desfeita.",
  youAreAboutToDeleteStaffProfile:
    "Você está prestes a deletar este perfil de funcionário. Isto não pode ser desfeito.",
  youAreAboutToDeleteTax: 'Você está prestes a remover o imposto "{0}".',
  youAreAboutToDisconnect:
    "Você está prestes a desconectar e remover permanentemente todas as informações deste aplicativo.",
  youAreAboutToRemoveFromMailingList:
    "Você está prestes a remover {0} desta lista de distribuição. Esta conta não poderá receber alertas de inventário.",
  youAreAboutToRemoveMultipleProduct:
    "Você está prestes a remover {0} produtos e suas variantes de seu inventário e de todos os seus locais. Isto não pode ser desfeito.",
  youAreAboutToRemoveProduct:
    "Você está prestes a remover este produto de seu inventário e de todos os seus locais. Isto não pode ser desfeito.",
  youAreAboutToRemoveVariantProduct:
    "Você está prestes a remover este produto e suas {0} variantes de seu inventário e de todos os seus locais. Isto não pode ser desfeito.",
  youAreAssigned: "Você está atribuído com as seguintes funções:",
  youAreLoggedInAs: "Você está conectado como {0}",
  youCanChangeThisLater: "Você pode mudar isso mais tarde!",
  youCanNowLogin: "Agora você pode fazer o login usando seu e-mail e senha.",
  youDontHavePermission: "Você não tem permissão para acessar este link",
  youHaveUnsavedChanges: "Você tem alterações não salvas nesta página.",
  youllFindYourBookingsHere: "Você encontrará suas reservas aqui.",
  yourGeneralSettings: "Suas configurações gerais, como nome do local e moeda.",
  yourMailingIsEmpty: "Sua lista de e-mails ainda está vazia.",
  yourVenues: "Seus Locais",
  youveReachedTheEndOfTheList: "Você chegou ao fim da lista.",
  zeroInventory: "Inventário Zero",
};

export default Object.freeze(locale);
