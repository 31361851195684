import React, { useMemo, useCallback, useContext } from "react";
import { FragmentA, ModuleWrapper } from "components/fragments";
import { HeaderB } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import {
  Field,
  Select,
  Panel,
  AlertMessage,
  DatePicker,
  TimePicker,
  ActionButton,
  Toast,
  Form,
  PageError,
} from "components/commons";
import initialFormState from "./stocktake-form.state";
import { useApi, useForm, useModal, useMount, useRouter } from "hooks";
import { AlertType, StocktakeStatus } from "enums";
import { getLocationsWithStocktakeStatus } from "apis";
import { VenueContext } from "contexts";
import { mapObjectsToSelect } from "services";
import { NoLocationModal } from "components/modals";
import moment from "moment";
import { locationWithStocktakeStatus } from "mappers/location.mapper";

const StocktakeForm = ({
  title,
  initialState = undefined,
  submitting = false,
  submit,
  deleteButton,
  error,
}) => {
  const { history } = useRouter();
  const unsaveChangesModal = useModal();
  const noLocationModal = useModal();
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const {
    request: getLocationsRequest,
    loading: loadingLocation,
    mappedData: locations,
  } = useApi({
    api: getLocationsWithStocktakeStatus,
    isArray: true,
    mapper: locationWithStocktakeStatus,
  });

  useMount(() => {
    fetchLocations();
  });

  const fetchLocations = useCallback(async () => {
    const locations = await getLocationsRequest({ venueId: venue.venueId });
    if (locations && locations.data.length === 0) {
      noLocationModal.show();
    }
  }, [getLocationsRequest, venue.venueId, noLocationModal]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, dirty } = useForm({
    initialState: formState,
  });

  const goToList = useCallback(() => {
    history.push(Path.INVENTORY_STOCKTAKE);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, goToList, unsaveChangesModal]);

  const locationOptions = useMemo(() => {
    return mapObjectsToSelect(locations, { textKey: "name" });
  }, [locations]);

  const handleSubmit = useCallback(async () => {
    try {
      const params = getFormValues();
      let values = {
        locationId: params.locationId,
        startDate: moment(params.startDate.toString()).format(),
        status: StocktakeStatus.InProgress,
      };
      await submit({ ...values, venueId });
      Toast({
        content: lang.success,
        success: true,
        icon: "check",
      }).open();
      //   history.push(Path.INVENTORY_STOCKTAKE);
    } catch ({ code, handleError }) {
      const err = {
        3100: () => {
          applyFieldErrors({
            name: lang.stocktakeAlreadyExists,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
    // eslint-disable-next-line
  }, [applyFieldErrors, history, submit, getFormValues, locationOptions]);

  const submitFormCb = useCallback(() => {
    submitForm(handleSubmit);
  }, [handleSubmit, submitForm]);

  return (
    <ModuleWrapper
      header={
        <HeaderB title={title} returnText={lang.stocktake} returnPath={Path.INVENTORY_STOCKTAKE} />
      }
    >
      <NoLocationModal content={lang.toContinueCreatingStocktake} {...noLocationModal} />
      <FragmentA title={lang.basicInfo}>
        <Panel>
          {error ? (
            <PageError />
          ) : (
            <Form unsaveChangesModal={unsaveChangesModal} isPrompt={dirty}>
              <Field {...fields.locationId}>
                <Select
                  {...fields.locationId}
                  onChange={modifyField}
                  options={locationOptions}
                  loading={loadingLocation}
                />
              </Field>
              <AlertMessage
                type={AlertType.Warning}
                message={lang.pleaseEnsureLocation}
                showIcon={true}
                icon="info"
                className="mt-lg mb-sm"
              />
              <div className="flex gap-4">
                <Field label={lang.date} {...fields.startDate}>
                  <DatePicker
                    {...fields.startDate}
                    onChange={(name, value) => modifyField(name, { value: value })}
                  />
                </Field>
                <Field label={lang.time} {...fields.startDate}>
                  <TimePicker
                    {...fields.startDate}
                    onChange={(startTime) => modifyField("startDate", { value: startTime })}
                  />
                </Field>
              </div>
            </Form>
          )}
        </Panel>
      </FragmentA>
      <ActionButton
        showLine
        loading={submitting}
        primary={{
          disabled: !fields.locationId.value,
          text: lang.startStocktake,
          onClick: () => {
            submitFormCb();
          },
        }}
        secondary={{
          text: lang.cancel,
          onClick: () => leavePage(),
        }}
        danger={deleteButton}
      />
    </ModuleWrapper>
  );
};

export default StocktakeForm;
