import React, { useContext } from "react";
import { Panel, Skeleton, Text, Title, Field, PageError } from "components/commons";
import lang from "translations";
import classNames from "classnames";
import { formatNumberToMoneyWithCurrencyParams } from "services/money.service";
import { useMount } from "hooks";
import style from "./booking-summary.module.scss";
import { BookingItemType } from "enums";
import { Divider } from "antd";
import { mixpanel } from "mixpanel";
import { VenueContext } from "contexts";

const BookingSummary = ({ data: bookingDetailsData, loading: loadingBookingDetails, error }) => {
  const { venue } = useContext(VenueContext);

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.bookingSummary,
    });
  });

  const labelText = (label = "", text) => {
    return (
      <Field label={label}>
        <Text>{text ?? "-"}</Text>
      </Field>
    );
  };

  const labelArray = (label = "", array) => {
    return (
      <Field label={label}>
        {array && array.length > 0 ? (
          array.map((item, index) => <Text key={index}>{item.notes}</Text>)
        ) : (
          <Text>-</Text>
        )}
      </Field>
    );
  };

  if (error) {
    return <PageError />;
  }

  return (
    <div className="grid grid-cols-12 gap-x-4">
      <Panel className={classNames("col-span-12 md:col-span-8", style.panelHeight)}>
        <Title className="mb-lg">{lang.bookingDetails}</Title>
        {loadingBookingDetails ? (
          <Skeleton />
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {labelText(lang.bookingNo, bookingDetailsData.bookingNumber)}
              {labelText(lang.bookedBy, bookingDetailsData.bookedBy)}
              {labelText(lang.emailAddress, bookingDetailsData.bookedByCustomer?.email)}

              {labelText(
                lang.mobileNumber,
                bookingDetailsData.bookedByCustomer?.mobileNumber === "" ||
                  !bookingDetailsData.bookedByCustomer?.mobileNumber
                  ? "-"
                  : bookingDetailsData.bookedByCustomer?.mobileNumber
              )}
              {labelText(lang.arrivalDate, bookingDetailsData.startTime)}
              {labelText(lang.departureDate, bookingDetailsData.endTime)}
              {labelText(lang.noOfNights, bookingDetailsData.noOfNights)}
              {labelText(lang.noOfGuests, bookingDetailsData.noOfGuests)}
              {labelText(lang.companyDetails, bookingDetailsData.company)}
              {labelArray(lang.notes, bookingDetailsData.notes)}
            </div>
          </>
        )}
      </Panel>
      <Panel className={classNames("col-span-12 md:col-span-4", style.panelHeight)}>
        <Title className="mb-lg">{lang.bookingSummary}</Title>
        {loadingBookingDetails ? (
          <Skeleton />
        ) : (
          <>
            {bookingDetailsData.bookingItems &&
              bookingDetailsData.bookingItems.map((item, index) => (
                <div className="my-sm flex justify-between" key={index}>
                  <div className="flex">
                    <div className="pr-md">
                      <Text className="font-bold">{item.quantity}x</Text>
                    </div>
                    <div>
                      <Text className="font-bold">{item.name}</Text>
                      {item.type === BookingItemType.PLACE && (
                        <Text className="text-gray-light">
                          {(item.adultCount ?? 0) + (item.childCount ?? 0)} {lang.guests}
                        </Text>
                      )}
                    </div>
                  </div>{" "}
                  <div className="text-right">
                    <Text className="font-bold">
                      {formatNumberToMoneyWithCurrencyParams(
                        item.totalAmount,
                        true,
                        venue.currencyCode
                      )}
                    </Text>
                    <Text className="text-gray-light">
                      {formatNumberToMoneyWithCurrencyParams(
                        item.itemAmount,
                        true,
                        venue.currencyCode
                      )}{" "}
                      / {item.type === BookingItemType.PLACE ? lang.night : lang.item}
                    </Text>
                  </div>
                </div>
              ))}
            <Divider />
            <div className="flex justify-between items-center">
              <Text size="medium" className="font-bold">
                {lang.billAmount}
              </Text>
              <Text size="medium" className="font-bold">
                {formatNumberToMoneyWithCurrencyParams(
                  bookingDetailsData.totalBillAmount,
                  true,
                  venue.currencyCode
                )}
              </Text>
            </div>
          </>
        )}
      </Panel>
    </div>
  );
};

export default BookingSummary;
