import {
  Form,
  Modal,
  Field,
  Input,
  Toast,
  ActionButton,
  Select,
  DraggableItems,
  ButtonLink,
} from "components/commons";
import { useForm, useModal } from "hooks";
import React, { useCallback, useEffect, useMemo } from "react";
import lang from "translations";
import initialFormState, { optionFormState } from "./question-form-state";
import DeleteQuestionModal from "../delete-question/delete-question-modal";
import { Field as FieldType } from "enums";
import { mixpanel, TrackEvent } from "mixpanel";

const QuestionFormModal = ({
  title,
  active,
  close,
  primaryText,
  initialState = undefined,
  isEdit,
  save,
  submitting,
  id,
  refreshList,
  guestProfiles,
}) => {
  const unsaveChangesModal = useModal();
  const deleteQuestionModal = useModal();

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const {
    dirty,
    fields,
    modifyField,
    clearForm,
    submitForm,
    getFormValues,
    modifyForm,
    applyFieldErrors,
  } = useForm({
    initialState: formState,
  });

  const exitForm = () => {
    clearForm();
    unsaveChangesModal.close();
  };

  const onClose = () => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          exitForm();
          setTimeout(() => {
            close();
          });
        },
      });
      return;
    }
    close();
    exitForm();
  };

  useEffect(() => {
    if (active) {
      clearForm();
    }
    // eslint-disable-next-line
  }, [active]);

  const onSave = useCallback(() => {
    const params = getFormValues();
    if (isEdit) {
      params.id = id;
    }
    params.options = params.options.map((value, index) => {
      return {
        option: value,
        sort: index + 1,
      };
    });
    save(
      params,
      (res, { message }) => {
        Toast({
          content: message,
          success: true,
          icon: "check",
        }).open();
        close();
        clearForm();
      },
      ({ code, handleError }) => {
        const err = {
          3085: () => {
            applyFieldErrors({
              name: lang.questionAlreadyExist,
            });
          },
        };
        if (err[code]) {
          err[code]();
        } else {
          handleError();
        }
      }
    );
  }, [save, clearForm, close, getFormValues, id, isEdit, applyFieldErrors]);

  const options = useMemo(() => {
    if (fields.options.value) {
      return fields.options.value.map((option, index) => {
        return {
          ...option,
          id: `QUESTION_FORM_${index}`,
          className: "my-xs py-sm",
          content: (
            <Field {...option} errorClassName={"absolute"}>
              <Input
                {...option}
                maxLength={75}
                onChange={(name, { value }) => {
                  modifyField("options", {
                    value: fields.options.value.map((o, key) => {
                      if (key === index) {
                        return {
                          ...o,
                          value,
                        };
                      }
                      return o;
                    }),
                  });
                }}
              />
            </Field>
          ),
        };
      });
    }
  }, [fields, modifyField]);
  return (
    <div>
      <Modal
        active={active}
        hidden={unsaveChangesModal.active || deleteQuestionModal.active}
        onClose={() => onClose()}
        primaryText={primaryText}
        loading={submitting}
        title={title}
        noCloseButton
        actionContent={
          <ActionButton
            loading={submitting}
            className="md-md mr-md ml-md pb-md"
            mb="md"
            primary={{
              text: primaryText,
              onClick: () => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: lang.saveQuestions,
                  Page: lang.questionSettings,
                });
                submitForm(onSave);
              },
              disabled: !dirty,
            }}
            secondary={{
              onClick: () => {
                onClose();
              },
              text: lang.cancel,
            }}
            danger={
              isEdit
                ? {
                    text: lang.deleteQuestion,
                    onClick: () => deleteQuestionModal.show(),
                  }
                : null
            }
          />
        }
      >
        <Form unsaveChangesModal={unsaveChangesModal} isPrompt={dirty}>
          <Field {...fields.name}>
            <Input required {...fields.name} onChange={modifyField} />
          </Field>
          <Field {...fields.type} className="mt-md">
            <Select
              required
              {...fields.type}
              onChange={(name, { value }) => {
                modifyForm({
                  [name]: {
                    value,
                  },
                  options: {
                    value: [FieldType.DROPDOWN, FieldType.RADIO].includes(value)
                      ? [optionFormState, optionFormState]
                      : [],
                  },
                });
              }}
            />
          </Field>
          {[FieldType.DROPDOWN, FieldType.RADIO].includes(fields.type.value) && (
            <Field label={lang.options} className="mt-md">
              <DraggableItems
                name="options"
                items={fields.options.value}
                render={options}
                onChange={modifyField}
                hasRemove={options.length > 2}
              />
              <ButtonLink
                className="mt-md"
                onClick={() => {
                  modifyField("options", {
                    value: [...fields.options.value, optionFormState],
                  });
                }}
              >
                {lang.addOption}
              </ButtonLink>
            </Field>
          )}
        </Form>
      </Modal>
      <DeleteQuestionModal
        {...deleteQuestionModal}
        name={initialState?.name}
        id={id}
        guestProfiles={guestProfiles}
        goToList={() => {
          close();
          refreshList();
        }}
      />
    </div>
  );
};

export default QuestionFormModal;
