import React from "react";
import { Icon } from "components/commons";
import classnames from "classnames";
import styles from "./button.module.scss";
import { Link } from "react-router-dom";
const ButtonLink = ({
  className,
  children,
  path,
  onClick,
  icon,
  suffix = false,
  prefix = !suffix,
  loading,
  newTabPath,
  flex = true,
  iconClassName = "text-xs",
  size = "text-sm",
  color = "text-pelorous",
  anchor,
  disabled,
}) => {
  const renderIcon = icon ? <Icon loading={loading} name={icon} className={iconClassName} /> : null;

  const content = (
    <>
      {prefix && renderIcon}
      {children}
      {suffix && renderIcon}
    </>
  );
  if (anchor) {
    return (
      <a
        className={classnames("text-pelorous", size ? size : "text-md")}
        rel="noreferrer"
        href={newTabPath}
        target="_blank"
      >
        {content}
      </a>
    );
  }

  if (path) {
    return (
      <Link
        to={path || ""}
        type="button"
        className={classnames("cursor-pointer inline-block", className, size, styles.buttonLink, {
          "flex items-center": flex,
          "text-gray cursor-not-allowed": disabled,
          "text-pelorous": !disabled,
        })}
        disabled={disabled}
        onClick={() => {
          if (disabled) {
            return false;
          }
        }}
      >
        {content}
      </Link>
    );
  }

  return (
    <div
      className={classnames(
        "cursor-pointer inline-block",
        className,
        size,
        styles.buttonLink,
        disabled ? "text-gray cursor-not-allowed" : color,
        {
          "flex items-center": flex,
        }
      )}
      onClick={() => {
        if (disabled) {
          return false;
        }
        if (newTabPath) {
          const win = window.open(newTabPath, "_blank");
          win.focus();
        } else {
          if (onClick) {
            onClick();
            return;
          }
        }
        if (onClick) {
          onClick();
          return;
        }
      }}
    >
      {content}
    </div>
  );
};

export default ButtonLink;
