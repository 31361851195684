import React from "react";
import {
  TotalSalesChart,
  TopLocationsChart,
  TotalTopUpsChart,
  TotalReturnCreditsChart,
  CheckInsChart,
  TopProductsChart,
  TopSpendingGuestsChart,
  // LowOrOutOfStockChart,
  TopCategoriesBySalesChart,
  TotalSalesOfLocationChart,
} from "./chart";
import ChartContainer from "./chart-container";
import lang from "translations";
import classnames from "classnames";
import { Tooltip } from "components";
import { Title, Text } from "components/commons";
import { formatNumberWithComma } from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";

const DashboardCharts = ({
  graphOfTotalSales,
  graphOfTotalSalesOfLocation,
  topLocationsBySales,
  graphOfTopUpSales,
  graphOfReturnCredits,
  graphOfActivatedGuest,
  topProductsBySales,
  topSpendingGuest,
  outOfStockProducts,
  topCategoriesBySales,
  averageOrderValue,
  totalTransactionsValue,
  totalUnspentCredits,
  totalCreditsReceived,
  totalCreditsKept,
  hasLocations,
}) => {
  return (
    <div className="grid grid-cols-12 gap-4">
      {hasLocations ? (
        <div className={classnames("md:col-span-12 col-span-12")}>
          <TotalSalesOfLocationChart {...graphOfTotalSalesOfLocation} />
        </div>
      ) : (
        <div className={classnames("lg:col-span-8 md:col-span-8 col-span-12")}>
          <TotalSalesChart {...graphOfTotalSales} />
        </div>
      )}

      {!hasLocations && (
        <div className="col-span-12 md:col-span-4 ">
          <TopLocationsChart {...topLocationsBySales} />
        </div>
      )}
      <div className="md:col-span-4 col-span-12">
        <TotalTopUpsChart {...graphOfTopUpSales} />
      </div>
      <div className="md:col-span-4 col-span-12">
        <TotalReturnCreditsChart {...graphOfReturnCredits} />
      </div>
      <div className="md:col-span-4 col-span-12">
        <CheckInsChart {...graphOfActivatedGuest} />
      </div>
      <div className="md:col-span-4 col-span-12">
        <ChartContainer title={lang.totalUnspentCredits} {...totalUnspentCredits} h="auto">
          <Text className="text-sm text-gray">
            <lang.Translate
              text={lang.totalUnspentCreditsDesc}
              items={[
                <Tooltip
                  title={lang.propertyManagementSystemExample}
                  placement="topRight"
                  className="text-sm underline cursor-pointer"
                >
                  {lang.propertyManagementSystem}
                </Tooltip>,
              ]}
            />
          </Text>
          <div className="flex items-center">
            <Title xl className="mt-sm" fontWeight="font-normal">
              {formatNumberToMoneyWithCurrencySymbol(totalUnspentCredits.result?.data || 0)}
            </Title>
            <Tooltip
              className="cursor-pointer"
              title="This value can still change if guests are still currently spending credits on your property."
            >
              <span className="icon-warning text-gray-400 text-xs ml-xs"></span>
            </Tooltip>
          </div>
        </ChartContainer>
      </div>
      <div className="md:col-span-4 col-span-12">
        <ChartContainer title={lang.averageOrderValue} {...averageOrderValue} h="auto">
          <Title xl className="mt-sm" fontWeight="font-normal">
            {formatNumberToMoneyWithCurrencySymbol(averageOrderValue.result?.data || 0)}
          </Title>
        </ChartContainer>
        <ChartContainer
          title={lang.totalCreditsReceived}
          {...totalCreditsReceived}
          tooltipText={lang.totalCreditsReceivedTooltip}
          h="auto"
          className="mt-2"
        >
          <Title xl className="mt-sm" fontWeight="font-normal">
            {formatNumberToMoneyWithCurrencySymbol(totalCreditsReceived.result?.data || 0)}
          </Title>
        </ChartContainer>
      </div>
      <div className="md:col-span-4 col-span-12">
        <ChartContainer title={lang.totalTransactions} {...totalTransactionsValue} h="auto">
          <Title xl className="mt-sm" fontWeight="font-normal">
            {formatNumberWithComma(totalTransactionsValue.result?.data || 0)}
          </Title>
        </ChartContainer>
        <ChartContainer
          title={lang.totalCreditsKept}
          {...totalCreditsKept}
          tooltipText={lang.totalCreditsKeptTooltip}
          h="auto"
          className="mt-2"
        >
          <Title xl className="mt-sm" fontWeight="font-normal">
            {formatNumberToMoneyWithCurrencySymbol(totalCreditsKept.result?.data || 0)}
          </Title>
        </ChartContainer>
      </div>
      <div className="md:col-span-6 col-span-12">
        <TopSpendingGuestsChart {...topSpendingGuest} />
      </div>
      {/* <div className="md:col-span-6 col-span-12">
        <LowOrOutOfStockChart {...outOfStockProducts} />
      </div> */}
      <div className="md:col-span-6 col-span-12">
        <TopCategoriesBySalesChart {...topCategoriesBySales} />
      </div>

      <div className="md:col-span-6 col-span-12">
        <TopProductsChart {...topProductsBySales} />
      </div>
    </div>
  );
};

export default DashboardCharts;
