import { timeAgo, formatName, formatNumberToOrdinal } from "services";
import lang from "translations";
import * as moment from "moment";
import { DateTime } from "enums";
import { dateTimeRequest, filterRequest, selectToObjectRequest } from "./common.mapper";

export const additionalChargeResponseMapper = {
  id: { key: "id" },
  name: { key: "name" },
  addToCart: { key: "addToCart" },
  calculateMethod: { key: "calculateMethod" },
  venueId: { key: "venueId" },
  locations: { key: "locations" },
  locationNames: {
    transform: ({ src }) => {
      if (src.isActiveAllLocations) {
        return lang.allLocations;
      } else {
        return `${src.locations ? src.locations.map((x) => x.locationName).join(", ") : "-"}`;
      }
    },
  },
  locationIds: {
    transform: ({ src }) => {
      return src.locations ? src.locations.map((x) => ({ value: x.locationId })) : [];
    },
  },
  isActiveAllLocations: { key: "isActiveAllLocations" },
  status: { key: "status" },
  value: { key: "value" },
  type: { key: "type" },
  showInPos: { key: "showInPos" },
  transactionId: {
    transform: ({ src }) => {
      return src.transaction ? src.transaction.transactionId : "";
    },
  },
  transactionDate: {
    transform: ({ src }) => {
      return src.transaction ? timeAgo(src.transaction.deviceUpdatedDate, true) : "";
    },
  },
  guest: {
    transform: ({ src }) => {
      if (src.transaction && src.transaction.guest) {
        const { guest = {}, userTagUseCount = 0, userTagUid } = src.transaction;
        const { firstName, lastName, email, dob, phone, phoneCountryCode } = guest || {};
        const guestName = formatName(firstName, lastName);
        const name =
          guestName ||
          (userTagUseCount
            ? lang.populate(lang.nthGuestWhoUseThisTag, [formatNumberToOrdinal(userTagUseCount)])
            : "Unavailable");

        let postpaidCreditLimit = null;
        guest?.guestCheckins?.forEach((guestCheckin) => {
          guestCheckin.guestTags?.forEach((guestTag) => {
            postpaidCreditLimit = guestTag.postpaidCreditLimit;
          });
        });

        return {
          name,
          postpaidCreditLimit,
          tagUid: userTagUid,
          hasName: Boolean(guestName),
          email,
          phone: `${phoneCountryCode ? `+${phoneCountryCode.replace("+", "")}` : ""} ${
            phone || ""
          }`.trim(),
          dob: dob ? moment(dob).format(DateTime.A) : "",
        };
      }
      return "";
    },
  },
  staffName: {
    transform: ({ src }) => {
      if (src.transaction && src.transaction.staff) {
        const { fullName } = src.transaction.staff;
        return `${fullName}`;
      }
      return "";
    },
  },
  locationName: {
    transform: ({ src }) => {
      if (src.transaction && src.transaction.location) {
        const { locationName } = src.transaction.location;
        return `${locationName}`;
      }
      return "";
    },
  },
  chargeAmount: { key: "chargedAmount" },
};

export const additionalChargesReportListRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  chargeIds: selectToObjectRequest("charges"),
  locationIds: selectToObjectRequest("locations"),
  staffIds: selectToObjectRequest("staffs"),
  orders: {
    transform: ({ src }) => {
      console.log(src);
      if (src.sort.key === "transactionDate") {
        return { [`t.deviceUpdatedDate`]: src.sort.value };
      }
      if (src.sort.key === "locationName") {
        return { [`l.locationName`]: src.sort.value };
      }
      return { [`${src.sort.key}`]: src.sort.value };
    },
  },
};
