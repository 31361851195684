import React, { memo } from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import styles from "./simple-table.module.scss";
import { Text } from "components";
import classNames from "classnames";

export const SimpleTable = ({
  addBorder,
  addBorderContent,
  data,
  columns,
  className,
  style,
  showHeader = true,
  content,
  useKeys,
  breakAll = false,
  minWidth,
}) => {
  return (
    <div className={classNames(className, "overflow-x-auto")}>
      <table className={classnames("table-fixed table w-full")} style={{ minWidth }}>
        {showHeader && (
          <thead>
            <tr>
              {columns.map((c) => {
                return (
                  <th
                    key={uuidv4()}
                    className={classnames(
                      "uppercase text-xs font-medium text-gray align-bottom py-sm",
                      c.width,
                      c.headerClassName,
                      {
                        "border-solid border-b border-gray-lightest": addBorder,
                      }
                    )}
                  >
                    {c.text}
                  </th>
                );
              })}
            </tr>
          </thead>
        )}

        {content || (
          <tbody>
            {data.map((row, rowIndex) => {
              const previousHeader = data[rowIndex - 1]?.header;
              const currentHeader = row.header;
              return (
                <>
                  {row.header && currentHeader !== previousHeader ? (
                    <Text className="mx-lg py-sm text-gray">{row.header}</Text>
                  ) : null}
                  <tr
                    key={useKeys ? `${useKeys}-row-${rowIndex}` : uuidv4()}
                    className={classnames(styles.row, {
                      [`${styles.removeHighlightOnHover}`]: row._highlightRemove,
                    })}
                  >
                    {columns.map((c, columnIndex) => {
                      return (
                        <td
                          key={useKeys ? `${useKeys}-column-${rowIndex}${columnIndex}` : uuidv4()}
                          className={classnames("text-sm align-top break-word", c.className, {
                            "border-solid border-gray-lightest":
                              addBorder && rowIndex + 1 !== data.length,
                            "break-all": breakAll,
                            "border-solid border-b border-gray-lightest":
                              addBorderContent && rowIndex !== data?.length - 1,
                          })}
                        >
                          {row[c.key]}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default memo(SimpleTable);
