import { Component, useMemo } from "react";
import {
  Text,
  SimpleTable,
  Checkbox,
  Panel,
  Title,
  Icon,
  Field,
  Button,
  Container,
} from "components";
import lang from "translations";
import styles from "./receipt-setting.module.scss";
import { StyleType } from "enums";
import { prettifyReceiptOptions } from "services";
import TextField from "@mui/material/TextField";

class TextFieldWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.settings.value[this.props.settingKey].customValue,
    };
    this.updateValue = this.updateValue.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  updateValue(e) {
    this.setState({
      value: e.target.value,
    });
  }

  handleValueChange(e) {
    const newSettings = this.props.settings;
    newSettings.value[this.props.settingKey].customValue = e.target.value;
    this.props.modifyField(this.props.settingsType, newSettings);
  }

  render() {
    return (
      <TextField
        value={this.state.value}
        id="standard-basic"
        label={lang.customLabel}
        variant="standard"
        onChange={this.updateValue}
        onBlur={this.handleValueChange}
        fullWidth
      />
    );
  }
}

const ReceiptSettingForm = ({
  fields,
  modifyField,
  submitForm,
  previewVisible,
  onChangeVisibility,
}) => {
  const headerData = useMemo(() => {
    const keys = Object.keys(fields.headerSettings.value);
    return keys.map((key) => {
      const d = fields.headerSettings.value[key];
      const isCustom = d.name === "CUSTOM_HEADER";
      return {
        name: (
          <div>
            {isCustom ? (
              <TextFieldWrapper
                className="pl-1"
                settingKey={key}
                settings={fields.headerSettings}
                settingsType="headerSettings"
                modifyField={modifyField}
              />
            ) : (
              <Title md className="pl-1">
                {prettifyReceiptOptions(d.name)}
              </Title>
            )}
          </div>
        ),
        isIncluded: (
          <div className="text-center ml-auto mr-0">
            <Field>
              <Checkbox
                {...fields.headerSettings.value[key].isIncluded}
                name="isIncluded"
                value={fields.headerSettings.value[key].isIncluded}
                className={"m-auto"}
                disabled={d.name === "VENUE_NAME" || d.name === "BUSINESS_NAME"}
                onChange={(name, value) => {
                  const newHeaderSettings = fields.headerSettings;
                  newHeaderSettings.value[key].isIncluded = value.value;
                  modifyField("headerSettings", newHeaderSettings);
                }}
              />
            </Field>
          </div>
        ),
      };
    });
  }, [fields.headerSettings, modifyField]);

  const footerData = useMemo(() => {
    const keys = Object.keys(fields.footerSettings.value);
    return keys.map((key) => {
      const d = fields.footerSettings.value[key];
      const isCustom = d.name === "CUSTOM_FOOTER";
      return {
        ...d,
        name: (
          <div>
            {isCustom ? (
              <TextFieldWrapper
                settingKey={key}
                settings={fields.footerSettings}
                settingsType="footerSettings"
                modifyField={modifyField}
              />
            ) : (
              <Title md className="pl-1">
                {prettifyReceiptOptions(d.name)}
              </Title>
            )}
          </div>
        ),
        isIncluded: (
          <div className="text-center ml-auto mr-0">
            <Field>
              <Checkbox
                {...fields.footerSettings.value[key].isIncluded}
                name="isIncluded"
                value={fields.footerSettings.value[key].isIncluded}
                className={"m-auto"}
                onChange={(name, value) => {
                  const newFooterSettings = fields.footerSettings;
                  newFooterSettings.value[key].isIncluded = value.value;
                  modifyField("footerSettings", newFooterSettings);
                }}
              />
            </Field>
          </div>
        ),
      };
    });
  }, [fields.footerSettings, modifyField]);

  const salesData = useMemo(() => {
    const keys = Object.keys(fields.salesSettings.value);
    return keys.map((key) => {
      const d = fields.salesSettings.value[key];
      return {
        ...d,
        name: (
          <Title md className="pl-1">
            {prettifyReceiptOptions(d.name)}
          </Title>
        ),
        isIncluded: (
          <div className="text-center ml-auto mr-0">
            <Field>
              <Checkbox
                {...fields.salesSettings.value[key].isIncluded}
                name="isIncluded"
                value={fields.salesSettings.value[key].isIncluded}
                className={"m-auto"}
                onChange={(name, value) => {
                  const newSalesSettings = fields.salesSettings;
                  newSalesSettings.value[key][name] = value.value;
                  modifyField("salesSettings", newSalesSettings);
                }}
              />
            </Field>
          </div>
        ),
      };
    });
  }, [fields.salesSettings, modifyField]);

  return (
    <>
      <Panel>
        <div className="flex justify-between">
          <Title md className="mb-sm">
            {lang.formatting}
          </Title>
          {/* <ButtonLink size="text-sm">
            <Image
              className="ml-3"
              src={ManageBusiness}
              style={{ width: "20px", height: "20px" }}
            />
            <Text color="text-green-500">{lang.manageBusinessInfo}</Text>
            <Icon name="arrow-diagonal-right" className="text-green-500 text-xxs ml-xs" />
          </ButtonLink> */}
        </div>
        <Text color="" size="text-sm" className="">
          {lang.customizeSectionReceipt}
        </Text>
        <SimpleTable
          showHeader
          className="-mx-5 mt-sm"
          columns={[
            {
              text: (
                <Title md className="mb-sm text-gray capitalize">
                  {lang.headerSection}
                </Title>
              ),
              key: "name",
              className: styles.tableItem,
              headerClassName: "pl-lg",
            },
            {
              text: (
                <div className="text-center">
                  <Title md className="mb-sm ml-md text-gray capitalize">
                    {lang.include}
                  </Title>
                </div>
              ),
              key: "isIncluded",
              className: styles.tableItem,
            },
          ]}
          data={headerData}
        />
        <SimpleTable
          showHeader
          className="-mx-5 mt-sm"
          columns={[
            {
              text: (
                <Title md className="mb-sm text-gray capitalize">
                  {lang.footerSection}
                </Title>
              ),
              key: "name",
              className: styles.tableItem,
              headerClassName: "pl-lg",
            },
            {
              key: "isIncluded",
              className: styles.tableItem,
            },
          ]}
          data={footerData}
        />
      </Panel>
      <Panel>
        <Title md className="mb-sm">
          {lang.salesInformation}
        </Title>
        <Text color="" size="text-sm" className="">
          {lang.chooseInformation}
        </Text>
        <SimpleTable
          showHeader
          className="-mx-5 mt-sm"
          columns={[
            {
              key: "name",
              className: styles.tableItem,
              headerClassName: "pl-lg",
            },
            {
              key: "isIncluded",
              className: styles.tableItem,
            },
          ]}
          data={salesData}
        />
      </Panel>
      <Container className={"flex flex-row-reverse"} mb={0} paddingless={true}>
        <div className="flex align-center">
          <Button
            type={StyleType.Primary}
            onClick={() => {
              submitForm();
            }}
          >
            {lang.save}
          </Button>
        </div>
        {!previewVisible ? (
          <div className="flex align-center">
            <Button type={StyleType.Link}>
              <div
                className="flex items-center text-blue cursor-pointer"
                onClick={() => onChangeVisibility(!previewVisible)}
              >
                <Text color="text-blue">{lang.viewPreview}</Text>
                <Icon name={previewVisible ? "eye-close" : "eye-open"} />
              </div>
            </Button>
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default ReceiptSettingForm;
