import { RouteRender } from "components";
import InvoiceDetail from "modules/invoices-siigo/detaill";
import InvoicesModule from "modules/invoices-siigo/invoices";
import { Path } from "paths";
import React from "react";

export const SiigoInvoicesPage = () => {
  return (
    <RouteRender
      defaultRedirect={Path.SIIGO_INVOICES}
      renders={{
        [Path.SIIGO_INVOICES]: {
          exact: "true",
          component: InvoicesModule,
        },
        [Path.SIIGO_INVOICE_DETAIL]: {
          exact: "true",
          component: InvoiceDetail,
        },
      }}
    />
  );
};
