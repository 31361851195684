export const locale = {
  aWholeProductIsFinished:
    "一个完整的产品指的是在你的场所销售的成品。你可以选择出售这个产品的不同变体，比如口味、颜色和尺寸。所有的整体产品和它们的变体都会有各自的供应品项。",
  accessControl: "访问管理",
  accessControlReport: "访问管理报告",
  accessControlRoleAppDesc: "这个角色无法访问在线场馆仪表板。 ",
  accessControlRoleDashboardDesc: "允许访问场所“应用程序的检查访问”",
  accessPoint: "访问点",
  accessPointCreateSuccess: "成功创建访问点",
  accessPointExisted: "该访问点已经存在。请选择一个不同的名称",
  accessPointName: "访问点名称",
  accessToken: "访问令牌/标识",
  accommodation: "住宿",
  account: "账户",
  accountAlreadyExists: "这个电子邮箱已与此场所的账户有关联",
  accountCantSeemToFind: "糟糕！我们似乎找不到您的账户。请联系场馆经理寻求帮助。",
  accountCode: "账户代码",
  accountCodeAddedName: "账户代码“{0}”已添加。",
  accountCodeAlreadyExist: "账户代码已经存在。",
  accountEmailAdded: "账户“{0}”已添加。",
  accountEmailRemoved: "账户“{0}”已移除。",
  accountIsActive: "账户处于活动状态",
  accountMapping: "账户映射",
  accountMappingDescription: "设置并将您的账户映射到PouchVENUE系统。",
  accountName: "账户名称",
  accountNotFound: "找不到账户",
  accountSettings: "账户设置",
  accountType: "账户类型",
  accountingSoftware: "会计软件",
  accounts: "帐户",
  accrNo: "Accr#:",
  actions: "动作",
  activateInPos: "在 POS 中激活",
  activateOnOnlineMenu: "在在线菜单上激活",
  activateOnPOS: "在销售点 (POS) 上激活",
  activateOnPos: "在销售点 (POS) 上激活",
  activateVariant: "激活变体",
  active: "激活",
  activeDateRange: "激活日期范围",
  actualClosingBalance: "实际期末余额",
  actualQty: "实际数量",
  actualStockQty: "实际库存数量",
  add: "添加",
  addANote: "添加注释",
  addAProduct: "添加产品",
  addASupplyItem: "添加补给品",
  addAValidAttributeAndOptions: "添加有效属性和选项。",
  addAWholeProduct: "添加整个产品",
  addAccount: "添加帐户",
  addAndManageAdditionalCharge: "添加和管理 POS 销售的额外费用。",
  addAnotherAccount: "添加另一个帐户",
  addAnotherAttribute: "添加另一个属性",
  addAnotherProductOrSupplyItem: "添加另一个产品或供应品",
  addAnotherSupplyItem: "添加另一个供应品项",
  addCharge: "添加费用",
  addCustomQuestion: "添加自定义问题",
  addNewAccount: "添加新账户",
  addNewAccountCode: "添加新账户代码",
  addNewAccountCodeName: "添加新账户代码“{0}”",
  addNewPaymentMethod: "添加新付款方式",
  addNewSupplyItem: "添加新供应品项",
  addNewSupplyItemSelect: "添加新供应品项“{0}”",
  addNewTax: "添加新税收",
  addNote: "添加备注",
  addOption: "添加选项",
  addOptions: "添加选项",
  addPaymentMethod: "添加付款方式",
  addProduct: "添加产品",
  addProductLimit:
    "要添加更多产品，请尝试删除一些产品以创建新产品，或者请您的场馆业主增加您的产品限制！",
  addProductLimitOwner:
    "要添加更多产品，请尝试删除一些产品以创建新产品，或者升级您的计划以增加您的产品限制！",
  addProducts: "在此产品集中添加产品或供应品项。",
  addQty: "添加数量",
  addStaffLimit: "要添加更多员工，请尝试删除一些员工或请您的场馆业主增加您的员工限制！",
  addStaffLimitOwner: "要添加更多员工，请尝试删除一些员工或升级您的计划以增加您的员工限制！",
  addSupplyItem: "添加供应品项",
  addThisProduct: "将此产品添加为新产品和供应品项。",
  addVariant: "添加变体",
  addedStock: "已添加库存",
  additionalChargeReport: "附加费用报告",
  additionalCharges: "附加费用",
  adjustQuantity: "调整数量",
  adjustStocksuccessfully: "成功调整库存",
  adjustTheSupplyItemsVariant: "调整这个特定变体的供应品项和数量。",
  advancedReporting: "高级报告",
  all: "所有",
  allAccessPoints: "所有访问点",
  allAccountTypes: "所有账户类型",
  allCategories: "所有类别",
  allDevices: "所有设备",
  allDevicesUsed: "所有已使用设备",
  allDiscounts: "所有折扣",
  allGuestProfiles: "所有客人档案",
  allLocation: "所有地点、位置",
  allLocations: "所有地点、位置",
  allMeasurements: "所有测量",
  allPaymentMethods: "所有付款方式",
  allProducts: "所有产品",
  allReportsReadyForDownload: "所有报告均已准备好下载",
  allResponse: "所有回应",
  allRightsReserved: "版权所有",
  allRoles: "所有角色",
  allSafeToDeleteLocation: "全部安全！此地点、位置没有任何产品。",
  allSelectedAvailablePos: "所有选定的产品将在销售终端（POS) 上可用。",
  allSelectedNotPos: "所有选定的产品将不会显示在您的销售终端（POS）上。",
  allShiftsClosed: "所有班次已关闭",
  allSources: "所有来源",
  allStaff: "所有员工",
  allStatus: "所有状态",
  allStockLevels: "所有库存水平",
  allTransactionTypes: "所有交易类型",
  allTransactions: "所有交易",
  allVenues: "所有场馆",
  allVoucherTypes: "所有礼券类型",
  allVouchers: "所有礼券",
  allowAccess: "允许访问",
  allowAccessAndConnect: "允许访问并连接",
  allowAndSetTip: "允许小费和设置百分比",
  allowBargainDesc: "通过勾选此项，员工可以更改销售终端 （POS）中商品的价格",
  allowBargainOnThisProduct: "允许该产品议价",
  allowCustomTip: "允许客人添加自定义小费",
  allowGuestProfile: "允许客户档案",
  allowGuestWBKeeping: "允许客人在退房、登出时保留其标签。",
  allowOrRestrictGuestWBKeeping: "允许或限制客人在退房、登出时保留其标签。",
  allowPostpaidAndLimit: "允许后付信用额并设置限额。",
  allowPostpaidInGuestProfile:
    "允许此客户档案在没有信用额的情况下购买。 当他们从场地、场馆结账时，他们将被收费。",
  allowThisGuestProfileLimit:
    "允许此客户档案在没有信用额的情况下购买。 当他们从场地、场馆结账时，他们将被收费。",
  allowedAccessPoint: "允许访问点",
  allowedGuestProfile: "允许的客户档案",
  allowedPaymentMethod: "允许的付款方式",
  almostThere: "几乎到达了！",
  alreadyExists: "已经存在。",
  amount: "金额",
  anHourAgo: "一个小时前",
  and: "和",
  andManyMore: "以及更多！",
  andWouldBe: "将会",
  anyMoreQuestions: "还有其他问题吗？ {0}",
  appDate: "应用程序安装/更新日期",
  appReinstall: "重新安装应用程序",
  appVersion: "应用程序版本",
  applyFilter: "应用筛选器",
  areYouSureYouWantToDeleteCharge: "您确定要删除收费项目 {0} 吗？",
  areYouSureYouWantToDeleteLocation: "您确定要删除{0}吗？该场地、位置当前的库存价值为{1}。",
  areYouSureYouWantToDeleteProduct: "您确定要删除{0}吗？该供应品目前库存中有{1} {2}。",
  areYouSureYouWantToDeleteProduct2:
    "您确定要删除{0}吗？它将不再在您的场馆中可用。之前的交易和报告不会受影响。",
  areYouSureYouWantToRemoveVariant: "您确定要删除“{0}”变体吗？其供应品不会被删除。",
  arrivalDate: "到达日期",
  arrivals: "抵达、到达",
  askDeleteGuestProfile: "删除客户档案？",
  askDeleteStaffProfile: "删除员工档案？",
  askGuestInfoCheckIn: "在办理入住手续时询问客人的信息。",
  askVenueOwnerToUpgradePlan: "要求场馆业主升级您的计划并开始使用此功能。",
  assemble: "组装",
  assembleAProduct: "组装产品",
  asset: "资产",
  assignAProfile: "分配一个档案",
  assignFreeCreditsStaff: "将免费积分分配给该组的员工",
  assignVoucherStaff: "将礼券分配给该组的员工",
  assignVoucherToGuest: "在注册、入住时将礼券分配给该组的客人。",
  assignedRooms: "已分配的房间/床",
  assignedSpace: "已分配空间",
  atLeast1GuestProfile: "至少需要1个客户档案",
  attribute: "属性",
  attributeAlreadyExists: "属性名称已存在于此产品中",
  automaticallyAddThisChargeToTheCart: "是否自动将此费用添加到购物车？",
  automaticallyAddThisChargeToTheCartDesc:
    "一旦第一个商品添加到购物车，此费用将自动添加。 员工可以移除此费用。",
  automaticallyGenerateSku: "自动生成SKU（库存单位）",
  availability: "可用性",
  availabilityOnPos: "销售终端（POS）上可用",
  available: "可用的",
  availableAnytime: "随时可用",
  availableEveryday: "每天均可用",
  availableOnPos: "在销售点销售终端 (POS）上可用",
  averageAmountPerItem: "平均每件物品金额",
  averageDurationOfStay: "平均停留时长",
  averageOrderValue: "平均订单价值",
  awaiting: "等待中",
  awaitingInviteResponse: "等待邀请回应。",
  back: "返回",
  backOffice: "后勤办公室",
  backOfficeRoleAppDesc: "此角色与应用程序无关，并且将无法在设备上登录。",
  backOfficeRoleDashboardDesc:
    "允许访问场馆仪表板的见解、销售、库存、位置、员工、客户档案、礼券、折扣、打印机配置文件和场馆设置",
  balance: "平衡、差额",
  balanceMovementsDescription: "查看来宾余额所在的场馆群中来自哪个场馆",
  balanceMovementsIn: "平衡、剩余内的移动",
  balanceMovementsInPage: "平衡、剩余内的移动",
  balanceMovementsOut: "平衡、剩余外的移动",
  balanceMovementsOutPage: "平衡、剩余外的移动",
  banATag: "禁用标签",
  banDetails: "禁用详细信息",
  banList: "禁用列表",
  banTag: "禁用标签",
  banTagListSettings: "禁用标签列表设置",
  banTags: "禁用标签",
  bannedBy: "由...封禁",
  bannedTags: "已封禁标签",
  banningThisTagWillOnlyTakeEffect:
    "封禁此标签将在您的设备下次同步和更新时生效（通常在连接到互联网时，每5分钟同步一次）。为了立即反映这些更改，请在封禁此标签后同步并更新您的设备。",
  bargainAllowed: "允许议价",
  bargainRequiresApproval: "议价需批准",
  bargainRequiresApprovalOnThisProduct: "在 销售终端（POS） 更改该商品价格时需要经理授权",
  basicInfo: "基本信息",
  belowParLevel: "低于标准水平",
  billAmount: "账单金额",
  birthdate: "出生日期",
  birthday: "生日",
  bookedBy: "预订者",
  bookingAccomodation: "预订住宿",
  bookingAmount: "预订金额",
  bookingDescription: "跟踪您场馆的每个预订",
  bookingDetails: "预订详细信息",
  bookingGuests: "预订客人",
  bookingId: "预订ID",
  bookingList: "预订列表",
  bookingNo: "预订编号",
  bookingPrice: "预订价格",
  bookingStatus: "预订状态",
  bookingSummary: "预订摘要、总结",
  bookings: "预订",
  bottle: "瓶",
  browseProducts: "浏览产品",
  browseVouchers: "浏览礼卷、优惠券",
  businessAddress: "营业地址",
  businessContactNumber: "营业联系电话",
  businessDay: "营业日",
  businessDetails: "营业详细信息",
  businessName: "营业名称",
  byAssemblingAProduct:
    "通过组装个别产品，您可以创建具有多个供应品的产品，也可以拆分具有变体的单个供应品。",
  byCreatingPrinterProfiles:
    "通过创建打印机配置文件，您可以设置在订单单上为您的不同场地站点（如厨房、酒吧等）打印哪些产品。",
  cacheCleared: "缓存已清除",
  cancel: "取消",
  cancelBanTag: "取消禁用标签",
  cancelCloseShift: "取消关闭班次",
  cancelled: "取消了",
  cannotBeDeleted: "不能删除。",
  cannotDeletePartOfComposite: "{0} 无法删除，因为它是 {1} 的组合 {2} 的一部分。",
  cantFindDesc: "找不到所需内容？尝试其他搜索或筛选。",
  capacity: "容量",
  card: "卡",
  cardApprovalNumber: "卡批准号",
  cardNumber: "卡号",
  cash: "现金",
  cashSales: "现金销售",
  cashlessTransactionsWithDoor: "门锁激活的无现金交易！",
  categories: "分类",
  categoriesDeleted: "删除了 {0} 个分类。",
  categorizeAndManage: "使用会计账户表对每笔交易进行分类和管理。",
  category: "种类",
  categoryCreated: "已创建种类“{0}”。",
  categoryDeleted: "已删除种类“{0}”。",
  categoryList: "种类列表",
  categoryName: "种类名称",
  categoryNameAlreadyExist: "种类名称已存在。",
  categoryNameReserved: "种类名称已保留，请使用其他名称。",
  categoryReport: "种类报告",
  categoryUpdated: "已更新种类“{0}”。",
  change: "更改",
  changePassword: "更改密码",
  changePhoto: "更改照片",
  changesSaved: "更改已保存。",
  changesSavedSuccessfully: "更改已成功保存",
  changingCurrencyWontAffect: "更改货币不会影响产品的价值价格，只会更改其货币符号。",
  charge: "收费",
  chargeAmount: "收费金额",
  chargeCalculation: "收费计算",
  chargeDeleted: "删除收费 {0}",
  chargeItem: "收费项目",
  chargeName: "收费名称",
  chargeTaxesOnThisProduct: "在该产品上收取税费",
  chartOfAccounts: "会计账户表",
  chartOfAccountsDescription: "更新并管理您的会计账户表。",
  checkInDate: "办理入住日期",
  checkInDetails: "办理入住详情",
  checkInQuestions: "办理入住问题",
  checkInSettings: "办理入住设置",
  checkIns: "入住登记",
  checkOutDetails: "办理退房详情",
  checkOutTheGuide: "查看连接整合指南。",
  checkOuts: "退房",
  checkStocks: "查看存货",
  checkYourEmail: "查看您的电子邮箱",
  checkYourEmailAt: "请在 {0} 检查您的电子邮箱以完成账户设置。",
  checkYourEmailOrAdmin: "请检查您的电子邮箱或联系您的管理员。",
  checkbox: "复选框",
  checkedIn: "已入住",
  checkedInBy: "登记者",
  checkedInDate: "入住日期",
  checkedOut: "已退房",
  checkedOutBy: "退房者",
  checkedOutDate: "退房日期",
  checkedOutOn: "在...退房",
  checkinAmount: "办理入住金额",
  checkinId: "入住ID",
  checkingYourInternet: "检查您的网络或数据连接。",
  checkout: "退房",
  checkoutOurEbook: "查看我们的电子书，了解如何优化您的场馆！",
  chooseAPassword: "请使用您在其他地方未使用的高强度密码。",
  chooseAReason: "选择原因",
  chooseASimpleOrMultipleItem: "选择一个或多个供应品以创建产品。",
  chooseAVenue: "选择场地",
  chooseAtleast1Location: "选择至少1 个位置",
  chooseCategoryColor: "选择一个类别颜色：",
  chooseColumns: "选择您想在这份报告中看到的列。",
  chooseInformation: "选择要包含在销售明细中的信息。",
  chooseLocationForThisStaff: "为这位员工选择指定地点",
  choosePassword: "选择密码",
  choosePaymentMethod: "选择付款方式",
  chooseProductsCanBeDiscounted: "选择您库存中可以打折的产品。",
  chooseRole: "为这位员工选择角色",
  chooseTheLocationWhereThisProduct: "选择这个产品销售的位置、场地，以及库存被扣除的地方。",
  chooseVouchers: "选择代金券、优惠卷",
  cityStateCountry: "城市、州、邮政编码",
  clear: "清除",
  clearAllFilters: "清除所有过滤器",
  clearDownloads: "清除下载",
  clearedTags: "清除标签",
  clearedUnusedAndExpiredCredits: "已清除、未使用和过期的积分",
  clearedUnusedAndExpiredCreditsMessage: "对于充值但已清除、不可退还或过期的积分。",
  clearedUnusedAndExpiredCreditsPlaceholder: "其他收入（到期）",
  clearedUnusedExpiredCredit: "其他收入（到期）",
  clickHereToRetry: "点击此处重试。",
  close: "关闭",
  closePreview: "关闭预览",
  closeReceiptPreview: "关闭收据预览",
  closeShift: "关闭/结束班次",
  closeShiftWarning:
    "在强制关闭、结束班次之前确保所有交易已同步，并且只有在应用程序无法关闭班次时才使用此功能。",
  cloudbeds: "cloudbeds",
  code: "代码",
  coffeeBeans: "咖啡豆",
  columns: "列",
  combineYourProducts: "将您的产品组合成套出售。",
  comingSoon: "即将推出",
  communityGuidelines: "社区准则",
  companyDetails: "公司详情",
  companyRegistrationNo: "公司注册号",
  completeStocktake: "完成库存盘点",
  completeStocktakeConfirmation: "完成{0}？",
  completeSubscription: "完成订阅",
  completeYourSubscription: "完成订阅以访问您的场馆，",
  completed: "完成",
  completedBy: "完成者",
  completedOn: "完成日期",
  composite: "复合",
  composition: "成分、组成",
  compositionGuide: "成分、组成指南",
  confirm: "确认",
  confirmImport: "确认导入",
  confirmImportDesc: "您确定要导入供应品吗？",
  confirmPassword: "确认密码",
  confirmStockAdjustment: "确认库存调整",
  confirmTransfer: "确认转移",
  confirmationPending: "确认待处理",
  confirmed: "已确认",
  connect: "连接",
  connectAndInstall: "连接并安装",
  connectPurchasePlus: "将Purchase Plus连接到您的PouchNATION账户。",
  connected: "已连接",
  contactNumber: "联系电话",
  contactUs: "联系我们",
  contactUs2: "联系我们",
  continue: "继续",
  continueAs: "继续作为{0}",
  continueBanningThisTag: "继续禁止此标签？",
  continuingOnThisPageWillLogout: "在此页面上继续将会登出到您当前的会话期间。",
  copied: "已复制！",
  copyLink: "复制链接",
  copyrightYear: "版权所有 © 2024",
  costOfGoodsSold: "销货成本（COGS）",
  costOfGoodsSoldMessage: "一旦销售，供应产品成本的默认账户。",
  costOfGoodsSoldPlaceholder: "cOGS/销货成本",
  country: "国家",
  createALocation: "创建地点",
  createAccessPoint: "创建访问点",
  createAccount: "创建账户",
  createAccountCode: "创建账户代码",
  createAndManageProducts: "创建和管理可在您的地点出售的产品",
  createAndManageRawMaterials: "为每个场地创建和管理原材料项目及其库存数量。",
  createAndManagerDiscounts: "创建和管理折扣产品的折扣",
  createAndManagerVouchers: "创建和管理将用于兑换产品的代金券、优惠卷",
  createCategoriesToLink: "创建类别以链接和分类您的产品",
  createCategory: "创建类别、分类",
  createDiscountPage: "创建折扣页面",
  createLocation: "创建位置、场地",
  createLocationToStoreYourProducts: "创建一个场地、场馆来存储您的产品。",
  createNewCategory: "创建新类别",
  createNewCharge: "创建新费用",
  createNewDiscount: "创建新折扣",
  createNewGuestProfile: "创建新客人档案",
  createNewLocation: "创建新位置、场地、场馆",
  createNewProduct: "创建新产品",
  createNewProductSet: "创建新产品套装",
  createNewProfile: "创建新档案",
  createNewStaffProfile: "创建新员工档案",
  createNewStocktake: "创建新库存盘点",
  createNewSupplyItem: "创建新供应品",
  createNewVoucher: "创建新优惠卷、代金卷、礼卷",
  createNote: "创建备注",
  createPrinterProfile: "创建打印机配置文件",
  createProduct: "创建产品",
  createProductSet: "创建产品组合",
  createStaffProfile: "创建员工档案",
  createStaffWhoCan: "创建可以充值积分余额、注册客人并在您场所销售的员工。",
  createTheProduct: "创建要在您场所销售的产品。",
  createVenue: "创建场所",
  createVenuePage: "创建场所页面",
  createVoucherPage: "创建礼卷、优惠卷、代金卷页面",
  createVouchersForGuest: "为您的客人和员工创建礼卷！",
  createYourOwnIntegration: "使用PouchCONNECT创建您自己的集成",
  createYourVenue: "创建您的场所",
  createdSupplyItem: "已创建供应品",
  credentialsAreInvalid: "糟糕，某些凭证无效。请输入正确的数据并重试。",
  credit: "信用、积分",
  creditAutomaticallyExpires: "积分在结账后自动失效",
  creditBalance: "积分余额",
  creditDebit: "信用/借记",
  creditDebitSales: "信用/借记销售",
  creditLimits: "积分限额",
  creditSettings: "积分设置",
  credits: "积分",
  creditsDescription: "为这个群组的客人分配免费积分，并设置他们的后付积分限额。",
  creditsExpiration: "积分到期",
  creditsExpirationDescription: "设置积分在客人结帐时失效。",
  creditsExpirationOnCheckout: "结账时积分到期",
  creditsKept: "保留积分",
  creditsKeptByGuests: "客人保留的积分",
  creditsLiabilityAccount: "积分负债账户",
  creditsLiabilityAccountMessage: "所有在销售前充值标签积分的保留账户。",
  creditsLiabilityAccountPlaceholder: "负债/积分",
  creditsNeverExpires: "积分在结账后永不过期。",
  creditsReceived: "收到的积分",
  creditsReceivedFromVenues: "从其他场所收到的积分",
  creditsWouldNeverExpire: "客人当前住宿时积分永不过期。",
  currency: "货币",
  currencyAndFormat: "货币和格式",
  currentGuestProfiles: "当前客人档案",
  currentPassword: "当前密码",
  currentPasswordAndNewPasswordMatched: "当前密码和新密码匹配",
  currentQty: "当前数量",
  custom: "自定义",
  customDateTime: "自定义日期和时间范围",
  customFields: "自定义字段",
  customLabel: "自定义标签",
  customName: "自定义名称",
  customNameIsAlreadyExisting: "自定义名称已存在。",
  customQuestion: "自定义问题",
  customQuestions: "自定义问题",
  customTax: "自定义税",
  customerBehaviourOverTime: "客户行为随时间变化",
  customizeReceipt: "定制您的收据的每个部分。",
  customizeSectionReceipt: "定制您的收据的各个部分。",
  daily: "每日",
  dashboard: "仪表板",
  dataError: "数据错误",
  dataIncorrectly: "数据不正确",
  date: "日期",
  dateAndTime: "日期和时间",
  dateAndTimeOfTransaction: "交易日期和时间",
  dateBanned: "禁止日期",
  dateCreated: "创建日期",
  dateIssuedWithColonSymbol: "发行日期：",
  dateModified: "修改日期",
  dateOfCheckin: "入住、登记日期",
  dateOfCheckout: "退房日期",
  dateOfRegistration: "注册日期",
  dateRange: "日期范围",
  day: "日",
  dayEnd: "日终",
  dayStart: "日开始",
  daySummary: "日总结",
  days: "天",
  daysAfterCheckout: "结账后多少天",
  debitCredit: "借记卡/信用卡",
  default: "默认",
  defaultAccountForTaxes: "每种产品征收的税款的默认账户。",
  defaultFields: "默认字段",
  defaultMode: "默认模式",
  defaultModeSettingDesc: "默认情况下，手机应用程序将设置为此模式：",
  defaultQuestions: "默认问题",
  defaultTableName: "默认表名称",
  defaultTax: "默认税",
  defectiveTag: "有缺陷的标签",
  defectiveTags: "有缺陷的标签",
  delete: "删除",
  deleteAProduct: "删除产品",
  deleteAProduct2: "删除产品？",
  deleteAProductSet: "删除产品组合",
  deleteAccessPoint: "删除接入点",
  deleteAccessPointDesc: "您确定要删除此接入点吗？",
  deleteCategories: "删除{0}类别",
  deleteCategory: "删除类别",
  deleteCharge: "删除费用",
  deleteChargeFailed: "无法删除费用，请稍后重试",
  deleteCurrentlyBeingUsed:
    "{0}目前已被一些产品组合使用。要继续的话，请从以下产品组合中删除此产品。",
  deleteDiscount: "删除折扣",
  deleteGuestProfile: "删除客人档案",
  deleteLocation: "删除位置",
  deleteLocationNote: "注意：您在此场地的先前交易将被保留。",
  deleteName: "删除“{0}”？",
  deleteNote: "删除备注",
  deletePaymentMethod: "删除付款方式",
  deletePrinterProfile: "删除打印机配置文件",
  deleteProduct: "删除{0}吗？",
  deleteProductSet: "删除产品集？",
  deleteProducts: "删除{0}个产品？",
  deleteProducts2: "删除{0}个产品",
  deleteQuestion: "删除问题",
  deleteSelectedCategories: "删除所选类别",
  deleteSelectedCategory: "删除所选类别",
  deleteSelectedProduct: "删除所选产品",
  deleteSelectedProducts: "删除所选产品",
  deleteStaff: "删除员工",
  deleteStaffModalDesc: "您即将移除这（些）员工。 这些员工将不再能访问场馆。 此操作无法撤消。",
  deleteStaffModalNote: "注意：您与这些员工的先前交易将被保留",
  deleteStaffProfile: "删除员工档案",
  deleteStaffSuccessfully: "成功删除员工",
  deleteSupplyItem: "删除供应项",
  deleteTax: "删除税",
  deleteThis: "删除此{0}？",
  deleteThisMultiple: "删除{0} {1}？",
  deleteThisQuestion: "删除此问题？",
  deleteVoucher: "删除优惠券",
  deleted: "已删除",
  deletedPaymentMethod: "付款方式“{0}”已删除。",
  deletedSuccessfully: "删除成功删除",
  deletedTax: "税“{0}”已删除。",
  deletingLoading: "删除中...",
  deletingName: "删除 {0}",
  deletingThisLocation: "删除此场地也将删除所有供应项的库存余额。 此操作无法撤消。",
  denominations: "面额",
  departure: "出发",
  departureDate: "出发日期",
  describeThisProduct: "描述此产品...",
  describeThisProductSet: "描述此产品集",
  description: "描述",
  destinationLocation: "目的地位置、场地",
  destinationLocationNewQty: "目的地位置、场地新数量",
  device: "设备",
  deviceId: "设备ID",
  deviceImei: "设备国际移动设备识别码（IMEI）",
  deviceImeiSerial: "设备国际移动设备识别码（IMEI） / 序列号",
  deviceInfo: "设备信息",
  deviceLimitExceeded:
    "您正在使用{0}台设备，已超出{1}台设备的计划限制。 请联系场馆业主以升级您的计划。",
  deviceLimitExceededManager: "您正在使用{0}台设备，已超出{1}台设备的计划限制。 请升级您的计划。",
  deviceList: "设备列表",
  deviceMac: "设备MAC地址",
  deviceMacAddress: "设备MAC地址",
  deviceName: "设备名称",
  deviceNameAlreadyExists: "此设备名称已存在",
  deviceNameUpdated: "设备“{0}”已更新。",
  deviceSerialNumber: "设备序列号",
  deviceUid: "设备UID",
  devices: "设备",
  difference: "差异",
  directory: "目录",
  disableSellingOnPos: "在销售终端（POS）设备上禁止销售",
  disabled: "已禁用",
  discard: "丢弃",
  discardChanges: "放弃更改",
  discardWristbandKeepingSettings: "放弃手环保留设置",
  disclaimerCategoryReport:
    "免责声明：可能会有将多个类别分配给个别产品，这将导致每个行品目中的双重计数。",
  disconnect: "断开连接",
  discount: "折扣",
  discountApplied: "已应用折扣",
  discountCanBeApplied: "此折扣可在您的场馆中应用。",
  discountCreated: "已创建折扣“{0}”。",
  discountDeleted: "已删除折扣“{0}”。",
  discountName: "折扣名称",
  discountNameAlreadyExist: "此折扣名称已存在。",
  discountRateOrValue: "折扣率或价值",
  discountReport: "折扣报告",
  discountType: "折扣类型",
  discountUpdated: "已更新折扣“{0}”。",
  discountableProducts: "可折扣产品",
  discounts: "折扣",
  discountsPage: "折扣页面",
  discrepancy: "差异",
  displayCategoryInPos: "在销售终端（POS) 设备中显示此类别。",
  displayedIn: "显示在",
  distribution: "分销、物流",
  done: "完成",
  dontSellOnPos: "不在销售终端（POS) 上销售",
  dontWorry: "别担心！",
  download: "下载",
  downloadAndSync: "下载PouchVENUE应用程序并同步您的第一笔交易",
  downloadTemplate: "下载模板",
  downloading: "下载中...",
  dropdown: "下拉菜单",
  duplicateOption: "复制选项",
  eWallet: "电子钱包",
  easierGuestReg: "更轻松的客人登记和签到",
  edit: "编辑",
  editAccessPoint: "编辑访问点",
  editAccessToken: "编辑访问令牌",
  editAccount: "编辑账户",
  editCategory: "编辑类别",
  editCharge: "编辑费用",
  editDetails: "编辑详细信息",
  editDevice: "编辑设备",
  editDiscountsPage: "编辑折扣页面",
  editGuestProfile: "编辑客人档案",
  editLocation: "编辑位置",
  editLocations: "编辑位置",
  editNewCategory: "编辑新类别",
  editNote: "编辑备注",
  editPaymentMethod: "编辑付款方式",
  editPaymentMethods: "编辑付款方式",
  editPrinterProfile: "编辑打印机配置文件",
  editProduct: "编辑产品",
  editProductSet: "编辑产品集",
  editQuestions: "编辑问题",
  editSFTPDetails: "编辑SFTP详细信息",
  editStaff: "编辑员工",
  editStaffPage: "编辑员工页面",
  editStaffProfilePage: "编辑员工档案页面",
  editSupplyItem: "编辑供应项目",
  editTax: "编辑税收",
  editVariants: "编辑变体",
  editVoucherPage: "编辑优惠券页面",
  editYourAccountPreferences: "编辑您的账户首选项、电子邮箱地址和密码。",
  egAppliesToGuests: "例如 适用于60岁及以上的客人",
  egSeniorDiscount: "例如 老年折扣",
  egSizeFlavor: "例如 大小、尺寸、口味",
  eightCharactersMinimum: "最少8个字符",
  eitherYouHavePoorConnection: "您的网络连接可能较差，访问已被拒绝，或者我们这边出了一些问题。",
  email: "电子邮件 、电子邮箱",
  emailAddress: "电子邮箱地址",
  emailAlreadyInVenue: "此电子邮箱地址已与该场所的账户关联。",
  emailDoesntHaveRecord: "您输入的电子邮箱地址不匹配我们的记录，请仔细检查并重试。",
  enableDifferentiateEntryExit: "启用不同入口/出口",
  enableSellingOnPos: "在销售终端（POS）上启用销售",
  enableTip: "启用小费？",
  end: "结束",
  endDay: "结束日期",
  endOfDay: "一天结束、一日总结",
  endOfDayChecklist: "一天结束、一日总结清单",
  endOfDayCompleted: "一天结束、一日总结已完成",
  endOfDayFailed: "无法完成一天结束、一日总结",
  endOfDayReports: "一日总结报告",
  endOfDayReportsListPage: "一日总结报告列表页面",
  endOfDayRunning: "正在运行一日总结。 请刷新页面以更新EOD的已结束时间状态。",
  endedBy: "由......结束",
  enterEachOption: "输入每个选项，用逗号分隔",
  enterEmailToLogin: "输入您的电子邮箱地址以注册或登录。",
  enterYourEmailAddress: "输入您的电子邮箱地址",
  enterYourPassword: "输入您的密码",
  enteredPasswordIncorrect: "输入的密码不正确。",
  equity: "资产净值",
  errorLoadingColors: "加载颜色时出错。 请稍后重试。",
  eventMode: "活动模式",
  example1: "示例1",
  example1AssembleProduct:
    "{0}：一杯拿铁需要{1}、{2}和{3}。 当购买一杯拿铁时会从咖啡豆、牛奶和糖中扣减库存。",
  example2: "示例2",
  example2AssembleProduct:
    "{0}：葡萄酒可以作为{1}或{2}出售。 选择葡萄酒作为供应项目，然后创建变体“瓶”和“杯”，并根据每个变体调整该项目的数量，使其从相同的供应项目中扣减。",
  expStockQty: "预期库存数量",
  expectedEndingBalance: "预期结余",
  expectedQty: "预期数量",
  expense: "费用",
  expired: "已过期",
  export: "出口",
  exportCheckinDetails: "导出签到详情",
  exportGuestReport: "导出客人报告",
  exportList: "导出列表",
  exportRegisterReport: "导出班次报告",
  exportTaxReport: "导出税务报告",
  exportTransactions: "导出交易",
  exportXlsx: "导出为XLSX",
  extendThePower: "通过最佳附加组件扩展您的PouchVENUE的功能。",
  externalPaymentStatus: "外部付款状态",
  extremelyHighDenominations: "非常高的面额",
  failed: "失败",
  failedToLoad: "加载失败。",
  featureLocked: "功能已锁定",
  feedback: "反馈",
  feelFreeToContactPouchnation: "请随时与PouchNATION联系。",
  female: "女性",
  fileImported: "文件导入成功",
  filters: "筛选器",
  findSimilar: "查找相似项",
  firstName: "名字",
  fixedAmount: "固定金额",
  fixedAmountRange: "固定金额应介于1-9,999,999之间",
  footerSection: "页脚部分",
  forApproval: "待批准",
  forProperReportingAndTo:
    "为了正确报告和保持您的仪表板最新，请每天运行一次一日总结，以避免任何数据丢失。",
  forProperReportingWithYourMews:
    "对于与您的MEWS账户的正确报告，请确保您的会计可编辑历史窗口设置为7天。 每天运行一日总结，以避免任何数据丢失。",
  forceBanned: "强制退房和禁止详情",
  forceCheckOutBy: "强制退房者",
  forceCheckout: "强制退房",
  forceCheckoutDetails: "强制退房详情",
  forceCheckoutName: "强制退房{0}？",
  forcedCheckedOutBy: "强制退房者",
  formatting: "格式化",
  freeCredits: "免费积分",
  freeCreditsAreCreditsThatAreFreeOfCharge: "免费积分是免费的积分。这些积分是",
  freeCreditsHoverTooltip: "免费积分不可退还，并且在付款时将被优先使用。",
  freeCreditsIssued: "发放的免费积分",
  freeCreditsKept: "保留的免费积分",
  freeCreditsLiabilityAccount: "免费积分负债账户",
  freeCreditsLiabilityAccountMessage: "一旦客人使用发放的免费积分的成本",
  freeCreditsLiabilityAccountPlaceholder: "负债/免费积分",
  freeCreditsReceived: "收到的免费积分",
  freeCreditsRemoved: "已删除的免费积分",
  freeCreditsReport: "免费积分报告",
  freeTrial: "30天免费试用！",
  frontOffice: "前台",
  frontOfficeRoleAppDesc: "此角色与应用程序无关，无法登录设备。",
  frontOfficeRoleDashboardDesc:
    "允许访问场馆仪表板的见解，预订，客人，交易，报告，设备和标签禁止。",
  future: "未来",
  gender: "性别",
  general: "一般的",
  generalSettings: "常规设置",
  generateAndDownload: "生成并下载",
  generateQRCode: "生成二维码",
  generateQRDescription: "生成和打印二维码，以便客人扫描并从此位置的在线菜单中订购。",
  generatingReport: "生成报告中…",
  getNotifications: "接收通知",
  getPouchVENUEeBook: "获取PouchVENUE电子书",
  getStarted: "开始",
  glass: "玻璃",
  glossary: "术语表",
  goToXDashboard: "转至{0} 仪表板",
  grossProfit: "毛利润",
  guest: "客人",
  guestCheckedOutAndBanned: "客人已退房并标记禁止进入。",
  guestDetails: "客人详情",
  guestId: "客人ID",
  guestInfoNotYetSync: "客人信息尚未同步",
  guestInformationYouCollect: "您在办理入住手续时收集的客人信息。",
  guestList: "客人列表",
  guestName: "客人姓名",
  guestNameAndOrTagId: "客人姓名和/或标签ID",
  guestOrTagStatus: "客人或标签状态",
  guestProfile: "客人档案",
  guestProfileAmountPlaceholder: "0.00",
  guestProfileDeleted: "已删除客人档案。",
  guestProfileDescriptionPlaceholder: "例如“在美食广场签到的客人”",
  guestProfileList: "客人档案列表",
  guestProfileName: "客人档案名称",
  guestProfileNameCreate: "生成了客人档案“{0}”。",
  guestProfileNamePlaceholder: "例如“美食广场客人”",
  guestProfiles: "客人档案",
  guestQuestions: "客人问题",
  guestSince: "自{0}以来的客人",
  guestSpecialRequests: "客人特别要求",
  guestTap: "客人触碰",
  guestWithColonSymbol: "客人：",
  guests: "客人",
  guestsCheckedIn: "已签到、入住客人",
  guestsNewBalance: "客人新余额",
  guestsPreviousBalance: "客人之前的余额",
  guestsPreviousBalanceTotalPaidAndNewBalance: "客人之前的余额，总共支付的金额和新余额",
  guestsStatus: "客人状态",
  headOfBooking: "预订主管",
  headerSection: "页眉部分",
  hello: "你好、您好",
  helpCenter: "求助中心",
  hiHeresWhatsHappening: "嗨、你好{0}，这是与{1}相关的情况。",
  hidden: "隐藏",
  hide: "隐藏",
  hideDetails: "隐藏详情",
  hideInPos: "在销售终端 （POS）中隐藏",
  hideOnlineMenu: "在在线菜单中隐藏",
  hideVariant: "隐藏变体",
  highDenominations: "高面额",
  holdingAccountForToppedUpItems: "在销售前，在标签中充值所有积分的保留账户",
  hostname: "主办名 、主机名",
  hour: "小时",
  hourly: "按小时计",
  hours: "小时",
  id: "标识/识别资料",
  ifYouLeaveThisPage: "如果您离开此页面，所有未保存的更改将丢失。您确定要放弃更改吗？",
  ifYoudLikeToJustStopSellingProduct:
    "如果您只是想在您的销售终端 (POS) 上停止销售某个产品，只需通过关闭“在销售终端 （POS）上销售”来禁用它。",
  ignore: "忽略",
  ignoreAllOpenShifts: "忽略所有未完成、开始的班次",
  ignoreAllShifts: "忽略所有班次",
  ignoredShiftFromPrevious: "忽略前一日的班次",
  imageTypeSize: "JPG，GIF或PNG格式。最大1MB",
  impItemMaxSize: "最大尺寸25MB",
  impItemModalNote1: "导入、进口将增加已存储在库存中的供应物品/产品的数量",
  impItemModalNote2: "对于库存中不存在的供应物品/产品，点击“确认”将在库存生成新的SKU（库存单位）",
  impItemsErr1: "无法找到位置{0}",
  impItemsErr2: "未找到度量{0}",
  impItemsErr3: "表{0} {1} 数据格式不正确",
  impItemsErr4: "供应物品{0} + 度量{1}的价格不同",
  impItemsErr5: "表{0}不应超过200行",
  impItemsErr6: "分类未找到",
  impItemsErr7: "产品{0} + 测量{1}价格有差异",
  impItemsPlsRecheck: "请重新检查文件导入的数据并重新上传",
  import: "导入",
  importSupplyItems: "导入供应物品",
  importSupplyItemsCreateWholeProducts: "导入供应物品并创建整个产品",
  importSupplyItemsErr: "场地名称与场馆中现有场所不匹配。请重新检查",
  in: "在",
  inHouse: "内部",
  inProgress: "进行中",
  inStock: "现存",
  inactive: "停用",
  include: "包括",
  indeterminate: "不确定的",
  information: "信息",
  informationToCollect: "在客人注册时收集信息。",
  inputReason: "输入原因",
  integrateDesc: "融入与强大的物业管理系统等。",
  integration: "整合",
  integrationSettingDesc: "连接其他商业和生产力、生产率应用程序，帮助您增长并简化业务。",
  integrationSettings: "整合设置",
  integrations: "整合",
  interval: "间隔",
  invalidFileType: "无效的文件类型。允许的文件类型为JPG或PNG。",
  invalidOptions: "无效的选项。",
  invalidTimeRange: "无效的时间范围",
  invalidToken: "无效的令牌",
  inventory: "库存",
  inventoryAccount: "库存账户",
  inventoryAccountMessage: "产品出售前的采购供应品和库存。",
  inventoryAccountPlaceholder: "资产/库存",
  inventoryExisted: "已存在的库存",
  inventoryGainLossAccount: "库存盈利/亏损账户",
  inventoryGainLossAccountMessage: "为库存盘点、库存调整等的差异。",
  inventoryGainLossAccountPlaceholder: "库存销售盈亏",
  inventoryManagement: "库存管理",
  inventoryMovement: "库存变动",
  inventoryMovementPage: "库存变动页面",
  inventoryReport: "库存报告",
  inventoryValue: "库存价值",
  invitationHasBeenSentTo: "邀请已发送至{0}。",
  inviteNewStaff: "邀请新员工",
  inviteResponse: "邀请回应",
  inviteYourStaff: "邀请您的员工",
  inviteYourVenueStaff: "邀请您场馆的员工，管理他们在收银机中的角色和访问权限",
  invoiceNumber: "发票编号",
  isAreserveWord: "是一个保留字。",
  issuanceLimit: "发行限制",
  issue: "发放、发行",
  issueFreeCredits: "发放免费积分",
  issuedBy: "发放人",
  issuedOn: "发放日期",
  item: "项目、品目、物品",
  itemBargain: "此项的价格已从{0}变为{1}",
  itemImport: "物品导入",
  itemName: "项目、品目、物品名称",
  itemTax: "项目、品目、物品税",
  items: "项目、品目、物品",
  itemsSold: "已售出物品",
  justNow: "刚刚",
  lastIssuedTo: "最近发放给",
  lastModifiedBy: "上次修改人",
  lastModifiedOn: "上次修改日期",
  lastName: "姓氏",
  lastPurchasedProduct: "上次购买的产品",
  lastSync: "上次同步",
  lastSynced: "上次同步{0}",
  lastVisitedLocation: "上次访问的位置、场地",
  learnHowToRenewSubscription: "了解如何续订您的订阅",
  learnMore: "了解更多",
  legalBusinessName: "法定商业名称",
  letsGetStarted: "让我们开始吧",
  liability: "负债、法律责任",
  limit: "限制",
  limitVoucherPerPerson: "每人发放的代金券限制",
  linkAccountingItem: "链接会计项目",
  linkOutlet: "链接出口、门店",
  linkOutletHoverTooltip: "与MEWS出口、门店链接",
  linkWithMewsAccountingItems: "与MEWS会计项目链接",
  litePlanMaxProductReached: "您已经达到了您的{1}计划中{0}产品的最大数量。",
  litePlanMaxStaffReached: "您已经达到了您的{1}计划中{0}员工的最大数量。",
  loading: "加载中...",
  location: "位置、场地",
  locationDesignation: "位置、场地指定",
  locationName: "位置、场地名称",
  locationNameAlreadyExist: "此位置、场地名称已存在。请选择不同的名称。",
  locationNameCreated: "位置、场地“{0}”已创建。",
  locationNameDeleted: "位置、场地“{0}”已删除。",
  locationNameUpdated: "位置、场地“{0}”已更新。",
  locationQRCode: "位置、场地二维码（整个位置、场地共用一个）",
  locationVoucherRedeemed: "选择兑换此代金券的位置。",
  locations: "位置、场地",
  locationsList: "位置、场地列表",
  logIn: "登录",
  loginHasEnded: "您的登录会话已经结束。请重新登录。",
  loginSessionEnded: "登录会话结束",
  logout: "注销",
  looksLikeAlreadyInSystem: "看起来您已经在我们的系统中了！",
  looksLikeYouEncounteredSomething:
    "看起来您遇到了一个错误，我们会自动跟踪这些错误。在此期间，请尝试刷新页面。",
  looksLikeYouHaveNoLocationYet: "看起来您尚未添加位置、场地。",
  lostTag: "丢失标签",
  lostTags: "丢失标签",
  lowDenominations: "低面额",
  lowOrOutOfStock: "低库存或无库存",
  lowParLevel: "低存货水平（PAR) ",
  lowerCaseNoneRefundable: "不可退还",
  lowerCaseTopUpCredits: "充值积分",
  mailingList: "邮件列表",
  male: "男性",
  manageAccountNotificationStockLevel: "管理账户以在物品达到报警水平时通知。",
  manageAndAssignQuestions: "管理和分配入住问题、免费积分和代金券给不同的客人档案。",
  manageBusinessInformation: "管理业务信息",
  manageEmailNotif: "管理发送给您和您的员工的电子邮件通知。",
  manageHowVenueTax: "根据您的地区，管理场馆如何收取税款。请咨询税务专家了解您的税务义务。",
  manageImportantDetailsSettings:
    "管理您场地的重要细节和规则，如场地设置、付款和信用设置、入住设置以及您的账户设置。",
  manageInventory: "管理库存",
  manageInventoryAndProducts: "管理这组货物的库存和产品。",
  manageInventoryAndSupply: "管理此产品的库存和供应物品。",
  manageLocationTable: "管理此场地的桌子和座位安排。",
  manageNotifcations: "管理发送给您员工的电子邮件警报和通知。",
  manageOfPermissionToEnterSpecificAreas: "管理特定区域的进入权限",
  managePlacesYouStock: "管理您储备库存和销售产品的地点。",
  manageReceipts: "管理您收据的外观和信息。",
  manageSupplyItems: "管理供应物品",
  manageThePosDevicesVenue: "管理在您场地登录并使用的销售终端 （POS）设备",
  manageTheStocksPerLocation: "管理此供应物品每个场地的库存。",
  manageTipDesc: "启用小费，并设置您想要在销售终端 （POS）上显示的小费比例。",
  manageToppedUpCredits: "管理已充值的积分、免费积分、后付积分和过期积分。",
  manageVariants: "管理变体",
  manageVenues: "管理场馆、场地",
  manageYourAccountsSales: "管理涉及销售、商品成本、商品税收或折扣的账户。",
  manageYourStaffProfileDescription: "管理您的员工，他们的角色、职称和员工档案。",
  manageYourVenueSetting: "管理您的场地设置，如税收和货币。",
  manageYourVenueSubscription: "管理您场地的订阅、账单信息和发票。",
  manageYourVenueTax: "添加和管理场地税收收费方式。",
  manager: "管理者",
  managerAuthorization: "管理授权",
  managerRoleDesc: "全面访问在线场地仪表板和场地应用程序。",
  mapAndManageAccounts: "为每个PouchVENUE业务流程映射和管理默认账户。",
  mapAndManageAccountsLink: "在{0}中映射和管理您的账户以进行妥当的会计处理",
  mapYourAccounts: "映射您的账户以进行库存资产、差异费用等处理。",
  margin: "利润率",
  markUp: "标记/涨价",
  maxProductReached: "达到最大产品数",
  maxStaffReached: "达到最大员工数",
  maximumLengthCharacters: "最大{0}字符数。",
  maximumValueAllowed: "允许的最大值为20,000,000",
  maximumValueIs: "最大值为",
  measurement: "测量",
  measurements: "测量",
  mediumDenominations: "中等面额",
  menuEngineering: "菜单工程",
  mews: "MEWS",
  mewsAccounting: "MEWS_会计",
  mewsAccounting1: "MEWS会计",
  mewsBookings: "MEWS_预订",
  mewsBookings1: "MEWS预订",
  mewsSystems: "MEWS系统",
  mewsTaxCode: "税码是否来自MEWS?",
  mewsTaxCodeEditNote: "注意：同步交易时，与此税收关联的产品将在MEWS中具有增值税金额 （VAT)。",
  milk: "牛奶",
  minOfNCharacters: "最少{0}字符",
  minimum: "最低",
  minimumCharactersAllowed: "允许最少{0}字符",
  minimumOf: "最少需要2个项目/物品。",
  minimumValueIs: "最小值为",
  minute: "分钟",
  minutes: "分钟",
  missedCheckIn: "错过入住",
  missedCheckOut: "错过退房",
  mixed: "混合",
  mobileNumber: "手机号码",
  mode: "模式",
  month: "月",
  monthly: "每月",
  months: "个月",
  monthsAfterCheckout: "退房后个月",
  mostPurchasedProduct: "最畅销的产品",
  mostVisitedLocation: "最常去的地点/场地",
  movement: "流动/移动",
  multiProperty: "多属性",
  nOptions: "{0}选项",
  nTon: "{0}至{1}",
  na: "不适用",
  name: "名称",
  nameAdded: "已添加“{0}”。",
  nameYourProduct: "给您的产品命名...",
  nameYourProductSet: "给您的产品组命名...",
  nameYourSupplyItem: "给您的供应物品命名",
  nationality: "国籍",
  needHelp: "需要帮助吗？",
  netWeightN: "净重{0}",
  new: "新",
  newBalance: "新余额",
  newGuests: "新客人",
  newInviteLink: "新链接已发送至“{0}”。",
  newPassWord: "新密码",
  newProduct: "新产品",
  newProductSet: "新产品组",
  newQty: "新数量",
  newQuantity: "新数量",
  newSku: "新SKU（库存单位）",
  newToPouchNation: "首次使用PouchNATION？",
  nfcStatus: "nFC状态",
  nfcTags: "nFC标签",
  night: "晚上",
  no: "不",
  noAccountsAvailable: "没有可用的账户",
  noAccountsYet: "暂无账户",
  noAvailableAccessPoint: "没有可用的接入点",
  noAvailableCategories: "没有可用类别",
  noAvailableGuestProfile: "没有可用访客资料",
  noAvailableLocations: "没有可用位置/场地",
  noAvailableLocationsYet: "暂无可用位置/场地",
  noAvailablePaymentMethodYet: "暂无可用支付方式",
  noAvailableStaff: "没有可用员工",
  noBannedTagsYet: "尚无禁用标签。",
  noBookingsYet: "暂无预订。",
  noCheckInsInDateRange: "在此日期范围内没有签到记录。",
  noData: "没有数据",
  noDataAvailable: "没有可用数据",
  noDeviceAvailable: "没有可用设备",
  noDevicesFound: "找不到设备",
  noEndDate: "没有截止日期",
  noLocation: "没有位置",
  noLocationAvailable: "没有可用位置",
  noNotesFound: "没有找到笔记",
  noOfCheckedIn: "{0}签到",
  noOfCheckedOut: "{0}签出",
  noOfGuests: "客人人数",
  noOfNights: "住宿晚数",
  noOfTables: "桌子数量",
  noProductsAvailable: "没有可用产品",
  noProductsSelected: "尚未选择产品。",
  noRecords: "没有记录。",
  noRecordsOf: "没有记录",
  noRemainingBalance: "没有剩余余额",
  noReportsFound: "没有找到报告",
  noResultInSearch: "我们没有找到与您搜索相关的任何结果。",
  noResults: "没有结果",
  noResultsFound: "没有找到结果",
  noResultsMatching: "没有匹配的结果",
  noResultsMatchingX: "没有匹配“{0}”的结果",
  noReturnCreditsInDateRange: "在此日期范围内没有退款积分",
  noSalesInDateRange: "在此日期范围内没有销售",
  noSearchFound: "未找到搜索结果",
  noSearchResultsForName: "没有找到{0}的搜索结果。",
  noShow: "未出现",
  noStocks: "没有库存",
  noTagUid: "没有标签UID (唯一识别号）",
  noTagYet: "尚未添加标签",
  noTagsFound: "没有找到标签",
  noVoucherAvailable: "没有可用礼卷/优惠卷",
  noVoucherMovement: "没有礼卷/优惠卷移动/流动",
  noVouchersSelectedYet: "尚未选择礼卷/优惠卷",
  nonRefundable: "不可退款",
  none: "无",
  notCheckedIn: "未签到",
  notSellingOnPos: "未在销售终端 （POS）上销售",
  note: "注意",
  notePreviousTransaction: "注意：之前与这些产品的交易记录将被保留。",
  noteTransactionStaff: "注意：之前与该员工的交易记录将被保留。",
  notedByOn: "{1}于{0}注意到",
  notes: "注意事项",
  notificationSettings: "通知设置",
  notifications: "通知",
  nowToDo: "现在要做",
  nthGuestWhoUseThisTag: "使用此标签的{0}位客人。",
  nthStaffWhoUsedThisTag: "使用此标签的{0}位员工。",
  nthUse: "使用{0}",
  numberOfGuests: "客人人数",
  numberOfNights: "住宿晚数",
  offline: "离线",
  ok: "好",
  okay: "好的",
  oldGuestId: "旧客人ID",
  onPos: "在销售终端 （POS）上",
  onboardingDescription: "按照这些简单步骤设置您的场地，以启动您的场地。",
  onboardingDescriptionCompleted:
    "太棒了！您现在可以开始在您的PouchVENUE 销售终端 （POS）设备上销售！立即订购您的NFC标签，以激活门锁进行无现金交易！同时查看我们的电子书，了解如何优化您的场地！",
  onboardingTitle: "欢迎！开始设置您的场地。",
  onboardingTitleCompleted: "您的场地已准备好启动！",
  onceYouBanATag: "一旦禁止一个标签，该标签将无法在您的场地内进行交易。",
  onceYouCompleteStocktake: "完成库存盘点后，所有供应品的实际数量将相应调整。此操作无法撤销。",
  onlineMenuOnly: "仅在线菜单",
  onlyDateRangesLessThanOneYear: "仅接受不到一年的日期范围",
  open: "打开",
  openReceiptPreview: "打开收据预览",
  openWindow: "打开到位置/场地的窗口",
  opened: "已打开",
  openingBalance: "期初余额",
  optionNameAlreadyExists: "选项名称已存在",
  options: "选项",
  orNo: "OR#",
  orderQty: "订购数量",
  orderQuantityRequired: "订单数量是必需的",
  orderYour: "订购您的",
  orderYourNFCTags: "订购您的NFC标签",
  original: "原始",
  other: "其他",
  others: "其他人",
  outlet: "门店",
  overview: "概览",
  pairedTagOnCheckin: "签到时已关联/连接标签",
  pairedTags: "已关联/连接标签",
  parLevel: "定期自动补货数量",
  password: "密码",
  passwordDoesNotMatch: "您输入的密码不匹配",
  passwordIncorrect: "您输入的密码不正确。",
  passwordsDoNotMatch: "密码不匹配",
  paymentAndCreditSettings: "支付和信用设置",
  paymentMethod: "付款方式",
  paymentMethodAddedName: "添加支付方式“{0}”。",
  paymentMethodAlreadyExist: "支付方式已存在",
  paymentMethodName: "付款方式名称",
  paymentMethods: "付款方式",
  paymentMethodsDescription: "管理您场地接受的付款方式。",
  paymentMethodsSettings: "支付方式设置",
  paymentMethodsYouAccept: "您场地接受的付款方式。",
  paymentStatus: "付款状态",
  pending: "待处理",
  perPage: "每页{0}",
  percentage: "百分比",
  percentageBetween: "百分比应该在1-100之间",
  permitNumberWithColonSymbol: "许可号码：",
  phone: "电话",
  phoneNo: "联系电话",
  pleaseCheckYourNetwork: "请检查您的网络连接以更新您的场地数据。",
  pleaseChooseLocation: "请选择地点",
  pleaseConnectToAnAccounting: "请连接到一个会计集成以管理您的账户表。",
  pleaseContactOwnerToReactiveVenue: "请联系场地业主续订订阅并重新激活该场地。",
  pleaseContactSupportRenewSubscription: "请联系客服来更新您的订阅并重新激活您的场馆。",
  pleaseCreateAccounts: "请从您的集成会计平台创建账户代码。",
  pleaseCreateLocation: "请创建至少一个场地来分配此供应品的库存。",
  pleaseEmailUs: "请发送电子邮件至 {0} {1}。",
  pleaseEnsureLocation: "请确保在开始盘点前，该场地没有正在进行中的交易。",
  pleaseEnterYourSFTP: "请输入您的SFTP服务器详细信息和凭据。",
  pleaseRenewSubscription: "请更新您的订阅以重新激活您的场馆。",
  pleaseSelectLocation: "请选择一个位置/场地。",
  pleaseSelectStockUnit: "请选择库存单位。",
  pleaseUpgradeToUseLockedFeature: "请升级您的计划以使用此锁定的功能。",
  pointOfSale: "销售点",
  posAndOnlineMenu: "销售点 + 线上菜单",
  posDevices: "销售点设备",
  posOnly: "仅限销售点",
  posProvider: "销售点供应商",
  postPaidCreditsIsTheAmount:
    "后付款积分是客人在余额达到零时可以使用的金额。当客人有后付款积分余额时，该金额应通过充值或结账时结清。",
  postPaidCreditsUsed: "已使用后付款积分",
  postpaidCreditLimit: "后付款积分限额",
  postpaidCredits: "后付款积分",
  postpaidCreditsLiabilityAccount: "后付款积分责任账户",
  postpaidCreditsLiabilityAccountMessage: "客人达到零余额时使用的积分。结账时应通过充值支付。",
  postpaidCreditsLiabilityAccountPlaceholder: "资产/后付款积分",
  postpaidCreditsUsed: "已使用后付款积分",
  postpaidCreditsUsedCanOnlyBeReplenished: "已使用后付款积分只能通过充值或结账时补充和支付。",
  pouchConnect: "PouchCONNECT",
  pouchLogo: "PouchNATION 商标",
  pouchPay: "PouchPAY",
  pouchnation: "PouchNATION",
  powerYourVenue: "通过集成为您的场馆提供动力。",
  preloadedCredit: "预加载积分",
  preloadedVouchers: "预加载券",
  prepareImport: "准备导入/进口",
  preparingReport: "准备报告",
  previousBalance: "之前余额",
  price: "价格",
  pricePerOrderQty: "每个订单数量的价格",
  pricing: "定价/价格",
  pricingAndTaxes: "价格和税费",
  printableItems: "可打印物品",
  printableItemsSelect: "选择在该打印机配置文件上打印哪些产品。",
  printerLocationsDesc: "选择可以使用该打印机配置文件的位置/场地。",
  printerProfile: "打印机配置文件",
  printerProfileCreated: "打印机配置文件“{0}”已创建。",
  printerProfileDeleted: "删除打印机配置文件",
  printerProfileName: "打印机配置文件名称",
  printerProfileNameAlreadyExists: "该打印机配置文件名称已存在。",
  printerProfileUpdated: "打印机配置文件“{0}”已更新。",
  printerProfiles: "打印机配置文件",
  printers: "打印机",
  prioritizedAndUsedFirst: "在支付时首先优先使用。",
  privacy: "隐私",
  privacyPolicy: "隐私政策",
  privacyPolicy2: "隐私政策",
  proceed: "进行",
  proceedToCheckout: "进行结账",
  processingTransactions: "正在处理交易...",
  product: "产品",
  productCategories: "产品类别",
  productCategory: "产品类别",
  productCount: "产品数量",
  productDeleted: "产品“{0}”已删除。",
  productList: "产品清单",
  productName: "产品名称",
  productRedeemed: "产品已兑换",
  productRemovedFromInventory: "从库存中删除 {0} 产品。",
  productSet: "产品套装",
  productSetDeleted: "产品套装“{0}”已删除。",
  productSetName: "产品套装名称",
  productUpdated: "{0} 产品已更新。",
  products: "产品",
  productsArePartOfComposite:
    "这些产品是其他复合产品的一部分。如果您想删除，请先将它们从复合产品中移除或与携带它们的复合产品同时删除。",
  productsAreSupplyItemsAre:
    "{0} 是您在场所销售的物品。{1} 是您用来制造产品的物品。供应物品在您的{2} 中管理。",
  productsCanBeRedeemed: "选择哪些产品可以用这张优惠券兑换。",
  productsDeleted: "{0} 产品已删除。",
  productsDoesNotExist: "要添加的产品必须存在于所有场地。",
  productsForDeletion: "位于此场地的 {0} 产品也将被删除。这将无法撤消。",
  productsRemovedFromInventory: "从库存中移除 {0} 产品。",
  productsToBeDeleted: "要删除的产品",
  productsUpdated: "{0} 产品已更新。",
  productsVSupplyItems: "产品与供应品",
  productsVariants: "产品/变体",
  productsWithNoCategory: "这些是尚无类别的产品",
  profile: "配置文件",
  profileName: "配置文件名称",
  profileNameAlreadyExists: "配置文件名称已存在，请输入一个不同的名称。",
  propertyManagementSystem: "物业管理系统",
  propertyManagementSystemExample: "诸如MEWS和Cloudbeds之类的系统",
  purchasePlus: "PurchasePlus",
  purchaseplus: "加购买",
  purchasingAndInventory: "采购和库存",
  qrCodeForOnlineMenu: "在线菜单的QR码",
  qty: "数量",
  quantity: "数量",
  quantitySupplyItems: "此产品使用的供应品数量。",
  quarter: "季",
  quarterly: "季度",
  question: "问题",
  questionAddedName: "问题“ {0}”已添加。",
  questionAlreadyExist: "问题已存在。",
  questionDeleted: "问题“ {0}”已删除。",
  questionDescription: "客人登记时要收集的信息。通过将其分配给您的{0}来使用这些问题。",
  questionSettings: "问题设置",
  questions: "问题",
  radioButton: "单选按钮/单选框",
  reachedReorderPoint: "达到重新订购点",
  readyForDownload: "准备下载",
  reallyUnbanTag: "真的要取消此标签的禁令吗？",
  reallyUnbanThisTagDescription:
    "取消此标签将在下次您的设备同步和更新时生效（通常在连接到互联网时每5分钟更新一次）。想要立即反映这些更改，请取消此标签后将您的设备同步和更新。",
  reason: "原因",
  reasonsForStay: "停留/居住原因",
  receiptFormatting: "收据格式化",
  receiptNumber: "收据编号",
  receiptSettings: "收据设置",
  receipts: "收据",
  recentTransactions: "最近交易",
  reconnect: "重新连接",
  redeem: "兑换",
  redeemableProducts: "可兑换产品",
  redeemed: "已兑换",
  referenceNo: "参考编号",
  refreshingThisPage: "刷新此页面",
  refundedCredits: "退还积分",
  registerGuests: "注册客人",
  registerGuestsRoleAppDesc: "此角色无法访问在线Venue仪表板。",
  registerGuestsRoleDashboardDesc: "允许访问Venue应用程序里的客人登记和退房。",
  registerInfo: "注册信息",
  registerReports: "班次报告",
  registerReportsPage: "班次报告页面",
  registerStaff: "注册员工",
  registeredBusinessInformation: "注册业务信息",
  registeredBusinessTaxNumber: "注册业务税号",
  registeredOfficialBusinessName: "注册/官方业务名称",
  regulateEntryExit: "规定进出",
  regulateEntryExitDesc:
    '""激活此功能可精确调节接入点/出入点的进出。当停用时，访问控制仍然有效，但不会区分进出。""',
  remove: "删除",
  removeFreeCredits: "删除免费积分",
  removeFromList: "从列表中删除",
  removeNameAsYourStaff: "删除{0}作为您的员工？",
  removeSku: "已删除SKU（库存单位）",
  removeStaff: "删除员工？",
  removeStocks: "删除库存？",
  removeStocksConfirm: "删除库存",
  removeStocksDetails: "您确定要删除{3}中{2}的{0} {1}的库存吗？",
  removeThisAccount: "删除此账户？",
  removeThisSupplyItem: "先从产品中删除此供应品以删除此产品。",
  removeVariant: "删除变体",
  removeVariantFirstFromProduct: "先从产品中删除这个变体",
  removedStock: "已删除库存",
  renewSubscription: "续订订阅",
  reorderPoint: "重新订购点",
  reorderPointReached: "已达到重新订购点",
  reorderStocks: "重新订购库存",
  report: "报告",
  reportAProblem: "报告问题",
  reportingDay: "报告日期",
  reports: "报告",
  reportsFailedToLoad: "报告加载失败。",
  reportsInfoPage: "报告信息页面",
  requireManagerAuthorization: "领取折扣时需要经理授权。",
  requireManagerAuthorizationVoucher: "发放礼券时需要经理授权。",
  required: "必要的",
  resendInvite: "重新发送邀请？",
  resetExpiration: "重设过期时间",
  resetTheExpirationPeriod: "当客人完成交易但未签到时重设过期时间。",
  restrictUseOfTags: "限制在场所使用特定标签。",
  retailPrice: "零售价格",
  retailPriceMustNotBeNegative: "零售价格不能为负数",
  retryReloadingChart: "重试加载图表",
  returnCredits: "返还积分",
  returnCreditsRoleAppDesc: "此角色无法访问在线场所仪表板。",
  returnCreditsRoleDashboardDesc: "允许访问场所应用的返还积分",
  returnedTags: "已归还的标签",
  returnedVoucher: "已归还的礼券",
  returningGuests: "返回的客人",
  revenue: "收入",
  revenueAccount: "收入账户",
  revenueAccountMessage: "所有销售收入的默认账户。",
  revenueAccountPlaceholder: "收入/销售",
  reviewYourDailyVenueActivity: "查看您每天的场所活动。",
  revokeInvite: "撤销邀请",
  revokeThisInvite: "撤销此邀请？",
  roadMap: "路线图",
  role: "角色",
  roleAndDesignation: "角色和职务",
  roles: "角色",
  rooms: "房间",
  runEndOfDay: "运行日终",
  running: "正在运行",
  runningTheEodWithUnsynced: "使用未同步的交易运行日终会导致未完成的报告。",
  sale: "销售",
  saleDiscounts: "收入/销售折扣",
  saleFoc: "收入/销售免费提供",
  saleReturn: "销售退货",
  sales: "销售",
  salesDiscounts: "销售折扣",
  salesDiscountsMessage: "所有应用的折扣成本。",
  salesDiscountsPlaceholder: "销售折扣",
  salesFOC: "免费提供的销售",
  salesFOCMessage: "所有应用的免费积分成本。",
  salesFOCPlaceholder: "收入/销售免费提供",
  salesInformation: "销售信息",
  salesOverTime: "随时间变化的销售情况",
  salesReport: "销售报告",
  salesSummary: "销售摘要",
  save: "保存",
  saveAccountSetting: "保存账户设置",
  saveCategoryForm: "保存分类表格",
  saveCreditSetting: "保存信用/积分设置",
  saveDeviceForm: "保存设备表格",
  saveDiscountForm: "保存折扣表格",
  saveGeneralSettings: "保存一般设置",
  saveLocationForm: "保存位置/场地表格",
  saveNewSupplyItemForm: "保存新供应项目表格",
  savePaymentMethod: "保存付款方式",
  savePrinterProfileForm: "保存打印机配置表格",
  saveProduct: "保存产品",
  saveProductSet: "保存产品组",
  saveQuestion: "保存问题",
  saveReceiptSettings: "保存收据设置",
  saveStaffForm: "保存商业模式",
  saveStaffProfileForm: "保存员工配置表格",
  saveTaxSetting: "保存税收设置",
  saveVenueForm: "保存场所表格",
  saveVoucherForm: "保存礼券表格",
  saveWristbandKeepingSettings: "保存腕带保持设置",
  savedChanges: "保存更改",
  scheduleACall: "安排电话",
  search: "搜索",
  search2: "搜索...",
  searchBanTags: "根据标签UID搜索受限制的标签",
  searchCategories: "搜索并选择此礼券的类别",
  searchForProducts: "搜索产品或供应项目",
  searchForStockId: "通过库存ID搜索...",
  searchForStocktakeId: "通过盘点ID搜索...",
  searchGuestsBalanceMovements: "通过姓名或标签ID搜索客人",
  searchGuestsByNameOrId: "通过姓名或标签ID搜索客人",
  searchMovementPlaceholder: "通过礼券名称，设备，客人搜索活动/流动",
  searchProductByKeyOrSku: "通过关键字或SKU（库存单位）搜索产品",
  searchProductsByKeyword: "按关键字搜索产品",
  searchProductsIn: "按{0}搜索产品",
  searchReportsByShiftId: "按班次ID搜索报告",
  searchResult: "搜索结果",
  searchResults: "搜索结果",
  searchStaffEmailOrTagID: "按员工电子邮箱或标签ID搜索",
  searchSupplyItemBy: "按名称或SKU（库存单位）搜索供应品项",
  searchTag: "搜索标签",
  searchTransactionPlaceholder: "按交易ID、标签ID、签到ID搜索交易",
  searchVouchers: "搜索优惠卷",
  second: "秒",
  seconds: "秒钟",
  selectAType: "选择类型",
  selectAccessPoints: "选择访问点",
  selectAccount: "选择账户",
  selectAccountType: "选择账户类型",
  selectAndMakeComposition: "选择此产品的制造和成分。",
  selectCategoriesOrProducts: "从您选择的地点中选择类别或产品",
  selectCategory: "选择类别",
  selectCharge: "选择费用",
  selectCountry: "选择国家",
  selectDevice: "选择设备",
  selectGuestProfile: "选择客人档案",
  selectItemsThisProductMadeOf:
    "选择该产品所含项目、产品。设置每个销售订单会扣除的每个供应品项的数量。",
  selectLocation: "选择位置",
  selectNumberOfTables: "选择表、桌位的数量",
  selectOutlet: "选择门店",
  selectProducts: "选择产品",
  selectRedeemableProducts: "选择可兑换产品",
  selectSource: "选择来源",
  selectStaff: "选择员工",
  selectStatus: "选择状态",
  selectStockLevel: "选择库存水平",
  selectStockUnit: "选择库存单位",
  selectSupplyItem: "选择供应项目/物品",
  selectTax: "选择税",
  selectToIncludeCategory: "选择将此产品包含在某一类别中..",
  selectToIncludeProductSetInCategory: "选择将此产品套装包含在某一类别中..",
  selectVenue: "选择场馆",
  selectVouchers: "选择优惠券",
  sellOnPos: "在销售终端 （POS）上销售",
  sellOnPosRoleAppDesc: "该角色无法访问在线场馆仪表板。",
  sellOnPosRoleDashboardDesc: "允许访问场馆应用程序的销售和在线订单。",
  selling: "销售",
  sellingOnPos: "在销售终端 （POS）上销售",
  sellingQty: "销售数量",
  sellingQtyMustBeGreaterThanZero: "销售数量必须大于零。",
  serialNumber: "序列号",
  serialNumberAbbv: "序列号",
  set: "套 / 设置 ",
  setAnAmountToLimitPostPaid: "设置限制客人后付积分的额度",
  setAsDefaultTax: "设置为默认税",
  setAsNonRefundable: "设置为不可退款",
  setCode: "设置代码",
  setExpirationPeriod: "设置到期日期",
  setUpYourAccount: "设置您的账户！",
  sets: "设置",
  settings: "设置",
  share: "分享",
  shareThisLink: "分享此链接以在任何设备上打开{0}！复制链接并与您的员工分享。",
  shift: "班次",
  shiftAlreadyClosed: "此班次已经关闭/结束。",
  shiftClosed: "班次已关闭/结束",
  shiftEnd: "班次结束",
  shiftId: "班次ID",
  shiftStart: "班次开始",
  shifts: "班次",
  shiftsAndTransactions: "班次和交易",
  shouldBeANumber: "应该是一个数字。",
  show: "显示",
  showHideColumns: "显示/隐藏列",
  showLess: "显示更少",
  showMore: "显示更多",
  showOnlineMenu: "在在线菜单中显示",
  signIn: "登录",
  signInButton: "登录按钮",
  signUp: "注册",
  sku: "SKU（库存单位）",
  skuAlreadyExists: "SKU（库存单位）已经存在。",
  skuStockKeepingUnit: "SKU（库存单位）",
  someArePartOfCompositeNote:
    "{0}以下的{1} 无法删除，因为它们是其他组合产品的一部分。想要删除这些，请将其从组合产品中移除或与携带它们的组合产品一起删除。",
  someShiftsHaveUnsynced: "有些班次有未同步的交易。请检查班次的设备以推送场馆数据。",
  somethingWentWrong: "出了些错误/页面出错",
  somethingWentWrongPlsTryAgain: "出了些错误/页面出错。请重试",
  sorryVenueSuspended: "抱歉，此场馆已被停用。",
  source: "来源",
  sourceLocation: "来源位置",
  sourceLocationOriginalQty: "来源位置原始数量",
  sourceLocationRemainQty: "来源位置剩余数量",
  sourceLocationTransferQty: "来源位置转移数量",
  specificCategories: "特定类别",
  specificProducts: "特定产品",
  spend: "花费",
  staff: "员工",
  staffCanAccess: "员工可以访问销售终端 （POS）应用程序并执行操作。",
  staffList: "员工列表",
  staffName: "员工姓名",
  staffNameAndOrTagId: "员工姓名和/或标签ID",
  staffProfile: "员工简介/档案",
  staffProfileDeleted: "员工简介/档案已删除",
  staffProfileList: "员工简介/档案列表",
  staffProfileName: "员工简介/档案名称",
  staffProfileNameCreated: "员工简介“{0}”已创建。",
  staffProfileNamePlaceholder: "输入此员工简介/档案的名称...",
  staffProfiles: "员工简介/档案",
  staffRemoved: "员工已删除。",
  staffTagWithColonSymbol: "员工标签:",
  staffWithColonSymbol: "员工:",
  standard: "标准",
  start: "开始",
  startStocktake: "开始盘点",
  startedOn: "开始于",
  status: "状态",
  staying: "逗留",
  stockAdjSupplyItemsTxtConfirm: "您确定要调整{0}中供应项目的库存吗",
  stockAdjustment: "库存调整",
  stockBelowParLevel: "库存低于标准水平",
  stockLevel: "库存水平",
  stockLevelAlerts: "库存水平警报",
  stockLevelNotificationSettings: "库存水平通知设置",
  stockLevels: "库存水平",
  stockManager: "库存管理人员",
  stockManagerRoleAppDesc: "该角色没有与应用程序相关的权限，无法在设备上登录。",
  stockManagerRoleDashboardDesc: "允许访问仅限于库存盘点的场馆仪表板。",
  stockReachedReorderPoint: "库存已达到重新订购点",
  stockUnit: "库存单位",
  stockVariance: "库存差异",
  stockWeightPerN: "每{0}的库存重量",
  stocks: "库存",
  stocktake: "盘点",
  stocktakeAlreadyExists: "盘点已存在",
  stocktakeSaved: "已保存盘点",
  stocktakeWithId: "盘点 #ST-{0}",
  streetVillage: "街道地址，镇/村",
  subTotal: "小计",
  subscribeAndRegister: "立即订阅并注册以获得",
  subscription: "订阅",
  subscriptionCanceledReason: "您已取消订阅。",
  subscriptionExpiredReason: "您已取消订阅。",
  subscriptionSuspended: "订阅已结束。请在{1}之前联系场馆业主续订订阅，以保持对{2}的访问权限。",
  subscriptionSuspendedIn5:
    "此场馆/场地将在5天后被暂停。请在{0}之前联系场馆业主续订订阅，以保持对{1}的访问权限。",
  subscriptionSuspendedIn5Manager:
    "您的场地将在不到5天内暂停使用。请在{0}之前续订您的订阅以保留对{1}的访问权限。",
  subscriptionSuspendedManager: "{0}请在{1}之前续订您的订阅以保留对{2}的访问权限。",
  subscriptionSuspendedToday:
    "您的场地将在今天被暂停使用。请联系场地业主以续订订阅以保留对{0}的访问权限。",
  subscriptionSuspendedTodayManager:
    "您的场地将在今天被暂停使用。请在今天续订您的订阅以保留对{0}的访问权限。",
  subscriptionUnpaidReason: "您的付款详情似乎有问题。",
  subscription_only_owner_access: "只有订阅了此计划的场地业主才能访问此订阅",
  success: "成功",
  sugar: "糖",
  suggested: "建议",
  summary: "摘要/终结",
  supplyCost: "供应成本",
  supplyItem: "供应物品",
  supplyItemAlreadyExists: "供应物品名称已存在。",
  supplyItemDeleted: "供应物品已删除。",
  supplyItemNameCreated: "供应物品“{0}”已创建。",
  supplyItemSummaryPage: "供应物品摘要页面",
  supplyItems: "供应物品",
  supplyTaxes: "供应税",
  supplyUnit: "供应单元",
  supplyValue: "供应值",
  supportPouchnation: "support@pouchnation.com",
  suspended: "已暂停",
  syncToMews: "同步至MEWS",
  tableManagement: "桌面管理",
  tableQRCode: "桌子QR码（每张桌子）",
  tables: "桌子",
  tagBanned: "标签被禁止。",
  tagId: "标签ID",
  tagIds: "标签ID",
  tagInfo: "标签信息",
  tagUID: "标签UID",
  tagUnbanned: "标签解禁。",
  tags: "标签",
  tagsIssued: "已发行的标签",
  tapCount: "敲击/点击计数",
  tapStatus: "敲击/点击状态",
  tax: "税",
  taxAddedName: "添加了税“{0}”。",
  taxAlreadyExists: "税已存在。",
  taxCode: "税号码",
  taxEditNote: "注意：与此税项相关联的产品将自动更新。这不会影响过去的销售。",
  taxEg: "例如“增值税”",
  taxIncluded: "已包含税",
  taxInvoiceNo: "税发票号：",
  taxInvoiceNumber: "税发票编号",
  taxName: "税名",
  taxNumber: "税码",
  taxOnPurchasesAccount: "购买账户上的税",
  taxOnPurchasesAccountMessage: "增值税 (VAT) 和其他采购订单上的税。",
  taxOnPurchasesAccountPlaceholder: "负债/购买税",
  taxOnSalesLiabilityAccount: "销售/卖空负债税账户",
  taxOnSalesLiabilityAccountMessage: "每种产品收取的税的默认账户。",
  taxOnSalesLiabilityAccountPlaceholder: "负债/销售税",
  taxPercentageAndValue: "税（{0}%）：{1}",
  taxRate: "税率",
  taxSettings: "税务设置",
  taxes: "税",
  termsAndConditions: "条款和条件",
  termsInAllowingAccess:
    "通过点击“允许访问”，您授权{0}使用、接收和存储您的信息。请查看{1}的{2}和{3}。您可以随时在您的PouchNATION账户的集成设置中停止访问。",
  termsInAllowingAccess2:
    "通过点击“允许访问”，您授权{0}使用、接收和存储您的信息。您可以随时在您的PouchNATION账户的集成设置中停止访问。",
  termsInAllowingAccess3: "通过点击“允许访问”，您授权{0}存储您的{1}信息。请查看我们的{2}和{3}。",
  termsInSignUp: "通过注册，我同意PouchNATION的{0}、{1}和{2}。",
  termsOfService: "服务条款",
  termsOfUse: "使用条款",
  textbox: "文本框",
  theActualNumberOfGuests: "实际签入/签出的客人人数",
  theAmountTheGuestWillPay: "客人将支付的预订费用。",
  theBookingStatus: "来源的预订状态。",
  theGuestHasNoAssignedRoom: "此客人没有分配的房间！",
  theLinkYouAreTryingToAccess: "您正在尝试访问的链接不属于您的场地。",
  theLinkYouAreTryingToAccessDoesNotBelongToLocation: "您正在尝试访问的链接不属于您分配的位置/场地",
  theShiftAndTransactionDetails: "一旦在收银机中关闭了班次，将显示这里的班次和交易详情。",
  thereIsSomeError: "糟糕！我们这边出现了一些错误。请您耐心等待并稍后再次检查。",
  theseNthOtherProducts: "{0} {1} 其他在于此场地的产品和其他场地将保留在您的库存中。",
  theseProductsCannotBeDeleted: "这些产品无法删除。",
  thisAppRequests: "此应用程序请求以下信息：",
  thisAppWouldRead: "此应用程序将阅读和/或写入以下信息：",
  thisEmailIsAlreadyAdded: "这个电子邮箱已经添加。",
  thisEmailIsInvalid: "这个电子邮箱无效。",
  thisFieldIsRequired: "此字段为必填字段",
  thisIsForDirectIntegrationWithPaymentTerminalPartner: "这是为了与付款终端合作伙伴直接集成。",
  thisLocationHasTables: "这个场地有桌子和座位布置。",
  thisMonth: "本月",
  thisProductNameAlreadyExists: "该产品名称已经存在",
  thisProductsHasMultipleVariant: "该产品具有多个选项，如尺寸、颜色、口味等。",
  thisQuestionIsLinkedToNProfiles: "这个问题与{0}个客人档案相关联。",
  thisServesAsAnOfficialReceipt: "-这将作为正式收据-",
  thisShiftHasUnsyncedTransactions: "这个班次有未同步的交易",
  thisSkuExists: "该SKU（库存单位）已经存在",
  thisStaffHasNpLongerAccess: "这位员工将不再有{0}访问权限。此操作无法撤消。",
  thisTagIdIsAlreadyBanned: "该标签ID已被禁止。",
  thisVenueFreeTrial: "此场馆的免费试用期为三十（30）天。",
  thisWeek: "本周",
  ticketNo: "门票编号",
  ticketing: "售票",
  time: "时间",
  timeEnded: "时间结束",
  timeRange: "时间范围",
  timeStarted: "时间开始",
  timeout: "超时",
  tip: "小费",
  tipOptions: "小费选项",
  tipReport: "小费报告",
  tips: "小费",
  tipsAndCharges: "小费和费用",
  to: "至",
  toAddMoreCreditsToATag: "客人能。。。以向标签添加更多积分",
  toBeCheckedIn: "待办登记",
  toBeCheckedOut: "待办登出",
  toContinueCreatingDiscount: "要继续创建此折扣，请先创建一个位置/场地。",
  toContinueCreatingProducts: "要继续创建此产品，请先创建一个位置/场地。",
  toContinueCreatingStaff: "要继续创建此员工，请先创建一个位置/场地。",
  toContinueCreatingStocktake: "要继续创建此盘点，请先创建一个位置/场地。",
  toDeleteItem: "删除此项目/物品/品目。",
  toScheduleACall: "与我们的客户支持安排通话。",
  today: "今天",
  together: "一起",
  topCategoriesBySales: "按销售额前三类别",
  topLocations: "热门位置/场地",
  topProducts: "热门产品",
  topSpendingGuests: "顶级消费者",
  topupAndReturnCredits: "充值并返回积分",
  topupCredits: "充值积分",
  topupCreditsRoleAppDesc: "此角色无法访问在线Venue仪表盘。",
  topupCreditsRoleDashboardDesc: "允许访问Venue应用程序的充值",
  total: "总",
  totalAmount: "总金额",
  totalBookings: "总预订数",
  totalCreditsKept: "总积分保留（退房后）",
  totalCreditsKeptByGuests: "客人保留的总积分",
  totalCreditsKeptTooltip: "客人在退房后保留的总积分",
  totalCreditsReceived: "收到的总积分",
  totalCreditsReceivedFromVenues: "其他场馆收到的总积分",
  totalCreditsReceivedTooltip: "从其他场馆保留积分的客人的积分总数",
  totalExpectedEndingBalance: "总期望结束余额",
  totalFreeCreditsIssued: "发行的免费积分总额",
  totalFreeCreditsRemoved: "移除的免费积分总额",
  totalInStock: "总库存",
  totalOpeningBalance: "总期初余额",
  totalPaid: "总支付",
  totalProductSales: "总产品销售额",
  totalRefunded: "总退款",
  totalRefundedCredits: "总退还的积分",
  totalRemoved: "总移除",
  totalReturnCredits: "总返还积分",
  totalReturnedCredits: "总归还积分",
  totalSales: "总销售额",
  totalSpent: "总开支",
  totalSpentToDate: "到目前为止的总开支",
  totalStocksPerLocation: "个别场地的总库存",
  totalTagsUsed: "发行的标签总数",
  totalTax: "总税",
  totalTopUps: "总充值金额",
  totalTopupCredits: "总充值积分",
  totalTransactions: "总交易数",
  totalUnspentCredits: "未使用总积分",
  totalUnspentCreditsDesc:
    "与{0}关联，且计划在您以上筛选器日期之间退房的客人的所有未使用积分的总和。",
  totalUse: "总使用",
  totalVisits: "总访客数{0}",
  totals: "合计",
  trackAndManage: "跟踪和管理手动库存统计。",
  trackEveryGuestCheckedIn: "跟踪进入您场地的每位客人喜欢的产品和位置/场地",
  trackWhichItemsLow: "跟踪哪些物品因库存不足而需要补充",
  trackedInventory: "存货跟踪",
  tradeTypeTransactionNumber: "{0}交易编号",
  transaction: "交易",
  transactionID: "交易ID",
  transactionInfoPage: "交易信息页面",
  transactionStatus: "交易状态",
  transactionType: "交易类型",
  transactions: "交易",
  transactionsFailedToProcess: "未能处理交易。",
  transactionsPage: "交易页面",
  transfer: "转移",
  transferSupplyItems: "转移供应品",
  transferSupplyItemsSuccessfully: "成功转移供应品",
  transferSupplyItemsTxtConfirm: "您确定要将供应品从{0}转移到{1}吗",
  try: "尝试：",
  turnOffPos: "关闭销售{0}{1}？",
  turnOnPos: "打开销售{0}{1}？",
  txnId: "Txn Id：＃",
  type: "类型",
  typeAQuestion: "输入问题",
  typeAReason: "输入一个原因...",
  typeAnOption: "输入一个选项",
  typeDisconnect: "输入断开以确认",
  typeHere: "在这里输入...",
  typeToSearch: "输入以搜索项目！",
  typeToSetAccountCode: "输入以设置或选择账户代码。",
  unableToDelete: "无法删除",
  unbaleToUploadMoreThanMb: "无法上传大小超过1mb的图像。",
  unban: "解禁",
  unbanTag: "解禁标签",
  unbanningWillMakeTagAccessible: "解禁将使此标签可在您的场所访问。",
  uncategorized: "未分类",
  undefinedErrorPlsTryAgain: "未定义错误。请刷新页面并重试",
  undo: "撤销",
  unignore: "取消忽略",
  unignoreAllOpenShifts: "对所有未完成的班次取消忽略",
  unignoreAllShifts: "对所有班次取消忽略",
  unitSold: "已售出单位",
  unitsSold: "已售出单位",
  unknown: "未知",
  upToDate: "最新",
  updatedOn: "更新于 {0}",
  upgradeNow: "立即升级",
  upgradePlanHeader: "立即升级您的计划，就能大幅度提升您的场地并获取更多高级功能！",
  upgradeYourPlan: "立即升级您的计划！✨",
  upload: "上传",
  uploadExcelFileToImport: "上传Excel文件以导入",
  uploadPhoto: "上传照片",
  uponRegistration: "在注册时或与场所内的任何充值员工或亭",
  usage: "用量/使用",
  usageDatePeriod: "使用日期区间",
  useThisVenueDetails: "使用此场所详情",
  useVenueAppAndLogin:
    "使用场所应用程序并使用您的已激活的NFC标签，或使用您当前的用户名或密码登录。如果您还没下载应用程序，请询问您的场所经理或 {0}。",
  used: "已使用",
  username: "用户名",
  usesLastIssuedTo: "用途，上次分发给",
  validUntilWithColonSymbol: "有效期至：",
  value: "价值",
  valueBetween: "价值应在之间",
  valueRedeemed: "价值已兑换",
  variant: "变体",
  variantAlreadyExist: "变体已存在",
  variantIsCurrentlyBeingUsed: "变体当前正在以下产品中使用",
  variants: "变体",
  vatReg: "税号",
  venue: "场所",
  venueCurrency: "场所货币",
  venueDashboard: "场所仪表板",
  venueDetails: "场所详情",
  venueInLitePlan: "该场所目前处于精简版（Lite） 计划中。",
  venueInformation: "场所信息",
  venueLocation: "场所位置",
  venueLogo: "场所徽标",
  venueMode: "场所模式",
  venueName: "场所名称",
  venueNameAlreadyExist: "场所名称已存在，请选择其他名称",
  venueSettings: "场所设置",
  venueSuspended: "场所已暂停/封禁",
  veryHighDenominations: "非常高面值",
  view: "查看",
  viewAllTransactions: "查看所有交易",
  viewAndExportDiscountReport: "查看并导出您的折扣列表以及每个折扣减少的总金额",
  viewAndExportEachClosedRegister: "查看并导出由您的员工创建的每个关闭/结束班次报告",
  viewAndExportFreeCreditsReport: "查看并导出发放和删除的免费积分。",
  viewAndExportSalesReportByCategories: "按类别查看并导出您的销售报告",
  viewAndExportVoucherReport:
    "查看并导出在赎回产品上应用的所有代金券及每个代金券赎回的零售和供应总额",
  viewBillingStatement: "查看账单报表",
  viewBookingInfo: "查看从 MEWS、PouchNATION 票务等不同平台获取的预订信息！",
  viewBy: "按查看类别",
  viewCategoryDetails: "查看类别详情",
  viewDetails: "查看详情",
  viewDevice: "查看设备",
  viewDiscountDetails: "查看折扣详情",
  viewEveryTransactions: "查看在某一时间段内在您的场所进行的每笔交易",
  viewInfo: "查看信息",
  viewIntegrations: "查看集成",
  viewNOfNTransactions: "查看{0} / {1} 交易",
  viewNTransactions: "查看{0} 交易",
  viewPlansAndPricing: "查看方案和定价",
  viewPreview: "查看预览",
  viewPrinter: "查看打印机",
  viewTaxSettings: "查看税务设置",
  viewTheSalesSummaryOfYourVenue: "查看场所销售摘要及其顶级位置、产品等。",
  viewVoucherDetails: "查看代金券详情",
  void: "作废/无效",
  voidAuthorization: "无效授权",
  voidConfirmation: "您确定要作废 {0} 吗？此操作无法撤消。",
  voidId: "作废 {0}？",
  voidInfo: "作废信息",
  voidReason: "无效理由",
  voidStocktake: "作废盘点",
  voided: "已作废",
  voidedBy: "作废者",
  voidedItems: "作废商品",
  voidedOn: "作废日期",
  voidedValue: "作废价值",
  voucher: "代金券",
  voucherApplied: "已应用代金券",
  voucherAvailable: "此代金券可在您的场所发行。",
  voucherCreated: "已添加 {0}。",
  voucherDeleted: "代金券“{0}”已删除。",
  voucherIssue: "代金券发行",
  voucherMovementPage: "代金券流动页面",
  voucherName: "代金券名称",
  voucherNameAlreadyExist: "此代金券名称已存在。",
  voucherReachedIssuanceLimit: "此代金券已达到其 {0} 的最大发行限制。",
  voucherRemove: "删除代金券",
  voucherReport: "代金券报告",
  voucherRetailValue: "代金券零售价值",
  voucherSupplyValue: "代金券供应价值",
  voucherType: "代金券类型",
  voucherUpdated: "更改已保存。",
  vouchers: "代金券",
  vouchersCreationLimitInfo:
    "每个场地最多只能创建8个代金券。您可以删除过期或无效的代金券，以腾出空间制作更多代金券。",
  vouchersIssued: "发行的代金券",
  vouchersListPage: "代金券列表页面",
  vouchersMaximumNumberReached: "代金券数量已达最大限制。",
  vouchersRedeemed: "代金券已兑换",
  vouchersRemoved: "代金券已移除",
  vouchersUsage: "代金券使用情况",
  weDidNotFoundReports: "我们没有找到符合您筛选条件的任何报告。",
  weekMon2Sun: "每周（星期一至星期日）",
  weekSun2Sat: "每周（星期日至星期六）",
  weeksAfterCheckout: "退房后的几周",
  weightInGrams: "重量（克）",
  welcomeBack: "欢迎回来！",
  whatAreFreePostpaidCredits: "什么是免费积分和后付积分？",
  whenAnItemAlertLevel: "物品达到提醒水平时。",
  whole: "整体",
  wholeProducts: "整体产品",
  withPendingPayments: "与待付款",
  withRemainingBalance: "与剩余余额",
  wouldReadOrWrite: "{0}将阅读和/或编写以下信息：",
  wristbandKeeping: "手环保管",
  wristbandTag: "手环标签",
  wristbandTagKeeping: "手环标签保管",
  wristbandTagSettings: "手环标签设置",
  xConnectedFailed: "我们似乎无法识别此访问令牌。请参考下面的说明步骤检索您的正确访问令牌。",
  xConnectedSuccessfully: "{0}已成功连接",
  year: "年",
  years: "年",
  yes: "是的",
  yesterday: "昨天",
  youAreAboutToDelete: '您即将删除""{0}"" ""{1}""。这将无法撤消。',
  youAreAboutToDeleteAQuestionThatIsLinked: "您即将删除与客人档案关联的问题。这将无法撤消。",
  youAreAboutToDeleteGuestProfile: "您即将删除此客人档案。这将无法撤消。",
  youAreAboutToDeleteMultiple: "您即将删除{0} {1}。这将无法撤消。",
  youAreAboutToDeletePaymentMethod: '您即将删除""{0}""付款方式。',
  youAreAboutToDeleteQuestion: "您即将删除此问题。这将无法撤消。",
  youAreAboutToDeleteStaffName: "您即将移除{0}作为您的员工。该员工将无权访问{1}。此操作无法撤消。",
  youAreAboutToDeleteStaffProfile: "您将删除此员工档案。这将无法撤消。",
  youAreAboutToDeleteTax: "您将删除“{0}”税。",
  youAreAboutToDisconnect: "您即将断开连接并永久删除此应用程序中的所有信息。",
  youAreAboutToRemoveFromMailingList: "您即将从邮件列表中删除{0}。此账户将无法接收库存水平警报。",
  youAreAboutToRemoveMultipleProduct:
    "您即将从库存和所有位置/场地中删除{0}产品及其变体。这将无法撤消。",
  youAreAboutToRemoveProduct: "您即将从库存和所有位置/场地中删除此产品。这将无法撤消。",
  youAreAboutToRemoveVariantProduct:
    "您即将从库存和所有位置/场地中删除此产品及其{0}个变体。这将无法撤消。",
  youAreAssigned: "您被分配以下角色：",
  youAreLoggedInAs: "您已登录为{0}",
  youCanChangeThisLater: "稍后可更改！",
  youCanNowLogin: "您现在可以使用您的电子邮箱地址和密码登录。",
  youDontHavePermission: "您无权访问此链接",
  youHaveUnsavedChanges: "您在此页面上有未保存的更改。",
  youllFindYourBookingsHere: "您将在这里找到您的预订。",
  yourGeneralSettings: "您的一般设置，如场地名称和货币。",
  yourMailingIsEmpty: "您的邮件列表仍为空。",
  yourSubscriptionCanceled: "适用于您的场地{1}的{0}计划订阅已取消。",
  yourSubscriptionEnded: "适用于您的场地{1}的{0}计划订阅已结束。",
  yourVenues: "您的场地",
  youveReachedTheEndOfTheList: "您已经到达了列表的尽头。",
  zeroInventory: "零库存",
};

export default Object.freeze(locale);
