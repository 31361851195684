import React, { useEffect } from "react";
import { Prompt as PromptRouterChange } from "react-router-dom";
import lang from "translations";

const PromptLeavePage = ({ isBlocking, message = lang.ifYouLeaveThisPage }) => {
  useEffect(() => {
    if (isBlocking) {
      window.onbeforeunload = () => true;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [isBlocking]);

  return (
    <PromptRouterChange
      when={isBlocking} // <-- blocking condition
      message={message}
    />
  );
};

export default PromptLeavePage;
