import React from "react";
import lang from "translations";
import { Modal, Text, Title } from "components";
import { MobileUpdatedVersionImg, MobilePushVenueDataImg, LampIcon } from "images";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { Path } from "paths";
import Notes from "./notes";
import useMobile from "hooks/useMobile";

const UpdateVersionModal = ({
  versionName = "",
  playStoreLink = "",
  apkS3Link = "",
  description = "",
  ...props
}) => {
  const dataNote = { versionName, playStoreLink, apkS3Link, description };
  return (
    <Modal {...props} noHeader width={1200} closable>
      <div className="md:mx-20 pt-4">
        <Title className={"mb-4"} color="text-pelorous">
          {lang.updatedVersionOfTheAppIsNowAvailable}
        </Title>
        <Notes {...dataNote} />
        <Guideline close={props.close} />
      </div>
    </Modal>
  );
};

export default UpdateVersionModal;

const Guideline = ({ close }) => {
  const isMobile = useMobile();
  return (
    <>
      <section className="mt-8 bg-green-100 rounded-lg px-6 py-3">
        <div className="flex gap-6 items-center">
          <div className="w-16">
            <img src={LampIcon} alt="" />
          </div>
          <div>
            <Title>{lang.guidelineDownloadOrUpdateApp}</Title>
            <Text size="text-md" color="text-pelorous-darker" fontWeight="font-bold">
              {lang.beforeUpdatingTheAppPleaseEnsureAllDataIsSyncedBy}
            </Text>
          </div>
        </div>
        <div className="flex mt-3">
          <div className="flex-1 flex flex-col justify-center items-center">
            <div className="rounded-full px-3 py-2 bg-green-400 text-white w-fit">
              {lang.step} 1
            </div>
            <Text className="my-4" size="text-base md:text-md" color="text-black-darker">
              {lang.goToVenueTabAndTapOnPushVenueData}
            </Text>
            <img src={MobilePushVenueDataImg} alt="" className="w-56" />
          </div>
          <div className="flex-1 flex flex-col justify-center items-center">
            <ArrowForwardOutlinedIcon sx={{ fontSize: isMobile ? 40 : 80 }} />
          </div>
          <div className="flex-1 flex flex-col justify-center items-center">
            <div className="rounded-full px-3 py-2 bg-green-400 text-white w-fit">
              {lang.step} 2
            </div>
            <Text className="my-4" size="text-base md:text-md" color="text-black-darker">
              {lang.goToVenueTabAndTapOnPushVenueData}
            </Text>
            <img src={MobileUpdatedVersionImg} alt="" className="w-56" />
          </div>
        </div>
        <Text
          className="pt-3 pb-8"
          size="text-md"
          color="text-pelorous-darker"
          fontWeight="font-bold"
        >
          {lang.toViewDetailedInstructions},{" "}
          <span>
            <a href={Path.DEVICE_NOTES_GUIDE} className="underline font-bold hover:underline">
              {lang.clickHere}
            </a>
          </span>
        </Text>
      </section>
      <Text className="pb-10" size="text-md" color="text-pelorous-darker" fontWeight="font-bold">
        {lang.noteUpdateLastVersion}
        <span className="underline font-bold cursor-pointer hover:text-blue-400" onClick={close}>
          {lang.clickHere}
        </span>
      </Text>
    </>
  );
};
