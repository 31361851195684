import { InvoicedSiigo } from "enums";

export const configMapper = {
  _keys: [
    "accountName",
    "selectedDocumentType",
    "selectedSeller",
    "selectedDefaultAccountGroup",
    "selectedProductTips",
  ],
  pnPaymentMethods: {
    transform: ({ src }) => {
      if (!src.pnPaymentMethods || !src.pnPaymentMethods.length) return [];
      return src.pnPaymentMethods.map(({ id, name }) => ({ text: name, value: id }));
    },
  },
  pnTaxes: {
    transform: ({ src }) => {
      if (!src.pnTaxes || !src.pnTaxes.length) return [];
      return src.pnTaxes.map(({ id, name, percentage }) => ({
        text: name,
        value: id,
        percentage,
      }));
    },
  },
  siigoDocumentTypes: {
    transform: ({ src }) => {
      if (!src.siigoDocumentTypes || !src.siigoDocumentTypes.length) return [];
      return src.siigoDocumentTypes.map(({ id, description, type, code }) => ({
        text: `${type}-${code}-${description}`,
        value: id,
      }));
    },
  },
  siigoSellers: {
    transform: ({ src }) => {
      if (!src.siigoSellers || !src.siigoSellers.length) return [];
      return src.siigoSellers.map(({ id, firstName, lastName, email }) => ({
        text: `${firstName || ""} ${lastName || ""} | ${email}`,
        value: id,
      }));
    },
  },
  siigoAccountGroups: {
    transform: ({ src }) => {
      if (!src.siigoAccountGroups || !src.siigoAccountGroups.length) return [];
      return src.siigoAccountGroups.map(({ id, name }) => ({
        text: name,
        value: id,
      }));
    },
  },
  siigoPaymentMethods: {
    transform: ({ src }) => {
      if (!src.siigoPaymentMethods || !src.siigoPaymentMethods.length) return [];
      return src.siigoPaymentMethods.map(({ id, name, type }) => ({
        text: name,
        value: id,
      }));
    },
  },
  siigoTaxes: {
    transform: ({ src }) => {
      if (!src.siigoTaxes || !src.siigoTaxes.length) return [];
      return src.siigoTaxes.map(({ id, name, percentage }) => ({
        text: name,
        value: id,
        percentage,
      }));
    },
  },
  paymentMethodsMapping: {
    transform: ({ src }) => {
      if (!src.paymentMethodsMapping || !src.paymentMethodsMapping.length) return [];
      return src.paymentMethodsMapping;
    },
  },
  taxesMapping: {
    transform: ({ src }) => {
      if (!src.taxesMapping || !src.taxesMapping.length) return [];
      return src.taxesMapping;
    },
  },
};

export const listInvoiceMapper = {
  id: { key: "invoiceId" },
  invoiceNo: { key: "invoiceId" },
  guestName: {
    transform: ({ src }) => {
      return `${src.guestFirstName || ""} ${src.guestLastName || ""}`.trim();
    },
  },
  issueDate: { key: "issuedDate" },
  state: { key: "state" },
  siigoInvoiceNo: { key: "siigoInvoiceNumber" },
  cufe: { key: "dianInvoiceNumber" },
  invoiceData: { key: "invoiceData" },
  errorReason: {
    transform: ({ src }) => {
      if (src.state === InvoicedSiigo.SiigoError) return src.errorReason?.messages || [];
      return src.errorReason?.Errors?.map(({ Message }) => Message) || [];
    },
  },
  warningResync: {
    transform: ({ src }) => {
      return !src.allTransactionsSynced;
    },
  },
};

export const detailInvoiceMapper = {
  _keys: [
    "venueLogo",
    "venueName",
    "venueAddress",
    "venueTaxNumber",
    "venuePhoneNumber",
    // "customerName",
    "customerPhoneNumber",
    "customerEmail",
    "customerId",
    "items",
    "payments",
    "issuedDate",
    "invoiceData",
    "siigoInvoiceNumber",
    "externalFile",
    "state",
  ],
  id: { key: "invoiceId" },
  cufe: { key: "dianInvoiceNumber" },
  guestName: {
    transform: ({ src }) => {
      return `${src.guestFirstName} ${src.guestLastName}`;
    },
  },
  errorReason: {
    transform: ({ src }) => {
      if (src.state === InvoicedSiigo.SiigoError) {
        return src.errorReason?.messages || [];
      }
      return src.errorReason?.Errors?.map(({ Message }) => Message) || [];
    },
  },
  customerName: {
    transform: ({ src }) => {
      const invoiceData = src.invoiceData;
      const { customerFirstName, customerLastName } = invoiceData || {};
      return `${customerFirstName || ""} ${customerLastName || ""}`.trim();
    },
  },
  warningResync: {
    transform: ({ src }) => {
      return !src.allTransactionsSynced;
    },
  },
  btn: {
    transform: ({ src }) => {
      let sendSiigo = false;
      let sendDian = false;

      switch (src.state) {
        case InvoicedSiigo.Unbill:
        case InvoicedSiigo.SiigoError:
          sendSiigo = src.allTransactionsSynced;
          sendDian = src.allTransactionsSynced;
          break;
        case InvoicedSiigo.SiigoAccepted:
          sendDian = src.allTransactionsSynced && !src.dianInvoiceNumber;
          break;
        default:
          break;
      }

      return { sendDian, sendSiigo };
    },
  },
};
