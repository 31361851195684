import { Form, Modal, Field, Input, Toast, ActionButton } from "components/commons";
import { useForm, useModal } from "hooks";
import React, { useCallback, useEffect, useMemo } from "react";
import lang from "translations";
import initialFormState from "./account-code.form-state";
import { mixpanel, TrackEvent } from "mixpanel";

const AccountCodeFormModal = ({
  title,
  active,
  close,
  primaryText,
  initialState = undefined,
  save,
  submitting,
}) => {
  const unsaveChangesModal = useModal();

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { dirty, fields, modifyField, clearForm, submitForm, getFormValues, applyFieldErrors } =
    useForm({
      initialState: formState,
    });

  const exitForm = () => {
    clearForm();
    unsaveChangesModal.close();
  };

  const onClose = () => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          exitForm();
          setTimeout(() => {
            close();
          });
        },
      });
      return;
    }
    close();
    exitForm();
  };

  useEffect(() => {
    if (active) {
      clearForm();
    }
    // eslint-disable-next-line
  }, [active]);

  const onSave = useCallback(() => {
    save(
      getFormValues(),
      (res, { message }) => {
        Toast({
          content: message,
          success: true,
          icon: "check",
        }).open();
        close();
        clearForm();
      },
      ({ code, handleError }) => {
        const err = {
          3068: () => {
            applyFieldErrors({
              name: lang.accountCodeAlreadyExist,
            });
          },
        };
        if (err[code]) {
          err[code]();
        } else {
          handleError();
        }
      }
    );
  }, [save, clearForm, close, getFormValues, applyFieldErrors]);

  return (
    <div>
      <Modal
        active={active}
        hidden={unsaveChangesModal.active}
        onClose={() => onClose()}
        primaryText={primaryText}
        loading={submitting}
        noCloseButton
        title={title}
        actionContent={
          <ActionButton
            loading={submitting}
            className="m-md pb-md"
            mb="md"
            primary={{
              text: primaryText,
              onClick: () => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: lang.createAccountCode,
                  Page: lang.accountMapping,
                });
                submitForm(onSave);
              },
              disabled: !dirty,
            }}
            secondary={{
              onClick: () => {
                onClose();
              },
              text: lang.cancel,
            }}
          />
        }
      >
        <div className="px-sm">
          <Form unsaveChangesModal={unsaveChangesModal} isPrompt={dirty}>
            <Field
              {...fields.code}
              className="mt-sm"
              message={fields.message || lang.typeToSetAccountCode}
            >
              <Input required {...fields.code} onChange={modifyField} />
            </Field>
            <Field {...fields.name} className="mt-sm">
              <Input required {...fields.name} onChange={modifyField} />
            </Field>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default AccountCodeFormModal;
