import React from "react";
import lang from "translations";
import { Text, Title } from "components";
import QrBox from "./qr-box";
import { DangerIcon, MobileVersionImg } from "images";
import useMobile from "hooks/useMobile";

const Notes = ({ versionName = "", playStoreLink = "", apkS3Link = "", description = "" }) => {
  const isMobile = useMobile();
  return (
    <div>
      <section className="pt-0">
        <Text>{lang.populate(lang.theLastVersionOfAppAvailable, [versionName])}</Text>
        <Text>{lang.theUpdateShouldBeAppliedAutomatically}</Text>
      </section>
      <section className="mt-10 flex border-b border-pelorous border-dashed mb-4 pb-4">
        <img
          src={MobileVersionImg}
          alt=""
          className="rounded-2xl"
          style={{ height: isMobile ? 200 : 357 }}
        />
        <div className="ml-6 md:ml-10">
          <QrBox title={lang.pleaseCopyUrl} url={playStoreLink} />
          <QrBox title={lang.pleaseCopyUrlApk} url={apkS3Link} />
        </div>
      </section>
      <section>
        <Title color="text-black-dark">{lang.changelog}</Title>
        <div className="text-base md:text-md whitespace-pre-wrap">{description}</div>
      </section>
      <section className="mt-8 bg-red-100 flex gap-6 items-center rounded-lg px-6 py-3">
        <div>
          <img src={DangerIcon} alt="" className="w-10 h-10 md:w-16 md:h-16" />
        </div>
        <div className="flex-1">
          <Title color="text-red">{lang.disclaimer}</Title>
          <div className="text-base md:text-md whitespace-pre-wrap">{lang.disclaimerDesc}</div>
        </div>
      </section>
    </div>
  );
};

export default Notes;
