import { SearchOutlined } from "@ant-design/icons";
import { getLocations, searchProduct } from "apis";
import {
  ActionButton,
  Button,
  ButtonLink,
  CheckboxField,
  Field,
  FieldWithTitle,
  Form,
  Icon,
  Input,
  InputCounter,
  InputNumber,
  MultipleSelect,
  PageError,
  Panel,
  RadioGroup,
  RangePicker,
  Skeleton,
  Switch,
  Text,
  TimePicker,
  Title,
  Toast,
} from "components/commons";
import { Fragment, FragmentA, ModuleWrapper } from "components/fragments";
import { HeaderB } from "components/headers";
import { VenueContext } from "contexts";
import { StyleType } from "enums";
import { useApi, useForm, useModal, useRouter } from "hooks";
import {
  location,
  browseProductCategoryListRequest,
  browseProductCategoryListResponse,
} from "mappers";
import { Path } from "paths";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { mapObjectsToSelect } from "services";
import lang from "translations";
import initialFormState from "./voucher-form.state";
import styles from "./voucher-form.module.scss";
import classnames from "classnames";
import { BrowseProductModal } from "components/modals";
import moment from "moment";
import { NoLocationModal } from "components/modals";
import { mixpanel, TrackEvent } from "mixpanel";
import { CategoryProductsTable } from "components";
import { useFlags } from "launchdarkly-react-client-sdk";
const VoucherForm = ({
  title,
  initialState,
  error,
  submitting,
  loading,
  submit,
  deleteButton,
  linkedProductsMap,
  linkedProductsLoaded = true,
}) => {
  const { history } = useRouter();
  const { redeemableProductsOption } = useFlags();
  const [hasLocationsSelected, setHasLocationsSelected] = useState(false);
  const [availableEveryday, setAvailableEveryday] = useState(true);
  const [availableAnytime, setAvailableAnytime] = useState();
  const [limitVoucherIssued, setLimitVoucherIssued] = useState();
  const [redeemableProducts, setRedeemableProducts] = useState();
  const [requiredRedeemableProducts, setRequiredRedeemableProducts] = useState();
  const [requiredLocation, setRequiredLocation] = useState();
  const [browseProductDisabled, setBrowseProductDisabled] = useState(false);

  const { venue } = useContext(VenueContext);

  const unsaveChangesModal = useModal();
  const browseProductModal = useModal();
  const noLocationModal = useModal();

  const {
    request: getLocationsRequest,
    loading: loadingLocation,
    mappedData: locations,
  } = useApi({
    api: getLocations,
    isArray: true,
    mapper: location,
  });

  const {
    request: searchProductRequest,
    loading: loadingProduct,
    mappedData: mappedProducts,
  } = useApi({
    api: searchProduct,
    isArray: true,
    mapper: browseProductCategoryListResponse,
    paramsMapper: browseProductCategoryListRequest,
  });

  useEffect(() => {
    if (loadingLocation === false) {
      if (locations.length === 0) {
        noLocationModal.show({
          ok: () => {
            goToList();
            noLocationModal.close();
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [locations]);

  const fetchLocations = useCallback(() => {
    getLocationsRequest({ venueId: venue.venueId });
  }, [getLocationsRequest, venue.venueId]);

  const fetchProducts = useCallback(
    async (value) => {
      const res = await searchProductRequest({
        venueId: venue.venueId,
        locations: value,
        searchKey: "",
      });

      setBrowseProductDisabled(res.data && res.data.length === 0);
    },
    [searchProductRequest, venue]
  );

  useEffect(() => {
    if (linkedProductsLoaded) {
      fetchLocations();
      fetchProducts([]);
    }
    // eslint-disable-next-line
  }, [linkedProductsLoaded]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, dirty, setPristine } =
    useForm({
      initialState: formState,
    });

  useEffect(() => {
    const availableEveryday = Boolean(formState.dateRange.value.includes(null));

    setAvailableEveryday(availableEveryday);

    if (!availableEveryday) {
      setAvailableAnytime(
        Boolean(
          formState.dateRange.value[0].format("HH:mm") ===
            moment().startOf("day").format("HH:mm") &&
            formState.dateRange.value[1].format("HH:mm") === moment().endOf("day").format("HH:mm")
        )
      );
    }

    initialState
      ? setLimitVoucherIssued(Boolean(initialState.issuanceLimit >= 1))
      : modifyField("issuanceLimit", {
          value: (formState.issuanceLimit.value = null),
          dirty: false,
        });
    // eslint-disable-next-line
  }, [formState]);

  useEffect(() => {
    if (linkedProductsMap) setRedeemableProducts(linkedProductsMap);
  }, [linkedProductsMap]);

  const removeRedeemableCategory = useCallback(
    (categoryName) => {
      if (redeemableProducts.has(categoryName)) {
        redeemableProducts.delete(categoryName);
      }
      const newRedeemableProducts = new Map(redeemableProducts);
      setRedeemableProducts(newRedeemableProducts);
      modifyField("productSkuIds", { value: getSelectedProductSkuIds(newRedeemableProducts) });
      redeemableProducts.size <= 0
        ? setRequiredRedeemableProducts(true)
        : setRequiredRedeemableProducts(false);
    },
    [redeemableProducts, modifyField]
  );

  const removeRedeemableProduct = useCallback(
    (productName, categoryName) => {
      redeemableProducts.forEach((productMap) => {
        if (productMap.has(productName)) {
          productMap.delete(productName);
        }
      });
      const newRedeemableProducts = new Map(redeemableProducts);
      setRedeemableProducts(newRedeemableProducts);
      modifyField("productSkuIds", { value: getSelectedProductSkuIds(newRedeemableProducts) });
      for (let product of redeemableProducts.values()) {
        product.size <= 0 && redeemableProducts.size <= 1
          ? setRequiredRedeemableProducts(true)
          : setRequiredRedeemableProducts(false);
      }
    },
    [redeemableProducts, modifyField]
  );

  const goToList = useCallback(() => {
    history.push(Path.VOUCHER);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, goToList, unsaveChangesModal]);

  const showBrowseProductCb = useCallback(() => {
    browseProductModal.show();
  }, [browseProductModal]);

  const locationOptions = useMemo(() => {
    return mapObjectsToSelect(locations, { textKey: "name" });
  }, [locations]);

  const changeDateRangeCb = useCallback(
    (name, value) => {
      modifyField(name, { value });
    },
    [modifyField]
  );

  const usageAvailabilityCb = useCallback(
    (val) => {
      setAvailableEveryday(val);
      if (val === true) {
        modifyField("dateRange", { value: formState.dateRange.value });
      } else {
        modifyField("dateRange", { value: [moment().startOf("day"), moment().endOf("day")] });
        setAvailableAnytime(true);
      }
    },
    [modifyField, formState.dateRange]
  );

  const availableAnytimeCb = useCallback(
    (name, { value }) => {
      setAvailableAnytime(value);
      if (value === true) {
        modifyField("dateRange", {
          value: [
            fields.dateRange.value[0].clone().startOf("day"),
            fields.dateRange.value[1].clone().endOf("day"),
          ],
        });
      }
    },
    [modifyField, fields.dateRange]
  );

  const limitVoucherIssuedCb = useCallback(
    (name, { value }) => {
      if (value) {
        setLimitVoucherIssued(true);
        if (formState.issuanceLimit.value === null) {
          modifyField("issuanceLimit", { value: (formState.issuanceLimit.value = 10) });
        }
      } else {
        setLimitVoucherIssued(false);
        modifyField("issuanceLimit", { value: (formState.issuanceLimit.value = null) });
      }
    }, // eslint-disable-next-line
    [modifyField]
  );

  const handleSubmit = useCallback(async () => {
    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: lang.saveVoucherForm,
        Page: lang.vouchers,
      });

      const formValues = getFormValues();

      if (formValues.locations.length === 0) {
        formValues.locations = locationOptions;
      }

      if (formValues.productSkuIds.length === 0) {
        return;
      }

      if (availableEveryday) {
        formValues.dateRange = [null, null];
      }

      const res = await submit({ ...formValues });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.VOUCHER);
    } catch ({ code, handleError }) {
      const err = {
        3055: () => {
          applyFieldErrors({
            name: lang.voucherNameAlreadyExist,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [applyFieldErrors, history, submit, getFormValues, availableEveryday, locationOptions]);

  const handleEditLocations = () => {
    const win = window.open("/location", "_blank");
    win.focus();
  };

  const validateSelectedProducts = () => {
    const formValues = getFormValues();
    if (formValues?.productSkuIds.length === 0) {
      setRequiredRedeemableProducts(true);
    }
    if (formValues?.productSkuIds.length > 0) {
      setRequiredRedeemableProducts(false);
    }
  };

  const removeProductsThatAreNoLongerAvailable = useCallback(
    (mappedProducts, redeemableProducts) => {
      const formValues = getFormValues();

      if (mappedProducts && mappedProducts.length > 0) {
        formValues?.productSkuIds.forEach((productSkuId) => {
          var forRemoval = true;
          mappedProducts.forEach((product) => {
            if (product.productSkus) {
              product.productSkus.forEach((productSku) => {
                if (productSku.productSkuId === productSkuId) {
                  forRemoval = false;
                }
              });
            }
          });
          if (forRemoval && redeemableProducts) {
            redeemableProducts.forEach((productMap) => {
              productMap.forEach((value, key) => {
                if (value && value.indexOf(productSkuId) > -1) {
                  productMap.delete(key);
                }
              });
            });
            const newRedeemableProducts = new Map(redeemableProducts);
            setRedeemableProducts(newRedeemableProducts);
            modifyField("productSkuIds", {
              value: getSelectedProductSkuIds(newRedeemableProducts),
            });
            for (let product of redeemableProducts.values()) {
              product.size <= 0 && redeemableProducts.size <= 1
                ? setRequiredRedeemableProducts(true)
                : setRequiredRedeemableProducts(false);
            }
          }
        });
      } else {
        const newRedeemableProducts = new Map();
        setRedeemableProducts(newRedeemableProducts);
        modifyField("productSkuIds", { value: getSelectedProductSkuIds(newRedeemableProducts) });
        if (redeemableProducts) {
          for (let product of redeemableProducts.values()) {
            product.size <= 0 && redeemableProducts.size <= 1
              ? setRequiredRedeemableProducts(true)
              : setRequiredRedeemableProducts(false);
          }
        }
      }
    },
    [getFormValues, modifyField]
  );

  useEffect(() => {
    if (hasLocationsSelected && linkedProductsLoaded) {
      removeProductsThatAreNoLongerAvailable(mappedProducts, redeemableProducts);
    }
  }, [
    mappedProducts,
    removeProductsThatAreNoLongerAvailable,
    hasLocationsSelected,
    redeemableProducts,
    linkedProductsLoaded,
  ]);

  const submitFormCb = useCallback(() => {
    validateSelectedProducts();
    if (!requiredRedeemableProducts) {
      submitForm(handleSubmit);
    }
    // eslint-disable-next-line
  }, [handleSubmit, submitForm]);

  return (
    <ModuleWrapper>
      <BrowseProductModal
        {...browseProductModal}
        locations={fields.locations.value}
        preSelectedSkus={fields.productSkuIds.value}
        onAdd={(v) => {
          setRedeemableProducts(v);
          setRequiredRedeemableProducts(false);
          modifyField("productSkuIds", { value: getSelectedProductSkuIds(v) });
        }}
      />
      <NoLocationModal
        content={lang.toContinueCreatingProducts}
        {...noLocationModal}
        onClose={() => {
          fetchLocations();
        }}
      />
      <HeaderB title={title} returnText={lang.vouchers} onClick={leavePage} />
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormCb} isPrompt={dirty}>
        {error ? (
          <PageError />
        ) : (
          <Fragment>
            <FragmentA title={lang.basicInfo}>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <Fragment>
                    <FieldWithTitle className="mb-md" {...fields.posStatus}>
                      <Switch {...fields.posStatus} onChange={modifyField} />
                    </FieldWithTitle>
                    <Field className="mb-md" {...fields.name} noLabel>
                      <InputCounter required {...fields.name} onChange={modifyField} />
                    </Field>
                    <Field>
                      <InputCounter {...fields.description} textarea onChange={modifyField} />
                    </Field>
                  </Fragment>
                )}
              </Panel>
            </FragmentA>
            <FragmentA title={lang.location} description={lang.locationVoucherRedeemed}>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <Fragment>
                    <div>
                      <div className="flex justify-between pb-md">
                        <Text strong size="text-sm">
                          {lang.location}
                        </Text>
                        <ButtonLink size="text-sm" onClick={handleEditLocations}>
                          {lang.editLocations}{" "}
                          <Icon name="arrow-diagonal-right" className="text-blue text-xxs ml-xs" />
                        </ButtonLink>
                      </div>
                      <Field className="mb-sm" {...fields.locations} noLabel>
                        <MultipleSelect
                          {...fields.locations}
                          name="locations"
                          selectAllText={lang.allLocations}
                          loading={loadingLocation}
                          options={locationOptions}
                          onClick={() => {
                            setHasLocationsSelected(true);
                            fetchLocations();
                          }}
                          onChange={(name, obj) => {
                            setRequiredLocation(!obj.isAll && !obj.value);
                            let isDirty = dirty;
                            modifyField(name, { value: obj.value });
                            if (obj.isAll && !isDirty) {
                              setPristine();
                            }
                            if (obj.value !== null) {
                              fetchProducts(obj.value);
                            } else {
                              setBrowseProductDisabled(true);
                              setRequiredRedeemableProducts(false);
                              setRedeemableProducts(null);
                              modifyField("productSkuIds", {
                                value: getSelectedProductSkuIds(new Map()),
                              });
                              modifyField(name, { value: [] });
                            }
                          }}
                          placeholder={
                            !locationOptions.length ? lang.noLocationAvailable : lang.selectLocation
                          }
                          error={requiredLocation}
                        />
                      </Field>
                    </div>
                  </Fragment>
                )}
              </Panel>
            </FragmentA>
            <FragmentA title={lang.redeemableProducts} description={lang.productsCanBeRedeemed}>
              <Panel>
                {loading || !linkedProductsLoaded ? (
                  <Skeleton width="100%" active />
                ) : (
                  <div>
                    {redeemableProductsOption && (
                      <div className="mb-md">
                        <RadioGroup
                          title={lang.selectRedeemableProducts}
                          options={[
                            { text: lang.allProducts, value: false, disabled: true },
                            { text: lang.specificCategories, value: false, disabled: true },
                            { text: lang.specificProducts, value: "specific-products" },
                          ]}
                          value="specific-products"
                        />
                      </div>
                    )}
                    {/* <MultipleSelect {...fields.categories} /> */}
                    <div
                      className="flex"
                      onClick={() => {
                        if (!browseProductDisabled) {
                          mixpanel.track(TrackEvent.ClickedButton, {
                            Button: lang.browseProducts,
                            Page: lang.vouchers,
                          });
                          showBrowseProductCb();
                        }
                      }}
                    >
                      <Input
                        disabled={loadingProduct || browseProductDisabled}
                        className={classnames("rounded-r-none", styles.browseInput, {
                          [`${styles.browseInputError}`]: requiredRedeemableProducts,
                        })}
                        placeholder={
                          browseProductDisabled
                            ? lang.noProductsAvailable
                            : lang.selectCategoriesOrProducts
                        }
                        iconPrefix={<SearchOutlined className="mr-sm" />}
                        readOnly
                      />
                      <Button
                        loading={loadingProduct}
                        disabled={loadingProduct || browseProductDisabled}
                        type={requiredRedeemableProducts ? StyleType.Danger : StyleType.Secondary}
                        className={classnames(
                          "rounded-l-none bg-gradient-to-b from-white to-white-dark",
                          { [`${styles.browseButton}`]: !requiredRedeemableProducts },
                          `${requiredRedeemableProducts ? "border-red" : "border-white-darker"}`
                        )}
                      >
                        {lang.browseProducts}
                      </Button>
                    </div>
                    <CategoryProductsTable
                      linkedProducts={redeemableProducts}
                      required={requiredRedeemableProducts}
                      removeProduct={removeRedeemableProduct}
                      removeCategory={removeRedeemableCategory}
                    />
                  </div>
                )}
              </Panel>
            </FragmentA>
            <FragmentA>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <div>
                    <Title md className="mb-md">
                      {lang.managerAuthorization}
                    </Title>
                    <CheckboxField
                      {...fields.managerAuthorization}
                      onChange={modifyField}
                      textSize="text-sm"
                    >
                      {lang.requireManagerAuthorizationVoucher}
                    </CheckboxField>
                  </div>
                )}
              </Panel>
            </FragmentA>
            <FragmentA>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <div>
                    <div className="mb-md">
                      <RadioGroup
                        title={lang.usageDatePeriod}
                        options={[
                          { text: lang.availableEveryday, value: true },
                          { text: lang.customDateTime, value: false },
                        ]}
                        onChange={usageAvailabilityCb}
                        value={availableEveryday}
                      />
                      {!availableEveryday && (
                        <div className="pl-lg">
                          <Field className="mb-md" label={fields.dateRange.label}>
                            <RangePicker {...fields.dateRange} onChange={changeDateRangeCb} />
                          </Field>
                          <Field className="mb-md" label={lang.timeRange}>
                            <div className="flex items-center">
                              <TimePicker
                                disabled={availableAnytime}
                                value={fields.dateRange.value[0]}
                                onChange={(startTime) => {
                                  modifyField("dateRange", {
                                    value: [
                                      fields.dateRange.value[0].set({
                                        hour: startTime.get("hour"),
                                        minute: startTime.get("minute"),
                                      }),
                                      fields.dateRange.value[1],
                                    ],
                                  });
                                }}
                              />
                              <Text className="mx-sm" color="text-black-light">
                                to
                              </Text>
                              <TimePicker
                                disabled={availableAnytime}
                                value={fields.dateRange.value[1]}
                                onChange={(endTime) => {
                                  modifyField("dateRange", {
                                    value: [
                                      fields.dateRange.value[0],
                                      fields.dateRange.value[1].set({
                                        hour: endTime.get("hour"),
                                        minute: endTime.get("minute"),
                                      }),
                                    ],
                                  });
                                }}
                              />
                            </div>
                          </Field>
                          <CheckboxField
                            value={availableAnytime}
                            onChange={availableAnytimeCb}
                            textSize="text-sm"
                          >
                            {lang.availableAnytime}
                          </CheckboxField>
                        </div>
                      )}
                    </div>
                    <div>
                      <Title md className="mb-md">
                        {lang.issuanceLimit}
                      </Title>
                      <CheckboxField
                        value={limitVoucherIssued}
                        className="mb-sm"
                        textSize="text-sm"
                        onChange={limitVoucherIssuedCb}
                      >
                        {lang.limitVoucherPerPerson}
                      </CheckboxField>
                      {limitVoucherIssued && (
                        <InputNumber min={1} {...fields.issuanceLimit} onChange={modifyField} />
                      )}
                    </div>
                  </div>
                )}
              </Panel>
            </FragmentA>
          </Fragment>
        )}
        <ActionButton
          showLine
          loading={submitting}
          primary={{
            onClick: () => {
              submitFormCb();
            },
            disabled: submitting,
          }}
          secondary={{
            text: lang.cancel,
            onClick: () => leavePage(),
          }}
          danger={deleteButton}
        />
      </Form>
    </ModuleWrapper>
  );
};

const getSelectedProductSkuIds = (v) => {
  const selectedProductSkusSet = new Set();

  v.forEach((productMap) => {
    productMap.forEach((productSkuIds) => {
      productSkuIds.forEach((pskuId) => {
        selectedProductSkusSet.add(pskuId);
      });
    });
  });

  return [...selectedProductSkusSet];
};

export default VoucherForm;
