import { ActionButton, Button, Form, Line, Toast } from "components/commons";
import { FragmentA, ModuleWrapper } from "components/fragments";
import { HeaderC } from "components/headers";
import { useApi, useForm, useModal, useMount, useRouter } from "hooks";
import { Path } from "paths";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import initialFormState from "./product-set-form.state";
import { searchCategory } from "apis/category.api";
import { getLocations } from "apis/location.api";
import { VenueContext } from "contexts";
import BasicInfoForm from "../product-form/basic-info-form";
import LocationForm from "../product-form/location-form";
import SkuForm from "../product-form/sku-form";
import PricingAndTaxesForm from "../product-form/pricing-and-taxes-form";
import { getTaxes } from "apis/tax.api";
import ItemsForm from "./items-form";
import { parseAmountToNumber } from "services";
import { parseMoneyToNumber } from "services/money.service";
import { StyleType } from "enums";
import DeleteSingleProductModal from "../delete-single-product-modal/delete-single-product-modal";
import { mixpanel, TrackEvent } from "mixpanel";
import { mapAccountOptions } from "services/accounting.service";
import { accountingResponse } from "mappers/accounting.mapper";
import { getAccountCodes, getMewsIntegrationStatus } from "apis";

const ProductSetForm = ({
  error,
  title,
  initialState = undefined,
  onSubmit,
  onEdit = false,
  onDeleteProduct,
  productSetId,
  submitting,
}) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history, location } = useRouter();
  const unsaveChangesModal = useModal();
  const deleteProductModal = useModal();

  const { state: locationState } = location || {};

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const form = useForm({
    initialState: formState,
  });

  const {
    dirty,
    modifyField,
    fields,
    submitForm,
    getFormValues,
    // isFormSubmittable,
    modifyForm,
    applyFieldErrors,
  } = form;

  const goToList = useCallback(() => {
    history.push(Path.PRODUCT, {
      page: locationState?.page,
      categories: locationState?.categories,
    });
  }, [history, locationState]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const requestCategories = useApi({
    api: searchCategory,
    params: {
      venueId,
      page: 1,
      itemsPerPage: null,
    },
    mapper: {
      text: {
        key: "categoryName",
      },
      value: {
        key: "categoryId",
      },
    },
    isArray: true,
  });

  const requestLocations = useApi({
    api: getLocations,
    params: {
      venueId,
      page: 1,
      itemsPerPage: null,
    },
    mapper: {
      text: {
        key: "locationName",
      },
      value: {
        key: "locationId",
      },
    },
    isArray: true,
  });

  const requestTax = useApi({
    api: getTaxes,
    params: {
      venueId,
    },
    mapper: {
      text: {
        key: "taxName",
      },
      value: {
        key: "taxId",
      },
      taxPercentage: {
        key: "taxPercentage",
      },
      _keys: ["taxDefault"],
    },
    isArray: true,
  });

  const { request: mewsRequest } = useApi({
    api: getMewsIntegrationStatus,
    params: {
      venueId: venue?.venueId,
    },
    mapper: {
      id: { key: "id" },
      integrationType: { key: "integrationType" },
    },
  });

  const { request: accountRequest, mappedData: accountListMappedData } = useApi({
    api: getAccountCodes,
    params: {
      businessId: venueId,
    },
    isArray: true,
    mapper: accountingResponse,
  });

  const accountOptions = useMemo(() => {
    return mapAccountOptions(accountListMappedData);
  }, [accountListMappedData]);

  useMount(async () => {
    requestCategories.request();
    requestLocations.request();
    requestTax.request();

    accountRequest();
    mewsRequest();

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createProductSet,
    });
  });

  const actionButton = (showLine) => (
    <ActionButton
      loading={submitting}
      primary={{
        loading: submitting,
        disabled: checkIfFormHasError || !dirty,
        onClick: () => {
          submitForm(submit);
        },
      }}
      secondary={{
        onClick: leavePage,
        text: lang.cancel,
      }}
      showLine={showLine}
    />
  );

  const formField = useMemo(() => {
    const formItems = {};
    [
      "productLogo",
      "productName",
      "description",
      "category",
      "revenueAccountCodeId",
      "cogsAccountCodeId",
      "activePos",
      "activeOnlineMenu",
      "location",
      "inventoryType",
      "sku",
      "generatedSku",
      "supplyUnit",
      "supplyQuantity",
      "sellingQuantity",
      "hasVariants",
      "hasTax",
      "supplyCost",
      "markUp",
      "retailPrice",
      "variants",
      "attributeAndOptions",
      "tax",
      "products",
      "productSkuId",
      "bargainAllowed",
      "bargainRequiresApproval",
    ].forEach((key) => {
      formItems[key] = {
        ...fields[key],
        onChange: modifyField,
      };
    });
    return formItems;
  }, [fields, modifyField]);

  const submit = useCallback(async () => {
    const formValues = getFormValues();
    let params = {
      imageLink: formValues.productLogo,
      productName: formValues.productName,
      description: formValues.description,
      revenueAccountCodeId: formValues.revenueAccountCodeId,
      cogsAccountCodeId: formValues.cogsAccountCodeId,
      inventoryTracked: false,
      categories: formValues.category?.map(({ text, value }) => {
        return { categoryName: text, categoryId: value };
      }),
      locationIds: formValues.location?.length
        ? formValues.location?.map(({ value }) => {
            return value;
          })
        : [],
      isActiveAllLocations: fields.location.isAll,
      isActiveAllCategories: fields.category.isAll,
      taxId: formValues.hasTax ? Number(formValues.tax) : null,
      sellableInPos: formValues.activePos,
      isActiveMobileOrdering: formValues.activeOnlineMenu,
      creationType: formValues.inventoryType,
      sku: formValues.sku,
      compositions: formValues.products.map((product) => {
        return {
          productSkuId: product.productSkuId,
          quantity: parseAmountToNumber(product.quantity),
        };
      }),
      supplyPrice: parseMoneyToNumber(formValues.supplyCost).value,
      retailPrice: parseMoneyToNumber(formValues.retailPrice).value,
      bargainAllowed: formValues.bargainAllowed,
      bargainRequiresApproval: formValues.bargainRequiresApproval,
    };

    try {
      mixpanel.track(TrackEvent.Clickedbutton, {
        Button: lang.saveProductSet,
      });
      if (!params.isActiveAllLocations && params.locationIds.length === 0) {
        applyFieldErrors({
          location: lang.pleaseSelectLocation,
        });
        return;
      }
      const res = await onSubmit({
        ...params,
        venueId,
      });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      goToList();
    } catch ({ handleError, code }) {
      const error = {
        3006: () => {
          applyFieldErrors({
            productName: lang.thisProductNameAlreadyExists,
          });
        },
        3001: () => {
          applyFieldErrors({
            sku: lang.thisSkuExists,
          });
        },
      };
      if (error[code]) {
        return error[code]();
      }
      return handleError();
    }
    // eslint-disable-next-line
  }, [onSubmit, getFormValues, goToList, venueId]);

  const checkIfFormHasError = useMemo(() => {
    let hasError = false;
    for (const [, formValue] of Object.entries(fields)) {
      if (formValue.error) {
        hasError = true;
      } else if (formValue.isFormArray) {
        hasError = formValue.value.some((fv) => {
          for (const [key] of Object.entries(fv.value)) {
            if (fv.value[key].error || fv.value[key].value === null) {
              return true;
            }
          }
          return fv.error;
        });
      }
    }

    return hasError;
  }, [fields]);

  return (
    <ModuleWrapper
      error={error || requestCategories.error}
      header={
        <HeaderC
          returnText={lang.products}
          title={title}
          onClick={leavePage}
          action={
            <ActionButton
              primary={{
                disabled: checkIfFormHasError || !dirty,
                onClick: () => {
                  submitForm(submit);
                },
              }}
              secondary={{ onClick: leavePage, text: lang.cancel }}
            />
          }
        />
      }
    >
      <Form unsaveChangesModal={unsaveChangesModal} isPrompt={dirty}>
        <BasicInfoForm
          loader={false}
          form={formField}
          categories={requestCategories.mappedData?.filter((category) => category.value !== 0)}
          accountingOptions={accountOptions}
          modifyField={modifyField}
        />
        <LocationForm form={formField} locations={requestLocations.mappedData} />
        <FragmentA title={lang.inventory} description={lang.manageInventoryAndProducts}>
          <SkuForm
            generatedSku={formField.generatedSku}
            sku={formField.sku}
            modifyForm={modifyForm}
            showAutogenerateCheckbox={!onEdit}
          />
          <ItemsForm
            locations={fields.location.value}
            form={formField}
            modifyForm={modifyForm}
            productSkuId={initialState?.productSkuId}
          />
        </FragmentA>
        <PricingAndTaxesForm form={formField} requestTax={requestTax} modifyForm={modifyForm} />
        {!onEdit ? (
          actionButton(true)
        ) : (
          <div>
            <Line />
            <div className="flex justify-between items-center ">
              <Button type={StyleType.Danger} onClick={() => deleteProductModal.show()}>
                {lang.deleteAProductSet}
              </Button>
              <div className="flex items-center">{actionButton(true)}</div>
              <DeleteSingleProductModal
                productId={productSetId}
                ok={onDeleteProduct}
                {...deleteProductModal}
              />
            </div>
          </div>
        )}
      </Form>
    </ModuleWrapper>
  );
};

export default ProductSetForm;
