import { ModuleWrapper, FragmentA } from "components/fragments";
import { HeaderB } from "components/headers";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { Path } from "paths";
import { useModal, useRouter, useForm, useApi, useMount } from "hooks";
import {
  Form,
  Panel,
  Field,
  InputMoney,
  Title,
  Text,
  Skeleton,
  ActionButton,
  Checkbox,
  Toast,
  Tooltip,
  Icon,
} from "components/commons";
import { Field as FieldType, CreditExpirationType } from "enums";
import Validation from "services/validation.service";
import { VenueContext } from "contexts";
import { setVenueCreditSettings } from "apis/venue.api";
import { creditLimitRequest } from "mappers";
import { mixpanel, TrackEvent } from "mixpanel";

const CreditLimitSetting = () => {
  const { venue, fetchVenue, loading, error } = useContext(VenueContext);
  const { history } = useRouter();
  const unsaveChangesModal = useModal();
  // const creditExpiration = CreditExpirationType.NeverExpiresAfterCheckout;

  const initialState = useMemo(() => {
    return {
      hasCreditLimit: {
        name: "hasCreditLimit",
        value: Boolean(venue.postPaidBillingLimit),
        type: FieldType.CHECKBOX,
        validations: [Validation.required()],
        required: true,
        maxLength: 75,
      },
      creditLimit: {
        name: "creditLimit",
        value: venue.postPaidBillingLimitMoney,
        type: FieldType.INPUT,
        validations: [Validation.required()],
        required: true,
        maxLength: 75,
      },
      creditExpirationType: {
        name: "creditExpirationType",
        value: venue.creditExpirationType,
        type: FieldType.RADIO,
      },
      creditExpirationCount: {
        name: "creditExpirationCount",
        value: venue.creditExpirationCount,
        type: FieldType.INPUT,
      },
      creditExpirationSpan: {
        name: "creditExpirationSpan",
        value: venue.creditExpirationSpan || "DAYS",
        type: FieldType.DROPDOWN,
      },
      isCreditResetExpirationActive: {
        name: "isCreditResetExpirationActive",
        value: venue.isCreditResetExpirationActive,
        type: FieldType.CHECKBOX,
      },
    };
  }, [venue]);

  const { fields, modifyField, modifyForm, dirty, submitForm, getFormValues, clearForm } = useForm({
    initialState,
  });

  const goToList = useCallback(() => {
    history.push(Path.SETTING);
  }, [history]);

  const { request, loading: submitting } = useApi({
    api: setVenueCreditSettings,
    params: {
      venueId: venue.venueId,
    },
    paramsMapper: creditLimitRequest,
    isAction: true,
  });

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const validateFields = (params) => {
    const creditType = params.creditExpirationType;
    const creditExpCount = params.creditExpirationCount;
    const creditExpirationSpan = params.creditExpirationSpan;
    if (creditType === CreditExpirationType.AutomaticallyExpiresAfterCheckout) {
      modifyForm({
        creditExpirationCount: { validations: [Validation.number(), Validation.required()] },
        creditExpirationSpan: { validations: [Validation.required()] },
      });
      if (creditExpCount === null || creditExpirationSpan === null) {
        return true;
      }
      if (creditExpCount === 0) {
        modifyField("creditExpirationCount", { value: null, validations: [Validation.required()] });
        return true;
      }
      return false;
    }
    if (creditType === CreditExpirationType.NeverExpiresAfterCheckout) {
      modifyForm({
        creditExpirationCount: { validations: [] },
        creditExpirationSpan: { validations: [] },
      });
    }
    return false;
  };

  const submitFormValue = useCallback(async () => {
    try {
      const params = getFormValues();
      const err = validateFields(params);
      if (!err) {
        await request(params);
        clearForm();
        await fetchVenue();
        history.push(Path.SETTING);
        Toast({
          content: lang.savedChanges,
          success: true,
          icon: "check",
        }).open();
      }
    } catch (e) {
      throw e;
    }
    // eslint-disable-next-line
  }, [fetchVenue, getFormValues, request, clearForm, history]);

  useMount(() => {
    fetchVenue();

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.creditSettings,
    });
  });

  // const creditExpirationOptions = useMemo(() => {
  //   return [
  //     { text: lang.daysAfterCheckout, value: "DAYS" },
  //     { text: lang.weeksAfterCheckout, value: "WEEKS" },
  //     { text: lang.monthsAfterCheckout, value: "MONTHS" },
  //   ];
  // }, []);

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          returnText={lang.settings}
          title={lang.credits}
          returnPath={Path.SETTING}
          onClick={leavePage}
        />
      }
    >
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={() => {}} isPrompt={dirty}>
        {loading ? (
          <>
            <FragmentA title={lang.creditLimits}>
              <Skeleton />
            </FragmentA>
            <FragmentA title={lang.creditExpirationOnCheckout}>
              <Skeleton />
            </FragmentA>
          </>
        ) : (
          <>
            <FragmentA title={lang.creditsLimits}>
              <Panel>
                <Title md className="mb-sm">
                  {lang.postpaidCredits}
                </Title>
                <Field className="flex">
                  <Checkbox
                    {...fields.hasCreditLimit}
                    onChange={(name, { value }) => {
                      modifyForm({
                        [name]: {
                          value,
                        },
                        creditLimit: {
                          value: "0",
                        },
                      });
                    }}
                  />
                  <Text className="ml-sm">{lang.allowThisGuestProfileLimit}</Text>
                </Field>
                {fields.hasCreditLimit.value && (
                  <Field
                    {...fields.creditLimit}
                    customLabel={
                      <div className="flex items-center">
                        <Text label>{lang.limit}</Text>
                        <Tooltip title={lang.setAnAmountToLimitPostPaid}>
                          <div>
                            <Icon name="info" className="text-xs text-gray" />
                          </div>
                        </Tooltip>
                      </div>
                    }
                    width="w-1/2"
                    className="mt-sm"
                  >
                    <InputMoney
                      required
                      {...fields.creditLimit}
                      onChange={modifyField}
                      limit={10000000}
                      noDecimal
                    />
                  </Field>
                )}
              </Panel>
            </FragmentA>
            {/* <FragmentA
              title={lang.creditsExpirationOnCheckout}
              description={lang.creditsExpirationDescription}
            >
              <Panel>
                <Hightlight className="mb-md">
                  <div className="flex items-center">
                    <Icon name="info" className="text-sm text-yellow flex px-sm" />
                    <Text>{lang.creditsWouldNeverExpire}</Text>
                  </div>
                </Hightlight>

                <Field {...fields.creditExpirationType}>
                  <RadioGroup
                    name={fields.creditExpirationType}
                    title={lang.creditsExpiration}
                    options={[
                      {
                        text: lang.creditsNeverExpires,
                        value: CreditExpirationType.NeverExpiresAfterCheckout,
                      },
                      {
                        text: lang.creditAutomaticallyExpires,
                        value: CreditExpirationType.AutomaticallyExpiresAfterCheckout,
                      },
                    ]}
                    value={
                      fields.creditExpirationType.value
                        ? fields.creditExpirationType.value
                        : creditExpiration
                    }
                    onChange={(value) => {
                      if (value === CreditExpirationType.AutomaticallyExpiresAfterCheckout) {
                        modifyForm({
                          creditExpirationType: { value: value },
                          creditExpirationCount: {
                            validations: [],
                          },
                          creditExpirationSpan: {
                            validations: [],
                          },
                        });
                      } else {
                        modifyForm({
                          creditExpirationType: { value: value },
                          creditExpirationCount: {
                            validations: [],
                          },
                          creditExpirationSpan: {
                            validations: [],
                          },
                        });
                      }
                    }}
                  />
                </Field>
                <Text label>{lang.setExpirationPeriod}</Text>
                <div className="flex items-center">
                  <Input
                    {...fields.creditExpirationCount}
                    inputType="text"
                    required
                    width="w-1/5"
                    maxLength={2}
                    onChange={(name, { value }) => {
                      const v = parseInt(value);
                      if (isNaN(v)) {
                        modifyField(name, {
                          value: null,
                          validations: [Validation.required(), Validation.number()],
                        });
                      } else {
                        modifyField(name, {
                          value: v,
                          validations: [Validation.required(), Validation.number()],
                        });
                      }
                    }}
                    disabled={
                      fields.creditExpirationType.value ===
                        CreditExpirationType.NeverExpiresAfterCheckout ||
                      fields.creditExpirationType.value === null
                    }
                  ></Input>
                  <Select
                    {...fields.creditExpirationSpan}
                    options={creditExpirationOptions}
                    value={
                      fields.creditExpirationSpan.value ? fields.creditExpirationSpan.value : "DAYS"
                    }
                    onChange={(name, { value }) => {
                      modifyField(name, {
                        value: value,
                        validations: [Validation.required()],
                      });
                    }}
                    className="w-1/2 mx-sm"
                    disabled={
                      fields.creditExpirationType.value ===
                        CreditExpirationType.NeverExpiresAfterCheckout ||
                      fields.creditExpirationType.value === null
                    }
                  />
                </div>
                <Line></Line>
                <Title>{lang.resetExpiration}</Title>
                <CheckboxField
                  {...fields.isCreditResetExpirationActive}
                  textSize="text-sm"
                  onChange={modifyField}
                  disabled={
                    fields.creditExpirationType.value ===
                      CreditExpirationType.NeverExpiresAfterCheckout ||
                    fields.creditExpirationType.value === null
                  }
                >
                  {lang.resetTheExpirationPeriod}
                </CheckboxField>
              </Panel>
            </FragmentA> */}
          </>
        )}
      </Form>
      <ActionButton
        showLine
        loading={submitting}
        primary={{
          onClick: () => {
            mixpanel.track(TrackEvent.ClickedButton, {
              Button: lang.saveCreditSetting,
              Page: lang.creditSettings,
            });
            submitForm(submitFormValue);
          },
          disabled: !dirty,
        }}
        secondary={{
          text: lang.cancel,
          onClick: () => leavePage(),
        }}
      />
    </ModuleWrapper>
  );
};

export default CreditLimitSetting;
