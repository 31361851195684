import { Field, Filter, RangePicker, Select } from "components";
import { InvoicedSiigo } from "enums";
import React, { useCallback, useMemo } from "react";
import { prettifyInvoiced } from "services/pretty.service";
import lang from "translations";

const InvoiceFilter = ({ filterState, modifyFilter, clearFilter, applyFilter, loading }) => {
  const invoicedOptions = useMemo(
    () =>
      [
        InvoicedSiigo.Unbill,
        InvoicedSiigo.SiigoError,
        InvoicedSiigo.SiigoAccepted,
        InvoicedSiigo.DianError,
        InvoicedSiigo.DianAccepted,
      ].map((e) => ({
        value: e,
        text: prettifyInvoiced(e),
      })),
    []
  );

  const applyFilterCb = useCallback(
    async (searchKey) => {
      await applyFilter(searchKey);
    },
    [applyFilter]
  );

  const clearFilterCb = useCallback(() => {
    clearFilter();
  }, [clearFilter]);

  const changeDateRangeCb = useCallback(
    (name, value) => {
      modifyFilter(name, { value });
    },
    [modifyFilter]
  );

  return (
    <Filter
      placeholder={lang.searchGuestNameOrId}
      className="mb-lg"
      onClear={clearFilterCb}
      onApply={applyFilterCb}
      filterState={filterState}
      loading={loading}
    >
      <Field className="col-span-4" filterLabel={lang.dateRange}>
        <RangePicker name="dateRange" onChange={changeDateRangeCb} value={filterState.dateRange} />
      </Field>

      <Field className="col-span-4" filterLabel={lang.state}>
        <Select
          name="state"
          options={invoicedOptions}
          value={filterState.state}
          onChange={modifyFilter}
          sortOptions={false}
          placeholder={lang.allState}
          allowClear
        />
      </Field>
    </Filter>
  );
};

export default InvoiceFilter;
