import { Field, FragmentA, Panel, Select } from "components";
import React from "react";
import lang from "translations";

const InventoryGroup = ({ form, options, loading }) => {
  const { siigoAccountGroupId } = form;
  return (
    <FragmentA title={lang.inventoryGroup} description={lang.selectInventoryGroupFormSiigoToAssign}>
      <Panel>
        <Field {...siigoAccountGroupId}>
          <Select {...siigoAccountGroupId} options={options} optionsLoading={loading} />
        </Field>
      </Panel>
    </FragmentA>
  );
};

export default InventoryGroup;
