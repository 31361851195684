import moment from "moment";

const filter_key = "filter";
const iniState = {
  name: "",
  filter: {},
};

export const useFilterStore = () => {
  const getState = () => {
    let temp = localStorage.getItem(filter_key);
    if (!temp) return iniState;
    temp = JSON.parse(temp);
    const dateRange = temp?.filter?.dateRange;
    if (dateRange) {
      temp.filter.dateRange = dateRange.map((i) => moment(i));
    }
    return temp;
  };

  const setState = (state) => {
    localStorage.setItem(filter_key, JSON.stringify(state));
  };

  const deleteState = () => {
    localStorage.removeItem(filter_key);
  };

  return {
    getState,
    setState,
    deleteState,
  };
};

export default useFilterStore;

export const filterName = {
  product: "product",
  category: "category",
  supplyItem: "supply-item",
  stocktake: "stocktake",
  location: "location",
  booking: "booking",
  balanceMovementIn: "balance-movement-in",
  balanceMovementOut: "balance-movement-out",
  staff: "staff",
  guest: "guest",
  guestsProfile: "guest-profile",
  voucher: "voucher",
  discount: "discount",
  transaction: "transaction",
  shiftReport: "shift-report",
  eod: "end-of-day",
  device: "device",
  printer: "printer",
  additionalCharge: "additional-charge",
  eInvoiced: "E-Invoiced",
};
