import { ModuleWrapper } from "components/fragments";
import { HeaderB } from "components/headers";
import { useApi, useMount, useRouter } from "hooks";
import { Path } from "paths";
import React, { useMemo } from "react";
import lang from "translations";
import { getTransactionById } from "apis/transaction.api";
import { FragmentB } from "components/fragments";
import TransactionSummary from "./transaction-summary";
import { viewTransactions } from "mappers";
import {
  Table,
  Title,
  Pill,
  Panel,
  Text,
  Icon,
  Skeleton,
  ButtonLink,
  Tooltip,
} from "components/commons";
import { prettifyTransactionType } from "services";
import { getPillTypeOfTransactionType } from "services/styling.service";
import { v4 as uuidv4 } from "uuid";
import { ExternalPaymentStatus, PaymentMethod, PillType, TapStatus, TransactionType } from "enums";
import { formatNumberToMoneyCurrency, getCurrencySymbol } from "services/money.service";
import { mixpanel, TrackEvent } from "mixpanel";
import { ExportOutlined } from "@ant-design/icons";

const ViewTransaction = () => {
  const { query } = useRouter();
  const { id } = query || {};
  const transactionTitle = `#${id}`;
  const { request, loading, mappedData, error } = useApi({
    api: getTransactionById,
    params: {
      transactionId: id,
    },
    mapper: viewTransactions,
  });

  useMount(() => {
    request();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.transactionInfoPage,
    });
  });

  const {
    guest = {},
    isVoided,
    isFailed,
    registerInfo = {},
    transactionDate,
    paymentMethod,
    type,
    historyTable = {
      columns: [],
      data: [],
    },
    isCheckout,
    nfcStatus,
    externalPaymentStatus,
  } = mappedData;

  const renderHistoryData = useMemo(() => {
    return historyTable.data.map(({ authorization = {}, dateTime, staff, ...d }) => {
      return {
        ...d,
        staff: (
          <div>
            <Text>{staff.name}</Text>
            <Text tagUid>{staff.tagUid}</Text>
          </div>
        ),
        authorization: (
          <div>
            <Text>{authorization.name}</Text>
            <Text tagUid>{authorization.tagUid}</Text>
          </div>
        ),
        dateTime: (
          <div>
            <Text>{dateTime.date}</Text>
            <Text>{dateTime.time}</Text>
          </div>
        ),
      };
    });
  }, [historyTable.data]);

  const guestDetails = useMemo(() => {
    const keys = [
      ["email", lang.email],
      ["phone", lang.phone],
      ["dob", lang.birthday],
    ];
    const data = [];
    keys.forEach(([key]) => {
      if (guest?.[key]) {
        data.push(guest[key]);
      }
    });

    if ((!guest.hasName && !data.length) || data.length) {
      return (
        <div className="mt-md">
          <Text uppercase strong color="text-gray" size="text-xs">
            {lang.information}
          </Text>
          {!data.length && <Text className="md:mt-sm mt-xs">{lang.guestInfoNotYetSync}</Text>}
          {data.map((d) => {
            return (
              <Text className="mt-sm leading-none" key={uuidv4()}>
                {d}
              </Text>
            );
          })}
        </div>
      );
    }
    return null;
  }, [guest]);

  const postpaidDetails = useMemo(() => {
    const currency = getCurrencySymbol();
    if (guest.postpaidCreditLimit) {
      return (
        <div className="mt-md">
          <Text uppercase strong color="text-gray" size="text-xs">
            {lang.postpaidCreditLimit}
          </Text>

          <Text className="mt-sm" key={uuidv4()}>
            {`${currency} ${formatNumberToMoneyCurrency(guest.postpaidCreditLimit)}`}
          </Text>
        </div>
      );
    }
    return null;
  }, [guest]);

  const registerInfoDetails = useMemo(() => {
    const keys = [
      ["deviceName", lang.deviceName],
      ["deviceImei", lang.deviceImei],
      ["deviceMacAddress", lang.deviceMacAddress],
      ["deviceSerialNumber", lang.deviceSerialNumber],
    ];
    const data = keys.map(([key, label]) => {
      return [registerInfo?.[key] || "-", label];
    });

    return (
      <div className="mt-md">
        {registerInfo.shiftId && (
          <div>
            <Text uppercase strong color="text-gray" size="text-xs">
              {lang.shiftId}
            </Text>
            <ButtonLink newTabPath={Path.REGISTER_REPORT_ID(registerInfo.shiftId)}>
              <div className="flex items-center text-sm">
                {`#${registerInfo.shiftId}`}
                <Icon name="arrow-diagonal-right" className="ml-sm" fontSize="10px" />
              </div>
            </ButtonLink>
          </div>
        )}

        {data.map(([value, label], index) => {
          return (
            <div className="mt-md" key={uuidv4()}>
              <Text uppercase strong color="text-gray" size="text-xs">
                {label}
              </Text>
              <Text tagUid={index}>{value}</Text>
            </div>
          );
        })}
      </div>
    );
  }, [registerInfo]);

  const pillType = useMemo(() => {
    if (isFailed) {
      return PillType.Red;
    }
    if (isVoided) {
      return PillType.Default;
    }

    return getPillTypeOfTransactionType(mappedData.type);
  }, [isFailed, isVoided, mappedData.type]);

  const nfcStatusPill = useMemo(() => {
    switch (nfcStatus) {
      case TapStatus.Success:
        return { type: PillType.Green, txt: lang.success };
      case TapStatus.Failed:
        return { type: PillType.Red, txt: lang.failed };
      default:
        return null;
    }
  }, [nfcStatus]);
  const externalPill = useMemo(() => {
    switch (externalPaymentStatus) {
      case ExternalPaymentStatus.Success:
        return { type: PillType.Green, txt: lang.success };
      case ExternalPaymentStatus.Failed:
        return { type: PillType.Red, txt: lang.failed };
      case ExternalPaymentStatus.Pending:
        return { type: PillType.Yellow, txt: lang.pending };
      case ExternalPaymentStatus.Timeout:
        return { type: PillType.Orange, txt: lang.timeout };
      default:
        return null;
    }
  }, [externalPaymentStatus]);

  const renderStatus = useMemo(() => {
    if (loading) return null;
    return (
      <>
        <Text className="pb-4 text-gray">{transactionDate}</Text>
        <div className="flex items-center gap-10 mb-2">
          <div>
            <Text size="text-base" className="mb-2">
              {lang.transactionType}:
            </Text>
            <div className="flex items-center">
              <Pill size="text-xs" type={pillType}>
                {isFailed ? `${lang.failed} ` : null}
                {prettifyTransactionType(mappedData.type)}
                {isVoided && isFailed
                  ? ` ${lang.void}`
                  : isVoided && !isFailed
                  ? ` ${lang.voided}`
                  : null}
              </Pill>
              {mappedData.type === TransactionType.TOPUP && isCheckout && (
                <Tooltip title="Checkout Payment Collected">
                  <ExportOutlined className="cursor-pointer" style={{ color: "gray" }} />
                </Tooltip>
              )}
              {mappedData.type === TransactionType.RETURN && isCheckout && (
                <Tooltip title="Checkout Credits Returned">
                  <ExportOutlined className="cursor-pointer" style={{ color: "gray" }} />
                </Tooltip>
              )}
            </div>
          </div>
          {nfcStatusPill && (
            <div>
              <Text size="text-base" className="mb-2">
                {lang.nfcStatus}:
              </Text>
              <Pill size="text-xs" type={nfcStatusPill.type}>
                {nfcStatusPill.txt}
              </Pill>
            </div>
          )}
          {externalPill && (
            <div>
              <Text size="text-base" className="mb-2">
                {lang.externalPaymentStatus}:
              </Text>
              <Pill size="text-xs" type={externalPill.type}>
                {externalPill.txt}
              </Pill>
            </div>
          )}
        </div>
      </>
    );
  }, [
    externalPill,
    isCheckout,
    isFailed,
    isVoided,
    loading,
    mappedData.type,
    nfcStatusPill,
    pillType,
    transactionDate,
  ]);

  const renderGuestPanel = useMemo(() => {
    const { name, tagUid } = guest;

    const isPaidByCashOrCard = [
      PaymentMethod.Cash,
      PaymentMethod.CreditDebit,
      PaymentMethod.Credit,
      PaymentMethod.Others,
      PaymentMethod.EWallet,
    ].includes(paymentMethod);

    if (isPaidByCashOrCard && type === TransactionType.SALE) {
      return;
    }

    return (
      <Panel className="mb-md">
        <Title className="mb-md">{lang.guest}</Title>
        <div className="flex items-center">
          <div>
            <Icon name="guest" className="text-pelorous mr-md" fontSize="25px" />
          </div>
          {loading ? (
            <div className="w-full ">
              <div className="mb-xs w-full">
                <Skeleton single />
              </div>
              <Skeleton single />
            </div>
          ) : (
            <div className="max-w-max">
              <Text className="overflow-ellipsis break-all">{name}</Text>
              <Text tagUid>{tagUid}</Text>
            </div>
          )}
        </div>
        {loading ? null : guestDetails}
        {loading ? null : postpaidDetails}
      </Panel>
    );
  }, [guestDetails, postpaidDetails, guest, loading, paymentMethod, type]);

  return (
    <ModuleWrapper
      error={error}
      header={
        <HeaderB
          loading={loading}
          title={<Title xl>{transactionTitle} </Title>}
          // description={transactionDate}
          customDescription={renderStatus}
          returnPath={Path.TRANSACTION}
          returnText={lang.transactions}
        ></HeaderB>
      }
    >
      <FragmentB
        sideContent={
          <div>
            {renderGuestPanel}
            <Panel>
              <Title className="mb-md">{lang.registerInfo}</Title>
              {loading ? <Skeleton /> : registerInfoDetails}
            </Panel>
          </div>
        }
      >
        <TransactionSummary data={mappedData} loading={loading} />
        <div className="overflow-x-auto overflow-hidden">
          <Table
            loading={loading}
            data={renderHistoryData}
            columns={historyTable.columns}
            loader={2}
            minWidth="700px"
          />
        </div>
      </FragmentB>
    </ModuleWrapper>
  );
};

export default ViewTransaction;
