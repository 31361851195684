// eslint-disable-next-line no-unused-vars
import moment from "moment";
import lang from "translations";
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";
import "moment/locale/th";
import "moment/locale/pt";
import localeEn from "antd/es/locale/en_US";
import localeEs from "antd/es/locale/es_ES";
import localeZhCn from "antd/es/locale/zh_CN";
import localeZhTw from "antd/es/locale/zh_TW";
import localeTh from "antd/es/locale/th_TH";
import localePt from "antd/es/locale/pt_PT";
import { ConfigProvider } from "antd";

const LocaleProvider = ({ children }) => {
  const { _language } = lang;

  moment.locale(_language);
  const locales = {
    en: localeEn,
    es: localeEs,
    pt: localePt,
    th: localeTh,
    "zh-cn": localeZhCn,
    "zh-tw": localeZhTw,
  };

  return <ConfigProvider locale={locales[_language]}>{children}</ConfigProvider>;
};

export default LocaleProvider;
