import React, { useCallback, useContext, useMemo, useState } from "react";
import { CheckboxField, HeaderB, Title, Text, Select } from "components";
import lang from "translations";
import { Path } from "paths";
import { FragmentA } from "components/fragments";
import {
  Field,
  Input,
  Panel,
  ActionButton,
  Toast,
  Form,
  Skeleton,
  Tooltip,
  Icon,
} from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { useForm, useModal, useRouter, useApi, useMount } from "hooks";
import initialFormState, { LocationTableField } from "./location.form-state";
import { VenueContext } from "contexts";
import { mixpanel, TrackEvent } from "mixpanel";
import LocationTableForm from "./location-table-form";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getMewsOutlet } from "../../../apis/outlet.api";
import { outletMapper } from "mappers/outlet.mapper";

const LocationForm = ({
  submit,
  title,
  submitting,
  initialState = undefined,
  deleteLocationButton,
  loading,
  error,
}) => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { venueId } = venue;
  const [searchKey, setSearchKey] = useState();
  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);
  const { locationTableManagement } = useFlags();
  const unsaveChangesModal = useModal();
  const { mewsLinkOutlet } = useFlags();

  const {
    request: outletRequest,
    mappedData: mappedOutlets,
    loading: outletLoading,
  } = useApi({
    api: getMewsOutlet,
    isArray: true,
    mapper: outletMapper,
    params: {
      venueId: venueId,
    },
    handleOwnError: true,
  });

  useMount(async () => {
    await outletRequest();
  });

  const { fields, modifyField, modifyForm, submitForm, getFormValues, applyFieldErrors, dirty } =
    useForm({
      initialState: formState,
    });

  const goToList = useCallback(() => {
    history.push(Path.LOCATION);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const validateTableFields = (tables) => {
    let valid = true;
    tables.forEach((table) => {
      const { tableName } = table.value;
      if (tableName.error) {
        valid = false;
        return valid;
      }
    });
    return valid;
  };

  const handleSubmit = useCallback(async () => {
    const params = getFormValues();
    const tables = [];
    params.tables.forEach((table, index) => {
      tables.push({
        defaultName: `Table #${index + 1}`,
        customName:
          table.tableName && typeof table.tableName === "object"
            ? table.tableName.value
            : table.tableName,
        tableId:
          table.tableId && typeof table.tableId === "object" ? table.tableId.value : table.tableId,
      });
    });
    const obj = {
      name: params.name,
      onlineOrderTables: params.hasTables ? tables : [],
      outletId: params.outletId,
    };
    try {
      mixpanel.track(TrackEvent.Clickedbutton, {
        Button: lang.saveLocationForm,
        Page: lang.createLocation,
      });
      const res = await submit({ ...obj, venueId });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.LOCATION);
    } catch ({ code, handleError }) {
      const err = {
        3003: () => {
          applyFieldErrors({
            name: lang.locationNameAlreadyExist,
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [submit, getFormValues, venueId, history, applyFieldErrors]);

  const submitFormValue = () => {
    const valid = validateTableFields(fields.tables.value);
    if (valid) {
      submitForm(handleSubmit);
    }
  };

  const numberOptions = useMemo(() => {
    let numbers = [];
    for (let i = 1; i <= 100; i++) {
      numbers.push({ text: i, value: i });
    }
    return numbers;
  }, []);

  const outletOptions = useMemo(() => {
    const activeOutlets = mappedOutlets.filter((outlet) => outlet.active);
    return activeOutlets.map((outlet) => {
      return { text: `${outlet?.outletName}`, value: outlet?.outletId };
    });
  }, [mappedOutlets]);

  return (
    <ModuleWrapper
      error={error}
      header={<HeaderB title={title} returnText={lang.locations} onClick={leavePage} />}
    >
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormValue} isPrompt={dirty}>
        <FragmentA title={lang.basicInfo}>
          <Panel>
            {loading ? (
              <Skeleton />
            ) : (
              <div>
                <Field label={lang.locationName} {...fields.name}>
                  <Input required {...fields.name} onChange={modifyField} />
                </Field>
                {mewsLinkOutlet && (
                  <Field
                    customLabel={
                      <div className="mb-xs flex items-center justify-between">
                        <Text label>{lang.linkOutlet}</Text>
                        <Tooltip title={lang.linkOutletHoverTooltip}>
                          <div>
                            <Icon name="info" className="text-xs text-pelorous" />
                          </div>
                        </Tooltip>
                      </div>
                    }
                    {...fields.outletId}
                  >
                    <Select
                      {...fields.outletId}
                      placeholder={lang.selectOutlet}
                      options={outletOptions}
                      loading={outletLoading}
                      disabled={outletLoading}
                      onChange={modifyField}
                    />
                  </Field>
                )}
              </div>
            )}
          </Panel>
        </FragmentA>
        {locationTableManagement ? (
          <FragmentA title={lang.tableManagement} description={lang.manageLocationTable}>
            <Panel paddingless>
              {loading ? (
                <Skeleton className="p-md" />
              ) : (
                <div>
                  <div className="p-md">
                    <Title>{lang.tables}</Title>
                    <CheckboxField
                      required
                      {...fields.hasTables}
                      onChange={(name, { value }) => {
                        modifyForm({
                          [name]: { value },
                          noOfTables: { value: 1 },
                          tables: { value: [LocationTableField] },
                        });
                      }}
                      textSize="text-base"
                      className="pt-sm pb-md"
                    >
                      {lang.thisLocationHasTables}
                    </CheckboxField>
                  </div>
                  {fields.hasTables.value && (
                    <>
                      <Field {...fields.noOfTables} className="px-md">
                        <Select
                          {...fields.noOfTables}
                          className="w-1/2 lg:w-1/3"
                          options={numberOptions}
                          sortOptions={false}
                          searchable
                          onSearch={(value) => {
                            setSearchKey(value);
                          }}
                          customNotFoundContent="Invalid"
                          searchValue={searchKey}
                          onChange={(name, { value }) => {
                            const tableSize = fields.tables.value.length + 1;
                            const tables = fields.tables.value;
                            if (value >= tableSize) {
                              for (let i = tableSize; i <= value; i++) {
                                tables.push(LocationTableField);
                              }
                              modifyForm({
                                [name]: {
                                  value,
                                },
                                tables: {
                                  value: tables,
                                },
                              });
                            } else {
                              const alteredTable = tables.slice(0, value);
                              modifyForm({
                                [name]: {
                                  value,
                                },
                                tables: {
                                  value: alteredTable,
                                },
                              });
                            }
                          }}
                        />
                      </Field>
                      <div className="mt-md flex px-md">
                        <Text label className="w-1/2">
                          {lang.defaultTableName}
                        </Text>
                        <Text label className="w-1/2">
                          {lang.customName}
                        </Text>
                      </div>
                      <LocationTableForm tables={fields.tables.value} modifyForm={modifyForm} />
                    </>
                  )}
                </div>
              )}
            </Panel>
          </FragmentA>
        ) : null}
        <ActionButton
          showLine
          loading={submitting}
          primary={{
            onClick: () => {
              submitFormValue();
            },
          }}
          secondary={{
            onClick: () => leavePage(),
          }}
          danger={deleteLocationButton}
        />
      </Form>
    </ModuleWrapper>
  );
};

export default LocationForm;
