import React, {
  Fragment,
  useCallback,
  // useContext,
  useMemo,
  useState,
} from "react";
import { Route } from "react-router-dom";
import { Switch, Redirect } from "react-router";
import { useApi, useMount, useDownloader } from "hooks";
import { getStaff, getVenue } from "apis";
import staffState from "./staff.state";
import venueState from "./venue.state";
import {
  // ProfileContext,
  StaffContext,
  VenueContext,
} from "contexts";
import {
  DashboardPage,
  GuestPage,
  LocationPage,
  StaffPage,
  DiscountPage,
  VoucherPage,
  TransactionPage,
  DevicePage,
  ProductPage,
  SettingPage,
  ReportPage,
  InventoryPage,
  BookingPage,
  MultiPropertyPage,
} from "pages";
import { LayoutB } from "components/layouts";
import { Path } from "paths";
import { Icon, SideNav } from "components/commons";
import navItems from "./nav-items";
import { mapObject } from "services";
import { staff as staffObj, venueResponse } from "mappers";
import { StaffRole } from "enums";
import GoToAppPage from "pages/staff/go-to-app.page";
// import { environment } from "environments/environment";
import { roleAccess } from "./role-access";
import { PendingPayment } from "modules";
import { SiigoInvoicesPage } from "pages/invoices-siigo";
import { fetchConnectStatusSiigo } from "apis/siigo.api";

const VenuePage = () => {
  // const { profile } = useContext(ProfileContext);
  const [staff, setStaff] = useState(staffState);
  const [venue, setVenue] = useState(venueState);
  const [siigoConnected, setSiigoConnected] = useState(false);
  const [siigoAppId, setSiigoAppId] = useState(0);
  const {
    request: getStaffRequest,
    loading: fetchingStaff,
    error: errorStaff,
  } = useApi({
    api: getStaff,
    pageError: true,
    handleOwnError: true,
  });
  const {
    request: getVenueRequest,
    loading = true,
    error,
  } = useApi({
    api: getVenue,
    handleOwnError: true,
    pageError: true,
  });
  const [initializing, setInitializing] = useState(true);
  const venueId = localStorage.getItem("venueId");
  const { downloader } = useDownloader(venueId);

  useMount(async () => {
    fetchStaff(venueId);
    fetchVenue(venueId);
    fetchSiigoStatus(venueId);
  });

  const fetchStaff = useCallback(async () => {
    const s = await getStaffRequest({ venueId });
    setStaff({
      ...s,
      ...mapObject(s, staffObj),
    });
  }, [getStaffRequest, venueId]);

  const subscriptionRoute = useMemo(() => {
    return <PendingPayment />;
    // return <Route path={Path.PENDING_PAYMENT} component={PendingPayment} />;
  }, []);

  const isPending = useMemo(() => {
    if (venue.subscriptionStatus === "INACTIVE") {
      return true;
    }
  }, [venue]);

  const fetchVenue = useCallback(async () => {
    const v = await getVenueRequest({ venueId });

    // if (v?.subscriptionStatus === SubscriptionStatus.Inactive && v.subscriptionCustomerId) {
    // const { registrationMark } = profile;
    // const isEnterprise = registrationMark === "ENTERPRISE";
    // window.location.href = `${
    //   isEnterprise
    //     ? environment.PABBLY_SUBSCRIPTION_WITHOUT_FREE_TRIAL
    //     : environment.PABBLY_SUBSCRIPTION_WITH_FREE_TRIAL
    // }?customer_id=${v.subscriptionCustomerId}`;

    localStorage.setItem("currencyCode", v.currencyCode);
    localStorage.setItem("currencySymbol", v.currencySymbol);
    setVenue({
      ...mapObject(v, venueResponse),
      ...v,
    });

    setInitializing(false);
  }, [
    getVenueRequest,
    venueId,
    // profile
  ]);

  const staffAccess = useMemo(() => {
    if (staff.roles.includes(StaffRole.Manager)) {
      return [];
    }
    const roles = [
      StaffRole.SellOnPos,
      StaffRole.TopupCredits,
      StaffRole.ReturnCredits,
      StaffRole.StockManager,
      StaffRole.FrontOffice,
      StaffRole.BackOffice,
    ];

    const access = [];
    roles.forEach((r) => {
      if (roleAccess?.[r] && staff.roles.includes(r)) {
        roleAccess[r].forEach((ra) => {
          access.push(ra);
        });
      }
    });

    return access;
  }, [staff]);

  const hasAccessOnRoute = useCallback(
    (route) => {
      if (!staffAccess.length) {
        return true;
      }
      return staffAccess.includes(route);
    },
    [staffAccess]
  );

  const hasAccess = useMemo(() => {
    if (staff.roles.includes(StaffRole.Manager)) {
      return true;
    }

    return staffAccess.length;
  }, [staff, staffAccess]);

  const renders = useMemo(() => {
    return {
      [Path.SLASH]: {
        exact: true,
        component: DashboardPage,
      },
      [Path.GUEST]: {
        exact: false,
        component: GuestPage,
      },
      [Path.LOCATION]: {
        exact: false,
        component: LocationPage,
      },
      [Path.BOOKING]: {
        exact: false,
        component: BookingPage,
      },
      [Path.MULTI_PROPERTY]: {
        exact: false,
        component: MultiPropertyPage,
      },
      [Path.STAFF]: {
        exact: false,
        component: StaffPage,
      },
      [Path.DISCOUNT]: {
        exact: false,
        component: DiscountPage,
      },
      [Path.VOUCHER]: {
        exact: false,
        component: VoucherPage,
      },
      [Path.TRANSACTION]: {
        exact: false,
        component: TransactionPage,
      },
      [Path.DEVICE]: {
        exact: false,
        component: DevicePage,
      },
      [Path.SETTING]: {
        exact: false,
        component: SettingPage,
      },
      [Path.PRODUCT]: {
        exact: false,
        component: ProductPage,
      },
      [Path.REPORT]: {
        exact: false,
        component: ReportPage,
      },
      [Path.INVENTORY]: {
        exact: false,
        component: InventoryPage,
      },
      [Path.SIIGO_INVOICES]: {
        exact: false,
        component: SiigoInvoicesPage,
      },
    };
  }, []);

  const routeNames = useMemo(() => {
    if (staffAccess.length) {
      return staffAccess.filter((access) => {
        return access;
      });
    }
    return Object.keys(renders);
  }, [staffAccess, renders]);

  const routes = useMemo(() => {
    return routeNames.map((r, key) => {
      return <Route {...renders[r]} path={r} key={key} />;
    });
  }, [routeNames, renders]);

  const pageContent = useMemo(() => {
    if (venue.venueId > 0 && staff.profileId > 0 && !initializing) {
      if (hasAccess) {
        return (
          <LayoutB sideMenu={<SideNav items={navItems(staffAccess, siigoConnected)} />}>
            <Fragment>
              <Switch>{routes}</Switch>
            </Fragment>
          </LayoutB>
        );
      }
      return <GoToAppPage />;
    }
    return (
      <div className="w-full h-full flex items-center">
        <Icon loading className="m-auto" fontSize="30px" />
      </div>
    );
  }, [
    venue.venueId,
    staff.profileId,
    initializing,
    hasAccess,
    staffAccess,
    siigoConnected,
    routes,
  ]);

  const fetchSiigoStatus = async (venueId) => {
    const res = await fetchConnectStatusSiigo({ venueId });
    setSiigoConnected(!!res.data.isConnected);
    setSiigoAppId(res.data.integrationSiigoId);
  };

  return (
    <StaffContext.Provider
      value={{
        staff,
        fetchStaff,
        loading: fetchingStaff,
        error: errorStaff,
        staffAccess,
        hasAccessOnRoute,
      }}
    >
      <VenueContext.Provider
        value={{
          venue,
          fetchVenue,
          loading,
          downloader,
          error,
          siigoAppId,
          siigoConnected,
          setSiigoConnected,
        }}
      >
        <Switch>
          {isPending ? subscriptionRoute : pageContent}
          <Redirect to={"/inventory"} />
        </Switch>
      </VenueContext.Provider>
    </StaffContext.Provider>
  );
};

export default VenuePage;
