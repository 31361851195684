import axios from "axios";
import { environment } from "environments/environment";
import { ApiPath } from "paths";
import { Delete, Get, Post, Put } from "services";

export const fetchConnectStatusSiigo = async (params) => {
  const data = await Get(ApiPath.SIIGO_INTEGRATION, params);
  return { data };
};

export const connectToSiigo = async (data) => {
  const res = await Post(ApiPath.SIIGO_INTEGRATION, data);
  return res;
};

export const disconnectSiigo = async ({ id }) => {
  const res = await Delete(`${ApiPath.SIIGO_INTEGRATION}?integrationSiigoId=${id}`);
  return res;
};

export const getSyncFromSiigo = async (params) => {
  const data = await Get(ApiPath.SIIGO_SYNC_FROM, params);
  return { data };
};

export const saveIntegration = async (data) => {
  const res = await Put(ApiPath.SIIGO_SYNC_FROM, data);
  return res;
};

export const getListInvoice = async (data) => {
  const res = await Get(ApiPath.SIIGO_INVOICES, data);
  return res;
};

export const sendToInvoicesApi = async (data) => {
  try {
    const res = await axios.post(`${environment.API_V3}/${ApiPath.SIIGO_SENDTO_INVOICES}`, data);
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getInvoiceDetail = async ({ id }) => {
  const res = await Get(ApiPath.SIIGO_GET_INVOICE_DETAIL(id));
  return { data: res };
};

export const resyncGuest = async (data) => {
  const res = await Post(ApiPath.SIIGO_RESYNC_GUEST, data);
  return { data: res };
};

export const resyncInvoiceApi = async (data) => {
  const res = await Post(ApiPath.SIIGO_RESYNC, data);
  return { data: res };
};
