import lang from "translations";

const columns = [
  {
    key: "name",
    text: lang.deviceName,
    width: "w-auto",
    custom: true,
  },
  {
    key: "imei",
    text: lang.deviceImeiSerial,
    width: "w-auto",
    custom: true,
  },
  {
    key: "appVersion",
    text: lang.appVersion,
    width: "w-auto",
    // custom: true,
  },
  {
    key: "appDate",
    text: lang.appDate,
    width: "w-auto",
    // custom: true,
  },
  {
    key: "lastSync",
    text: lang.lastSync,
    custom: true,
    width: "w-auto",
    sort: true,
  },
];

export default columns;
