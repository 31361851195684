import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthorizedPage, AuthPage, RegisterPage } from "pages";
import { AppContext } from "contexts";
import { useState } from "react";
import { Path } from "paths";
import { AuthorizedRoute, AuthRoute } from "routes";
import { useBeforeMount, useModal } from "hooks";
import { LogoutSessionModal, SomethingWentWrongModal } from "components/modals";
import { OctopusHiding, OctopusRain } from "images";
import { Image, Loader, Text, Title } from "components/commons";
import { LayoutA } from "components/layouts";
import locale from "translations";
import RedirectPage from "pages/redirect/redirect-page";
import { environment } from "environments/environment";
import { redirectTo } from "services";
import { mixpanel } from "mixpanel";
import firebase from "firebase/compat/app";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import LocaleProvider from "provider/locale.provider";

const history = createBrowserHistory();

if (environment.id !== "dev") {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: environment.id,
    tracesSampleRate: 1.0,
  });
}

function App() {
  const [appState, setAppState] = useState({ error: false });
  const logOutSessionModal = useModal();
  const globalErrorModal = useModal();
  const [globalLoading, setGlobalLoading] = useState(false);
  const [error, setError] = useState(false);

  useBeforeMount(() => {
    console.log("initializing mixpanel...");
    mixpanel.init(environment.MIXPANEL_TOKEN);
    console.log("initializing firebase...");
    firebase.initializeApp(environment.FIREBASE);
  });

  return (
    <AppContext.Provider
      value={{
        appState,
        setAppState,
        logOutSessionModal,
        globalErrorModal,
        pageErrorImage: OctopusRain,
        setGlobalLoading,
        setGlobalError: setError,
      }}
    >
      <LocaleProvider>
        <div className="App">
          {error ? (
            <LayoutA hasUserNav={false}>
              <div className="text-center">
                <Image className="m-auto" src={OctopusHiding} />
                <Title className="mt-md" xl color="text-blue">
                  {locale.somethingWentWrong}
                </Title>
                <Text> {locale.looksLikeYouEncounteredSomething}</Text>
              </div>
            </LayoutA>
          ) : (
            <Router history={history}>
              <Switch>
                <Route path={"/redirect"} component={RedirectPage} />
                <Route
                  path={"/enterprise"}
                  component={() => {
                    let url = new URL(`${environment.POUCH_ACCOUNTS}register`);
                    let parameters = new URLSearchParams(url.search.slice(1));
                    parameters.append(
                      "redirectUrl",
                      `${environment.POUCH_VENUES}redirect?redirectUrl=/create/venue`
                    );
                    parameters.append("clientId", "VENUE_DASHBOARD");
                    parameters.append("lang", localStorage.getItem("locale"));
                    redirectTo(`${url}?${parameters.toString()}&registrationMark=ENTERPRISE`);
                  }}
                />
                <Route path={Path.REGISTER} component={RegisterPage} />
                <AuthRoute path={Path.AUTH} component={AuthPage} />
                <AuthorizedRoute path={Path.SLASH} component={AuthorizedPage} />
              </Switch>
            </Router>
          )}
        </div>
        <LogoutSessionModal {...logOutSessionModal} />
        <SomethingWentWrongModal {...globalErrorModal} />
        <div className="hidden">
          <Image src={OctopusRain} />
        </div>
        <Loader loading={globalLoading} />
      </LocaleProvider>
    </AppContext.Provider>
  );
}

export default App;
