import GuestProfileForm from "../guest-profile-form/guest-profile-form";
import { useApi, useMount } from "hooks";
import React, { useCallback, useContext } from "react";
import lang from "translations";
import { createGuestProfile } from "apis/guest-profile.api";
import { guestProfileRequest } from "mappers";
import { mixpanel, TrackEvent } from "mixpanel";
import { VenueContext } from "contexts";

const CreateGuestProfile = () => {
  const { siigoConnected } = useContext(VenueContext);

  const { request, loading: submitting } = useApi({
    api: createGuestProfile,
    handleOwnError: {
      badrequest: true,
    },
    paramsMapper: guestProfileRequest,
  });

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.createNewGuestProfile,
    });
  });

  const submitForm = useCallback(
    async (params) => {
      try {
        const res = await request(params);
        return {
          response: res,
          message: lang.populate(lang.guestProfileNameCreate, [params.name]),
        };
      } catch (e) {
        throw e;
      }
    },
    [request]
  );

  return (
    <GuestProfileForm
      title={lang.createNewGuestProfile}
      submit={submitForm}
      submitting={submitting}
      initialState={{ isAskQuestion: siigoConnected }}
      isCreate
    />
  );
};

export default CreateGuestProfile;
