import React, { useContext, useMemo, useCallback, useEffect } from "react";
import IntegrationCard from "../../integration-card/integration-card";
import { v4 as uuidv4 } from "uuid";
import { useApi, useFilter, useMount, useRouter } from "hooks";
import { getMewsIntegrationStatus } from "apis/integration.api";
import { VenueContext } from "contexts";
import { getCloudBedsAccessToken, searchApplications } from "apis";
import { prettifyApplicationCategory } from "services/pretty.service";
import { Pagination } from "components/commons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { mapIntegrationFlag } from "services/object.service";
import { Path } from "paths";
import IntegrationCardListLoadingState from "./integration-card-list-loading-state";
import { IntegrationType } from "enums";
import { searchApplicationActivations } from "apis/application.api";
import { fetchConnectStatusSiigo } from "apis/siigo.api";

const IntegrationCardList = ({
  data,
  classname,
  mappedOutlets,
  loadingOutlets,
  mappedServices,
  outletRequest,
  serviceRequest,
}) => {
  const { venue, setSiigoConnected } = useContext(VenueContext);
  const { search } = window.location;
  const flags = useFlags();
  const { purchaseplusFlag } = useFlags();
  const cloudbedsQuery = localStorage.getItem("cloudbedsOAuthCode")
    ? new URLSearchParams(localStorage.getItem("cloudbedsOAuthCode"))
    : new URLSearchParams(search);
  const cloudbedsCode = cloudbedsQuery.get("code");
  const { history } = useRouter();

  const mewsRequest = useApi({
    api: getMewsIntegrationStatus,
    params: {
      venueId: venue?.venueId,
    },
  });

  const { request: cloudBedsRequest, loading: cloudbedsConnecting } = useApi({
    api: getCloudBedsAccessToken,
    params: {
      code: cloudbedsCode,
      state: venue?.venueId,
    },
    handleOwnError: true,
  });

  const {
    request: searchApps,
    result,
    loading,
  } = useApi({
    api: searchApplications,
    params: {
      venueId: venue?.venueId,
    },
    handleOwnError: true,
  });

  const activationsRequest = useApi({
    api: searchApplicationActivations,
    params: {
      venueId: venue?.venueId,
    },
  });

  const siigoRequest = useApi({
    api: fetchConnectStatusSiigo,
    mapper: { _keys: ["integrationSiigoId", "isConnected"] },
    params: { venueId: venue?.venueId },
  });

  const { totalElements = 0 } = result?.data || {};
  const { filterState, modifyFilters } = useFilter({
    page: 1,
    pageSize: 10,
  });

  const integrationData = useMemo(() => {
    const integrations = {
      MEWS_Bookings: mewsRequest,
      MEWS_Accounting: mewsRequest,
      Cloudbeds: mewsRequest,
      Purchase_Plus: mewsRequest,
      PROPERTY_MANAGEMENT_SYSTEM: activationsRequest,
    };
    const activationResult = mewsRequest?.result?.data
      ?.flatMap((dt) => dt.integrationType)
      .join(",");

    function getAccessToken(integration) {
      const { integrationType = "" } = integration;
      if (integrationType === IntegrationType.CLOUDBEDS) {
        return null;
      }
      if (
        integrationType === IntegrationType.MEWS ||
        integrationType === IntegrationType.MEWS_ACCOUNTING
      ) {
        return mewsRequest?.result?.data?.find(
          (dt) =>
            dt.integrationType.includes(IntegrationType.MEWS_ACCOUNTING) ||
            dt.integrationType.includes(IntegrationType.MEWS)
        )?.accessToken;
      }
      if (integrationType === IntegrationType.PROPERTY_MANAGEMENT_SYSTEM) {
        return activationsRequest?.result?.data?.find((dt) => dt.applicationId === integration.id)
          ?.accessToken;
      }
      return mewsRequest?.result?.data?.find(
        (dt) =>
          dt.integrationType === integrationType || dt.integrationType.includes(integrationType)
      )?.accessToken;
    }

    function getSFTPDetails(integration) {
      const { integrationType = IntegrationType.Purchase_Plus } = integration;

      return {
        hostname: mewsRequest?.result?.data?.find(
          (dt) =>
            dt.integrationType === integrationType || dt.integrationType.includes(integrationType)
        )?.enterpriseName,
        username: mewsRequest?.result?.data?.find(
          (dt) =>
            dt.integrationType === integrationType || dt.integrationType.includes(integrationType)
        )?.enterpriseId,
        password: mewsRequest?.result?.data?.find(
          (dt) =>
            dt.integrationType === integrationType || dt.integrationType.includes(integrationType)
        )?.accessToken,
        directory: mewsRequest?.result?.data?.find(
          (dt) =>
            dt.integrationType === integrationType || dt.integrationType.includes(integrationType)
        )?.integrationId,
      };
    }

    function getLoadingState(name, request) {
      if (name.toUpperCase() === IntegrationType.CLOUDBEDS && cloudbedsCode) {
        return cloudbedsConnecting;
      }
      return request.loading;
    }

    const integrationTypes = activationResult ? activationResult.split(",") : [];
    let integrationsList = [];
    const content = result?.data.content ?? [];
    if (filterState.page > 1) {
      integrationsList = [];
    } else {
      integrationsList = [...data];
    }

    content.forEach((app) => {
      integrationsList.push({
        name: app.displayName,
        description: app.description,
        approvalStatus: app.approvalStatus,
        type: prettifyApplicationCategory(app.category),
        dashboardLink: app.callbackUrl,
        id: app.id,
        imageLink: app.logoLink,
        isIntegrated: Boolean(app.approvalStatus),
        isConnected: app.activated,
        isApp: true,
        colors: app.colors,
        integrationType: app.category,
      });
    });

    return integrationsList.map((d) => {
      const { company } = d;
      const name = d.name.replaceAll(" ", "_");
      const request = !d.isApp ? integrations?.[name] : integrations?.[d.integrationType];
      const integration = request?.result || {};
      d.connectedIntegrationsList = integrationTypes;
      d.isIntegrated =
        company === "Purchase Plus"
          ? !purchaseplusFlag
          : d.featureFlag
          ? mapIntegrationFlag(d.featureFlag, flags)
          : true;
      if (request) {
        const currentIntegration = !d.isApp
          ? integration?.data?.find(
              (dt) =>
                dt.integrationType === d.integrationType ||
                (dt.integrationType &&
                  dt.integrationType.split(",").indexOf(d.integrationType) > -1)
            )
          : integration?.data?.find((dt) => dt.applicationId === d.id);

        return {
          ...d,
          isConnected: !d.isApp ? integrationTypes.includes(d.integrationType) : currentIntegration,
          loading: getLoadingState(name, request),
          appId: d.id,
          id: currentIntegration?.id,
          accessToken: getAccessToken(d),
          SFTPDetails: getSFTPDetails(d),
          request: !d.isApp ? mewsRequest : activationsRequest,
          connectedIntegrationsList: integrationTypes,
        };
      }
      if (name === "Siigo") {
        return {
          ...d,
          isConnected: siigoRequest.mappedData.isConnected,
          appId: siigoRequest.mappedData.integrationSiigoId,
        };
      }
      return d;
    });
  }, [
    data,
    mewsRequest,
    activationsRequest,
    result?.data?.content,
    filterState?.page,
    flags,
    cloudbedsConnecting,
    cloudbedsCode,
    purchaseplusFlag,
    siigoRequest,
  ]);

  useMount(async () => {
    mewsRequest.request();
    activationsRequest.request();
    siigoRequest.request();
    fetchApps();
    if (cloudbedsCode) {
      await cloudBedsRequest({ code: cloudbedsCode });
      await mewsRequest.request();
      history.push(Path.INTEGRATION_SETTING);
    }
  });

  const fetchApps = useCallback(
    async (params = {}) => {
      const p = {
        ...filterState,
        ...params,
      };

      return await searchApps({
        page: p.page,
        pageSize: p.pageSize,
      });
    },
    [filterState, searchApps]
  );

  const changePageCb = useCallback(
    async (page) => {
      const { filterState, requestState } = modifyFilters({ page });
      await searchApps(filterState, requestState);
    },
    [modifyFilters, searchApps]
  );

  const changePageSizeCb = useCallback(
    async (pageSize) => {
      const { filterState, requestState } = modifyFilters({
        page: 1,
        pageSize,
      });
      await searchApps(filterState, requestState);
    },
    [modifyFilters, searchApps]
  );

  useEffect(() => {
    setSiigoConnected(!!siigoRequest.mappedData.isConnected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siigoRequest.mappedData]);

  return !loading ? (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
        {integrationData.map((d) => {
          return (
            <IntegrationCard
              key={uuidv4()}
              data={d}
              className={classname}
              refreshList={fetchApps}
              mappedOutlets={mappedOutlets}
              loadingOutlets={loadingOutlets}
              mappedServices={mappedServices}
              onMewsRefresh={() => {
                mewsRequest.request();
              }}
              outletRequest={outletRequest}
              serviceRequest={serviceRequest}
              siigoRequest={siigoRequest.request}
            />
          );
        })}
      </div>
      <Pagination
        total={totalElements}
        onChangePage={changePageCb}
        onChangePageSize={changePageSizeCb}
        page={filterState.page}
        pageSize={filterState.pageSize}
      />
    </>
  ) : (
    <IntegrationCardListLoadingState loading />
  );
};

export default IntegrationCardList;
