import { Button, Field, Input, Modal, Select, Toast } from "components";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./style.scss";
import { VenueContext } from "contexts";
import lang from "translations";
import { useApi, useModal, useSelectItems } from "hooks";
import { StyleType } from "enums";
import {
  fetchListStockAdj,
  postSupplyItemStockAdjustment,
  searchSupplyItem,
} from "apis/supply-item.api";
import { formatNumberToMoney, mapObjectsToSelect } from "services";
import { supplyItem } from "mappers";
import { DataTable1 } from "components/commons";

const filterKey = "productId";

const StockAdjModal = ({ locationOptions, defaultIds, onSuccess, ...props }) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const [location, setLocation] = useState();
  const [isAdding, setIsAdding] = useState(false);
  const [listId, setListId] = useState([]);
  const [list, setList] = useState([]);
  const [actionId, setActionId] = useState({ on: 0, off: 0 });
  const [needAll, setNeedAll] = useState(true);
  const reasonRef = useRef();

  const confirmModal = useModal();

  const {
    loading,
    request: fetchList,
    mappedData = [],
  } = useApi({
    api: fetchListStockAdj,
    isArray: true,
    mapper: {
      _keys: ["productId", "productName", "measurementUnit"],
      price: { key: "pricePerOrderQuantity" },
      qty: { key: "inLocationStock" },
      measurementUnit: { transform: ({ src }) => src.measurement.unit },
    },
    params: { venueId },
  });

  const {
    selected,
    setSelected,
    isAllSelected,
    setSelectAll,
    selectedCount,
    clearSelected,
    replaceSelected,
  } = useSelectItems({
    items: list,
    indeterminate: true,
    key: filterKey,
  });

  const column = useMemo(
    () => [
      {
        key: "name",
        text: lang.supplyItem,
        align: "left",
        custom: true,
      },
      {
        key: "measurement",
        text: lang.measurement,
        custom: true,
        align: "right",
      },
      {
        key: "currentQty",
        text: lang.currentQty,
        custom: true,
        align: "right",
      },
      {
        key: "addQty",
        text: lang.addQty,
        custom: true,
        align: "right",
      },
      {
        key: "newQty",
        text: lang.newQty,
        custom: true,
        align: "right",
      },
      {
        key: "inventoryValue",
        text: lang.inventoryValue,
        custom: true,
        align: "right",
      },
    ],
    []
  );

  const onSelectAddItem = useCallback(
    (v) => {
      setListId([...listId, v]);
      setIsAdding(false);
      setActionId({ on: v, off: 0 });
    },
    [listId]
  );

  const onChangeAddQty = useCallback(
    (value, i) => {
      const temp = [...list];
      const row = temp[i];
      row.addQty = Number(value?.replaceAll(",", ""));
      setList(temp);
    },
    [list]
  );

  const renderData = useMemo(() => {
    const excludeSupplyIds = list.length ? list.map((i) => i.productId) : null;
    let data = list.map(({ productId, productName, measurementUnit, qty, addQty, price }, i) => {
      const newQty = qty + (addQty || 0);
      const inventoryValue = price * newQty;
      return {
        id: productId,
        name: productName,
        measurement: measurementUnit,
        currentQty: formatNumberToMoney(qty, true, true),
        addQty: <InputNum i={i} value={addQty} onBlur={onChangeAddQty} />,
        newQty: formatNumberToMoney(newQty, true, true),
        inventoryValue: `$${
          inventoryValue <= 0 ? 0 : formatNumberToMoney(inventoryValue, true, true)
        }`,
      };
    });
    if (isAdding) {
      let addRow = {
        name: <SelectSupplyItem params={{ excludeSupplyIds }} onChange={onSelectAddItem} />,
        measurement: "-",
        currentQty: "-",
        addQty: "-",
        newQty: "-",
        inventoryValue: "-",
      };
      return [addRow, ...data];
    }

    return data;
  }, [isAdding, list, onChangeAddQty, onSelectAddItem]);

  const addItem = useCallback(() => {
    setIsAdding(true);
  }, []);

  const onConfirm = useCallback(async () => {
    try {
      const ids = Object.values(selected).map(({ productId }) => productId);
      const items = list
        .filter((i) => ids.includes(i.productId) && !!i.addQty)
        .map(({ productId, addQty }) => ({
          productId,
          addingStock: addQty,
        }));
      const reason = reasonRef.current.value();

      await postSupplyItemStockAdjustment({
        venueId,
        location,
        items,
        reason,
      });
      confirmModal.close();
      onSuccess();
      Toast({
        content: lang.adjustStockSuccessfully,
        success: true,
        icon: "check",
      }).open();
    } catch (error) {
      console.log("ERROR STOCK ADJ", error);
      Toast({
        content: lang.somethingWentWrongPlsTryAgain,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
  }, [confirmModal, list, location, onSuccess, selected, venueId]);

  const handleSetSelectItems = (items = []) => {
    const obj = {};
    items.forEach((item, index) => {
      obj[item[filterKey]] = {
        ...item,
        index,
      };
    });
    replaceSelected(obj);
  };

  useEffect(() => {
    if (props.active) {
      setListId(defaultIds);
    }
    return () => {
      setLocation(undefined);
      setNeedAll(true);
      clearSelected();
      setIsAdding(false);
      setActionId({ on: 0, off: 0 });
      setList([]);
      setListId([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active]);

  useEffect(() => {
    if (listId && listId.length && location) {
      fetchList({ productIds: listId, location, venueId });
    } else {
      setList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, listId]);

  useEffect(() => {
    let temp = [...mappedData];
    if (!mappedData.length) return;

    temp = temp
      .sort((a, b) => a.productName.localeCompare(b.productName))
      .map((i) => {
        const v = list.find((e) => e.productId === i.productId);
        return { ...v, ...i };
      });
    setList(temp);

    if (actionId.on || actionId.off) {
      const arrId = Object.keys(selected).map((i) => Number(i));
      handleSetSelectItems(
        temp.filter(({ productId }) => {
          if (productId === actionId.off) return false;
          if (productId === actionId.on || arrId.includes(productId)) return true;
          return false;
        })
      );
    }

    if (!loading && needAll) {
      handleSetSelectItems(temp);
      setNeedAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedData]);

  return (
    <Modal {...props} noCloseButton closable className={styles.modal} width={1191}>
      <div className={`${styles["container-content"]} pt-4 pb-20 px-3 flex flex-col`}>
        <p className="text-pelorous-darker text-xl font-semibold pb-4">{lang.stockAdjustment}</p>

        <Field filterLabel={lang.locations} required width={"w-60"}>
          <Select
            options={locationOptions}
            placeholder={lang.pleaseChooseLocation}
            value={location}
            onChange={(_, { value }) => {
              setLocation(value);
            }}
          />
        </Field>
        <div className="flex gap-16">
          <Field filterLabel={lang.reason}>
            <Input placeholder={lang.inputReason} ref={reasonRef} />
          </Field>
          <div className="flex items-end mb-2.5">
            <Button type={StyleType.Primary} onClick={addItem}>
              {lang.addSupplyItem}
            </Button>
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          <DataTable1
            loading={false}
            pageable={false}
            total={list?.length}
            columns={column}
            data={renderData}
            selected={selected}
            setSelected={(id) => setSelected(id)}
            isAllSelected={isAllSelected}
            setSelectAll={setSelectAll}
            className="min-h-page bg-white"
          />
        </div>
        <div className="mt-10 flex justify-end gap-4">
          <Button type={StyleType.Secondary} onClick={props.onClose}>
            {lang.discard}
          </Button>
          <Button onClick={confirmModal.show} disabled={!selectedCount || !location}>
            {lang.confirm}
          </Button>
        </div>
      </div>

      <Modal
        {...confirmModal}
        customTitle={
          <p className="text-xl text-pelorous-darker font-semibold">{`${lang.confirmStockAdjustment} ?`}</p>
        }
      >
        <div className="pb-5">
          <p className="text-base mb-7">
            {lang.populate(lang.stockAdjSupplyItemsTxtConfirm, [
              getLocationName(locationOptions, location),
            ])}
          </p>

          <div className="flex justify-end gap-2">
            <Button type={StyleType.Secondary} onClick={confirmModal.close}>
              {lang.discard}
            </Button>
            <Button type={StyleType.Danger} onClick={onConfirm} disabled={loading}>
              {lang.confirm}
            </Button>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};

export default StockAdjModal;

const getLocationName = (list = [], id) => {
  return list.find((i) => i.value === id)?.text || "";
};

const InputNum = ({ i, value, onBlur }) => {
  const [val, setVal] = useState(value);
  return (
    <div className="flex flex-col items-end">
      <div className="w-28">
        <Input
          right
          value={val && formatNumberToMoney(val, true, true)}
          onChange={(name, { value }) => {
            setVal(value?.replaceAll(",", "").replaceAll("--", ""));
          }}
          onKeyPress={(event) => {
            if (event.key === "-") {
              let value = event.target.value;
              if (value) value = "-".concat(value);
              else value = "-0";
              setVal(value?.replaceAll(",", "").replaceAll("--", ""));
              event.preventDefault();
            } else if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onBlur={(value) => onBlur(value, i)}
        />
      </div>
    </div>
  );
};

const SelectSupplyItem = ({ params, onChange, onBlur }) => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  // const [searchStr, setSearchStr] = useState("");
  const {
    request,
    loading = true,
    result = { data: [] },
  } = useApi({
    api: searchSupplyItem,
    isArray: true,
    mapper: supplyItem,
    params: {
      venueId,
      pageSize: 9999,
    },
  });
  const options = useMemo(
    () => mapObjectsToSelect(result.data, { textKey: "productName", valueKey: "productId" }),
    [result.data]
  );
  // const debounced = debounce((str) => {
  //   setSearchStr(str);
  // }, 300);

  useEffect(() => {
    request({
      ...params,
      // searchKey: searchStr,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      options={options}
      loading={loading}
      className="w-48"
      searchable
      // onSearch={debounced}
      onChange={(name, { value }) => onChange(value)}
      onBlur={onBlur}
    />
  );
};
