const siigoPaths = {
  SIIGO_INTEGRATION: "integration/siigo",
  SIIGO_SYNC_FROM: "integration/siigo/configurations",
  SIIGO_SENDTO_INVOICES: "siigo/synced-batch",
  SIIGO_INVOICES: "siigo/invoices",
  SIIGO_GET_INVOICE_DETAIL: (id) => `siigo/${id}`,
  SIIGO_RESYNC_GUEST: "",
  SIIGO_RESYNC: "synced-batch",
};

export default siigoPaths;
