export const devicePaths = {
  DEVICE: "",
  DEVICES: "devices",
  DEVICE_NOTES_GUIDE: "notes-and-guideline",
  DEVICE_EDIT: "devices/:id",
  DEVICE_ID: (id) => `devices/${id}`,
  DEVICE_DELETE: "delete/:id",
  DEVICE_DELETE_ID: (id) => `delete/${id}`,
  PRINTER: "printer",
  PRINTER_EDIT: "printer/:id",
  PRINTER_ID: (id) => `printer/${id}`,
  PRINTER_CREATE: "printer/create",
};
