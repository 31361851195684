export const locale = {
  aWholeProductIsFinished:
    "一個完整的產品是一個可以在您場所出售的成品。您可以選擇出售不同變體的產品，例如口味，顏色和尺寸。所有完整產品及其變體將各自擁有自己的供應項目。",
  accessControl: "訪問控制",
  accessControlReport: "訪問控制報告",
  accessControlRoleAppDesc: "此角色無法訪問網上場所儀表板",
  accessControlRoleDashboardDesc: "允許訪問場所應用程式的檢查訪問",
  accessPoint: "訪問點",
  accessPointCreateSuccess: "訪問點成功建立",
  accessPointExisted: "此訪問點名稱已存在。請選擇不同的名稱",
  accessPointName: "訪問點名稱",
  accessToken: "訪問令牌",
  accommodation: "住宿",
  account: "賬戶",
  accountAlreadyExists: "此電郵地址已與該場所的賬戶有關聯",
  accountCantSeemToFind: "糟糕！我們似乎找不到您的賬戶。請聯繫場所經理尋求協助。",
  accountCode: "賬戶代碼",
  accountCodeAddedName: "賬戶代碼“{0}”已添加。",
  accountCodeAlreadyExist: "賬戶代碼已存在。",
  accountEmailAdded: "賬戶“{0}”已添加。",
  accountEmailRemoved: "賬戶“{0}”已移除。",
  accountIsActive: "賬戶已啟用",
  accountMapping: "賬戶映射",
  accountMappingDescription: "設定並將您的賬戶映射到PouchVENUE系統。",
  accountName: "賬戶名稱",
  accountNotFound: "找不到賬戶",
  accountSettings: "賬戶設定",
  accountType: "賬戶類型",
  accountingSoftware: "會計軟體",
  accounts: "帳戶",
  accrNo: "Accr#:",
  actions: "行動",
  activateInPos: "在 POS 中啟動",
  activateOnOnlineMenu: "在線上選單上啟用",
  activateOnPOS: "在銷售點 (POS) 上啟動",
  activateOnPos: "在銷售點 (POS) 上啟動",
  activateVariant: "啟動變體",
  active: "活躍",
  activeDateRange: "活動日期範圍",
  actualClosingBalance: "實際期末餘額",
  actualQty: "實際數量",
  actualStockQty: "實際庫存數量",
  add: "添加",
  addANote: "新增註解",
  addAProduct: "添加產品",
  addASupplyItem: "新增供應項目",
  addAValidAttributeAndOptions: "新增有效的屬性和選項。",
  addAWholeProduct: "新增整個產品",
  addAccount: "新增帳戶",
  addAndManageAdditionalCharge: "新增和管理 POS 銷售的額外費用。",
  addAnotherAccount: "新增另一個帳戶",
  addAnotherAttribute: "新增另一個屬性",
  addAnotherProductOrSupplyItem: "增加另一個產品或供應項目",
  addAnotherSupplyItem: "添加另一個供應項目",
  addCharge: "添加收費",
  addCustomQuestion: "添加自定問題",
  addNewAccount: "添加新賬戶",
  addNewAccountCode: "添加新賬戶代碼",
  addNewAccountCodeName: "添加新賬戶代碼“{0}”",
  addNewPaymentMethod: "添加新付款方法",
  addNewSupplyItem: "添加新供應項目",
  addNewSupplyItemSelect: "添加新供應項目“{0}”",
  addNewTax: "添加新稅",
  addNote: "添加備註",
  addOption: "添加選項",
  addOptions: "添加選項",
  addPaymentMethod: "添加付款方法",
  addProduct: "添加產品",
  addProductLimit:
    "如果您想要再添加產品，請刪除一些產品以創建新產品，或請求您的場所業主增加您的產品限制！",
  addProductLimitOwner:
    "如果您想要添加更多產品，請刪除一些產品以創建新產品，或升級您的計劃來增加您的產品限制！",
  addProducts: "在此產品集中添加產品或供應項目。",
  addQty: "添加數量",
  addStaffLimit: "如果您想要添加更多員工，請嘗試刪除一些員工或請求您的場所業主增加您的員工限制！",
  addStaffLimitOwner: "要添加更多員工，請嘗試刪除一些員工或升級您的計劃以增加您的員工限制！",
  addSupplyItem: "添加供應項目",
  addThisProduct: "將此產品添加為新產品和供應項目。",
  addVariant: "添加變體",
  addedStock: "已添加存貨",
  additionalChargeReport: "額外收費報告",
  additionalCharges: "額外收費",
  adjustQuantity: "調整數量",
  adjustStocksuccessfully: "成功調整存貨",
  adjustTheSupplyItemsVariant: "調整此特定變體的供應項目和數量。",
  advancedReporting: "高級報告",
  all: "全部",
  allAccessPoints: "全部存取點",
  allAccountTypes: "所有賬戶類型",
  allCategories: "所有類別",
  allDevices: "所有設備",
  allDevicesUsed: "所有已使用的設備",
  allDiscounts: "所有折扣",
  allGuestProfiles: "所有客人檔案",
  allLocation: "全部地址",
  allLocations: "所有地點",
  allMeasurements: "所有測量數據",
  allPaymentMethods: "所有付款方式",
  allProducts: "所有產品",
  allReportsReadyForDownload: "所有報告已準備好下載",
  allResponse: "所有回應",
  allRightsReserved: "保留所有權利",
  allRoles: "所有角色",
  allSafeToDeleteLocation: "全部安全！此地點/場地沒有任何產品。",
  allSelectedAvailablePos: "所有選定的產品將可在銷售終端 （POS）上使用。",
  allSelectedNotPos: "所有選定的產品將不會顯示在您的銷售終端 （POS）上。",
  allShiftsClosed: "所有班次已結束",
  allSources: "所有來源",
  allStaff: "所有員工",
  allStatus: "所有狀態",
  allStockLevels: "所有庫存水平",
  allTransactionTypes: "所有交易類型",
  allTransactions: "所有交易",
  allVenues: "所有場地",
  allVoucherTypes: "所有優惠券類型",
  allVouchers: "所有優惠券",
  allowAccess: "允許訪問",
  allowAccessAndConnect: "允許訪問並連接",
  allowAndSetTip: "允許小費和設定百分比",
  allowBargainDesc: "通過勾選此項，員工可以在銷售終端 （POS）中更改商品價格",
  allowBargainOnThisProduct: "允許該產品進行議價",
  allowCustomTip: "允許客人添加自定義小費",
  allowGuestProfile: "允許客戶檔案",
  allowGuestWBKeeping: "允許客人在退房時保留他們的標籤。",
  allowOrRestrictGuestWBKeeping: "允許或限制客人在退房時保留他們的標籤。",
  allowPostpaidAndLimit: "允許後付積分並設定限額。",
  allowPostpaidInGuestProfile:
    "允許此客戶在不使用積分的情況下進行購物。當他們從場地退房時，再收取費用。",
  allowThisGuestProfileLimit:
    "允許此客戶在不使用積分的情況下進行購物。當他們從場地退房時，再收取費用。",
  allowedAccessPoint: "允許的訪問點",
  allowedGuestProfile: "允許的客戶檔案",
  allowedPaymentMethod: "允許的付款方式",
  almostThere: "快到了！",
  alreadyExists: "已存在。",
  amount: "金額",
  anHourAgo: "一小時前",
  and: "和",
  andManyMore: "還有更多！",
  andWouldBe: "並將是",
  anyMoreQuestions: "還有更多問題嗎？{0}",
  appDate: "應用程式安裝/更新日期",
  appReinstall: "應用程式重新安裝",
  appVersion: "應用程式版本",
  applyFilter: "應用篩選器",
  areYouSureYouWantToDeleteCharge: "您確定要刪除收費項{0}嗎？",
  areYouSureYouWantToDeleteLocation: "您確定要刪除{0}嗎？此位置/場地目前的庫存價值為{1}。",
  areYouSureYouWantToDeleteProduct: "您確定要刪除{0}嗎？此供應項目目前有{1} {2}的庫存。",
  areYouSureYouWantToDeleteProduct2:
    "您確定要刪除{0}嗎？它將不在您的場地中可用，但是先前的交易和報告不會受影響。",
  areYouSureYouWantToRemoveVariant: "您確定要刪除“{0}”變體嗎？其供應項目將不會被刪除。",
  arrivalDate: "到達日期",
  arrivals: "到達",
  askDeleteGuestProfile: "刪除客戶檔案？",
  askDeleteStaffProfile: "刪除員工檔案？",
  askGuestInfoCheckIn: "在辦理入住手續時詢問客人的信息。",
  askVenueOwnerToUpgradePlan: "請求場地業主升級您的計劃並開始使用此功能。",
  assemble: "組裝",
  assembleAProduct: "組裝產品",
  asset: "資產",
  assignAProfile: "分配檔案檔案",
  assignFreeCreditsStaff: "將免費積分配給此組中的員工",
  assignVoucherStaff: "將優惠券分配給此組中的員工",
  assignVoucherToGuest: "在注冊時，將優惠券分配給此組中的客人。",
  assignedRooms: "指定的房間/床位",
  assignedSpace: "指定空間",
  atLeast1GuestProfile: "至少需要1個客戶檔案",
  attribute: "屬性",
  attributeAlreadyExists: "該產品中已存在相同名稱的屬性",
  automaticallyAddThisChargeToTheCart: "自動將此費用添加到購物車中？",
  automaticallyAddThisChargeToTheCartDesc:
    "一旦第一項商品被添加到購物車中，此費用將自動添加。員工可以移除此費用。",
  automaticallyGenerateSku: "自動生成SKU (庫存單位）",
  availability: "可用性",
  availabilityOnPos: "銷售終端 （POS）上的可用性",
  available: "可用",
  availableAnytime: "隨時可用",
  availableEveryday: "每天都可用",
  availableOnPos: "在銷售終端 （POS）上可用",
  averageAmountPerItem: "平均每項金額",
  averageDurationOfStay: "平均停留/入住時間",
  averageOrderValue: "平均訂單價值",
  awaiting: "等待中",
  awaitingInviteResponse: "等待邀請回應。",
  back: "返回",
  backOffice: "後勤辦公室",
  backOfficeRoleAppDesc: "此角色與應用程式無關，將無法在設備上登錄。",
  backOfficeRoleDashboardDesc:
    "允許訪問場地儀表板的見解、銷售、庫存、地點、員工、客戶檔案、優惠券、折扣、打印機檔案和場地設置",
  balance: "餘額",
  balanceMovementsDescription: "查看客人餘額來自哪個場所",
  balanceMovementsIn: "進入結算",
  balanceMovementsInPage: "進入結算",
  balanceMovementsOut: "離開結算",
  balanceMovementsOutPage: "離開結算",
  banATag: "禁止標籤",
  banDetails: "禁止詳細信息",
  banList: "禁止清單",
  banTag: "禁止標籤",
  banTagListSettings: "禁止標籤清單設置",
  banTags: "禁用標籤",
  bannedBy: "被禁止者",
  bannedTags: "已被禁止的標籤",
  banningThisTagWillOnlyTakeEffect:
    "禁用該標籤將在您的設備下次同步並更新時生效（通常在連接到互聯網時每5分鐘同步一次）。為了立即反映這些更改，請在禁止此標籤後同步並更新您的設備。",
  bargainAllowed: "許可議價",
  bargainRequiresApproval: "議價需要審批",
  bargainRequiresApprovalOnThisProduct: "當更改此項目在銷售終端（POS）上的價格時需要經理授權",
  basicInfo: "基本信息",
  belowParLevel: "下限水平",
  billAmount: "賬單金額",
  birthdate: "出生日期",
  birthday: "生日",
  bookedBy: "由...預訂",
  bookingAccomodation: "預訂住宿",
  bookingAmount: "預訂金額",
  bookingDescription: "在您的場所跟踪每個預定。",
  bookingDetails: "預訂詳情",
  bookingGuests: "預訂客人",
  bookingId: "預訂 ID",
  bookingList: "預訂清單",
  bookingNo: "預訂編號",
  bookingPrice: "預訂價格",
  bookingStatus: "預約狀態",
  bookingSummary: "預訂摘要",
  bookings: "預訂",
  bottle: "瓶子",
  browseProducts: "瀏覽產品",
  browseVouchers: "瀏覽優惠券",
  businessAddress: "商業地址",
  businessContactNumber: "商業聯繫號碼",
  businessDay: "營業日",
  businessDetails: "商業詳情",
  businessName: "商業名稱",
  byAssemblingAProduct:
    "通過組裝產品，您可以創建具有多個供應項目的產品，也可以拆分具有變體的單個供應項目。",
  byCreatingPrinterProfiles:
    "通過創建打印機配置文件，您可以設置哪些產品在不同的場所工作站（例如廚房、酒吧等）的訂單明細上打印。",
  cacheCleared: "緩存已清除",
  cancel: "取消",
  cancelBanTag: "取消禁止標籤",
  cancelCloseShift: "取消關閉/結束班次",
  cancelled: "已取消",
  cannotBeDeleted: "無法刪除。",
  cannotDeletePartOfComposite: "{0} 無法刪除，因為它是 {1} 組合中的一部分 {2} 。",
  cantFindDesc: "找不到您要找的東西？請嘗試其他搜索或篩選方式。",
  capacity: "容量",
  card: "卡片",
  cardApprovalNumber: "卡片批准號碼",
  cardNumber: "卡號",
  cash: "現金",
  cashSales: "現金銷售",
  cashlessTransactionsWithDoor: "無現金交易與門鎖啟動！",
  categories: "類別",
  categoriesDeleted: "已刪除 {0} 個類別。",
  categorizeAndManage: "將每筆交易分類並管理。",
  category: "類別",
  categoryCreated: '類別 ""{0}"" 已創建。',
  categoryDeleted: '類別 ""{0}"" 已刪除。',
  categoryList: "類別清單",
  categoryName: "類別名稱",
  categoryNameAlreadyExist: "類別名稱已存在。",
  categoryNameReserved: "類別名稱已預留。請使用其他名稱。",
  categoryReport: "類別報告",
  categoryUpdated: '類別 ""{0}"" 已更新。',
  change: "改變/更改",
  changePassword: "更改密碼",
  changePhoto: "更改照片",
  changesSaved: "更改已保存。",
  changesSavedSuccessfully: "更改已成功保存",
  changingCurrencyWontAffect: "更改貨幣不會影響產品價格，僅會影響他們的貨幣符號。",
  charge: "費用",
  chargeAmount: "費用金額",
  chargeCalculation: "費用計算",
  chargeDeleted: "刪除收費 {0}",
  chargeItem: "收費項目",
  chargeName: "收費名稱",
  chargeTaxesOnThisProduct: "在此產品上徵收稅款",
  chartOfAccounts: "會計科目表",
  chartOfAccountsDescription: "更新並管理您的會計科目表。",
  checkInDate: "報到/注冊/登記日期",
  checkInDetails: "報到/注冊/登記詳情",
  checkInQuestions: "報到/注冊/登記問題",
  checkInSettings: "報到/注冊/登記設置",
  checkIns: "報到/注冊/登記",
  checkOutDetails: "退房詳情",
  checkOutTheGuide: "查看連接整合的指南。",
  checkOuts: "登出/退房名單",
  checkStocks: "檢查存貨",
  checkYourEmail: "查看您的電子郵件",
  checkYourEmailAt: "請查看您的電子郵箱 {0} 以完成設置您的賬戶。",
  checkYourEmailOrAdmin: "查看您的電子郵箱或聯繫您的管理員。",
  checkbox: "復選框",
  checkedIn: "已報到/入住",
  checkedInBy: "登記/注冊者",
  checkedInDate: "報到/登記日期",
  checkedOut: "已退房",
  checkedOutBy: "退房者",
  checkedOutDate: "退房日期",
  checkedOutOn: "已退房於",
  checkinAmount: "報到金額",
  checkinId: "報到 ID",
  checkingYourInternet: "檢查您的互聯網或數據連接。",
  checkout: "退房",
  checkoutOurEbook: "看看我們的電子書，了解如何優化您的場所！",
  chooseAPassword: "使用您在其他地方未使用的高強度密碼。",
  chooseAReason: "選擇原因",
  chooseASimpleOrMultipleItem: "選擇單個或多個供應項目來創建產品。",
  chooseAVenue: "選擇一個場所",
  chooseAtleast1Location: "至少選擇 1 個位置",
  chooseCategoryColor: "選擇類別顏色:",
  chooseColumns: "選擇您想在此報告中看到的列。",
  chooseInformation: "選擇要包含在銷售明細中的信息。",
  chooseLocationForThisStaff: "為此職員指定地點/場地",
  choosePassword: "選擇密碼",
  choosePaymentMethod: "選擇付款方式",
  chooseProductsCanBeDiscounted: "選擇您庫存中可以打折的產品。",
  chooseRole: "為此職員選擇角色",
  chooseTheLocationWhereThisProduct: "選擇銷售此產品和從中扣除供應品的地點。",
  chooseVouchers: "選擇優惠券",
  cityStateCountry: "城市、州、郵政編碼",
  clear: "清除",
  clearAllFilters: "清除所有篩選器",
  clearDownloads: "清除下載",
  clearedTags: "已清除的標籤",
  clearedUnusedAndExpiredCredits: "清除、未使用和過期的積分",
  clearedUnusedAndExpiredCreditsMessage: "對已充值但已清除、不可退款或過期的積分。",
  clearedUnusedAndExpiredCreditsPlaceholder: "其他收入（到期）",
  clearedUnusedExpiredCredit: "其他收入（到期）",
  clickHereToRetry: "點擊此處重試。",
  close: "關閉",
  closePreview: "關閉預覽",
  closeReceiptPreview: "關閉收據預覽",
  closeShift: "關閉/結束班次",
  closeShiftWarning:
    "在強制關閉/結束班次之前，請確保所有交易已同步，並且僅在無法在應用程序中關閉/結束班次時使用此功能。",
  cloudbeds: "Cloudbeds",
  code: "代碼",
  coffeeBeans: "咖啡豆",
  columns: "列",
  combineYourProducts: "組合您的產品為一組出售。",
  comingSoon: "即將推出",
  communityGuidelines: "社區指南/準則",
  companyDetails: "公司詳情",
  companyRegistrationNo: "公司註冊號碼",
  completeStocktake: "完成盤點",
  completeStocktakeConfirmation: "完成{0}？",
  completeSubscription: "完成訂閱",
  completeYourSubscription: "完成您的訂閱以訪問您的場地，",
  completed: "完成",
  completedBy: "完成者",
  completedOn: "完成日期",
  composite: "綜合",
  composition: "組成",
  compositionGuide: "組成指南",
  confirm: "確認",
  confirmImport: "確認導入",
  confirmImportDesc: "您確定要導入供應品嗎？",
  confirmPassword: "確認密碼",
  confirmStockAdjustment: "確認庫存調整",
  confirmTransfer: "確認轉移",
  confirmationPending: "等待確認中",
  confirmed: "已確認",
  connect: "連接",
  connectAndInstall: "連接並安裝",
  connectPurchasePlus: "將Purchase Plus連接到您的PouchNATION賬戶。",
  connected: "已連接",
  contactNumber: "聯絡電話號碼",
  contactUs: "聯絡我們",
  contactUs2: "聯絡我們",
  continue: "繼續",
  continueAs: "作為{0}繼續",
  continueBanningThisTag: "繼續禁止此標籤嗎？",
  continuingOnThisPageWillLogout: "在這頁面繼續操作將導致當前會話登出。",
  copied: "已複製！",
  copyLink: "複製鏈接",
  copyrightYear: "版權 © 2024",
  costOfGoodsSold: "銷貨成本(COGS)",
  costOfGoodsSoldMessage: "一經銷售，供應品成本的默認賬戶",
  costOfGoodsSoldPlaceholder: "銷貨成本(COGS) ",
  country: "國家",
  createALocation: "創建地點",
  createAccessPoint: "創建存取點",
  createAccount: "創建賬戶",
  createAccountCode: "創建賬戶代碼",
  createAndManageProducts: "創建並管理可在您的地點出售的產品",
  createAndManageRawMaterials: "為每個地點創建和管理原材料及其庫存。",
  createAndManagerDiscounts: "創建和管理您的折扣與折扣產品",
  createAndManagerVouchers: "創建和管理將用於兌換產品的優惠券",
  createCategoriesToLink: "創建類別以鏈接和分類您的產品",
  createCategory: "創建類別",
  createDiscountPage: "創建折扣頁面",
  createLocation: "創建地點",
  createLocationToStoreYourProducts: "創建一個地點來存儲您的產品。",
  createNewCategory: "創建新類別",
  createNewCharge: "創建新收費",
  createNewDiscount: "創建新折扣",
  createNewGuestProfile: "創建新訪客資料檔",
  createNewLocation: "創建新地點",
  createNewProduct: "創建新產品",
  createNewProductSet: "創建新產品組",
  createNewProfile: "創建新檔案",
  createNewStaffProfile: "創建新員工檔案",
  createNewStocktake: "創建新的盤點",
  createNewSupplyItem: "創建新的供應項目",
  createNewVoucher: "創建新的優惠券",
  createNote: "創建備註",
  createPrinterProfile: "創建打印機配置文件",
  createProduct: "創建產品",
  createProductSet: "創建產品套裝",
  createStaffProfile: "創建員工配置檔",
  createStaffWhoCan: "創建可以補充信用、註冊訪客並在您的場所銷售的員工。",
  createTheProduct: "創建在您的場所銷售的產品。",
  createVenue: "創建場所",
  createVenuePage: "創建場所頁面",
  createVoucherPage: "創建優惠券頁面",
  createVouchersForGuest: "為您的訪客/客人和員工創建優惠券！",
  createYourOwnIntegration: "使用 PouchCONNECT 創建您自己的集成",
  createYourVenue: "創建您的場所",
  createdSupplyItem: "已創建的供應項目",
  credentialsAreInvalid: "糟糕，一些憑據無效。請輸入正確數據並重試。",
  credit: "信用/積分",
  creditAutomaticallyExpires: "結帳後積分自動過期",
  creditBalance: "積分餘額",
  creditDebit: "信用/借記",
  creditDebitSales: "信用/借記銷售",
  creditLimits: "信用/積分限制",
  creditSettings: "信用/積分設置",
  credits: "信用/積分",
  creditsDescription: "在此組中為訪客指定免費積分並設置他們的後付款積分。",
  creditsExpiration: "積分過期",
  creditsExpirationDescription: "設定在訪客結賬時積分過期。",
  creditsExpirationOnCheckout: "結賬時積分過期",
  creditsKept: "保留積分",
  creditsKeptByGuests: "訪客/客人保留的積分",
  creditsLiabilityAccount: "積分負債賬戶",
  creditsLiabilityAccountMessage: "在銷售前，為標籤中補充的所有積分的保留賬戶。",
  creditsLiabilityAccountPlaceholder: "負債/信用",
  creditsNeverExpires: "結賬後積分永不過期",
  creditsReceived: "收到的積分",
  creditsReceivedFromVenues: "來自其他場所的積分",
  creditsWouldNeverExpire: "訪客當前在住時積分永不過期。",
  currency: "貨幣",
  currencyAndFormat: "貨幣和格式",
  currentGuestProfiles: "當前訪客檔案",
  currentPassword: "當前密碼",
  currentPasswordAndNewPasswordMatched: "當前和新密碼匹配",
  currentQty: "當前數量",
  custom: "自定義",
  customDateTime: "自定義日期和時間範圍",
  customFields: "自定義字段",
  customLabel: "自定標籤",
  customName: "自定名稱",
  customNameIsAlreadyExisting: "自定名稱已存在。",
  customQuestion: "自定義問題",
  customQuestions: "自定義問題",
  customTax: "自定義稅",
  customerBehaviourOverTime: "客戶行為隨時間變化",
  customizeReceipt: "自定義收據的每個部分。",
  customizeSectionReceipt: "自定義收據的各個部分。",
  daily: "每日",
  dashboard: "儀表板",
  dataError: "數據錯誤",
  dataIncorrectly: "數據不正確",
  date: "日期",
  dateAndTime: "日期和時間",
  dateAndTimeOfTransaction: "交易日期和時間",
  dateBanned: "禁止日期",
  dateCreated: "創建日期",
  dateIssuedWithColonSymbol: "發行日期：",
  dateModified: "修改日期",
  dateOfCheckin: "入住日期",
  dateOfCheckout: "退房日期",
  dateOfRegistration: "註冊日期",
  dateRange: "日期範圍",
  day: "天",
  dayEnd: "結束一天",
  dayStart: "開始一天",
  daySummary: "每日摘要",
  days: "天",
  daysAfterCheckout: "登出/退房後的幾天",
  debitCredit: "借記/信用卡",
  default: "默認",
  defaultAccountForTaxes: "每個產品的稅金默認賬戶。",
  defaultFields: "默認字段",
  defaultMode: "默認模式",
  defaultModeSettingDesc: "默認情況下，手機應用程序將設置為該模式：",
  defaultQuestions: "默認問題",
  defaultTableName: "默認列表名稱",
  defaultTax: "默認稅",
  defectiveTag: "有缺陷的標籤",
  defectiveTags: "有缺陷的標籤",
  delete: "刪除",
  deleteAProduct: "刪除產品",
  deleteAProduct2: "刪除產品？",
  deleteAProductSet: "刪除產品套裝",
  deleteAccessPoint: "刪除接入點",
  deleteAccessPointDesc: "您確定要刪除此接入點嗎？",
  deleteCategories: "刪除 {0} 類別",
  deleteCategory: "刪除類別",
  deleteCharge: "刪除收費",
  deleteChargeFailed: "無法刪除收費，請稍後重試",
  deleteCurrentlyBeingUsed:
    "{0} 目前在某些產品套裝中使用。如果您想要繼續操作，請從以下產品套裝中移除該產品。",
  deleteDiscount: "刪除折扣",
  deleteGuestProfile: "刪除客戶資料/檔案",
  deleteLocation: "刪除地點",
  deleteLocationNote: "註：您先前在此地點的交易記錄將被保留。",
  deleteName: "刪除 “{0}“？",
  deleteNote: "刪除備註",
  deletePaymentMethod: "刪除付款方式",
  deletePrinterProfile: "刪除印表機配置文件",
  deleteProduct: "刪除 {0}？",
  deleteProductSet: "刪除產品組？",
  deleteProducts: "刪除 {0} 產品？",
  deleteProducts2: "刪除 {0} 產品",
  deleteQuestion: "刪除問題",
  deleteSelectedCategories: "刪除已選類別",
  deleteSelectedCategory: "刪除已選類別",
  deleteSelectedProduct: "刪除已選產品",
  deleteSelectedProducts: "刪除已選產品",
  deleteStaff: "刪除員工",
  deleteStaffModalDesc: "您即將刪除員工。這些員工將失去場地/場館的訪問權。此操作無法撤銷。",
  deleteStaffModalNote: "註：您先前與這些員工的交易將被保留",
  deleteStaffProfile: "刪除員工資料",
  deleteStaffSuccessfully: "成功刪除員工",
  deleteSupplyItem: "刪除存貨項目/物品",
  deleteTax: "刪除稅金",
  deleteThis: "刪除此 {0}？",
  deleteThisMultiple: "刪除 {0} {1}？",
  deleteThisQuestion: "刪除此問題？",
  deleteVoucher: "刪除優惠券",
  deleted: "已刪除",
  deletedPaymentMethod: "已刪除付款方式 “{0}“。",
  deletedSuccessfully: "成功刪除",
  deletedTax: "已刪除稅金 “{0}“。",
  deletingLoading: "正在刪除...",
  deletingName: "正在刪除 {0}",
  deletingThisLocation: "刪除此地點/場地也將刪除所有供應項目的庫存餘額。此操作無法撤銷。",
  denominations: "面額",
  departure: "出發",
  departureDate: "出發日期",
  describeThisProduct: "描述此產品...",
  describeThisProductSet: "描述此產品組",
  description: "描述",
  destinationLocation: "目的地地點",
  destinationLocationNewQty: "目的地新數量",
  device: "設備",
  deviceId: "設備ID",
  deviceImei: "設備國際移動設備識別碼 （IMEI）",
  deviceImeiSerial: "設備國際移動設備識別碼（IMEI） / 序號",
  deviceInfo: "設備資訊",
  deviceLimitExceeded:
    "您正在使用 {0} 台設備，超出您計劃的 {1} 台限制。請聯繫場所業主升級您的計劃。",
  deviceLimitExceededManager: "您正在使用 {0} 台設備，超出您計劃的 {1} 台限制。請升級您的計劃。",
  deviceList: "設備清單",
  deviceMac: "設備MAC位址",
  deviceMacAddress: "設備MAC位址",
  deviceName: "設備名稱",
  deviceNameAlreadyExists: "此設備名稱已存在",
  deviceNameUpdated: "設備 “{0}”已更新。",
  deviceSerialNumber: "設備序號",
  deviceUid: "設備UID",
  devices: "設備",
  difference: "差異",
  directory: "目錄",
  disableSellingOnPos: "在銷售終端 （POS）上停止銷售",
  disabled: "已停用",
  discard: "放棄",
  discardChanges: "放棄更改",
  discardWristbandKeepingSettings: "放棄手環保持設定",
  disclaimerCategoryReport: "免責聲明：可能會有被分配有多個類別的產品，導致每行項目的重複計算。",
  disconnect: "斷開連線",
  discount: "折扣",
  discountApplied: "已應用折扣",
  discountCanBeApplied: "此折扣可應用於您的場地。",
  discountCreated: '創建折扣 ""{0}""。',
  discountDeleted: '刪除折扣 ""{0}""。',
  discountName: "折扣名稱",
  discountNameAlreadyExist: "此折扣名稱已存在。",
  discountRateOrValue: "折扣率或值",
  discountReport: "折扣報告",
  discountType: "折扣類型",
  discountUpdated: '已更新折扣 ""{0}""。',
  discountableProducts: "可折扣產品",
  discounts: "折扣",
  discountsPage: "折扣頁面",
  discrepancy: "差異",
  displayCategoryInPos: "在銷售終端 （POS）設備中顯示此類別。",
  displayedIn: "顯示在",
  distribution: "分配",
  done: "完成",
  dontSellOnPos: "在銷售終端 （POS）上不出售",
  dontWorry: "別擔心！",
  download: "下載",
  downloadAndSync: "下載 PouchVENUE應用程式並同步您的第一筆交易",
  downloadTemplate: "下載模板",
  downloading: "下載中...",
  dropdown: "下拉選項",
  duplicateOption: "複製選項",
  eWallet: "電子錢包",
  easierGuestReg: "更順暢的客戶註冊和登入",
  edit: "編輯",
  editAccessPoint: "編輯訪問點",
  editAccessToken: "編輯訪問令牌/標記",
  editAccount: "編輯賬戶",
  editCategory: "編輯類別",
  editCharge: "編輯費用",
  editDetails: "編輯詳細資料",
  editDevice: "編輯設備",
  editDiscountsPage: "編輯折扣頁面",
  editGuestProfile: "編輯客戶資料",
  editLocation: "編輯位置",
  editLocations: "編輯地點",
  editNewCategory: "編輯新分類",
  editNote: "編輯備註",
  editPaymentMethod: "修改付款方式",
  editPaymentMethods: "編輯付款方式",
  editPrinterProfile: "編輯印表機配置文件",
  editProduct: "編輯產品",
  editProductSet: "編輯產品組合",
  editQuestions: "修改問題",
  editSFTPDetails: "編輯SFTP詳細信息",
  editStaff: "編輯員工",
  editStaffPage: "編輯員工頁面",
  editStaffProfilePage: "編輯員工資料",
  editSupplyItem: "編輯供應品項",
  editTax: "編輯稅",
  editVariants: "編輯變體",
  editVoucherPage: "編輯優惠券頁面",
  editYourAccountPreferences: "編輯您的帳號偏好設置、電子郵箱地址和密碼。",
  egAppliesToGuests: "例如，適用於60歲及以上的客人",
  egSeniorDiscount: "例如 老年優惠",
  egSizeFlavor: "例如 大小/尺寸、口味",
  eightCharactersMinimum: "至少8個字符",
  eitherYouHavePoorConnection: "您的網絡連接可能不佳、您的訪問被拒絕，或我們這邊出現問題。",
  email: "電子郵件/電子郵箱",
  emailAddress: "電子郵箱地址",
  emailAlreadyInVenue: "此電子郵箱地址已與該地點的賬戶關聯。",
  emailDoesntHaveRecord: "您輸入的電子郵箱與我們的記錄不匹配，請仔細檢查並重試。",
  enableDifferentiateEntryExit: "啟用不同入口/出口",
  enableSellingOnPos: "在銷售終端 （POS）上啟用銷售",
  enableTip: "啟用小費？",
  end: "結束",
  endDay: "結束一天/日終",
  endOfDay: "結束當天/日終",
  endOfDayChecklist: "日終檢查清單",
  endOfDayCompleted: "日終已完成",
  endOfDayFailed: "無法完成日終",
  endOfDayReports: "日終報告",
  endOfDayReportsListPage: "日終報告清單頁面",
  endOfDayRunning: "日終正在運行。請刷新頁面以更新當天結束時間的狀態。",
  endedBy: "結束者",
  enterEachOption: "請輸入每個選項，以逗號分隔",
  enterEmailToLogin: "輸入您的電子郵箱地址以註冊或登錄。",
  enterYourEmailAddress: "輸入您的電子郵箱地址",
  enterYourPassword: "輸入您的密碼",
  enteredPasswordIncorrect: "輸入的密碼不正確。",
  equity: "權益",
  errorLoadingColors: "加載顏色時出錯。請稍後重試。",
  eventMode: "活動模式",
  example1: "示例1",
  example1AssembleProduct:
    "{0}：咖啡拿鐵需要 {1}、 {2} 和 {3}。購買咖啡拿鐵時，庫存將從咖啡豆、牛奶和糖中扣除。",
  example2: "示例2",
  example2AssembleProduct:
    "{0}：葡萄酒可以作為{1} 或 {2} 出售。選擇葡萄酒作為供應品項，然後創建變體“瓶”和“杯”，並根據每個變體調整產品數量，這樣就可以從同一供應品項中相應扣除。",
  expStockQty: "預期庫存數量",
  expectedEndingBalance: "預期結餘",
  expectedQty: "預期數量",
  expense: "費用",
  expired: "過期",
  export: "導出",
  exportCheckinDetails: "導出登記資料",
  exportGuestReport: "導出客人報告",
  exportList: "導出清單",
  exportRegisterReport: "導出班次報告",
  exportTaxReport: "導出稅收報告",
  exportTransactions: "導出交易",
  exportXlsx: "為XLSX導出",
  extendThePower: "通過最佳插件，擴展您PouchVENUE的功能。",
  externalPaymentStatus: "外部支付狀態",
  extremelyHighDenominations: "非常高的面額",
  failed: "失敗",
  failedToLoad: "加載失敗。",
  featureLocked: "功能被鎖定",
  feedback: "反饋",
  feelFreeToContactPouchnation: "請隨時聯繫我們的PouchNATION。",
  female: "女性",
  fileImported: "文件已導入",
  filters: "篩選器",
  findSimilar: "查找相似之處",
  firstName: "名字",
  fixedAmount: "固定金額",
  fixedAmountRange: "固定金額應在1-9999999之間",
  footerSection: "頁腳部分",
  forApproval: "待批准",
  forProperReportingAndTo: "為了正確報告和保持您的儀表板最新，請每天運行日終以避免數據丟失。",
  forProperReportingWithYourMews:
    "為了與您的MEWS賬戶準確報告，請確保您的會計可編輯歷史窗口設為7天。每天運行日終以避免數據丟失。",
  forceBanned: "強制退房和禁止詳細信息",
  forceCheckOutBy: "強制退房者",
  forceCheckout: "強制退房",
  forceCheckoutDetails: "強制退房詳細信息",
  forceCheckoutName: "強制退房 {0}？",
  forcedCheckedOutBy: "由。。。強制退房",
  formatting: "格式設定",
  freeCredits: "免費積分",
  freeCreditsAreCreditsThatAreFreeOfCharge: "免費積分是免費的積分。這些積分是",
  freeCreditsHoverTooltip: "免費的積分是無法退款的，並會在付款時優先使用。",
  freeCreditsIssued: "發放免費積分",
  freeCreditsKept: "保留的免費積分",
  freeCreditsLiabilityAccount: "免費積分負債賬戶",
  freeCreditsLiabilityAccountMessage: "一旦使用，對客人發放免費積分的成本。",
  freeCreditsLiabilityAccountPlaceholder: "負債/免費積分",
  freeCreditsReceived: "已領取的免費積分",
  freeCreditsRemoved: "已移除免的費積分",
  freeCreditsReport: "免費積分報告",
  freeTrial: "30天免費試用！",
  frontOffice: "前台",
  frontOfficeRoleAppDesc: "此角色與應用程式無關，將登入設備。",
  frontOfficeRoleDashboardDesc:
    "允許訪問場地儀表板的見解、預訂、客人、交易、報告、設備和禁止標籤的功能",
  future: "未來",
  gender: "性別",
  general: "一般",
  generalSettings: "一般設定",
  generateAndDownload: "生成和下載",
  generateQRCode: "生成QR碼",
  generateQRDescription: "生成並列印QR碼，供客戶掃描並從該位置的在線菜單訂購。",
  generatingReport: "生成報告中...",
  getNotifications: "接收通知",
  getPouchVENUEeBook: "獲取PouchVENUE電子書",
  getStarted: "開始吧",
  glass: "玻璃",
  glossary: "詞彙表",
  goToXDashboard: "轉到 {0} 儀表板",
  grossProfit: "毛利",
  guest: "客人",
  guestCheckedOutAndBanned: "客人已退房並標記被禁止。",
  guestDetails: "客人檔案詳情",
  guestId: "客人ID",
  guestInfoNotYetSync: "客人信息尚未同步",
  guestInformationYouCollect: "入住/登記時您收集的客人信息。",
  guestList: "客人列表",
  guestName: "客人姓名",
  guestNameAndOrTagId: "客人姓名和/或標籤ID",
  guestOrTagStatus: "客人或標籤狀態",
  guestProfile: "客人檔案",
  guestProfileAmountPlaceholder: "0",
  guestProfileDeleted: "客人檔案已刪除。",
  guestProfileDescriptionPlaceholder: "例如 “在美食廣場報到的客人”",
  guestProfileList: "客人檔案列表",
  guestProfileName: "客人檔案名稱",
  guestProfileNameCreate: "客人檔案“{0}”已建立。",
  guestProfileNamePlaceholder: "例如 “美食廣場客人”",
  guestProfiles: "客人檔案",
  guestQuestions: "客人問卷",
  guestSince: "自 {0}以來的客人",
  guestSpecialRequests: "客人特殊要求",
  guestTap: "客人點擊",
  guestWithColonSymbol: "客人：",
  guests: "客人",
  guestsCheckedIn: "已登記的客人",
  guestsNewBalance: "客人新餘額",
  guestsPreviousBalance: "客人之前的餘額",
  guestsPreviousBalanceTotalPaidAndNewBalance: "客人之前的餘額、已支付金額和新餘額",
  guestsStatus: "客人狀態",
  headOfBooking: "預訂主管",
  headerSection: "標題部分",
  hello: "你好/您好",
  helpCenter: "幫助中心",
  hiHeresWhatsHappening: "嗨 {0}，這是發生在 {1} 的情況。",
  hidden: "已隱藏",
  hide: "隱藏",
  hideDetails: "隱藏詳細資料",
  hideInPos: "在銷售終端 （POS）中隱藏",
  hideOnlineMenu: "在線菜單中隱藏",
  hideVariant: "隱藏變體",
  highDenominations: "高面額",
  holdingAccountForToppedUpItems: "銷售前，在標籤中充值所有積分的保留賬戶",
  hostname: "主機名",
  hour: "小時",
  hourly: "每小時",
  hours: "小時",
  id: "標示/識別資料",
  ifYouLeaveThisPage: "如果您離開此頁面，您將丟失所有未保存的更改。您確定要放棄更改嗎？",
  ifYoudLikeToJustStopSellingProduct:
    "如果只想在您的銷售終端 （POS）上停止銷售產品，只需關閉“在銷售終端 （POS）上銷售”即可禁用該產品。",
  ignore: "忽略",
  ignoreAllOpenShifts: "忽略所有開放/開始的班次",
  ignoreAllShifts: "忽略所有班次",
  ignoredShiftFromPrevious: "從昨日日終忽略的輪班",
  imageTypeSize: "JPG、GIF或PNG。最大尺寸為1MB",
  impItemMaxSize: "最大尺寸25MB",
  impItemModalNote1: "對於在庫中已存在的供應品/產品，進口將增加該供應品在庫中的數量",
  impItemModalNote2: "對於在庫中不存在的供應品/產品，單擊“確認”將在庫中生成新的庫存單位（SKU）",
  impItemsErr1: "找不到位置/場地 {0}",
  impItemsErr2: "找不到測量 {0}",
  impItemsErr3: "表 {0} {1} 的數據格式不正確",
  impItemsErr4: "供應品 {0} + 測量 {1} 的價格有所不同",
  impItemsErr5: "表 {0} 不應超過200行",
  impItemsErr6: "未找到類別",
  impItemsErr7: "產品{0} + 測量{1}的價格有差異",
  impItemsPlsRecheck: "請重新檢查文件中的數據，並重新上傳",
  import: "導入",
  importSupplyItems: "導入供應品",
  importSupplyItemsCreateWholeProducts: "導入供應品並創建整個產品",
  importSupplyItemsErr: "位置名稱與場地中現有的位置不匹配。請重新檢查",
  in: "内",
  inHouse: "內部",
  inProgress: "進行中",
  inStock: "庫存中/有現貨",
  inactive: "無效",
  include: "包含",
  indeterminate: "不確定的",
  information: "資訊",
  informationToCollect: "客人註冊時收集的資訊。",
  inputReason: "輸入原因",
  integrateDesc: "與強大的物業管理系統等進行整合。",
  integration: "整合",
  integrationSettingDesc: "連接到其他商務和工作效率/生產力應用程序，以幫助您發展和精簡業務流程。",
  integrationSettings: "整合設定",
  integrations: "整合",
  interval: "間隔",
  invalidFileType: "無效的文件類型。允許的文件類型為JPG或PNG。",
  invalidOptions: "無效的選項。",
  invalidTimeRange: "無效的時間範圍",
  invalidToken: "無效的令牌/標記",
  inventory: "庫存",
  inventoryAccount: "庫存賬戶",
  inventoryAccountMessage: "為在銷售前已購買的供應品和庫存。",
  inventoryAccountPlaceholder: "資產/庫存",
  inventoryExisted: "庫存存在/存有清單",
  inventoryGainLossAccount: "庫存增減賬戶",
  inventoryGainLossAccountMessage: "用於庫存點檢、庫存調整等不一致情況。",
  inventoryGainLossAccountPlaceholder: "庫存銷售益損",
  inventoryManagement: "庫存管理",
  inventoryMovement: "庫存流動",
  inventoryMovementPage: "庫存移動頁面",
  inventoryReport: "庫存報告",
  inventoryValue: "庫存價值",
  invitationHasBeenSentTo: "已給{0}發送邀請。",
  inviteNewStaff: "邀請新員工",
  inviteResponse: "邀請回覆",
  inviteYourStaff: "邀請您的員工",
  inviteYourVenueStaff: "邀請您的場地員工，管理他們在銷售終端 （POS）中的角色和訪問權限",
  invoiceNumber: "發票編號",
  isAreserveWord: "是一個保留詞。",
  issuanceLimit: "發行限制",
  issue: "議題",
  issueFreeCredits: "發行免費信用額",
  issuedBy: "發行人",
  issuedOn: "發行日期",
  item: "項目/物品",
  itemBargain: "此項目/物品的價格已從{0}變更為{1}",
  itemImport: "商品導入",
  itemName: "項目/物品名稱",
  itemTax: "項目/物品稅",
  items: "項目/物品",
  itemsSold: "已售出的項目/物品",
  justNow: "剛才",
  lastIssuedTo: "上次發行給",
  lastModifiedBy: "最後修改者",
  lastModifiedOn: "最後修改日期",
  lastName: "姓",
  lastPurchasedProduct: "最後購買的產品",
  lastSync: "上次同步",
  lastSynced: "上次同步{0}",
  lastVisitedLocation: "上次訪問的位置/場地",
  learnHowToRenewSubscription: "了解如何更新您的訂閱",
  learnMore: "了解更多",
  legalBusinessName: "法定營業名稱",
  letsGetStarted: "讓我們開始吧",
  liability: "負債",
  limit: "限制",
  limitVoucherPerPerson: "每人發放的優惠券限額",
  linkAccountingItem: "鏈接會計項目",
  linkOutlet: "鏈接點",
  linkOutletHoverTooltip: "與MEWS出口鏈接",
  linkWithMewsAccountingItems: "與MEWS會計項目鏈接",
  litePlanMaxProductReached: "您已達到您的{1}計劃的最大{0}產品數量。",
  litePlanMaxStaffReached: "您已達到您的{1}計劃的最大{0}員工數量。",
  loading: "加載中...",
  location: "場地/位置",
  locationDesignation: "場地指定",
  locationName: "場地名稱",
  locationNameAlreadyExist: "此場地已存在。請選擇不同的名稱。",
  locationNameCreated: "場地“{0}”已創建。",
  locationNameDeleted: "場地“{0}”已刪除。",
  locationNameUpdated: "場地“{0}”已更新。",
  locationQRCode: "場地QR碼（整個場地通用）",
  locationVoucherRedeemed: "選擇優惠券兌換的地點。",
  locations: "地點",
  locationsList: "地點列表",
  logIn: "登錄",
  loginHasEnded: "您的登錄會話已結束。請重新登錄。",
  loginSessionEnded: "登錄會話已結束",
  logout: "登出",
  looksLikeAlreadyInSystem: "看來您已經在我們的系統中！",
  looksLikeYouEncounteredSomething:
    "看來您遇到了一個錯誤，我們會自動跟蹤這些錯誤。在此期間，請嘗試刷新頁面。",
  looksLikeYouHaveNoLocationYet: "看來您還沒有任何場地。",
  lostTag: "遺失的標籤",
  lostTags: "遺失的標籤",
  lowDenominations: "低面額",
  lowOrOutOfStock: "低或缺貨",
  lowParLevel: "低存貨水平（PAR）",
  lowerCaseNoneRefundable: "不可退款",
  lowerCaseTopUpCredits: "儲值積分",
  mailingList: "郵件列表",
  male: "男性",
  manageAccountNotificationStockLevel: "管理賬戶以在產品下降至警戒水平時通知。",
  manageAndAssignQuestions: "管理和分配登記問題、免費積分和優惠券不給予同的客人檔案。",
  manageBusinessInformation: "管理商務信息",
  manageEmailNotif: "管理發送到您和您的員工的電子郵件通知。",
  manageHowVenueTax: "根據您所在地區管理您的場地如何收取稅。請諮詢稅務專家以瞭解您的稅務義務。",
  manageImportantDetailsSettings:
    "管理場地的重要細節和規則，例如場地設置、付款和信用/積分設置，登記/入住設置和您的賬戶設置。",
  manageInventory: "管理庫存",
  manageInventoryAndProducts: "管理這組庫存和產品。",
  manageInventoryAndSupply: "管理這個產品的庫存和供應物品。",
  manageLocationTable: "管理此場地的桌子和座位安排。",
  manageNotifcations: "管理發送給您的員工的電子郵件提醒和通知。",
  manageOfPermissionToEnterSpecificAreas: "管理進入特定區域的許可權。",
  managePlacesYouStock: "管理您儲存庫存和銷售產品的地點。",
  manageReceipts: "管理您收據的外觀和信息。",
  manageSupplyItems: "管理供應物品",
  manageThePosDevicesVenue: "管理在您場所中登錄並使用的銷售終端 （POS）設備",
  manageTheStocksPerLocation: "管理此供應物品每個場地的庫存。",
  manageTipDesc: "啟用小費並設置銷售終端 （POS）上顯示的小費百分比",
  manageToppedUpCredits: "管理儲值積分 、免費積分、後付積分和過期積分。",
  manageVariants: "管理變體",
  manageVenues: "管理場地",
  manageYourAccountsSales: "管理關於銷售、商品成本、商品稅款或折扣的賬戶。",
  manageYourStaffProfileDescription: "管理您的員工、他們的角色、職稱和所屬的員工檔案。",
  manageYourVenueSetting: "管理您的場地設置，例如稅收和貨幣。",
  manageYourVenueSubscription: "管理您場地的訂閱、賬單信息和發票。",
  manageYourVenueTax: "添加和管理場地如何收取稅款。",
  manager: "經理",
  managerAuthorization: "管理/經理權限",
  managerRoleDesc: "完全訪問線上場地儀表板和場地應用程式。",
  mapAndManageAccounts: "映射和管理每個PouchVENUE業務流程的預設賬戶。",
  mapAndManageAccountsLink: "映射和管理您在{0}中的賬戶以進行正確的會計。",
  mapYourAccounts: "映射您的庫存資產、差異費用等賬戶。",
  margin: "利潤/保證金",
  markUp: "加價",
  maxProductReached: "達到最大產品數量",
  maxStaffReached: "達到最大員工數量",
  maximumLengthCharacters: "最大{0}個字符。",
  maximumValueAllowed: "允許的最大值為20,000,000",
  maximumValueIs: "最大值是",
  measurement: "測量",
  measurements: "尺寸",
  mediumDenominations: "中等面額",
  menuEngineering: "菜單工程",
  mews: "MEWS",
  mewsAccounting: "MEWS會計",
  mewsAccounting1: "MEWS會計",
  mewsBookings: "MEWS預訂",
  mewsBookings1: "MEWS預訂",
  mewsSystems: "MEWS系統",
  mewsTaxCode: "這是來自MEWS的稅碼嗎？",
  mewsTaxCodeEditNote: "備註：當交易同步時，與此稅收有關聯的產品將在MEWS中有增值稅金額。",
  milk: "牛奶",
  minOfNCharacters: "最少{0}個字符",
  minimum: "最小",
  minimumCharactersAllowed: "最少允許{0}個字符",
  minimumOf: "需要至少2個項目/物品。",
  minimumValueIs: "最小值為",
  minute: "分鐘",
  minutes: "分鐘",
  missedCheckIn: "錯過登記/入住",
  missedCheckOut: "錯過退房",
  mixed: "混合",
  mobileNumber: "手機號碼",
  mode: "模式",
  month: "月",
  monthly: "每月",
  months: "個月",
  monthsAfterCheckout: "退房後幾個月",
  mostPurchasedProduct: "最常購買的產品",
  mostVisitedLocation: "最經常訪問的地點",
  movement: "運動",
  multiProperty: "多物業",
  nOptions: "{0}個選項",
  nTon: "從{0}到{1}",
  na: "無效",
  name: "名稱",
  nameAdded: "已新增“{0}”。",
  nameYourProduct: "為您的產品命名…",
  nameYourProductSet: "為您的產品集命名…",
  nameYourSupplyItem: "為您的供應物品命名",
  nationality: "國籍",
  needHelp: "需要幫忙嗎？",
  netWeightN: "淨重量{0}",
  new: "新",
  newBalance: "新餘額",
  newGuests: "新客人",
  newInviteLink: "已向“{0}”發送新的邀請鏈接。",
  newPassWord: "新密碼",
  newProduct: "新產品",
  newProductSet: "新產品集",
  newQty: "新數量",
  newQuantity: "新數量",
  newSku: "新SKU (庫存單位）",
  newToPouchNation: "您是第一次使用PouchNATION嗎？",
  nfcStatus: "NFC狀態",
  nfcTags: "NFC標籤",
  night: "晚上",
  no: "否",
  noAccountsAvailable: "沒有可用的賬戶",
  noAccountsYet: "還沒有賬戶",
  noAvailableAccessPoint: "沒有可用的接入點",
  noAvailableCategories: "沒有可用的分類",
  noAvailableGuestProfile: "沒有可用的訪客資料",
  noAvailableLocations: "沒有可用的場地",
  noAvailableLocationsYet: "還沒有可用的場地",
  noAvailablePaymentMethodYet: "還沒有可用的付款方法",
  noAvailableStaff: "還沒有現有的員工",
  noBannedTagsYet: "還沒有禁止的標籤",
  noBookingsYet: "還沒有預訂。",
  noCheckInsInDateRange: "該日期範圍內沒有登記/入住記錄。",
  noData: "沒有數據",
  noDataAvailable: "沒有可用的數據",
  noDeviceAvailable: "沒有可用的設備",
  noDevicesFound: "找不到設備",
  noEndDate: "沒有結束日期",
  noLocation: "沒有地點",
  noLocationAvailable: "沒有可用的地點",
  noNotesFound: "找不到筆記/注解",
  noOfCheckedIn: "{0} 已登記",
  noOfCheckedOut: "{0} 已退房",
  noOfGuests: "客人數",
  noOfNights: "夜數",
  noOfTables: "桌子數量",
  noProductsAvailable: "沒有可用產品",
  noProductsSelected: "尚未選擇產品。",
  noRecords: "沒有記錄。",
  noRecordsOf: "無相關記錄",
  noRemainingBalance: "無剩餘餘額",
  noReportsFound: "找不到報告。",
  noResultInSearch: "我們找不到符合您搜索條件的結果。",
  noResults: "沒有結果",
  noResultsFound: "找不到結果",
  noResultsMatching: "沒有符合的結果",
  noResultsMatchingX: "找不到符合“{0}”的結果",
  noReturnCreditsInDateRange: "在此日期範圍內沒有退款額。",
  noSalesInDateRange: "在此日期範圍內沒有銷售額。",
  noSearchFound: "找不到搜索",
  noSearchResultsForName: "找不到與 {0} 相關的搜索結果。",
  noShow: "無法顯示",
  noStocks: "無庫存",
  noTagUid: "沒有標籤UID（唯一識別號）",
  noTagYet: "尚未添加標籤",
  noTagsFound: "找不到標籤。",
  noVoucherAvailable: "沒有可用的優惠券",
  noVoucherMovement: "沒有優惠券活動。",
  noVouchersSelectedYet: "尚未選擇優惠券。",
  nonRefundable: "不可退款",
  none: "無",
  notCheckedIn: "未登記",
  notSellingOnPos: "銷售終端 （POS）上未出售",
  note: "備註",
  notePreviousTransaction: "備註：您之前與這些產品的交易記錄將會被保留。",
  noteTransactionStaff: "備註：您之前與這位員工的交易記錄將會被保留。",
  notedByOn: "{0} 於 {1} 製作備註",
  notes: "備註",
  notificationSettings: "通知設定",
  notifications: "通知",
  nowToDo: "現在要做的",
  nthGuestWhoUseThisTag: "{0} 使用此標籤的客人。",
  nthStaffWhoUsedThisTag: "{0} 使用此標籤的員工。",
  nthUse: "使用次數 {0}",
  numberOfGuests: "客人數",
  numberOfNights: "夜數",
  offline: "離線",
  ok: "確定",
  okay: "好的",
  oldGuestId: "舊客人ID",
  onPos: "在銷售終端 （POS）上",
  onboardingDescription: "通過這些簡單步驟設置您的場地，使您的場地可以運作起來。",
  onboardingDescriptionCompleted:
    "太棒了！您現在可以在PouchVENUE 銷售終端 （POS）設備上開始銷售！立即訂購您的NFC標籤，以無現金交易及啟動門鎖功能！同時，查看我們的電子書，了解如何優化您的場地！",
  onboardingTitle: "歡迎！開始設置您的場地吧。",
  onboardingTitleCompleted: "您的場地已準備就緒！",
  onceYouBanATag: "一旦禁用一個標籤，該標籤將無法在您的場地內進行交易。",
  onceYouCompleteStocktake: "完成盤點後，所有供應項目的實際數量將相應調整。此操作無法撤銷。",
  onlineMenuOnly: "僅在線菜單",
  onlyDateRangesLessThanOneYear: "僅接受少過一年的日期範圍",
  open: "打開",
  openReceiptPreview: "打開收據預覽",
  openWindow: "打開場地視窗",
  opened: "已打開",
  openingBalance: "期初餘額",
  optionNameAlreadyExists: "選項名稱已存在",
  options: "選項",
  orNo: "訂單號",
  orderQty: "訂單數量",
  orderQuantityRequired: "訂單數量為必填",
  orderYour: "訂購您的",
  orderYourNFCTags: "訂購您的NFC標籤",
  original: "原始",
  other: "其它",
  others: "其他",
  outlet: "出口",
  overview: "概述",
  pairedTagOnCheckin: "登記時配對標籤",
  pairedTags: "配對的標籤",
  parLevel: "標準存貨水平",
  password: "密碼",
  passwordDoesNotMatch: "您輸入的密碼不匹配",
  passwordIncorrect: "您輸入的密碼不正確。",
  passwordsDoNotMatch: "密碼不匹配",
  paymentAndCreditSettings: "支付和信用設置",
  paymentMethod: "付款方式",
  paymentMethodAddedName: "新增付款方式“{0}”。",
  paymentMethodAlreadyExist: "付款方式已存在",
  paymentMethodName: "支付/付款方式名稱",
  paymentMethods: "付款方式",
  paymentMethodsDescription: "管理並創建您在場地中接受的付款方式。",
  paymentMethodsSettings: "付款方式設置",
  paymentMethodsYouAccept: "您在場地中接受的付款方式。",
  paymentStatus: "支付狀態",
  pending: "待處理",
  perPage: "每頁 {0} 條",
  percentage: "百分比",
  percentageBetween: "百分比應在1-100之間",
  permitNumberWithColonSymbol: "許可證號碼：",
  phone: "電話",
  phoneNo: "聯絡電話",
  pleaseCheckYourNetwork: "請檢查您的網絡，更新您場地的數據。",
  pleaseChooseLocation: "請選擇地點",
  pleaseConnectToAnAccounting: "請與會計整合連接以管理您的會計科目表。",
  pleaseContactOwnerToReactiveVenue: "請聯繫場地業主續訂訂閱並重新激活此場地。",
  pleaseContactSupportRenewSubscription: "請聯繫支援部門更新您的訂閱並重新啟用您的場地。",
  pleaseCreateAccounts: "請從您的會計整合平台創建賬戶代碼。",
  pleaseCreateLocation: "請至少創建一個位置來分配該供應品的庫存。",
  pleaseEmailUs: "請發送郵件至 {0} {1}。",
  pleaseEnsureLocation: "請確保在開始盤點之前場地沒有正在進行的交易。",
  pleaseEnterYourSFTP: "請輸入您的SFTP伺服器詳細資料和憑證。",
  pleaseRenewSubscription: "請更新您的訂閱以重新啟用您的場地。",
  pleaseSelectLocation: "請選擇一個位置。",
  pleaseSelectStockUnit: "請選擇庫存單位。",
  pleaseUpgradeToUseLockedFeature: "請升級您的計劃以使用此鎖定功能。",
  pointOfSale: "銷售點",
  posAndOnlineMenu: "銷售終端 （POS） + 在線菜單",
  posDevices: "銷售點設備",
  posOnly: "僅銷售終端 （POS）",
  posProvider: "銷售終端 （POS）供應商",
  postPaidCreditsIsTheAmount:
    "預付費積分是當客人餘額達到零時可以使用的金額。當客人有預付費積分餘額時，該金額應透過儲值或結賬時支付。",
  postPaidCreditsUsed: "已用預付費積分",
  postpaidCreditLimit: "預付費積分上限",
  postpaidCredits: "預付費積分",
  postpaidCreditsLiabilityAccount: "預付費信用負債賬戶",
  postpaidCreditsLiabilityAccountMessage:
    "當客人餘額達到零時使用的積分，需透過儲值或在結賬時支付。",
  postpaidCreditsLiabilityAccountPlaceholder: "資產/預付費信用",
  postpaidCreditsUsed: "已用預付費積分",
  postpaidCreditsUsedCanOnlyBeReplenished: "已用預付費積分僅可透過儲值或結帳支付。",
  pouchConnect: "PouchCONNECT",
  pouchLogo: "PouchNATION標誌",
  pouchPay: "PouchPAY",
  pouchnation: "PouchNATION",
  powerYourVenue: "與整合一起為您的場地提供動力。",
  preloadedCredit: "預先加載積分",
  preloadedVouchers: "預加載優惠券",
  prepareImport: "準備導入",
  preparingReport: "準備報告",
  previousBalance: "上一次餘額",
  price: "價格",
  pricePerOrderQty: "訂單數量單價",
  pricing: "定價",
  pricingAndTaxes: "定價和稅收",
  printableItems: "可列印物品",
  printableItemsSelect: "選擇在此打印機檔案/配置文件中打印哪些產品。",
  printerLocationsDesc: "選擇可以使用此打印機配置文件的場地。",
  printerProfile: "打印機配置文件",
  printerProfileCreated: "打印機配置文件“{0}”已創建。",
  printerProfileDeleted: "已刪除打印機配置文件",
  printerProfileName: "打印機配置文件名稱",
  printerProfileNameAlreadyExists: "此打印機配置文件名稱已存在。",
  printerProfileUpdated: "打印機配置文件“{0}”已更新。",
  printerProfiles: "打印機配置文件",
  printers: "打印機",
  prioritizedAndUsedFirst: "在付款時，首要並優先使用",
  privacy: "隱私",
  privacyPolicy: "隱私政策",
  privacyPolicy2: "隱私政策",
  proceed: "繼續",
  proceedToCheckout: "繼續結帳",
  processingTransactions: "處理交易中...",
  product: "產品",
  productCategories: "產品分類",
  productCategory: "產品類別",
  productCount: "產品計數",
  productDeleted: "產品“{0}”已刪除。",
  productList: "產品清單",
  productName: "產品名稱",
  productRedeemed: "已兌換產品",
  productRemovedFromInventory: "從庫存中刪除{0}個產品。",
  productSet: "產品套裝",
  productSetDeleted: "產品套裝“{0}”已刪除。",
  productSetName: "產品套裝名稱",
  productUpdated: "{0} 更新產品。",
  products: "產品",
  productsArePartOfComposite:
    "這些產品是其他複合產品的一部分。如果您想要刪除，首先從複合產品中移除它們，或者與帶有它們的複合產品一起刪除。",
  productsAreSupplyItemsAre:
    "{0} 是在您的場地銷售的物品。{1} 是用來製作您的產品的物品。供應物品在您的{2}中管理。",
  productsCanBeRedeemed: "選擇可以使用此優惠券兌換的產品。",
  productsDeleted: "已刪除{0}個產品。",
  productsDoesNotExist: "您要添加的產品在所有位置/場地上必須存在。",
  productsForDeletion: "有此位置的{0}個產品可能被刪除。此操作無法撤消。",
  productsRemovedFromInventory: "已從庫存中刪除{0}個產品。",
  productsToBeDeleted: "待刪除的產品",
  productsUpdated: "更新了{0}個產品。",
  productsVSupplyItems: "產品與供應物品",
  productsVariants: "產品/變體",
  productsWithNoCategory: "這些是尚未有分類的產品",
  profile: "配置文件/檔案",
  profileName: "配置文件/檔案名稱",
  profileNameAlreadyExists: "配置文件/檔案名稱已存在，請輸入不同的名稱。",
  propertyManagementSystem: "物业管理系统",
  propertyManagementSystemExample: "像MEWS和Cloudbeds这样的系统",
  purchasePlus: "PurchasePlus",
  purchaseplus: "加采购",
  purchasingAndInventory: "采购和库存",
  qrCodeForOnlineMenu: "在线菜单的QR码",
  qty: "数量",
  quantity: "数量",
  quantitySupplyItems: "用于此产品的供应物品数量。",
  quarter: "季度",
  quarterly: "季度的",
  question: "问题",
  questionAddedName: "问题“{0}”已添加。",
  questionAlreadyExist: "问题已存在。",
  questionDeleted: "问题“{0}”已删除。",
  questionDescription: "顾客注册时需收集的信息。通过将其分配给您的{0}，來使用这些问题。",
  questionSettings: "问题设置",
  questions: "问题",
  radioButton: "单选按钮",
  reachedReorderPoint: "达到重新订购点",
  readyForDownload: "已准备下载",
  reallyUnbanTag: "确实取消此标签的禁止吗？",
  reallyUnbanThisTagDescription:
    "解除此标签的禁止将在下次您的设备同步和更新时生效（通常每5分钟连接到互联网时）。如果您想要立即反映这些更改，请在解除禁令后同步和更新您的设备一次。",
  reason: "原因",
  reasonsForStay: "入住原因",
  receiptFormatting: "收据格式",
  receiptNumber: "收据编号",
  receiptSettings: "收据设置",
  receipts: "收据",
  recentTransactions: "最近交易",
  reconnect: "重新连接",
  redeem: "兑换",
  redeemableProducts: "可兑换产品",
  redeemed: "已兑换",
  referenceNo: "参考编号",
  refreshingThisPage: "刷新此页面",
  refundedCredits: "已退还的积分",
  registerGuests: "注册客人",
  registerGuestsRoleAppDesc: "此角色无权访问在线场馆仪表板。",
  registerGuestsRoleDashboardDesc: "允许访问场馆应用程序的办理入住與退房手續。",
  registerInfo: "注册信息",
  registerReports: "班次报告",
  registerReportsPage: "班次报告页面",
  registerStaff: "注册员工",
  registeredBusinessInformation: "注册业务信息",
  registeredBusinessTaxNumber: "注册商户税号",
  registeredOfficialBusinessName: "注册/官方商户名称",
  regulateEntryExit: "监管出入",
  regulateEntryExitDesc:
    "“激活此功能可精确调节在接入點的入口和出口。停用此功能时，访问控制仍然可用，但不会区分入口和出口。”",
  remove: "删除",
  removeFreeCredits: "删除免费积分",
  removeFromList: "从列表中删除",
  removeNameAsYourStaff: "將 {0} 從您的員工名單中刪除嗎？",
  removeSku: "已移除SKU (庫存單位）",
  removeStaff: "删除员工？",
  removeStocks: "删除库存？",
  removeStocksConfirm: "删除库存",
  removeStocksDetails: "您确定要删除{0} {1}库存中的{2}个{3}吗？",
  removeThisAccount: "删除此帐户？",
  removeThisSupplyItem: "首先，从產品中移除供应项目，此產品才能刪除。",
  removeVariant: "移除变体",
  removeVariantFirstFromProduct: "首先从产品中移除此变体",
  removedStock: "已移除库存",
  renewSubscription: "续订订阅",
  reorderPoint: "重新订购点",
  reorderPointReached: "已达到重新订购点",
  reorderStocks: "重新订购库存",
  report: "报告",
  reportAProblem: "报告问题",
  reportingDay: "报告日期",
  reports: "报告",
  reportsFailedToLoad: "报告加载失败。",
  reportsInfoPage: "報告信息頁",
  requireManagerAuthorization: "在申領此折扣時需要經理授權。",
  requireManagerAuthorizationVoucher: "在發行此代金券時需要經理授權。",
  required: "必填",
  resendInvite: "重新發送邀請？",
  resetExpiration: "重置到期",
  resetTheExpirationPeriod: "當客人完成交易但未登記入住時，重置到期期限。",
  restrictUseOfTags: "限制在您場所使用某些標籤。",
  retailPrice: "零售價格",
  retailPriceMustNotBeNegative: "零售價格不得為負數",
  retryReloadingChart: "重試加載圖表",
  returnCredits: "返回積分",
  returnCreditsRoleAppDesc: "此角色無法使用線上場所儀表板。",
  returnCreditsRoleDashboardDesc: "允許訪問場所應用程式的返回信用額",
  returnedTags: "返回標籤",
  returnedVoucher: "返還代金券",
  returningGuests: "返還客人",
  revenue: "營收",
  revenueAccount: "營收賬戶",
  revenueAccountMessage: "所有銷售營收的默認賬戶。",
  revenueAccountPlaceholder: "營收/銷售",
  reviewYourDailyVenueActivity: "查看您的每日場所活動。",
  revokeInvite: "撤銷邀請",
  revokeThisInvite: "撤銷此邀請？",
  roadMap: "路線圖",
  role: "角色",
  roleAndDesignation: "角色和職稱",
  roles: "角色",
  rooms: "房間",
  runEndOfDay: "執行日終",
  running: "執行",
  runningTheEodWithUnsynced: "執行未同步交易的日結/日終將導致報告不完整。",
  sale: "銷售",
  saleDiscounts: "營收/銷售折扣",
  saleFoc: "銷售/免費贈送營收",
  saleReturn: "銷售退貨",
  sales: "銷售",
  salesDiscounts: "銷售折扣",
  salesDiscountsMessage: "所有折扣應用的成本。",
  salesDiscountsPlaceholder: "銷售折扣",
  salesFOC: "銷售免費贈品",
  salesFOCMessage: "所有應用免費積分的費用。",
  salesFOCPlaceholder: "銷售/免費營收",
  salesInformation: "銷售信息",
  salesOverTime: "隨時間變化的銷售",
  salesReport: "銷售報告",
  salesSummary: "銷售摘要",
  save: "保存",
  saveAccountSetting: "保存賬戶設置",
  saveCategoryForm: "保存類別表單",
  saveCreditSetting: "保存信用/積分設置",
  saveDeviceForm: "保存設備表單",
  saveDiscountForm: "保存折扣表單",
  saveGeneralSettings: "保存一般設置",
  saveLocationForm: "保存位置/場地表單",
  saveNewSupplyItemForm: "保存新供貨項目表單",
  savePaymentMethod: "保存付款方式",
  savePrinterProfileForm: "保存印表機配置表單",
  saveProduct: "保存產品",
  saveProductSet: "保存產品組",
  saveQuestion: "保存問題",
  saveReceiptSettings: "保存收據設置",
  saveStaffForm: "保存合作夥伴關係管理（PRM）",
  saveStaffProfileForm: "保存員工配置表單",
  saveTaxSetting: "保存稅設置",
  saveVenueForm: "保存場所表單",
  saveVoucherForm: "保存代金券表單",
  saveWristbandKeepingSettings: "保存腕帶保持設置",
  savedChanges: "已保存更改",
  scheduleACall: "安排通話",
  search: "搜索",
  search2: "搜索...",
  searchBanTags: "按標籤UID (唯一識別號）搜索被禁止的標籤",
  searchCategories: "搜索並選擇此代金券的類別",
  searchForProducts: "搜索產品或供貨項目",
  searchForStockId: "按庫存ID搜索...",
  searchForStocktakeId: "按盤點ID搜索...",
  searchGuestsBalanceMovements: "按姓名或標籤ID搜索客人",
  searchGuestsByNameOrId: "按姓名或標籤ID搜索客人",
  searchMovementPlaceholder: "按代金券名稱，設備，客人搜索移動。",
  searchProductByKeyOrSku: "按關鍵字或SKU (庫存單位）搜索產品。",
  searchProductsByKeyword: "按關鍵字搜索產品",
  searchProductsIn: "在{0}中搜索產品",
  searchReportsByShiftId: "按班次ID搜索報告",
  searchResult: "搜索結果",
  searchResults: "搜索結果",
  searchStaffEmailOrTagID: "按員工電子郵箱地址或標籤ID搜索",
  searchSupplyItemBy: "按名稱或SKU (庫存單位）搜索供應項目",
  searchTag: "搜索標籤",
  searchTransactionPlaceholder: "按交易ID、標籤ID或簽到ID搜索交易",
  searchVouchers: "搜索優惠券",
  second: "秒",
  seconds: "秒",
  selectAType: "選擇類型",
  selectAccessPoints: "選擇訪問點",
  selectAccount: "選擇賬戶",
  selectAccountType: "選擇賬戶類型",
  selectAndMakeComposition: "選擇此產品的型號和組成。",
  selectCategoriesOrProducts: "從選定位置選擇類別或產品",
  selectCategory: "選擇類別",
  selectCharge: "選擇收費",
  selectCountry: "選擇國家",
  selectDevice: "選擇設備",
  selectGuestProfile: "選擇客人檔案",
  selectItemsThisProductMadeOf:
    "選擇此產品所組成的物品/項目。設置每筆銷售訂單中，將會扣除的每個供應物品/項目的數量。",
  selectLocation: "選擇地點/場地/場所",
  selectNumberOfTables: "選擇桌子的數量",
  selectOutlet: "選擇出口处",
  selectProducts: "選擇產品",
  selectRedeemableProducts: "選擇可兌換的產品",
  selectSource: "選擇來源",
  selectStaff: "選擇員工",
  selectStatus: "選擇狀態",
  selectStockLevel: "選擇庫存水平",
  selectStockUnit: "選擇庫存單位",
  selectSupplyItem: "選擇供應品項",
  selectTax: "選擇稅",
  selectToIncludeCategory: "選擇將此產品包含在一個類別中。",
  selectToIncludeProductSetInCategory: "選擇將此產品套組包含在一個類別中。",
  selectVenue: "選擇場地",
  selectVouchers: "選擇優惠券",
  sellOnPos: "在銷售終端 （POS）上銷售",
  sellOnPosRoleAppDesc: "此角色沒有訪問線上場地儀表板的權限。",
  sellOnPosRoleDashboardDesc: "允許訪問場地應用程式的銷售和線上訂單。",
  selling: "銷售",
  sellingOnPos: "在銷售終端 （POS）上銷售",
  sellingQty: "銷售數量",
  sellingQtyMustBeGreaterThanZero: "銷售數量必須大於零。",
  serialNumber: "序號",
  serialNumberAbbv: "序號",
  set: "設定",
  setAnAmountToLimitPostPaid: "設定客人後附積分的金額限制。",
  setAsDefaultTax: "設定為預設稅",
  setAsNonRefundable: "設為不可退款",
  setCode: "設定代碼",
  setExpirationPeriod: "設定到期時間",
  setUpYourAccount: "設置您的賬戶！",
  sets: "設置",
  settings: "設置",
  share: "分享",
  shareThisLink: "分享此鏈接以在任何設備上打開 {0}！複製鏈接並與員工分享。",
  shift: "班次",
  shiftAlreadyClosed: "此班次已經關閉/結束。",
  shiftClosed: "班次已關閉/結束",
  shiftEnd: "班次結束",
  shiftId: "班次 ID",
  shiftStart: "班次開始",
  shifts: "班次",
  shiftsAndTransactions: "班次和交易",
  shouldBeANumber: "應為一個數字。",
  show: "展示",
  showHideColumns: "顯示/隱藏列",
  showLess: "顯示更少",
  showMore: "顯示更多",
  showOnlineMenu: "顯示在線菜單中",
  signIn: "簽到/登記",
  signInButton: "簽到/登記按鈕",
  signUp: "註冊",
  sku: "SKU (庫存單位）",
  skuAlreadyExists: "SKU (庫存單位） 已存在。",
  skuStockKeepingUnit: "SKU (庫存單位）",
  someArePartOfCompositeNote:
    "因為{0} {1} 是其他複合產品的一部分，所以它們以下無法刪除。想要刪除這些內容，請從複合產品中移除或與攜帶它們的複合產品一起刪除 {2}。",
  someShiftsHaveUnsynced: "某些班次有未同步的交易。請檢查班次的設備以推送場地數據。",
  somethingWentWrong: "出問題了。",
  somethingWentWrongPlsTryAgain: "出問題了。請重試",
  sorryVenueSuspended: "對不起，此場地已被暫停使用。",
  source: "來源",
  sourceLocation: "來源位置",
  sourceLocationOriginalQty: "來源位置原始數量",
  sourceLocationRemainQty: "來源位置剩餘數量",
  sourceLocationTransferQty: "來源位置轉移數量",
  specificCategories: "特定類別",
  specificProducts: "特定產品",
  spend: "花費",
  staff: "員工",
  staffCanAccess: "員工可以訪問銷售終端 （POS）應用程式並執行操作。",
  staffList: "員工列表",
  staffName: "員工姓名",
  staffNameAndOrTagId: "員工姓名和/或標籤 ID",
  staffProfile: "員工資料",
  staffProfileDeleted: "員工檔案/資料已刪除",
  staffProfileList: "員工檔案列表",
  staffProfileName: "員工檔案名稱",
  staffProfileNameCreated: "員工檔案 “{0}” 已建立。",
  staffProfileNamePlaceholder: "輸入這個員工檔案的名字...",
  staffProfiles: "員工檔案",
  staffRemoved: "員工已移除。",
  staffTagWithColonSymbol: "員工標籤：",
  staffWithColonSymbol: "員工：",
  standard: "標準",
  start: "開始",
  startStocktake: "開始盤點庫存",
  startedOn: "啟動於",
  status: "狀態",
  staying: "逗留",
  stockAdjSupplyItemsTxtConfirm: "您是否確定要調整 {0} 中供應品項的庫存？",
  stockAdjustment: "庫存調整",
  stockBelowParLevel: "庫存低於警戒水平",
  stockLevel: "庫存水平",
  stockLevelAlerts: "庫存水平警報",
  stockLevelNotificationSettings: "庫存水平通知設置",
  stockLevels: "庫存水平",
  stockManager: "庫存經理",
  stockManagerRoleAppDesc: "此角色與應用程式無關，將無法登入設備。",
  stockManagerRoleDashboardDesc: "允許場地儀表板的訪問權限僅限於庫存項目盤點。",
  stockReachedReorderPoint: "庫存已達到重新訂購點",
  stockUnit: "庫存單位",
  stockVariance: "庫存差異",
  stockWeightPerN: "每 {0} 的庫存重量",
  stocks: "庫存",
  stocktake: "盤點",
  stocktakeAlreadyExists: "盤點已存在",
  stocktakeSaved: "盤點已保存",
  stocktakeWithId: "盤點 #ST-{0}",
  streetVillage: "街道地址，城鎮/村莊",
  subTotal: "小計",
  subscribeAndRegister: "立即訂閱並註冊，能獲得",
  subscription: "訂閱",
  subscriptionCanceledReason: "您已取消訂閱。",
  subscriptionExpiredReason: "您已取消訂閱。",
  subscriptionSuspended: "訂閱已結束。請在 {1} 前聯繫場地業主以續訂訂閱，以保留對 {2} 的訪問權。",
  subscriptionSuspendedIn5:
    "該場地即將在 5 天內暫停使用。請在 {0} 前聯繫場地業主以續訂訂閱，以保留對 {1} 的訪問權。",
  subscriptionSuspendedIn5Manager:
    "您的場地將在不到5天內暫停使用。請在 {0} 前更新您的訂閱以保留對 {1} 的訪問權限。",
  subscriptionSuspendedManager: "{0} 請在 {1} 前更新您的訂閱以保留對 {2} 的訪問權限。",
  subscriptionSuspendedToday:
    "您的場地將在今天被暫停。請聯繫場地業主更新訂閱以保留對 {0} 的訪問權限。",
  subscriptionSuspendedTodayManager:
    "您的場地將在今天被暫停。請儘快更新您的訂閱以保留對 {0} 的訪問權限。",
  subscriptionUnpaidReason: "您的支付明細似乎有問題。",
  subscription_only_owner_access: "只有訂閱此計劃的場地業主才能訪問此訂閱",
  success: "成功",
  sugar: "糖",
  suggested: "建議",
  summary: "摘要",
  supplyCost: "供應成本",
  supplyItem: "供應項目",
  supplyItemAlreadyExists: "供應項目名稱已存在。",
  supplyItemDeleted: "供應項目已刪除",
  supplyItemNameCreated: "已創建供應項目「{0}」。",
  supplyItemSummaryPage: "供應項目摘要頁面",
  supplyItems: "供應項目",
  supplyTaxes: "供應稅",
  supplyUnit: "供應單位",
  supplyValue: "供應值",
  supportPouchnation: "support@pouchnation.com",
  suspended: "已暫停",
  syncToMews: "同步到MEWS",
  tableManagement: "桌子管理",
  tableQRCode: "桌子QR碼（每張桌子）",
  tables: "桌子",
  tagBanned: "標籤被禁止。",
  tagId: "標籤ID",
  tagIds: "標籤ID",
  tagInfo: "標籤信息",
  tagUID: "標籤UID (唯一識別號）",
  tagUnbanned: "標籤已解除禁止。",
  tags: "標籤",
  tagsIssued: "已發行標籤",
  tapCount: "點擊次數",
  tapStatus: "點擊狀態",
  tax: "稅收",
  taxAddedName: "已新增稅收「{0}」。",
  taxAlreadyExists: "稅收已存在。",
  taxCode: "稅收代碼",
  taxEditNote: "備註：與此稅收相關的產品將自動更新。這不會影響過去的銷售。",
  taxEg: "例如「增值稅」",
  taxIncluded: "已包含稅",
  taxInvoiceNo: "稅收發票#：",
  taxInvoiceNumber: "稅收發票號碼",
  taxName: "稅收名稱",
  taxNumber: "稅收號碼",
  taxOnPurchasesAccount: "購買賬戶的稅收",
  taxOnPurchasesAccountMessage: "增值稅和其他采購訂單上的稅收。",
  taxOnPurchasesAccountPlaceholder: "負債/銷售稅",
  taxOnSalesLiabilityAccount: "銷售稅的負債賬戶",
  taxOnSalesLiabilityAccountMessage: "每個產品收取的稅金的默認賬戶。",
  taxOnSalesLiabilityAccountPlaceholder: "負債/銷售稅",
  taxPercentageAndValue: "稅（{0}%）：{1}",
  taxRate: "稅率",
  taxSettings: "稅收設置",
  taxes: "稅收",
  termsAndConditions: "條款與條件",
  termsInAllowingAccess:
    "點擊「允許訪問」，即代表您授權 {0} 使用、接收和存儲您的信息。請查看 {1} 的 {2} 和 {3}。您隨時可以在您的PouchNATION賬戶的整合設置中停止此操作。",
  termsInAllowingAccess2:
    "點擊「允許訪問」，即代表您授權 {0} 使用、接收和存儲您的信息。您隨時可以在您的PouchNATION賬戶的整合設置中停止此操作。",
  termsInAllowingAccess3:
    "點擊「允許訪問」，即代表您授權 {0} 存儲您的 {1} 信息。請查看我們的 {2} 和 {3}。",
  termsInSignUp: "通過註冊，我同意PouchNATION的 {0}、{1} 和 {2}。",
  termsOfService: "服務條款",
  termsOfUse: "使用條款",
  textbox: "文本框",
  theActualNumberOfGuests: "實際入住/離開、退房的客人人數",
  theAmountTheGuestWillPay: "此預定的客人將支付的金額。",
  theBookingStatus: "來源的預訂狀態。",
  theGuestHasNoAssignedRoom: "此客人尚未分配房間！",
  theLinkYouAreTryingToAccess: "您嘗試訪問的鏈接不屬於您的場地。",
  theLinkYouAreTryingToAccessDoesNotBelongToLocation: "您嘗試訪問的鏈接不屬於您被分配的場地",
  theShiftAndTransactionDetails: "一旦在收銀機結束這班次，在此處將顯示班次和交易的詳細信息。",
  thereIsSomeError: "糟糕！我們這邊出現了一些錯誤。請您耐心等待並稍後再檢查。",
  theseNthOtherProducts: "{0} {1} 其他產品（與此地點/場地和其他地點/場地）將保留在您的庫存中。",
  theseProductsCannotBeDeleted: "這些產品無法刪除。",
  thisAppRequests: "此應用程序請求以下信息：",
  thisAppWouldRead: "此應用程序將閱讀和/或寫入以下信息：",
  thisEmailIsAlreadyAdded: "這個電子郵箱地址已經被添加。",
  thisEmailIsInvalid: "此電子郵箱地址無效。",
  thisFieldIsRequired: "這是必填項。",
  thisIsForDirectIntegrationWithPaymentTerminalPartner: "這是用於與付款終端合作夥伴的直接整合",
  thisLocationHasTables: "這個地點/場地有桌子和座位安排。",
  thisMonth: "這個月",
  thisProductNameAlreadyExists: "此產品名稱已存在",
  thisProductsHasMultipleVariant: "此產品有多個選項，例如尺寸、顏色、口味等。",
  thisQuestionIsLinkedToNProfiles: "此問題與{0}位客人資料相關。",
  thisServesAsAnOfficialReceipt: "- 這將作為官方收據 -",
  thisShiftHasUnsyncedTransactions: "此班次有尚未同步的交易",
  thisSkuExists: "此SKU (庫存單位）已存在",
  thisStaffHasNpLongerAccess: "這名員工將不再可以存取{0}。此操作無法撤消。",
  thisTagIdIsAlreadyBanned: "這個標籤ID已被禁止。",
  thisVenueFreeTrial: "這家場地將在頭30天免費試用。",
  thisWeek: "本週",
  ticketNo: "票號",
  ticketing: "售票",
  time: "時間",
  timeEnded: "時間結束",
  timeRange: "時間範圍",
  timeStarted: "開始時間",
  timeout: "逾時",
  tip: "小費",
  tipOptions: "小費選擇",
  tipReport: "小費報告",
  tips: "小費",
  tipsAndCharges: "小費與費用",
  to: "給",
  toAddMoreCreditsToATag: "要為標籤增加更多積分，客人可以采取",
  toBeCheckedIn: "待登記入住",
  toBeCheckedOut: "待結賬/退房",
  toContinueCreatingDiscount: "想要繼續創建此折扣，請先創建一個地點/場地。",
  toContinueCreatingProducts: "想要繼續創建此產品，請先創建一個地點/場地。",
  toContinueCreatingStaff: "想要繼續創建此員工，請先創建一個地點/場地。",
  toContinueCreatingStocktake: "想要繼續創建此盤點，請先創建一個地點/場地。",
  toDeleteItem: "刪除此項目。",
  toScheduleACall: "安排與我們客戶支援的通話。",
  today: "今天",
  together: "一起",
  topCategoriesBySales: "銷售前幾位類別",
  topLocations: "頂級地點",
  topProducts: "熱門產品",
  topSpendingGuests: "最多消費的客人",
  topupAndReturnCredits: "充值並返回積分",
  topupCredits: "充值積分",
  topupCreditsRoleAppDesc: "此角色無法訪問線上場地儀表板。",
  topupCreditsRoleDashboardDesc: "允許訪問場地應用程式的充值",
  total: "總計",
  totalAmount: "總金額",
  totalBookings: "總預訂",
  totalCreditsKept: "結賬後留存的總積分",
  totalCreditsKeptByGuests: "客人保留的總積分",
  totalCreditsKeptTooltip: "客人結賬後保留的總積分金額",
  totalCreditsReceived: "總收到的積分",
  totalCreditsReceivedFromVenues: "從其他場地收到的總積分",
  totalCreditsReceivedTooltip: "來自客人保留其他場地積分的總金額",
  totalExpectedEndingBalance: "預期結餘總金額",
  totalFreeCreditsIssued: "總發放的免費積分",
  totalFreeCreditsRemoved: "總移除的免費積分",
  totalInStock: "總庫存",
  totalOpeningBalance: "總開始餘額",
  totalPaid: "總支付金額",
  totalProductSales: "總產品銷售額",
  totalRefunded: "總退款金額",
  totalRefundedCredits: "總退還積分",
  totalRemoved: "總移除的金額",
  totalReturnCredits: "總返回積分",
  totalReturnedCredits: "總已返回積分",
  totalSales: "總銷售額",
  totalSpent: "總花費",
  totalSpentToDate: "截至目前總花費",
  totalStocksPerLocation: "每個地點的庫存總數",
  totalTagsUsed: "發行的標籤總數",
  totalTax: "總稅額",
  totalTopUps: "總加值",
  totalTopupCredits: "總添加積分",
  totalTransactions: "總交易",
  totalUnspentCredits: "總未使用的積分",
  totalUnspentCreditsDesc: "所有與{0} 相連，並在您上述篩選器日期內退房的客人的未使用積分總和。",
  totalUse: "總使用",
  totalVisits: "總造訪次數{0}",
  totals: "總計",
  trackAndManage: "追蹤和管理您的手動庫存計數。",
  trackEveryGuestCheckedIn: "追蹤在您的場地入住的每位客人，並查看他們最喜歡的產品和地點",
  trackWhichItemsLow: "追蹤哪些物品庫存不足，需要補貨",
  trackedInventory: "已追蹤的庫存",
  tradeTypeTransactionNumber: "{0} 交易號",
  transaction: "交易",
  transactionID: "交易ID",
  transactionInfoPage: "交易資訊頁面",
  transactionStatus: "交易狀態",
  transactionType: "交易類型",
  transactions: "交易",
  transactionsFailedToProcess: "交易嘗試處理失敗。",
  transactionsPage: "交易頁面",
  transfer: "轉移",
  transferSupplyItems: "轉移提供物品",
  transferSupplyItemsSuccessfully: "成功轉移提供物品",
  transferSupplyItemsTxtConfirm: "您確定要從{0}轉移供應品到{1}嗎",
  try: "嘗試：",
  turnOffPos: "關閉{0} {1}的銷售？",
  turnOnPos: "開啟{0} {1}的銷售？",
  txnId: "交易ID：#",
  type: "類型/輸入",
  typeAQuestion: "輸入問題",
  typeAReason: "輸入原因...",
  typeAnOption: "輸入選項",
  typeDisconnect: "輸入斷開以確認",
  typeHere: "在這裡輸入...",
  typeToSearch: "輸入以搜索項目！",
  typeToSetAccountCode: "輸入以設定/選擇帳號代碼。",
  unableToDelete: "無法刪除",
  unbaleToUploadMoreThanMb: "無法上傳大小超過1mb的圖像。",
  unban: "解禁",
  unbanTag: "解禁標籤",
  unbanningWillMakeTagAccessible: "解禁後，此標籤將可以訪問您的場地。",
  uncategorized: "未分類",
  undefinedErrorPlsTryAgain: "未定義錯誤。請刷新頁面並重試",
  undo: "取消",
  unignore: "取消忽略",
  unignoreAllOpenShifts: "取消忽略所有已開啟的班次",
  unignoreAllShifts: "取消忽略所有班次",
  unitSold: "銷售單位",
  unitsSold: "已銷售單位",
  unknown: "未知",
  upToDate: "最新",
  updatedOn: "更新於{0}",
  upgradeNow: "立即升級",
  upgradePlanHeader: "升級您的計劃，啟用更多高級功能！",
  upgradeYourPlan: "立即升級您的計劃！✨",
  upload: "上傳",
  uploadExcelFileToImport: "上傳excel文件以導入",
  uploadPhoto: "上傳照片",
  uponRegistration: "註冊時或與在場地內的任何充值人員/自助機。",
  usage: "使用",
  usageDatePeriod: "使用日期周期",
  useThisVenueDetails: "使用此場地詳情",
  useVenueAppAndLogin:
    "使用場地應用程序，使用已激活的NFC標籤或您當前的用戶名或密碼登錄。還沒有應用程序？請向您的場地經理或{0}詢問。",
  used: "已使用",
  username: "用戶名",
  usesLastIssuedTo: "用途，上次發出給",
  validUntilWithColonSymbol: "有效截止日期：",
  value: "數值",
  valueBetween: "數值應在之間",
  valueRedeemed: "數值已兌換",
  variant: "變體",
  variantAlreadyExist: "變體已存在",
  variantIsCurrentlyBeingUsed: "變體目前在以下產品中使用",
  variants: "變體",
  vatReg: "稅號",
  venue: "場地",
  venueCurrency: "場地貨幣",
  venueDashboard: "場地儀表板",
  venueDetails: "場地詳情",
  venueInLitePlan: "此場地目前為精簡計劃。",
  venueInformation: "場地信息",
  venueLocation: "場地位置",
  venueLogo: "場地標識",
  venueMode: "場地模式",
  venueName: "場地名稱",
  venueNameAlreadyExist: "場地名稱已存在，請選擇另一個名稱",
  venueSettings: "場地設置",
  venueSuspended: "場地暫停",
  veryHighDenominations: "非常高面額",
  view: "查看",
  viewAllTransactions: "查看所有交易",
  viewAndExportDiscountReport: "查看並導出您每個折扣的列表及每個折扣所減免的總金額",
  viewAndExportEachClosedRegister: "查看並導出您的員工製作的每個關閉班次報告",
  viewAndExportFreeCreditsReport: "查看並導出已發出和移除的免費積分。",
  viewAndExportSalesReportByCategories: "按類別查看並匯出您的銷售報告",
  viewAndExportVoucherReport:
    "查看並導出在贖回的產品上應用的所有折價券/優惠券以及每個折價券/優惠券贖回的零售和供應的總金額",
  viewBillingStatement: "查看帳單聲明",
  viewBookingInfo: "查看來自不同平台（如MEWS，PouchNATION售票等）的預訂信息！",
  viewBy: "按照查看",
  viewCategoryDetails: "查看類別詳情",
  viewDetails: "查看詳情",
  viewDevice: "查看設備",
  viewDiscountDetails: "查看折扣設置",
  viewEveryTransactions: "查看特定時間內在您場所進行的每筆交易",
  viewInfo: "查看資訊",
  viewIntegrations: "查看整合",
  viewNOfNTransactions: "查看 {0} / {1} 交易",
  viewNTransactions: "查看 {0} 交易",
  viewPlansAndPricing: "查看計劃和定價",
  viewPreview: "查看預覽",
  viewPrinter: "查看打印機",
  viewTaxSettings: "查看稅務設定",
  viewTheSalesSummaryOfYourVenue: "查看場地及其頂級位置、產品等的銷售總結",
  viewVoucherDetails: "查看折價券/優惠券詳情",
  void: "作廢",
  voidAuthorization: "作廢授權",
  voidConfirmation: "您確定要作廢{0}？這個動作無法撤消。",
  voidId: "作廢{0}？",
  voidInfo: "作廢信息",
  voidReason: "作廢原因",
  voidStocktake: "作廢盤點",
  voided: "已作廢",
  voidedBy: "作廢者",
  voidedItems: "已作廢項目",
  voidedOn: "已作廢於",
  voidedValue: "已作廢金額",
  voucher: "折價券/優惠券",
  voucherApplied: "已應用折價券/優惠券",
  voucherAvailable: "此折價券/優惠券可在您的場地發行。",
  voucherCreated: "{0}已添加。",
  voucherDeleted: "折價券/優惠券「{0}」已刪除。",
  voucherIssue: "發出折價券/優惠券",
  voucherMovementPage: "折價券/優惠券運動/流動頁",
  voucherName: "折價券/優惠券名稱",
  voucherNameAlreadyExist: "此折價券/優惠券名稱已存在。",
  voucherReachedIssuanceLimit: "此折價券/優惠券已達到發行上限{0}。",
  voucherRemove: "移除折價券/優惠券",
  voucherReport: "折價券/優惠券報告",
  voucherRetailValue: "折價券/優惠券零售價值",
  voucherSupplyValue: "折價券/優惠券供應價值",
  voucherType: "折價券/優惠券類型",
  voucherUpdated: "更改已保存。",
  vouchers: "禮券/折價券/優惠券",
  vouchersCreationLimitInfo:
    "您在每個場地最多只能創建8張禮券。您可以刪除已過期或無效的禮券，為更多禮券腾出空間。",
  vouchersIssued: "已發行禮券",
  vouchersListPage: "禮券列表頁",
  vouchersMaximumNumberReached: "已達到最大禮券數量。",
  vouchersRedeemed: "已兌換禮券",
  vouchersRemoved: "已刪除禮券",
  vouchersUsage: "禮券使用",
  weDidNotFoundReports: "我們找不到符合您篩選條件的任何報告。",
  weekMon2Sun: "每週（週一至週日）",
  weekSun2Sat: "每週（週日至週六）",
  weeksAfterCheckout: "退房後幾周",
  weightInGrams: "重量（克）",
  welcomeBack: "歡迎回來！",
  whatAreFreePostpaidCredits: "什麼是免費積分和買後付款積分？",
  whenAnItemAlertLevel: "當物品達到警戒水平時。",
  whole: "全部",
  wholeProducts: "完整產品",
  withPendingPayments: "具有待付款",
  withRemainingBalance: "剩餘餘額",
  wouldReadOrWrite: "{0} 將閱讀和/或撰寫以下信息：",
  wristbandKeeping: "手帶管理",
  wristbandTag: "手帶標籤",
  wristbandTagKeeping: "手帶標籤管理",
  wristbandTagSettings: "手帶標籤設置",
  xConnectedFailed:
    "我們似乎無法識別此訪問令牌/標記。請參考以下說明步驟以檢索您正確的訪問令牌/標記。",
  xConnectedSuccessfully: "{0} 成功連接",
  year: "年",
  years: "年",
  yes: "是",
  yesterday: "昨天",
  youAreAboutToDelete: '您即將刪除“{0}”"" {1}”。這將無法撤消。',
  youAreAboutToDeleteAQuestionThatIsLinked: "您即將刪除與客人檔案相關聯的問題。這將無法撤消。",
  youAreAboutToDeleteGuestProfile: "您即將刪除此客人檔案。這將無法撤消。",
  youAreAboutToDeleteMultiple: "您即將刪除 {0} {1}。這將無法撤消。",
  youAreAboutToDeletePaymentMethod: "您即將刪除“{0}”支付方式。",
  youAreAboutToDeleteQuestion: "您即將刪除此問題。這將無法撤消。",
  youAreAboutToDeleteStaffName:
    "您即將將 {0} 從員工列表中移除。此員工將不再能夠訪問 {1}。此操作將無法撤消。",
  youAreAboutToDeleteStaffProfile: "您即將刪除此員工檔案。這將無法撤消。",
  youAreAboutToDeleteTax: "您即將刪除“{0}”稅項。",
  youAreAboutToDisconnect: "您即將中斷並永久刪除此應用中的所有信息。",
  youAreAboutToRemoveFromMailingList:
    "您即將從此郵件列表中刪除 {0}。此賬戶將無法接收庫存水平提醒。",
  youAreAboutToRemoveMultipleProduct:
    "您即將從庫存和所有位置中刪除 {0} 產品及其變體。這將無法撤消。",
  youAreAboutToRemoveProduct: "您即將從庫存和所有位置中移除此產品。這將無法撤消。",
  youAreAboutToRemoveVariantProduct:
    "您即將從庫存和所有位置中刪除此產品及其 {0} 變體。這將無法撤消。",
  youAreAssigned: "您被分配以下角色：",
  youAreLoggedInAs: "您目前以 {0} 登錄",
  youCanChangeThisLater: "稍後您可以更改！",
  youCanNowLogin: "現在您可以使用您的電子郵箱地址和密碼登錄。",
  youDontHavePermission: "您無權訪問此鏈接",
  youHaveUnsavedChanges: "您在此頁面上有未保存的更改。",
  youllFindYourBookingsHere: "您將在此找到您的預訂。",
  yourGeneralSettings: "您的一般設置，如場地名稱和貨幣。",
  yourMailingIsEmpty: "您的郵件列表仍然是空的。",
  yourSubscriptionCanceled: "您的場地 {1} 的 {0} 訂閱計劃已取消。",
  yourSubscriptionEnded: "您的場地 {1} 的 {0} 訂閱計劃已結束。",
  yourVenues: "您的場地",
  youveReachedTheEndOfTheList: "您已到達列表結尾。",
  zeroInventory: "庫存為零",
};

export default Object.freeze(locale);
