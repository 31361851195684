import React from "react";
import { UnsaveChangesModal } from "components/modals";
import PromptLeavePage from "../prompt-leave-page";

const Form = ({ children, unsaveChangesModal, onSubmit, className, isPrompt = false }) => {
  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onSubmit();
      }}
      className={className}
    >
      {children}
      <UnsaveChangesModal {...unsaveChangesModal} />
      <PromptLeavePage
        isBlocking={isPrompt && (!unsaveChangesModal || !unsaveChangesModal?.active)}
      />
    </form>
  );
};

export default Form;
