import React, { useCallback, useContext, useState } from "react";
import { ModuleWrapper } from "components/fragments";
import { useMount, useRouter, useApi, useFilter } from "hooks";
import { getGuestCheckinInfo, searchTransaction, getGuestCheckin } from "apis";
import { guestCheckinInfo, guestCheckin, transactionListFilterRequest } from "mappers";
import {
  GuestDetailsSkeleton,
  GuestDetailsHeader,
  GuestCheckinInfo,
  GuestRecentTransaction,
  GuestTagInfo,
} from "./";
import { VenueContext } from "contexts";
import { mapObject } from "services";
import { transactionListFilterState } from "modules/transaction/transaction-list/filters";
import { guestRecentTransactionResponse } from "mappers/guest.mapper";
import { mixpanel, TrackEvent } from "mixpanel";
import lang from "translations";

const GuestDetails = () => {
  const { venue } = useContext(VenueContext);
  const { query } = useRouter();
  const { id } = query;
  const { venueId } = venue;
  const [selectedGuestCheckinId, setSelectedGuestCheckinId] = useState(null);

  const { requestState } = useFilter(
    transactionListFilterState(venueId, {
      query,
      guestCheckinId: selectedGuestCheckinId,
    })
  );

  useMount(async () => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.guestDetails,
    });
    try {
      const res = await fetchCheckinInfo();
      const { currentCheckin = {}, guestCheckinIds } = res;
      let guestCheckInId = currentCheckin?.checkinId;
      if (!guestCheckInId && guestCheckinIds.length) {
        guestCheckInId = guestCheckinIds[0].checkinId;
      }
      setSelectedGuestCheckinId(guestCheckInId);
      fetchGuestApis(guestCheckInId);
    } catch (e) {
      console.log(e);
    }
  });

  const fetchGuestApis = (id) => {
    requestGuestCheckIn({
      checkinId: id,
    });
    fetchTransactions(requestState, id);
  };

  const {
    request: fetchCheckinInfo,
    loading: loadingGuestCheckinInfo,
    mappedData: mappedGuestCheckinInfo,
    error: errorCheckInInfo,
  } = useApi({
    api: getGuestCheckinInfo,
    params: { guestId: id, venueId },
    mapper: guestCheckinInfo,
    handleOwnError: true,
    returnMappedData: true,
  });

  const {
    request: requestGuestCheckIn,
    mappedData: mappedGuestCheckin,
    loading: guestCheckinLoading,
    error: guestCheckInError,
  } = useApi({
    api: getGuestCheckin,
    params: { guestId: id, venueId },
    mapper: guestCheckin,
    handleOwnError: true,
  });

  const transactionRequest = useApi({
    api: searchTransaction,
    params: { guestId: id, venueId, page: 1, pageSize: 5 },
    isArray: true,
    mapper: guestRecentTransactionResponse,
    handleOwnError: true,
  });

  const { loading: transactionLoading = true } = transactionRequest;

  const fetchTransactions = useCallback(
    (requestState, guestCheckinId) => {
      const params = mapObject(requestState, transactionListFilterRequest);
      params.startDateTime = null;
      params.endDateTime = null;
      params.guestCheckinId = guestCheckinId;
      params.searchKey = null;
      params.pageSize = 20;
      params.transactionStatus = null;
      transactionRequest.request(params);
    },
    [transactionRequest]
  );
  // console.log(mappedGuestCheckin);
  return loadingGuestCheckinInfo ? (
    <GuestDetailsSkeleton />
  ) : (
    <ModuleWrapper
      error={errorCheckInInfo}
      header={
        <GuestDetailsHeader
          mappedData={mappedGuestCheckinInfo}
          mappedGuestCheckin={mappedGuestCheckin}
          loading={loadingGuestCheckinInfo}
        />
      }
    >
      <div>
        <GuestCheckinInfo
          guestId={id}
          venueId={venueId}
          loading={loadingGuestCheckinInfo || guestCheckinLoading}
          mappedData={mappedGuestCheckinInfo}
          guestCheckinIds={mappedGuestCheckinInfo.guestCheckinIds}
          setSelectedGuestCheckinId={async (id) => {
            fetchGuestApis(id);
            setSelectedGuestCheckinId(id);
          }}
          selectedGuestCheckinId={selectedGuestCheckinId}
          mappedGuestCheckin={mappedGuestCheckin}
          lastSyncedDate={transactionRequest.mappedData[0]?.deviceCreatedDate}
          onForceCheckout={() => {
            fetchGuestApis(selectedGuestCheckinId);
          }}
          error={guestCheckInError}
        />
      </div>
      <GuestRecentTransaction
        guestCheckinId={selectedGuestCheckinId}
        venueId={venueId}
        loading={transactionLoading}
        mappedData={transactionRequest.mappedData}
      />
      <GuestTagInfo
        guestId={id}
        venueId={venueId}
        loading={loadingGuestCheckinInfo || guestCheckinLoading}
        mappedGuestCheckin={mappedGuestCheckin}
        selectedGuestCheckinId={selectedGuestCheckinId}
        setSelectedGuestCheckinId={(checkInId) => {
          setSelectedGuestCheckinId(checkInId);
          fetchTransactions(requestState, checkInId);
        }}
        error={guestCheckInError || errorCheckInInfo}
      ></GuestTagInfo>
    </ModuleWrapper>
  );
};

export default GuestDetails;
