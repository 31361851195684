import React, { useCallback, useContext, useMemo } from "react";
import { HeaderB } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { FragmentA } from "components/fragments";
import {
  Field,
  Panel,
  ActionButton,
  Toast,
  Form,
  Skeleton,
  InputCounter,
  Title,
  CheckboxField,
  InputMoney,
  Text,
  Tooltip,
  Icon,
  ButtonLink,
} from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { useForm, useModal, useRouter } from "hooks";
import initialFormState from "./guest-profile.form-state";
import { VenueContext } from "contexts";
import SelectQuestion from "../../setting/question-setting/select-question/select-question";
import BrowseVoucher from "../../voucher/browse-voucher/browse-voucher.module";
import CreditsInfoModal from "components/modals/credits-info-modal/credits-info-modal";
import { mixpanel, TrackEvent } from "mixpanel";
import { useFlags } from "launchdarkly-react-client-sdk";

const GuestProfileForm = ({
  submit,
  title,
  submitting,
  initialState = undefined,
  deleteButton,
  loading,
  error,
  isCreate,
}) => {
  const { venue } = useContext(VenueContext);
  const { history } = useRouter();
  const { venueId } = venue;
  const { guestProfileAmount } = useFlags();

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const unsaveChangesModal = useModal();
  const creditsInfoModal = useModal();

  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, dirty, modifyForm } =
    useForm({
      initialState: formState,
    });

  const goToList = useCallback(() => {
    history.push(Path.GUEST_PROFILE);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, unsaveChangesModal, goToList]);

  const handleSubmit = useCallback(async () => {
    const params = getFormValues();

    const sortVoucherId = {};
    params.sortQuestions.forEach((q, index) => {
      sortVoucherId[q.id] = index + 1;
    });

    params.customQuestions = Object.keys(params.customQuestionIncludes).map((key) => {
      return {
        questionId: key,
        required:
          params.customQuestionsRequired.hasOwnProperty(key) &&
          Boolean(params.customQuestionsRequired[key]),
        sort: sortVoucherId[key],
      };
    });
    [
      "firstNameRequired",
      "lastNameRequired",
      "emailRequired",
      "mobileRequired",
      "birthdayRequired",
      "genderRequired",
    ].forEach((key) => {
      params[key] = params.questions.hasOwnProperty(key);
    });
    params.topupCreditLimited = false;
    params.topupCreditLimit = 0;

    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: "Save Guest Profile Form",
        Page: lang.guestProfileList,
      });

      const res = await submit({ ...params, venueId });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.GUEST_PROFILE);
    } catch ({ code, handleError, metadata }) {
      const err = {
        3073: () => {
          applyFieldErrors({
            name: lang.profileNameAlreadyExists,
          });
        },
        3068: () => {
          applyFieldErrors({
            name: lang.profileNameAlreadyExists,
          });
        },
        3064: async () => {
          const { issuanceLimit, voucherId } = metadata;
          const vouchersValue = fields.vouchers.value;
          modifyField("vouchers", {
            value: vouchersValue.map(({ value, quantity, name }) => {
              return {
                value,
                quantity,
                name,
                error: value === voucherId ? true : false,
                message:
                  value === voucherId
                    ? lang.populate(lang.voucherReachedIssuanceLimit, [issuanceLimit])
                    : null,
                limit: value === voucherId ? issuanceLimit : null,
              };
            }),
          });
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
    // eslint-disable-next-line
  }, [submit, getFormValues, venueId, history, applyFieldErrors]);

  const submitFormValue = () => {
    submitForm(handleSubmit);
  };

  const showCreditsInfoModal = useCallback(() => {
    creditsInfoModal.show();
  }, [creditsInfoModal]);

  const additionalLeftContent = useMemo(() => {
    return (
      <Text sm color="text-blue" className="cursor-pointer mt-sm">
        <span
          className="text-sm"
          onClick={() => {
            showCreditsInfoModal();
          }}
        >
          {lang.whatAreFreePostpaidCredits}
        </span>
      </Text>
    );
  }, [showCreditsInfoModal]);

  return (
    <ModuleWrapper
      error={error}
      header={<HeaderB title={title} returnText={lang.guestProfiles} onClick={leavePage} />}
      loading={loading}
      loader={
        <FragmentA title={lang.basicInfo}>
          <Panel>
            <Skeleton />
          </Panel>
        </FragmentA>
      }
    >
      <CreditsInfoModal {...creditsInfoModal} />
      <Form unsaveChangesModal={unsaveChangesModal} onSubmit={submitFormValue} isPrompt={dirty}>
        <FragmentA title={lang.basicInfo}>
          <Panel>
            <Field className="mb-md pt-2" {...fields.name} noLabel>
              <Text className="text-xs font-semibold">{fields.name.label}</Text>
              <InputCounter
                {...fields.name}
                onChange={modifyField}
                labelHidden={true}
                customClass={
                  "bg-white border rounded border-solid border-white-darker px-md relative"
                }
              />
            </Field>
            <Text className="text-xs font-semibold pt-4">{fields.description.label}</Text>
            <InputCounter
              {...fields.description}
              onChange={modifyField}
              textarea
              labelHidden={true}
              // customClass={
              //   "bg-white border rounded border-solid border-white-darker pt-md px-md relative"
              // }
            />

            {guestProfileAmount && (
              <>
                <Text className="text-xs font-semibold pt-6">{fields.amount.label}</Text>
                <Field {...fields.amount} noLabel>
                  <InputMoney
                    {...fields.amount}
                    onChange={modifyField}
                    right={false}
                    limit={10000000}
                  />
                </Field>
              </>
            )}
            <Text className="text-xs text-gray-light">{fields.amount.description}</Text>
          </Panel>
        </FragmentA>
        <FragmentA title={lang.voucher} description={lang.assignVoucherToGuest}>
          <Panel>
            <Title className="mb-md">{lang.preloadedVouchers}</Title>
            <BrowseVoucher
              {...fields.vouchers}
              value={fields.vouchers.value}
              dirty={fields.vouchers.dirty}
              onChange={(value) => {
                modifyField("vouchers", {
                  value,
                });
              }}
            />
          </Panel>
        </FragmentA>
        <FragmentA
          title={lang.credits}
          description={lang.creditsDescription}
          additionalLeftContent={additionalLeftContent}
        >
          <Panel>
            <div className="flex">
              <Title className="mb-md">{lang.freeCredits}</Title>
              <Tooltip title={lang.freeCreditsHoverTooltip}>
                <div>
                  <Icon name="info" className="text-xs text-gray" />
                </div>
              </Tooltip>
            </div>

            <Field width="w-1/5" {...fields.freeCredit}>
              <InputMoney
                {...fields.freeCredit}
                onChange={modifyField}
                limit={10000000}
                noDecimal
              />
            </Field>
          </Panel>
          <Panel>
            <Title className="mb-md">{lang.postpaidCredits}</Title>
            <CheckboxField
              textSize="text-sm"
              name="isAllowedPostLimit"
              value={fields.isAllowedPostLimit.value}
              onChange={(name, { value }) => {
                modifyForm({
                  [name]: {
                    value,
                  },
                  limit: {
                    value: 0,
                  },
                });
              }}
            >
              {fields.isAllowedPostLimit.label}
            </CheckboxField>
            {fields.isAllowedPostLimit.value && (
              <Field
                {...fields.limit}
                customLabel={
                  <div className="flex items-center">
                    <Text label>{lang.limit}</Text>
                    <Tooltip title={lang.setAnAmountToLimitPostPaid}>
                      <div>
                        <Icon name="info" className="text-xs text-gray" />
                      </div>
                    </Tooltip>
                  </div>
                }
                width="w-1/5"
                className="mt-md"
              >
                <InputMoney {...fields.limit} onChange={modifyField} limit={10000000} noDecimal />
              </Field>
            )}
          </Panel>
        </FragmentA>
        <FragmentA title={lang.guestQuestions} description={lang.informationToCollect}>
          <Panel>
            <div className="mb-md flex justify-between">
              <Title>{lang.questions}</Title>
              <ButtonLink
                className="text-left"
                suffix
                icon="arrow-diagonal-right"
                newTabPath={Path.QUESTION_SETTING}
              >
                {lang.editQuestions}
              </ButtonLink>
            </div>
            <CheckboxField
              {...fields.isAskQuestion}
              textSize="text-sm"
              onChange={(name, { value }) => {
                modifyForm({
                  [name]: {
                    value,
                  },
                });
              }}
            >
              {lang.askGuestInfoCheckIn}
            </CheckboxField>
            {fields.isAskQuestion.value && (
              <SelectQuestion
                defaultQuestionField={fields.questions}
                customQuestionRequiredField={fields.customQuestionsRequired}
                customQuestionIncludesField={fields.customQuestionIncludes}
                onChangeDefaultQuestion={(value) => {
                  modifyField("questions", { value });
                }}
                onChangeCustomQuestionIncludesField={(value) => {
                  modifyField("customQuestionIncludes", { value });
                }}
                onChangeCustomQuestionRequiredField={(value) => {
                  modifyField("customQuestionsRequired", { value });
                }}
                venueId={venueId}
                sortQuestions={fields.sortQuestions.value}
                setSortQuestions={(value, dirty = true) => {
                  modifyField("sortQuestions", { value, dirty });
                }}
                isCreate={isCreate}
                modifyForm={modifyForm}
              />
            )}
          </Panel>
        </FragmentA>
        <ActionButton
          showLine
          loading={submitting}
          primary={{
            onClick: () => {
              submitFormValue();
            },
            disabled: !dirty,
          }}
          secondary={{
            onClick: () => leavePage(),
            text: lang.cancel,
          }}
          danger={deleteButton}
        />
      </Form>
    </ModuleWrapper>
  );
};

export default GuestProfileForm;
