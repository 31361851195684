import React, { useMemo } from "react";
import { TabBar } from "components/commons";
import { useRouter } from "hooks";
import { Path } from "paths";
import lang from "translations";
import { HeaderA } from "components/headers";
import { ModuleWrapper } from "components/fragments";
import NotesGuideline from "./notes-guideline";
import List from "./list";

const DeviceList = () => {
  const { pathname } = useRouter();

  const isList = useMemo(() => Path.DEVICES === pathname, [pathname]);
  const isNotes = useMemo(() => Path.DEVICE_NOTES_GUIDE === pathname, [pathname]);

  return (
    <ModuleWrapper>
      <HeaderA title={lang.devices} description={lang.manageThePosDevicesVenue} className="mb-sm" />
      <TabBar
        className="mb-lg"
        items={[
          { to: Path.DEVICE_NOTES_GUIDE, text: lang.notesAndGuideline },
          { to: Path.DEVICES, text: lang.pointOfSale },
        ]}
      />
      {isList && <List />}
      {isNotes && <NotesGuideline />}
    </ModuleWrapper>
  );
};

export default DeviceList;
