import React, { useContext, useState } from "react";
import lang from "translations";
import { Icon, Skeleton, Text, Title } from "components";
import { MobileUpdatedVersionImg, MobilePushVenueDataImg, LampIcon } from "images";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { /*getDeviceTracking ,*/ getLastVersion } from "apis/device.api";
import { VenueContext } from "contexts";
import { useMount } from "hooks";
import Notes from "./notes";

const NotesGuideline = () => {
  const { venue } = useContext(VenueContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchDeviceTracking = async () => {
    try {
      setLoading(true);
      const data = await getLastVersion({ venueId: venue.venueId });
      setData(data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useMount(() => {
    fetchDeviceTracking();
  });

  if (loading) return <Skeleton />;
  return (
    <div>
      <Title className={"mb-4"} color="text-pelorous">
        {lang.updatedVersionOfTheAppIsNowAvailable}
      </Title>
      <Notes {...data} />
      <Guideline />
    </div>
  );
};

export default NotesGuideline;

const Guideline = ({ ...props }) => {
  return (
    <section className="mt-8 bg-green-100 rounded-lg px-6 py-3">
      <div className="flex gap-6 items-center">
        <div className="w-16">
          <img src={LampIcon} alt="" className="w-10 h-10 md:w-16 md:h-16" />
        </div>
        <div className="flex-1">
          <Title>{lang.guidelineDownloadOrUpdateApp}</Title>
          <Text size="text-md" color="text-pelorous-darker" fontWeight="font-bold">
            {lang.beforeUpdatingTheAppPleaseEnsureAllDataIsSyncedBy}
          </Text>
        </div>
      </div>
      <div className="flex mt-3">
        <div className="flex-1 flex flex-col justify-center items-center">
          <div className="rounded-full px-3 py-2 bg-green-400 text-white w-fit">{lang.step} 1</div>
          <Text className="my-4" size="text-md" color="text-black-darker">
            {lang.goToVenueTabAndTapOnPushVenueData}
          </Text>
          <img src={MobilePushVenueDataImg} alt="" className="w-56" />
        </div>
        <div className="flex-1 flex flex-col justify-center items-center">
          <ArrowForwardOutlinedIcon sx={{ fontSize: 80 }} />
        </div>
        <div className="flex-1 flex flex-col justify-center items-center">
          <div className="rounded-full px-3 py-2 bg-green-400 text-white w-fit">{lang.step} 2</div>
          <Text className="my-4" size="text-md" color="text-black-darker">
            {lang.goToVenueTabAndTapOnPushVenueData}
          </Text>
          <img src={MobileUpdatedVersionImg} alt="" className="w-56" />
        </div>
      </div>

      <ManuallyDownloadUpdate />
      <ForDevicesGgPlay />
    </section>
  );
};

const RoundedNumber = ({ text }) => {
  return (
    <div className="rounded-full bg-orange-dark w-9 h-9 flex justify-center items-center">
      <div className="rounded-full w-7 h-7 bg-white text-orange-dark font-bold text-md flex justify-center items-center">
        {text}
      </div>
    </div>
  );
};

const StepBox = ({ index, last = false, children }) => {
  return (
    <div className="flex">
      <div className="flex flex-col items-center h-auto w-13 min-w-13">
        {last && <Icon name="check" className={"text-orange-dark"} fontSize={36} paddingless />}
        {!last && (
          <>
            <RoundedNumber text={index} />
            <div className="w-0.5 flex-1 bg-orange-dark"></div>
          </>
        )}
      </div>
      <div className="mb-4">{children}</div>
    </div>
  );
};

const ManuallyDownloadUpdate = () => {
  return (
    <>
      <Text className="pt-3 pb-3" size="text-lg" fontWeight="font-bold">
        {lang.manuallyDownloadUpdatePouchApp}
      </Text>
      <StepBox index={1}>
        <div className="mt-1.5">
          <Text size="text-md" color="text-black-dark" fontWeight="font-bold">
            {lang.scanningTheQRcodeOrUsingUrl}
          </Text>
          <ul>
            <li>
              <span className="font-bold">{lang.scanningTheQRcode}:</span>
              {lang.scanningTheQRcodeDesc}
            </li>
            <li>
              <span className="font-bold">{lang.usingTheURL}:</span>
              {lang.usingTheURLdesc}
            </li>
          </ul>
        </div>
      </StepBox>
      <StepBox index={2}>
        <div className="mt-1.5">
          <Text size="text-md" color="text-black-dark" fontWeight="font-bold">
            {lang.onceYoureOnTheAppsPage}
          </Text>
          <div className="whitespace-pre">{lang.onceYoureOnTheAppsPageDesc}</div>
        </div>
      </StepBox>
      <StepBox last>
        <Text
          className="mt-1.5 italic"
          size="text-md"
          color="text-orange-dark"
          fontWeight="font-semibold"
        >
          {lang.followTheOnScreenInstructionsToCompleteTheDownloadAndInstallation}
        </Text>
      </StepBox>
    </>
  );
};

const ForDevicesGgPlay = () => {
  return (
    <>
      <Text className="pt-3 pb-3" size="text-lg" fontWeight="font-bold">
        {lang.forDevicesWithoutTheGooglePlayStore}
      </Text>
      <StepBox index={1}>
        <div className="mt-1.5">
          <Text size="text-md" color="text-black-dark" fontWeight="font-bold">
            {lang.ifYouHaveAPreviousVersionOfApp}
          </Text>
          <div>{lang.pleaseMakeSureToSyncAllDataBeforeDeleteApp}</div>
        </div>
      </StepBox>
      <StepBox index={2}>
        <div className="mt-1.5">
          <Text size="text-md" color="text-black-dark" fontWeight="font-bold">
            {lang.accessURLOrQRCode}
          </Text>
          <div>{lang.copyTheApkDownloadUrl}</div>
        </div>
      </StepBox>
      <StepBox index={3}>
        <div className="mt-1.5">
          <Text size="text-md" color="text-black-dark" fontWeight="font-bold">
            {lang.downloadAPK}
          </Text>
          <div>{lang.pasteUrlInDeviceBrowserAndDownload}</div>
        </div>
      </StepBox>
      <StepBox index={4}>
        <div className="mt-1.5">
          <Text size="text-md" color="text-black-dark" fontWeight="font-bold">
            {lang.allowInstallationFromUnknownSources}
          </Text>
          <div>{lang.allowInstallationFromUnknownSourcesDesc}</div>
        </div>
      </StepBox>
      <StepBox index={5}>
        <div className="mt-1.5">
          <Text size="text-md" color="text-black-dark" fontWeight="font-bold">
            {lang.locateAPK}
          </Text>
          <div> {lang.locateAPKDesc}</div>
        </div>
      </StepBox>
      <StepBox last>
        <Text
          className="mt-1.5 italic"
          size="text-md"
          color="text-orange-dark"
          fontWeight="font-semibold"
        >
          {lang.installAPK}
        </Text>
        <div> {lang.tapOnTheAPKFileToStartTheInstallationProcess}</div>
      </StepBox>
    </>
  );
};
