import React from "react";
import classnames from "classnames";
import styles from "./image.module.scss";

const Image = ({ className, src, alt, onClick, style }) => {
  return (
    <img
      className={classnames(className, styles.image)}
      onClick={onClick}
      src={src}
      alt={alt}
      style={style}
    />
  );
};

export default Image;
