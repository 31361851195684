import React from "react";
import { Title, Text, Button, ButtonsGroup } from "components/commons";
import { useRouter } from "hooks";
import classNames from "classnames";
import { mixpanel, TrackEvent } from "mixpanel";

const HeaderA = ({ title, description, button, className, buttonsGroup }) => {
  const { history } = useRouter();
  const {
    path,
    text,
    validate,
    disabled: primaryDisabled = false,
    loading: primaryLoading = false,
    onClick,
    iconPrefix,
  } = button || {};
  const { options, disabled, loading, handleClick } = buttonsGroup || {};
  return (
    <div className={classNames(className, "md:flex items-center justify-between")}>
      <div>
        <Title xl className="font-semibold">
          {title}
        </Title>
        {description && <Text>{description}</Text>}
      </div>
      <div className="flex justify-end mt-2 md:mt-0">
        {button && (
          <Button
            loading={primaryLoading}
            disabled={primaryDisabled || primaryLoading}
            onClick={
              onClick
                ? () => {
                    mixpanel.track(TrackEvent.ClickedButton, {
                      button: text,
                    });
                    onClick();
                  }
                : () => {
                    mixpanel.track(TrackEvent.ClickedButton, {
                      button: text,
                    });
                    const validated = validate ? validate() : true;
                    if (validated) {
                      history.push(path);
                    }
                  }
            }
            iconPrefix={iconPrefix}
          >
            {text}
          </Button>
        )}
        {buttonsGroup && (
          <ButtonsGroup
            options={options}
            loading={loading}
            disabled={disabled}
            handleClick={handleClick}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderA;
