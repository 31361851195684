import { profilePaths } from "./profile.path";
import { authPaths } from "./auth.path";
import venuePaths from "./inventory/venue.path";
import guestPaths from "./inventory/guest.path";
import locationPaths from "./inventory/location.path";
import discountPaths from "./inventory/discount.path";
import voucherPaths from "./inventory/voucher.path";
import transactionPaths from "./inventory/transaction.path";
import productPaths from "./inventory/product.path";
import productSkuPaths from "./inventory/product-sku.path";
import devicePaths from "./inventory/device.path";
import categoryPaths from "./inventory/category.path";
import shiftPaths from "./inventory/shift.path";
import staffPaths from "./inventory/staff.path";
import taxPaths from "./inventory/tax.path";
import printerPaths from "./inventory/printer.path";
import reportPaths from "./inventory/report.path";
import supplyItemPaths from "./inventory/supply-item.path";
import guestCheckinPaths from "./inventory/guest-checkin.path";
import bookingPaths from "./inventory/booking.path";
import stocktakePaths from "./inventory/stocktake.path";
import additionalChargePaths from "./inventory/additional-charge.path";
import multiPropertyPaths from "./inventory/multi-property.path";
import tipPaths from "./inventory/tip.path";
import freeCreditsPaths from "./inventory/free-credits.path";
import accessPointPaths from "./inventory/access-point";
import importProcessPaths from "./inventory/import-process.path";
import siigoPaths from "./siigo.path";

const preparePaths = ({ prefix, paths }) => {
  let newPaths = {};

  for (const [k, path] of Object.entries(paths)) {
    if (typeof path === "function") {
      newPaths[k] = (id) => `${prefix}/${path(id)}`.replace(/\/+/g, "/");
    } else if (typeof path === "string") {
      if (path.length > 0) {
        newPaths[k] = `${prefix}/${path}`.replace(/\/+/g, "/");
      } else {
        newPaths[k] = `${prefix}`.replace(/\/+/g, "/");
      }
    } else {
      newPaths[k] = path;
    }
  }
  return newPaths;
};

const ApiPath = {
  ...preparePaths({ prefix: "auth", paths: authPaths }),
  ...preparePaths({ prefix: "profile", paths: profilePaths }),
  ...preparePaths({
    prefix: "storage",
    paths: {
      STORAGE_UPLOAD: "upload",
    },
  }),
  ...preparePaths({ prefix: "inventory/venue", paths: venuePaths }),
  ...preparePaths({
    prefix: "inventory/profile",
    paths: { PROFILE_ACTIVATE_STAFF: "activate-staff" },
  }),
  STAFFS: "inventory/profile/staffs",
  ...preparePaths({ prefix: "inventory/profile/staff", paths: staffPaths }),
  ...preparePaths({ prefix: "inventory/product", paths: productPaths }),
  ...preparePaths({ prefix: "inventory/product-sku", paths: productSkuPaths }),
  ...preparePaths({ prefix: "inventory/supply-item", paths: supplyItemPaths }),
  ...preparePaths({ prefix: "inventory/stocktake", paths: stocktakePaths }),
  ...preparePaths({ prefix: "inventory/guest", paths: guestPaths }),
  ...preparePaths({ prefix: "inventory/guest-checkin", paths: guestCheckinPaths }),
  ...preparePaths({ prefix: "inventory/booking", paths: bookingPaths }),
  ...preparePaths({ prefix: "inventory/location", paths: locationPaths }),
  ...preparePaths({ prefix: "inventory/discount", paths: discountPaths }),
  ...preparePaths({ prefix: "inventory/voucher", paths: voucherPaths }),
  ...preparePaths({ prefix: "inventory/transaction", paths: transactionPaths }),
  ...preparePaths({ prefix: "inventory/device", paths: devicePaths }),
  ...preparePaths({ prefix: "inventory/category", paths: categoryPaths }),
  ...preparePaths({ prefix: "inventory/shift", paths: shiftPaths }),
  ...preparePaths({ prefix: "inventory/printer", paths: printerPaths }),
  ...preparePaths({ prefix: "inventory/report", paths: reportPaths }),
  ...preparePaths({ prefix: "inventory/import-process", paths: importProcessPaths }),
  SEARCH_STOCK_LOW_QUANTITY: "inventory/location-stock/out-of-stock",
  ...preparePaths({ prefix: "inventory/tax", paths: taxPaths }),
  ...preparePaths({ prefix: "inventory/additional-charge", paths: additionalChargePaths }),
  ...preparePaths({ prefix: "inventory/tip", paths: tipPaths }),
  ...preparePaths({ prefix: "inventory/free-credits", paths: freeCreditsPaths }),
  SEARCH_CURRENCY_LIST: "inventory/currency/search",
  ...preparePaths({
    prefix: "inventory/payment-method",
    paths: {
      PAYMENT_METHOD: "",
      SEARCH_PAYMENT_METHOD: "search",
      PAYMENT_METHOD_ID: (id) => id,
    },
  }),
  ...preparePaths({
    prefix: "inventory/question",
    paths: {
      QUESTION: "",
      QUESTION_ID: (id) => id,
    },
  }),
  ...preparePaths({
    prefix: "inventory/guest-profile",
    paths: {
      SEARCH_GUEST_PROFILE: "/search",
      GUEST_PROFILE: "",
      GUEST_PROFILE_ID: (id) => id,
    },
  }),
  ...preparePaths({
    prefix: "inventory/banned-tag",
    paths: {
      CREATE_BAN_TAG: "",
      SEARCH_BAN_TAG: "search",
      SEARCH_BAN_TAG_FOR_BANNING: "search/banned-tag",
      DELETE_BAN_TAG: (id) => id,
    },
  }),
  ...preparePaths({
    prefix: "inventory/notification-profile",
    paths: {
      NOTIFICATION_STOCK_LEVEL: "",
      SEARCH_NOTIFICATION_STOCK_LEVEL: "search",
      NOTIFICATION_STOCK_LEVEL_ID: (id) => id,
    },
  }),
  ...preparePaths({
    prefix: "inventory/staff-profile",
    paths: {
      SEARCH_STAFF_PROFILE: "/search",
      STAFF_PROFILE: "",
      STAFF_PROFILE_ID: (id) => id,
    },
  }),
  ...preparePaths({
    prefix: "inventory/measurement",
    paths: {
      GET_MEASUREMENTS: (id) => `/venue/${id}`,
    },
  }),
  ...preparePaths({
    prefix: "inventory/integration",
    paths: {
      BOOKING_CHECK: "booking-check",
      MEWS_INTEGRATION: "mews/activation",
      MEWS_ACTIVATE: "mews/activate",
      MEWS_DEACTIVATE: ({ id, integrationType, venueId }) =>
        `mews/activation/${id}/deactivate?integrationType=${integrationType}&venueId=${venueId}`,
      CLOUDBEDS_ACTIVATE: "cloudbeds/activate",
      PURCHASE_PLUS_ACTIVATE: "purchase-plus/activate",
    },
  }),
  ...preparePaths({
    prefix: "inventory/application",
    paths: {
      SEARCH_APPLICATIONS: "/search",
      ACTIVATE_APPLICATION: (id) => `${id}/activate`,
      DEACTIVATE_APPLICATION: (id) => `${id}/deactivate`,
      SEARCH_APPLICATION_ACTIVATIONS: "/search/activations",
    },
  }),
  ...preparePaths({
    prefix: "inventory/account-codes",
    paths: {
      ACCOUNT_CODES: "",
    },
  }),
  ...preparePaths({
    prefix: "inventory/account-mappings",
    paths: {
      ACCOUNT_MAPPINGS: "",
    },
  }),
  ...preparePaths({
    prefix: "inventory/end-of-day",
    paths: {
      SEARCH_END_OF_DAY_REPORT: "/search",
      RUN_END_OF_DAY: (eodId) => `${eodId}/run`,
      END_OF_DAY_REPORT_SUMMARY: "/summary",
      END_OF_DAY_ID: (eodId) => eodId,
      EOD_UPDATE_INCLUDED_SHIFT: ({ eodId, shiftId }) => `${eodId}/shift/${shiftId}`,
      END_OF_DAY_ID_INCLUDED_VENUE: ({ eodReportId, venueId }) => `${eodReportId}/venue/${venueId}`,
    },
  }),
  ...preparePaths({
    prefix: "inventory/outlet",
    paths: {
      MEWS_OUTLET: (venueId) => `?venueId=${venueId}`,
    },
  }),
  ...preparePaths({
    prefix: "inventory/mews-service",
    paths: {
      MEWS_SERVICE: (venueId) => `?venueId=${venueId}`,
    },
  }),
  ...preparePaths({ prefix: "inventory/venue-group", paths: multiPropertyPaths }),
  ...preparePaths({ prefix: "inventory/access-point", paths: accessPointPaths }),
  ACCESS_POINT_SEARCH_REPORT: "access-point/access-point-action/graph/unique-attendee",
  ACCESS_POINT_EXPORT_REPORT: "access-point/access-point-action/export",
  // siigo paths
  ...preparePaths({ prefix: "invoice", paths: siigoPaths }),
};

export default ApiPath;
