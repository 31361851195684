import { getLocations, searchProduct } from "apis";
import { HeaderB } from "components/headers";
import { VenueContext } from "contexts";
import { useApi, useForm, useModal, useMount, useRouter } from "hooks";
import {
  browseProductCategoryListRequest,
  browseProductCategoryListResponse,
  location,
} from "mappers";
import { Path } from "paths";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import initialFormState from "./discount-form.state";
import lang from "translations";
import { mapObjectsToSelect } from "services";
import { FragmentA, ModuleWrapper } from "components/fragments";
import { BrowseProductModal, NoLocationModal } from "components/modals";
import {
  ActionButton,
  Button,
  ButtonLink,
  CheckboxField,
  DatePicker,
  Field,
  FieldWithTitle,
  Form,
  Icon,
  Input,
  InputCounter,
  InputMoney,
  MultipleSelect,
  PageError,
  Panel,
  RadioGroup,
  RangePicker,
  Skeleton,
  Switch,
  Text,
  TimePicker,
  Title,
  Toast,
} from "components/commons";
import { DiscountType, StyleType } from "enums";
import styles from "./discount-form.module.scss";
import classnames from "classnames";
import { SearchOutlined, PercentageOutlined } from "@ant-design/icons";
import Validation from "services/validation.service";
import { mixpanel, TrackEvent } from "mixpanel";
import { CategoryProductsTable } from "components";
import { useFlags } from "launchdarkly-react-client-sdk";

const DiscountForm = ({
  title,
  initialState,
  error,
  submitting,
  loading,
  submit,
  linkedProductsMap,
  deleteButton,
}) => {
  const { history } = useRouter();
  const [discountableProducts, setDiscountableProducts] = useState();
  const [requiredDiscountableProducts, setRequiredDiscountableProducts] = useState();
  const [browseProductDisabled, setBrowseProductDisabled] = useState(false);
  const discountNameElement = useRef(null);
  const { redeemableProductsOption } = useFlags();

  const { venue } = useContext(VenueContext);

  const unsaveChangesModal = useModal();
  const browseProductModal = useModal();
  const noLocationModal = useModal();

  const {
    request: getLocationsRequest,
    loading: loadingLocation,
    mappedData: locations,
  } = useApi({
    api: getLocations,
    isArray: true,
    mapper: location,
  });

  const {
    request: searchProductRequest,
    loading: loadingProduct,
    // mappedData,
  } = useApi({
    api: searchProduct,
    isArray: true,
    mapper: browseProductCategoryListResponse,
    paramsMapper: browseProductCategoryListRequest,
  });

  useMount(() => {
    fetchLocations();
    fetchProducts([]);
  });

  const fetchLocations = useCallback(async () => {
    const l = await getLocationsRequest({ venueId: venue.venueId });
    if (l && l.data.length === 0) {
      noLocationModal.show();
    }
  }, [getLocationsRequest, venue.venueId, noLocationModal]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  useEffect(() => {
    if (linkedProductsMap) setDiscountableProducts(linkedProductsMap);
  }, [linkedProductsMap]);

  const { fields, modifyField, modifyForm, submitForm, getFormValues, applyFieldErrors, dirty } =
    useForm({
      initialState: formState,
    });

  const fetchProducts = useCallback(
    async (value) => {
      const res = await searchProductRequest({
        venueId: venue.venueId,
        locations: value,
        searchKey: "",
      });

      setBrowseProductDisabled(res.data && res.data.length === 0);
    },
    [searchProductRequest, venue]
  );

  // const browseProductDisabled = useMemo(() => {
  //   return !mappedData.length || loadingProduct || loadingLocation || !fields.locations.value;
  // }, [mappedData, loadingLocation, loadingProduct, fields.locations]);

  const removeDiscountableCategory = useCallback(
    (categoryName) => {
      if (discountableProducts.has(categoryName)) {
        discountableProducts.delete(categoryName);
      }
      const newDiscountableProducts = new Map(discountableProducts);
      setDiscountableProducts(newDiscountableProducts);
      modifyField("productSkuIds", { value: getSelectedProductSkuIds(newDiscountableProducts) });
    },
    [discountableProducts, modifyField]
  );

  const removeDiscountableProduct = useCallback(
    (productName) => {
      discountableProducts.forEach((productMap) => {
        if (productMap.has(productName)) {
          productMap.delete(productName);
        }
      });
      const newDiscountableProducts = new Map(discountableProducts);
      setDiscountableProducts(newDiscountableProducts);
      modifyField("productSkuIds", { value: getSelectedProductSkuIds(newDiscountableProducts) });
    },
    [discountableProducts, modifyField]
  );

  const goToList = useCallback(() => {
    history.push(Path.DISCOUNT);
  }, [history]);

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          goToList();
          unsaveChangesModal.close();
        },
      });
      return;
    }
    goToList();
  }, [dirty, goToList, unsaveChangesModal]);

  const showBrowseProductCb = useCallback(() => {
    browseProductModal.show();
  }, [browseProductModal]);

  const locationOptions = useMemo(() => {
    return mapObjectsToSelect(locations, { textKey: "name" });
  }, [locations]);

  const discountTypeCb = useCallback(
    (type) => {
      modifyForm({
        type: { value: type },
        value: {
          value: 0,
          validations:
            type === DiscountType.Percentage
              ? [
                  Validation.required(),
                  Validation.number(),
                  Validation.rangeValue(1, 100, lang.percentageBetween),
                ]
              : [
                  Validation.required(),
                  Validation.number(),
                  Validation.rangeValue(1, 9999999, lang.fixedAmountRange),
                ],
        },
      });
    },
    [modifyForm]
  );

  const availableAnytimeCb = useCallback(
    (name, { value }) => {
      if (value === true) {
        modifyForm({
          availableAnytime: { value },
          dateRange: {
            value: [
              fields.dateRange.value[0].clone().startOf("day"),
              fields.dateRange.value[1].clone().endOf("day"),
            ],
          },
        });
      } else {
        modifyField("availableAnytime", { value });
      }
    },
    [fields.dateRange.value, modifyField, modifyForm]
  );

  const changeDatePickerCb = useCallback(
    (name, value) => {
      modifyField("dateRange", { value: [value, fields.dateRange.value[1]] });
    },
    [modifyField, fields.dateRange.value]
  );

  const changeDateRangeCb = useCallback(
    (name, value) => {
      modifyField(name, { value });
    },
    [modifyField]
  );

  const handleSubmit = useCallback(async () => {
    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: lang.saveDiscountForm,
        Page: lang.createDiscountPage,
      });

      const formValues = getFormValues();

      if (formValues?.productSkuIds.length === 0) {
        setRequiredDiscountableProducts(true);
        return;
      }

      if (formValues.locations.length === 0) {
        formValues.locations = locationOptions;
      }

      const res = await submit({ ...formValues });
      Toast({
        content: res.message,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.DISCOUNT);
    } catch ({ code, handleError }) {
      const err = {
        3075: () => {
          applyFieldErrors({
            name: lang.discountNameAlreadyExist,
          });
          if (discountNameElement.current != null) {
            discountNameElement.current.focus();
          }
        },
      };
      if (err[code]) {
        err[code]();
      } else {
        handleError();
      }
    }
  }, [applyFieldErrors, history, submit, getFormValues, locationOptions]);

  const submitFormCb = useCallback(() => {
    submitForm(handleSubmit);
  }, [handleSubmit, submitForm]);

  const handleEditLocations = () => {
    const win = window.open("/location", "_blank");
    win.focus();
  };

  return (
    <ModuleWrapper width="medium">
      <HeaderB title={title} returnText={lang.discounts} onClick={leavePage} />
      <BrowseProductModal
        {...browseProductModal}
        preSelectedSkus={fields.productSkuIds.value}
        locations={fields.locations.value}
        onAdd={(v) => {
          setDiscountableProducts(v);
          setRequiredDiscountableProducts(false);
          modifyField("productSkuIds", { value: getSelectedProductSkuIds(v) });
        }}
      />
      <NoLocationModal content={lang.toContinueCreatingDiscount} {...noLocationModal} />
      <Form unsaveChangesModal={unsaveChangesModal} isPrompt={dirty}>
        {error ? (
          <PageError />
        ) : (
          <Fragment>
            <FragmentA title={lang.basicInfo}>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <Fragment>
                    <FieldWithTitle className="mb-md" {...fields.posStatus}>
                      <Switch {...fields.posStatus} onChange={modifyField} />
                    </FieldWithTitle>
                    <Field className="mb-md" {...fields.name} noLabel>
                      <InputCounter
                        {...fields.name}
                        onChange={modifyField}
                        reference={discountNameElement}
                      />
                    </Field>
                    <Field>
                      <InputCounter {...fields.description} onChange={modifyField} />
                    </Field>
                  </Fragment>
                )}
              </Panel>
            </FragmentA>
            <FragmentA title={lang.location} description={lang.locationVoucherRedeemed}>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <Fragment>
                    <Field className="mb-md" {...fields.locations} noLabel>
                      <div className="flex justify-between pb-md">
                        <Text strong size="text-sm">
                          {lang.location}
                        </Text>
                        <ButtonLink size="text-sm" onClick={handleEditLocations}>
                          {lang.editLocations}{" "}
                          <Icon name="arrow-diagonal-right" className="text-blue text-xxs ml-xs" />
                        </ButtonLink>
                      </div>
                      <MultipleSelect
                        {...fields.locations}
                        name="locations"
                        selectAllText={lang.allLocations}
                        loading={loadingLocation}
                        options={locationOptions}
                        onChange={(name, obj) => {
                          modifyField(name, { ...obj, value: obj.value });
                          fetchProducts(obj.value || []);
                        }}
                        placeholder={
                          !locationOptions.length ? lang.noLocationAvailable : lang.selectLocation
                        }
                      />
                    </Field>
                  </Fragment>
                )}
              </Panel>
            </FragmentA>
            <FragmentA
              title={lang.discountableProducts}
              description={lang.chooseProductsCanBeDiscounted}
            >
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <div>
                    {redeemableProductsOption && (
                      <div className="mb-md">
                        <RadioGroup
                          title={lang.selectRedeemableProducts}
                          options={[
                            { text: lang.allProducts, value: false, disabled: true },
                            { text: lang.specificCategories, value: false, disabled: true },
                            { text: lang.specificProducts, value: "specific-products" },
                          ]}
                          value="specific-products"
                        />
                      </div>
                    )}
                    {/* <MultipleSelect {...fields.categories} /> */}
                    <div
                      className="flex"
                      onClick={() => {
                        if (!browseProductDisabled) {
                          mixpanel.track(TrackEvent.ClickedButton, {
                            Button: lang.browseProducts,
                            Page: lang.createDiscountPage,
                          });
                          showBrowseProductCb();
                        }
                      }}
                    >
                      <Input
                        disabled={loadingProduct || browseProductDisabled}
                        className={classnames("rounded-r-none", styles.browseInput)}
                        placeholder={
                          browseProductDisabled
                            ? lang.noProductsAvailable
                            : lang.selectCategoriesOrProducts
                        }
                        iconPrefix={<SearchOutlined className="mr-sm" />}
                        readOnly
                      />
                      <Button
                        loading={loadingProduct}
                        disabled={loadingProduct || browseProductDisabled}
                        type={StyleType.Secondary}
                        className={classnames(
                          "rounded-l-none bg-gradient-to-b from-white to-white-dark border-l-0 border-white-darker",
                          styles.browseButton
                        )}
                      >
                        {lang.browseProducts}
                      </Button>
                    </div>
                    <CategoryProductsTable
                      linkedProducts={discountableProducts}
                      required={requiredDiscountableProducts}
                      removeProduct={removeDiscountableProduct}
                      removeCategory={removeDiscountableCategory}
                    />
                  </div>
                )}
              </Panel>
            </FragmentA>
            <FragmentA>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <div>
                    <RadioGroup
                      title={lang.discountType}
                      options={[
                        { text: lang.percentage, value: DiscountType.Percentage },
                        { text: lang.fixedAmount, value: DiscountType.Fixed },
                      ]}
                      value={fields.type.value}
                      onChange={discountTypeCb}
                    />
                    <Field {...fields.value}>
                      {fields.type.value === DiscountType.Percentage ? (
                        <Input
                          name="value"
                          width="w-64"
                          right
                          {...fields.value}
                          onChange={modifyField}
                          iconSuffix={<PercentageOutlined className="ml-sm" />}
                        />
                      ) : (
                        <InputMoney
                          width="w-64"
                          name="value"
                          {...fields.value}
                          onChange={modifyField}
                        />
                      )}
                    </Field>
                  </div>
                )}
              </Panel>
            </FragmentA>
            <FragmentA>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <div>
                    <Title md className="mb-md">
                      {lang.managerAuthorization}
                    </Title>
                    <CheckboxField
                      {...fields.managerAuthorization}
                      onChange={modifyField}
                      textSize="text-sm"
                    >
                      {lang.requireManagerAuthorization}
                    </CheckboxField>
                  </div>
                )}
              </Panel>
            </FragmentA>
            <FragmentA>
              <Panel>
                {loading ? (
                  <Skeleton width="100%" active />
                ) : (
                  <div>
                    <Title md className="mb-md">
                      {lang.activeDateRange}
                    </Title>
                    <Field className="mb-md" label={lang.dateRange}>
                      {fields.noEndDate.value ? (
                        <DatePicker
                          value={fields.dateRange.value[0]}
                          onChange={changeDatePickerCb}
                        />
                      ) : (
                        <RangePicker {...fields.dateRange} onChange={changeDateRangeCb} />
                      )}
                    </Field>
                    <CheckboxField
                      className="mb-md"
                      textSize="text-sm"
                      name="noEndDate"
                      value={fields.noEndDate.value}
                      onChange={modifyField}
                    >
                      {lang.noEndDate}
                    </CheckboxField>
                    <Field className="mb-md" label={lang.timeRange}>
                      <div className="flex items-center">
                        <TimePicker
                          disabled={fields.availableAnytime.value}
                          value={fields.dateRange.value[0]}
                          onChange={(startTime) => {
                            modifyField("dateRange", {
                              value: [
                                fields.dateRange.value[0].set({
                                  hour: startTime.get("hour"),
                                  minute: startTime.get("minute"),
                                }),
                                fields.dateRange.value[1],
                              ],
                            });
                          }}
                        />
                        <Text className="mx-sm" color="text-black-light">
                          to
                        </Text>
                        <TimePicker
                          disabled={fields.availableAnytime.value}
                          value={fields.dateRange.value[1]}
                          onChange={(endTime) => {
                            modifyField("dateRange", {
                              value: [
                                fields.dateRange.value[0],
                                fields.dateRange.value[1].set({
                                  hour: endTime.get("hour"),
                                  minute: endTime.get("minute"),
                                }),
                              ],
                            });
                          }}
                        />
                      </div>
                    </Field>
                    <CheckboxField
                      textSize="text-sm"
                      value={fields.availableAnytime.value}
                      onChange={availableAnytimeCb}
                    >
                      {lang.availableAnytime}
                    </CheckboxField>
                  </div>
                )}
              </Panel>
            </FragmentA>
          </Fragment>
        )}
        <ActionButton
          showLine
          loading={submitting}
          primary={{
            onClick: () => {
              submitFormCb();
            },
          }}
          secondary={{
            onClick: () => leavePage(),
          }}
          danger={deleteButton}
        />
      </Form>
    </ModuleWrapper>
  );
};

const getSelectedProductSkuIds = (v) => {
  const selectedProductSkusSet = new Set();

  v.forEach((productMap) => {
    productMap.forEach((productSkuIds) => {
      productSkuIds.forEach((pskuId) => {
        selectedProductSkusSet.add(pskuId);
      });
    });
  });

  return [...selectedProductSkusSet];
};

export default DiscountForm;
