import React, { useCallback, useMemo, useContext, useState } from "react";
import { VenueContext } from "contexts";
import { useRouter, useMount, useApi, useModal, useForm } from "hooks";
import { HeaderB, Container, Toast, Panel, Text, Icon, Form } from "components";
import lang from "translations";
import { Path } from "paths";
import ReceiptSettingForm from "./receipt-setting-form/receipt-setting-form";
import { getReceiptSettings, setReceiptSettings } from "apis";
import { receiptSettingResponse, receiptUpdateRequest } from "mappers";
import ReceiptSettingPreview from "./receipt-setting-preview/receipt-setting-preview";
import { mapObject } from "services";
import { Skeleton } from "antd";
import { StyleType } from "enums";
import { mixpanel, TrackEvent } from "mixpanel";
import { ReceiptNameOrder } from "enums";
import initialFormState from "./receipt-setting-form/receipt-setting.form-state";

const ReceiptSetting = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { history } = useRouter();
  const [dirty, setDirty] = useState();
  const [previewVisible, setPreviewVisible] = useState(true);
  const unsaveChangesModal = useModal();

  const {
    request: getReceiptSettingsData,
    mappedData,
    loading,
  } = useApi({
    api: getReceiptSettings,
    params: {
      venueId,
    },
    isArray: true,
    mapper: receiptSettingResponse,
  });

  const { request: submit } = useApi({
    api: setReceiptSettings,
    params: {
      venueId,
    },
  });

  const initialState = useMemo(() => {
    var settings = {
      headerSettings: mappedData.filter((data) => {
        return data.placement === "HEADER";
      }),
      salesSettings: mappedData.filter((data) => {
        return data.placement === "SALES";
      }),
      footerSettings: mappedData.filter((data) => {
        return data.placement === "FOOTER";
      }),
    };

    settings.headerSettings.sort(
      (a, b) => ReceiptNameOrder.header[a.name] - ReceiptNameOrder.header[b.name]
    );
    settings.footerSettings.sort(
      (a, b) => ReceiptNameOrder.footer[a.name] - ReceiptNameOrder.footer[b.name]
    );
    settings.salesSettings.sort(
      (a, b) => ReceiptNameOrder.sales[a.name] - ReceiptNameOrder.sales[b.name]
    );

    return settings;
  }, [mappedData]);

  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);

  const { fields, modifyField, getFormValues, submitForm } = useForm({
    initialState: formState,
  });

  const onFieldChange = useCallback(
    (name, obj) => {
      if (!dirty) setDirty(true);
      modifyField(name, obj);
    },
    [dirty, modifyField]
  );

  const fetchReceiptSettings = useCallback(async () => {
    await getReceiptSettingsData({ venueId });
  }, [getReceiptSettingsData, venueId]);

  useMount(() => {
    fetchReceiptSettings();
    const isMobile = checkIfMobileView();
    setPreviewVisible(!isMobile);

    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.receiptSettings,
    });
  });

  const onSubmit = useCallback(async () => {
    const params = formatData(getFormValues());

    try {
      mixpanel.track(TrackEvent.ClickedButton, {
        Button: lang.saveReceiptSettings,
        Page: lang.receiptSettings,
      });
      const mappedData = params.map((p) => mapObject(p, receiptUpdateRequest));
      await submit(mappedData);
      Toast({
        content: lang.changesSaved,
        success: true,
        icon: "check",
      }).open();
      history.push(Path.SETTING);
    } catch (e) {
      throw e;
    }
  }, [getFormValues, history, submit]);

  const formatData = (params) => {
    const res = [];
    params.headerSettings.forEach((p) => {
      res.push(p);
    });
    params.footerSettings.forEach((p) => {
      res.push(p);
    });
    params.salesSettings.forEach((p) => {
      res.push(p);
    });
    return res;
  };

  const checkVisibility = (value) => {
    setPreviewVisible(value);
  };

  const checkIfMobileView = () => {
    return window.outerWidth < 768;
  };

  const leavePage = useCallback(() => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          unsaveChangesModal.close();
          history.push(Path.SETTING);
        },
      });
      return;
    }
    history.push(Path.SETTING);
  }, [dirty, history, unsaveChangesModal]);

  return (
    <>
      <Container className={previewVisible ? "sm:w-full md:w-3/4 md:mr-xl lg:w-4/6" : "w-full"}>
        <HeaderB
          returnText={lang.settings}
          title={lang.receiptFormatting}
          // returnPath={Path.SETTING}
          onClick={leavePage}
          button={
            !previewVisible
              ? {
                  type: StyleType.Link,
                  text: (
                    <div
                      className="flex items-center text-blue cursor-pointer -ml-md"
                      onClick={() => setPreviewVisible(!previewVisible)}
                    >
                      <Text color="text-blue">{lang.viewPreview}</Text>
                      <Icon name={previewVisible ? "eye-close" : "eye-open"} />
                    </div>
                  ),
                }
              : null
          }
          description={lang.customizeReceipt}
        />
        {loading ? (
          <Panel>
            <Skeleton />
          </Panel>
        ) : (
          <Form unsaveChangesModal={unsaveChangesModal} isPrompt={dirty}>
            <ReceiptSettingForm
              fields={fields}
              modifyField={onFieldChange}
              submitForm={() => submitForm(onSubmit)}
              previewVisible={previewVisible}
              onChangeVisibility={checkVisibility}
            />
          </Form>
        )}
      </Container>
      <ReceiptSettingPreview
        loading={loading}
        initialState={getFormValues()}
        venue={venue}
        previewVisible={previewVisible}
        onChangeVisibility={checkVisibility}
      />
    </>
  );
};

export default ReceiptSetting;
