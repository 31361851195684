import { sendToInvoicesApi } from "apis/siigo.api";
import { Button, Icon, Modal, Title, Toast } from "components";
import { StyleType } from "enums";
import React, { useCallback, useState } from "react";
import lang from "translations";

const SendToModal = ({ ids, toDian, setErr, ...props }) => {
  const [loading, setLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    const data = ids.map((i) => ({
      invoiceId: i,
      synced: !toDian,
      syncedDian: toDian,
    }));
    try {
      setLoading(true);
      await sendToInvoicesApi(data);
      Toast({ content: lang.success, success: true, icon: "check" }).open();
    } catch (error) {
      if (error.messages && error.messages.length) {
        setErr(error.messages);
      }
      // Toast({ content: lang.somethingWentWrong, error: true, icon: "exclamation-fill" }).open();
    } finally {
      setLoading(false);
      props.close();
    }
  }, [ids, props, setErr, toDian]);

  return (
    <Modal {...props} noHeader>
      <div className="flex py-lg justify-between">
        <Title>{lang.sendTo}</Title>
        <Icon name="remove" onClick={props.close} />
      </div>
      <div>{lang.areYouSureSentToDian}</div>
      <div className="flex my-md justify-end gap-2 pb-4">
        <Button type={StyleType.Secondary} onClick={props.close}>
          {lang.cancel}
        </Button>
        <Button loading={loading} onClick={onConfirm}>
          {lang.sendTo}
        </Button>
      </div>
    </Modal>
  );
};

export default SendToModal;
